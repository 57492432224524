import axios from "axios";
import {
  productListByCategoryUrl,
  productFindUrl,
  productFetchUrl,
  similarProductListByCategoryUrl,
  latestProductListUrl,
  getProductPriceURL,
  getProductByFiltersURL,
} from "@/services/api";
import { getHeader, authHeader } from "@/_helpers";

export function productListByCategory(values) {
  return axios({
    headers: authHeader(),
    url: productListByCategoryUrl(),
    method: "POST",
    data: values,
  });
}

export function similarProductListByCategory(values) {
  return axios({
    headers: authHeader(),
    url: similarProductListByCategoryUrl(),
    method: "POST",
    data: values,
  });
}

export function latestProductList() {
  return axios({
    headers: authHeader(),
    url: latestProductListUrl(),
    method: "GET",
  });
}

export function productFind(values) {
  return axios({
    url: productFindUrl(),
    method: "POST",
    data: values,
    headers: getHeader(),
  });
}

export function productFetch(values) {
  return axios({
    url: productFetchUrl(),
    method: "POST",
    data: values,
    headers: getHeader(),
  });
}

export function getProductPrice(values) {
  return axios({
    url: getProductPriceURL(),
    method: "POST",
    data: values,
  });
}

export function getProductByFilters(values) {
  return axios({
    url: getProductByFiltersURL(),
    method: "POST",
    data: values,
  });
}

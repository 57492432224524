import config from "config";

export function brandListUrl() {
  return `${config.apiUrl}/brand-list`;
}

export function brandFindUrl(id) {
  return `${config.apiUrl}/brand-details/${id}`;
}

export function brandProductListUrl() {
  return `${config.apiUrl}/product-list-by-brand`;
}

export function brandProductByFilterListUrl() {
  return `${config.apiUrl}/product-list-by-brand-filter`;
}

export function productListByBrandUrl() {
  return `${config.apiUrl}/productListByBrand`;
}

export function brandProductListFiltersUrl() {
  return `${config.apiUrl}/brandProductListFilters`;
}

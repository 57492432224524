import React, { Component } from "react";
import Fade from "react-reveal/Fade";
import Header from "@/Pages/Components/header/Header";
import Footer from "@/Pages/Components/footer/Footer";
import kitbag from "@/assets/images/kitbag.png";

import {
  Col,
  Row,
  Form,
  Button,
  Container,
  Breadcrumb,
  Card,
  Accordion,
} from "react-bootstrap";

import { bindActionCreators } from "redux";
import {
  addToCart,
  removeFromCart,
  decrementProductUnit,
  clearCart,
} from "@/redux/cart/Action";
import { connect } from "react-redux";

import {
  getCountries,
  getStatesByCountry,
  getCitiesByState,
  subGroupsListForRepairs,
  listOfLocationsForSelect,
  saveOrderRepairFromWebsite,
} from "@/services/api_function";
import { pincodeReg, SUPPORTED_FORMATS_IMG, truncateString } from "@/_helpers";

import { authenticationService } from "@/services/api_function";
import { history } from "@/_helpers";
import Notifications, { notify } from "react-notify-toast";
import Select from "react-select";
import { Formik } from "formik";
import * as Yup from "yup";

import GuestLoginOrder from "@/Pages/ShippingAddress/GuestLoginOrder";

class RepaireSportsKit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      lstSubGroup: [],
      countryOpt: [],
      stateOpt: [],
      cityOpt: [],
      locationOpt: [],
      show: true,
    };
  }

  nextStep = () => {
    this.setState({ activeIndex: this.state.activeIndex + 1 });
  };
  handleIndex = (step) => {
    this.setState({ activeIndex: step });
  };

  countriesData = () => {
    getCountries()
      .then((response) => {
        let res = response.data;
        if (res.responseStatus == 200) {
          let country = res.responseObject;
          let countryOpt = country.map(function (values) {
            return { value: values.id, label: values.name };
          });
          this.setState({ countryOpt: countryOpt });
        }
      })
      .catch((error) => {
        console.log({ error });
      });
  };

  statesData = (countryId) => {
    let reqData = new FormData();
    reqData.append("countryId", countryId);
    getStatesByCountry(reqData)
      .then((response) => {
        let res = response.data;
        // console.log({ res });
        if (res.responseStatus == 200) {
          let state = res.responseObject;
          let stateOpt = state.map(function (values) {
            return { value: values.id, label: values.name };
          });
          this.setState({ stateOpt: stateOpt });
        }
      })
      .catch((error) => {
        console.log({ error });
      });
  };

  citiesData = (stateId) => {
    let reqData = new FormData();
    reqData.append("stateId", stateId);
    getCitiesByState(reqData)
      .then((response) => {
        let res = response.data;
        // console.log({ res });
        if (res.responseStatus == 200) {
          let city = res.responseObject;
          let cityOpt = city.map(function (values) {
            return { value: values.id, label: values.name };
          });
          this.setState({ cityOpt: cityOpt });
        }
      })
      .catch((error) => {
        console.log({ error });
      });
  };

  lstSubGroupList = () => {
    subGroupsListForRepairs()
      .then((response) => {
        let res = response.data;
        if (res.responseStatus == 200) {
          let list = res.responseObject;
          this.setState({ lstSubGroup: list });
        }
      })
      .catch((error) => {
        this.setState({ lstSubGroup: [] });
        console.log("error", error);
      });
  };

  lstLocations = () => {
    listOfLocationsForSelect()
      .then((response) => {
        let res = response.data;
        if (res.responseStatus == 200) {
          let list = res.responseObject;
          let location_Opt = list.map(function (values) {
            return { value: values.locationId, label: values.locationName };
          });

          this.setState({ locationOpt: location_Opt });
        }
      })
      .catch((error) => {
        this.setState({ locationOpt: [] });
        console.log("error", error);
      });
  };

  checkUserLoginFun = () => {
    let currentUser = authenticationService.currentUserValue;
    // console.log({ currentUser });
    if (currentUser != null) {
      this.nextStep();
    } else {
      notify.show("Please login first", "error", 2000);
    }
  };

  componentDidMount() {
    this.lstSubGroupList();
    this.lstLocations();
    this.countriesData();
  }

  render() {
    const customStyles = {
      control: (base) => ({
        ...base,
        height: 34,
        minHeight: 34,
        border: "1px solid #ced4da",
        padding: "0 6px",
        boxShadow: "none",
        color: "#a4a4a4",
        fontFamily: "'Nunito Sans', sans-serif",
        fontSize: "13px",
        //lineHeight: "10",
      }),
    };
    const {
      activeIndex,
      lstSubGroup,
      countryOpt,
      stateOpt,
      cityOpt,
      locationOpt,
    } = this.state;
    return (
      <div>
        <Header {...this.props} />
        <div id="" className="repairekitpg">
          <div className="cartbread">
            <Container fluid>
              <Notifications options={{ zIndex: 9999, top: "50px" }} />
              <Breadcrumb className="profile-b-crumb rgst-breadcrumb pt-0">
                <Breadcrumb.Item
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.history.push(`/`);
                  }}
                  style={{ color: "#1776a4" }}
                >
                  Home
                </Breadcrumb.Item>

                <Breadcrumb.Item active>Repaire Sports Kit</Breadcrumb.Item>
              </Breadcrumb>
            </Container>
          </div>

          <Container fluid>
            <div className="existingkit">
              <h4>Repaire your existing Sports Kit</h4>
            </div>
            <Row>
              <Col lg="6" md="8">
                <div className="cart-item-div">
                  <div className="pr-3">
                    <Accordion
                      defaultActiveKey={activeIndex.toString()}
                      activeKey={activeIndex.toString()}
                      className="shipping-add"
                    >
                      <Card className="mb-3">
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey="0"
                          className="p-2 mb-0"
                          onClick={(e) => {
                            e.preventDefault();
                            this.handleIndex(0);
                          }}
                        >
                          <span className="pull-left">
                            You have to continue as Guest or Login
                          </span>
                          <span className="faqplus pull-right">+</span>
                        </Accordion.Toggle>
                        <Accordion.Collapse show eventKey="0" className="">
                          <Card.Body className="p-3">
                            <GuestLoginOrder />
                            {authenticationService.currentUserValue != null && (
                              <Button
                                className="pull-right continuebtn"
                                onClick={(e) => {
                                  e.preventDefault();
                                  // this.checkUserLoginFun();
                                  this.nextStep();
                                }}
                              >
                                CONTINUE
                              </Button>
                            )}
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>

                      {authenticationService.currentUserValue != null && (
                        <Formik
                          validateOnChange={false}
                          validateOnBlur={false}
                          initialValues={{
                            subGroupId: "",
                            brandName: "",
                            reason: "",
                            orderFrom: "0",
                            repairImage: "",
                            cityId: "",
                            stateId: "",
                            countryId: "",
                            pincode: "",
                            locationId: "",
                          }}
                          validationSchema={Yup.object().shape({
                            subGroupId: Yup.string()
                              .trim()
                              .required("Select Sub Group"),
                            brandName: Yup.string()
                              .trim()
                              .required("Brand Name is required"),
                            reason: Yup.string()
                              .trim()
                              .required("Reason is required"),
                            cityId: Yup.object().required("City is required"),
                            stateId: Yup.object().required("State is required"),
                            countryId: Yup.object().required(
                              "Country is required"
                            ),
                            locationId: Yup.object().required(
                              "Location is required"
                            ),
                            pincode: Yup.string()
                              .trim()
                              .matches(pincodeReg, "Pincode invalid")
                              .required("Pincode is required"),
                            repairImage: Yup.lazy((v) => {
                              if (v != undefined) {
                                return Yup.mixed()
                                  .test(
                                    "fileType",
                                    "Upload JPG,JPEG,PNG with MAX. 5MB sizes",
                                    (value) =>
                                      SUPPORTED_FORMATS_IMG.includes(value.type)
                                  )
                                  .test(
                                    "fileSize",
                                    "File size should be less than 5MB",
                                    (v) => {
                                      const size = 1024 * 1024 * 5;
                                      return v && v.size <= size;
                                    }
                                  );
                              }
                              return Yup.mixed().notRequired("Upload image");
                            }),
                          })}
                          onSubmit={(values, { setSubmitting, resetForm }) => {
                            if (
                              authenticationService.currentUserValue == null
                            ) {
                              notify.show(
                                "Please login as guest or login ",
                                "error",
                                2000
                              );
                            } else {
                              const requestData = new FormData();
                              requestData.append(
                                "subGroupId",
                                values.subGroupId
                              );
                              requestData.append("brandName", values.brandName);
                              requestData.append("reason", values.reason);
                              requestData.append("pincode", values.pincode);
                              requestData.append(
                                "countryId",
                                values.countryId.value
                              );
                              requestData.append(
                                "stateId",
                                values.stateId.value
                              );
                              requestData.append("cityId", values.cityId.value);
                              requestData.append(
                                "locationId",
                                values.locationId.value
                              );
                              requestData.append(
                                "repairImage",
                                values.repairImage
                              );
                              requestData.append("orderFrom", 0);

                              saveOrderRepairFromWebsite(requestData)
                                .then((response) => {
                                  console.log({ response });
                                  let res = response.data;
                                  if (res.responseStatus == 200) {
                                    notify.show(res.message, "success", 2000);
                                    if (
                                      authenticationService.currentUserValue
                                        .isGuest == true
                                    ) {
                                      setTimeout(() => {
                                        this.props.history.push("/");
                                        authenticationService.logout();
                                      }, 1500);
                                    } else {
                                      setTimeout(() => {
                                        this.props.history.push("/");
                                      }, 1500);
                                    }
                                  } else {
                                    notify.show(res.message, "error", 2000);
                                    // resetForm();
                                  }
                                })
                                .catch((error) => console.log({ error }));
                            }
                          }}
                        >
                          {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            resetForm,
                            setFieldValue,
                          }) => (
                            <Form onSubmit={handleSubmit} className="">
                              <Card className="mb-3">
                                <Accordion.Toggle
                                  as={Card.Header}
                                  eventKey="1"
                                  className="p-2 mb-0"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.handleIndex(1);
                                  }}
                                >
                                  <span class="pull-left">
                                    Select What do you want to repaire? &nbsp;
                                    <span className="text-danger">
                                      {errors.subGroupId && "Select any one"}
                                    </span>
                                  </span>
                                  <span class="pull-right faqplus">+</span>
                                </Accordion.Toggle>
                                <Accordion.Collapse className="" eventKey="1">
                                  <Card.Body className="p-3">
                                    <Row>
                                      {lstSubGroup.length > 0 &&
                                        lstSubGroup.map((v, i) => {
                                          return (
                                            <Col md="3">
                                              <label className="card radiocard">
                                                <input
                                                  className="radio repairkitradio"
                                                  type="radio"
                                                  name="subGroupId"
                                                  id="subGroupId"
                                                  value={v.subGroupId}
                                                  onChange={(e) => {
                                                    // e.preventDefault();
                                                    setFieldValue(
                                                      "subGroupId",
                                                      e.target.value
                                                    );
                                                  }}
                                                />

                                                <span className="plan-details repair-p-det">
                                                  <span className="plan-type">
                                                    <img
                                                      style={{ width: "100px" }}
                                                      src={v.subGroupImage}
                                                      alt={v.subGroupName}
                                                    />
                                                    <h6>{v.subGroupName}</h6>
                                                  </span>
                                                </span>
                                              </label>
                                            </Col>
                                          );
                                        })}
                                    </Row>
                                    {values.subGroupId != "" &&
                                      values.subGroupId != null && (
                                        <Button
                                          className="continuebtn continuebtn1 pull-right"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.nextStep();
                                          }}
                                        >
                                          CONTINUE
                                        </Button>
                                      )}
                                  </Card.Body>
                                </Accordion.Collapse>
                              </Card>

                              {values.subGroupId != "" &&
                                values.subGroupId != null && (
                                  <Card className="mb-3">
                                    <Accordion.Toggle
                                      as={Card.Header}
                                      eventKey="2"
                                      className="p-2 mb-0"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.handleIndex(2);
                                      }}
                                    >
                                      <span class="pull-left">
                                        Your Kit Information
                                      </span>
                                      <span class="pull-right faqplus">+</span>
                                    </Accordion.Toggle>{" "}
                                    <Accordion.Collapse
                                      className=""
                                      eventKey="2"
                                    >
                                      <Card.Body className="p-3">
                                        <div className=" repairekitform">
                                          <Row>
                                            <Col md="12">
                                              <Form.Group>
                                                <Form.Label>
                                                  Brand Name
                                                </Form.Label>
                                                <Form.Control
                                                  type="text"
                                                  name="brandName"
                                                  id="brandName"
                                                  onChange={handleChange}
                                                  value={values.brandName}
                                                  isValid={
                                                    touched.brandName &&
                                                    !errors.brandName
                                                  }
                                                  isInvalid={!!errors.brandName}
                                                />
                                              </Form.Group>
                                              <Form.Control.Feedback type="invalid">
                                                {errors.brandName}
                                              </Form.Control.Feedback>
                                            </Col>
                                            <Col md="12">
                                              <Form.Group>
                                                <Form.Label>
                                                  Reason of Repairing
                                                </Form.Label>
                                                <Form.Control
                                                  as="textarea"
                                                  rows={3}
                                                  name="reason"
                                                  id="reason"
                                                  onChange={handleChange}
                                                  value={values.reason}
                                                  isValid={
                                                    touched.reason &&
                                                    !errors.reason
                                                  }
                                                  isInvalid={!!errors.reason}
                                                />
                                              </Form.Group>
                                              <Form.Control.Feedback type="invalid">
                                                {errors.reason}
                                              </Form.Control.Feedback>
                                            </Col>
                                            <Col md="6">
                                              <Form.Group>
                                                <Form.Label>
                                                  Country
                                                  <span className="redstar">
                                                    *
                                                  </span>
                                                </Form.Label>
                                                <Select
                                                  isClearable={true}
                                                  onChange={(v) => {
                                                    setFieldValue(
                                                      "countryId",
                                                      v
                                                    );
                                                    setFieldValue(
                                                      "stateId",
                                                      ""
                                                    );
                                                    setFieldValue("cityId", "");

                                                    if (v != null) {
                                                      this.statesData(v.value);
                                                    } else {
                                                      this.setState({
                                                        stateOpt: [],
                                                        cityOpt: [],
                                                      });
                                                    }
                                                  }}
                                                  name="countryId"
                                                  options={countryOpt}
                                                  value={values.countryId}
                                                  styles={customStyles}
                                                  //width="200px"
                                                />
                                                <span className="text-danger">
                                                  {errors.countryId &&
                                                    "Country is required"}
                                                </span>
                                              </Form.Group>
                                            </Col>
                                            <Col md="6">
                                              <Form.Group>
                                                <Form.Label>State</Form.Label>
                                                <Select
                                                  isClearable={true}
                                                  styles={customStyles}
                                                  onChange={(v) => {
                                                    setFieldValue("stateId", v);
                                                    setFieldValue("cityId", "");
                                                    if (v != null) {
                                                      this.citiesData(v.value);
                                                    } else {
                                                      this.setState({
                                                        cityOpt: [],
                                                      });
                                                    }
                                                  }}
                                                  className="clsselect2"
                                                  name="stateId"
                                                  options={stateOpt}
                                                  value={values.stateId}
                                                  width="200px"
                                                />
                                                <span className="text-danger">
                                                  {errors.stateId}
                                                </span>
                                              </Form.Group>
                                            </Col>
                                            <Col md="6">
                                              <Form.Group>
                                                <Form.Label>City</Form.Label>
                                                <Select
                                                  isClearable={true}
                                                  styles={customStyles}
                                                  onChange={(v) => {
                                                    setFieldValue("cityId", v);
                                                  }}
                                                  className="clsselect2"
                                                  name="cityId"
                                                  options={cityOpt}
                                                  value={values.cityId}
                                                  width="200px"
                                                />
                                                <span className="text-danger">
                                                  {errors.cityId}
                                                </span>
                                              </Form.Group>
                                            </Col>
                                            <Col md="6">
                                              <Form.Group>
                                                <Form.Label>Pincode</Form.Label>
                                                <Form.Control
                                                  type="text"
                                                  name="pincode"
                                                  id="pincode"
                                                  onChange={handleChange}
                                                  value={values.pincode}
                                                  isValid={
                                                    touched.pincode &&
                                                    !errors.pincode
                                                  }
                                                  isInvalid={!!errors.pincode}
                                                  maxLength={6}
                                                />
                                                <span className="text-danger">
                                                  {errors.pincode}
                                                </span>
                                              </Form.Group>
                                            </Col>
                                            <Col md="6">
                                              {/* <Form.Group className="uploadkit">
                                                <Form.Label>
                                                  Upload Photo
                                                </Form.Label>
                                                <Form.File
                                                  label={`${
                                                    values.repairImage != ""
                                                      ? truncateString(
                                                          values.repairImage
                                                            .name,
                                                          20
                                                        )
                                                      : "Browse image"
                                                  }`}
                                                  id="repairImage"
                                                  name="repairImage"
                                                  className="kit-upload2"
                                                  onChange={(e) => {
                                                    setFieldValue(
                                                      "repairImage",
                                                      e.target.files[0]
                                                    );
                                                  }}
                                                />
                                                <span className="text-danger">
                                                  {errors.repairImage}
                                                </span>
                                              </Form.Group> */}

                                              <Form.Group
                                                controlId="formFile"
                                                className="mb-3"
                                              >
                                                <Form.Label>
                                                  Upload Photo
                                                </Form.Label>
                                                <Form.Control
                                                  type="file"
                                                  id="repairImage"
                                                  name="repairImage"
                                                  className="r-kit-upload"
                                                  onChange={(e) => {
                                                    setFieldValue(
                                                      "repairImage",
                                                      e.target.files[0]
                                                    );
                                                  }}
                                                />
                                                <span className="text-danger">
                                                  {errors.repairImage}
                                                </span>
                                              </Form.Group>
                                            </Col>
                                            <Col md="6">
                                              <Form.Group>
                                                <Form.Label>
                                                  Select Nearest Ubhe Sports
                                                  Store
                                                </Form.Label>
                                                <Select
                                                  isClearable={true}
                                                  styles={customStyles}
                                                  name="locationId"
                                                  id="locationId"
                                                  className="clsselect2"
                                                  onChange={(v) => {
                                                    setFieldValue(
                                                      "locationId",
                                                      v
                                                    );
                                                  }}
                                                  options={locationOpt}
                                                  value={values.locationId}
                                                  //width="200px"
                                                />
                                                <span className="text-danger">
                                                  {errors.locationId}
                                                </span>
                                              </Form.Group>
                                            </Col>
                                          </Row>
                                        </div>
                                        {/* <Button
                                    className="rainbow d-btn"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.handleCheckoutSubmit();
                                    }}
                                  >
                                    Submit
                                  </Button> */}
                                        <Button
                                          variant="primary"
                                          type="submit"
                                          disabled={isSubmitting}
                                          className="new-u-btn rainbow rainbow-1 loginbtn"
                                        >
                                          {isSubmitting && <>Saving...</>}
                                          Submit
                                        </Button>
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Card>
                                )}
                            </Form>
                          )}
                        </Formik>
                      )}
                    </Accordion>
                  </div>
                </div>
              </Col>
              <Col lg="6">
                <div className="kitbag">
                  <img src={kitbag} alt="Repair Kit" />
                  <div className="kitflow">
                    <h2>Repair Sports Kit Instructions</h2>
                    <ul>
                      <li>
                        <i className="fa fa-caret-right"></i> &nbsp;Login
                      </li>
                      <li>
                        <i className="fa fa-caret-right"></i> &nbsp;Select the
                        product which you want to repair
                      </li>
                      <li>
                        <i className="fa fa-caret-right"></i> &nbsp;Feel the
                        form of repaire kit
                      </li>
                      <li>
                        <i className="fa fa-caret-right"></i> &nbsp;Upload
                        repair kit photo. Photo size should be in 500 KB
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer {...this.props} />
      </div>
    );
  }
}

const mapStateToProps = ({ cartReducer }) => {
  return { cartReducer };
};

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      addToCart,
      removeFromCart,
      decrementProductUnit,
      clearCart,
    },
    dispatch
  );
};
// export default RepaireSportsKit;

export default connect(mapStateToProps, mapActionsToProps)(RepaireSportsKit);

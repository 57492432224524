import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Breadcrumb,
  Accordion,
  Card,
  Form,
  Button,
  Modal,
  Table,
} from "react-bootstrap";
import moment from "moment";
import { Layout } from "@/Pages/Layout";
import { bindActionCreators } from "redux";
import {
  addToCart,
  removeFromCart,
  decrementProductUnit,
  clearCart,
  applyWalletBalance,
  applyVoucherCode,
} from "@/redux/cart/Action";
import { connect } from "react-redux";
import GuestLogin from "./GuestLogin";
import AddressForm from "../Components/MyCartComponent1/AddressForm";

import {
  getUserAddresses,
  saveOrder,
  authenticationService,
  deleteAddress,
  getCountries,
  getStatesByCountry,
  getCitiesByState,
  findAddress,
  updateAddress,
  getUserWallet,
  listOfGiftVoucherForWeb,
} from "@/services/api_function";
import Notifications, { notify } from "react-notify-toast";
import { Formik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { printSimilarProductName } from "@/_helpers";

const customStyles = {
  control: (base) => ({
    ...base,
    height: 33,
    minHeight: 33,
    // border: "none",
    // outline: "none",
    boxShadow: "none",
    padding: "0",
  }),
};

class ShippingAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      billingAddress: [],
      selectedBillingAddress: "",
      isNewAddress: false,
      selectedPaymentMode: "",
      addressId: "",
      deleteAddressModal: false,
      addressObject: "",
      editAddressModal: false,
      countryOpt: [],
      stateOpt: [],
      cityOpt: [],
      useWallet: false,
      userWalletBalance: 0,
      enteredWalletAmount: "",
      voucherList: 0,
      voucherListModal: false,
      selectedVoucher: "",
      netAmount: 0,
    };
  }
  handleChange = (status) => {
    let { enteredWalletAmount, selectedPaymentMode } = this.state;
    let wAmt = enteredWalletAmount;
    let paymode = selectedPaymentMode;
    if (!status) {
      wAmt = "";
      paymode = "";
    }
    this.setState({
      useWallet: status,
      enteredWalletAmount: wAmt,
      selectedPaymentMode: paymode,
    });
  };

  getValue = (opts, val) => {
    return opts.find((o) => o.value === val);
  };
  nextStep = () => {
    this.setState({ activeIndex: this.state.activeIndex + 1 });
  };
  handleIndex = (step) => {
    this.setState({ activeIndex: step });
  };

  getUserAddressesFun = () => {
    getUserAddresses()
      .then((response) => {
        let res = response.data;
        // console.log("res  ", res);
        if (res.responseStatus == 200) {
          // console.log("res.responseObject ", typeof res.responseObject);
          this.setState({
            billingAddress: res.responseObject,
            isNewAddress: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ billingAddress: "" });
        console.log("error", error);
      });
    // console.log("fun called");
  };

  listOfGiftVoucherForWebFun = () => {
    listOfGiftVoucherForWeb()
      .then((response) => {
        let res = response.data;
        if (res.responseStatus == 200) {
          this.setState({
            voucherList: res.response,
          });
        }
      })
      .catch((error) => {
        this.setState({ billingAddress: "" });
        console.log("error", error);
      });
    // console.log("fun called");
  };
  handleSelectedBillingAddress = (id) => {
    this.setState({ selectedBillingAddress: id });
  };
  handleNewAddressStatus = (status) => {
    this.setState({ isNewAddress: status });
  };
  handlePaymentMode = (status) => {
    this.setState({ selectedPaymentMode: status });
  };
  componentDidMount() {
    console.log(
      "authenticationService.currentUserValue ",
      authenticationService.currentUserValue
    );
    if (authenticationService.currentUserValue !== null) {
      this.getUserAddressesFun();
      this.listOfGiftVoucherForWebFun();
      this.getUserWalletData();
    }
    this.countriesData();

    let cartReducer = this.props.cartReducer;
    if (cartReducer != null && cartReducer.cart != null) {
      let grandTotalMrp = cartReducer.grandTotalMrp;
      this.setState({ netAmount: grandTotalMrp });
    }
  }
  deleteAddtessModalFun = () => {
    let requestData = new FormData();
    requestData.append("id", this.state.addressId);
    deleteAddress(requestData)
      .then((response) => {
        let res = response.data;
        if (res.responseStatus == 200) {
          notify.show(res.message, "success", 2000);
          this.setState({ deleteAddressModal: false }, () => {
            this.getUserAddressesFun();
          });
        } else {
          notify.show(res.message, "error", 2000);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  getUserWalletData = () => {
    let reqData = new FormData();
    reqData.append("userId", authenticationService.currentUserValue.userId);
    getUserWallet(reqData)
      .then((response) => {
        let res = response.data;
        console.log("responser wallet", res);
        if (res.responseStatus == 200) {
          this.setState({
            userWalletBalance: res.response,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  handleCheckoutSubmit = () => {
    const {
      selectedBillingAddress,
      selectedPaymentMode,
      enteredWalletAmount,
      selectedVoucher,
      voucherAmount,
      netAmount,
    } = this.state;
    if (authenticationService.currentUserValue == null) {
      notify.show("Please login as guest or login ", "error", 2000);
    } else if (
      selectedBillingAddress != "" &&
      selectedPaymentMode != "" &&
      authenticationService.currentUserValue != null
    ) {
      const requestData = new FormData();
      const serializedState = localStorage.getItem("state");
      let cartProducts = "";
      let grandTotalDiscount = 0;
      let grandTotalMrp = 0;
      let grandTotalPrice = 0;
      let cartReducer = this.props.cartReducer;
      if (cartReducer != null && cartReducer.cart != null) {
        // console.log("state", JSON.parse(serializedState));
        cartProducts = JSON.stringify(cartReducer.cart);
        grandTotalDiscount = cartReducer.grandTotalDiscount;
        grandTotalMrp = cartReducer.grandTotalMrp;
        grandTotalPrice = cartReducer.grandTotalPrice;
      }
      requestData.append("cartProducts", cartProducts);
      requestData.append("grandTotalDiscount", grandTotalDiscount);
      requestData.append("grandTotalMrp", grandTotalMrp);
      requestData.append("grandTotalPrice", grandTotalPrice);

      requestData.append("deliveryAddressId", selectedBillingAddress);
      requestData.append("paymentMode", selectedPaymentMode);
      requestData.append("paymentStatus", "completed");
      requestData.append("orderFrom", 1);
      requestData.append("walletAmount", enteredWalletAmount);
      requestData.append(
        "voucherId",
        selectedVoucher != "" ? selectedVoucher.id : ""
      );
      for (var pair of requestData.entries()) {
        console.log(pair[0] + "====>????-->" + pair[1]);
      }
      saveOrder(requestData)
        .then((response) => {
          let res = response.data;
          if (res.responseStatus == 200) {
            notify.show(res.message, "success", 2000);
            this.props.clearCart();
            if (authenticationService.currentUserValue.isGuest == true) {
              setTimeout(() => {
                authenticationService.logout();
                this.props.history.push("/");
              }, 1500);
            } else {
              setTimeout(() => {
                this.props.history.push("/");
              }, 1500);
            }
          } else {
            notify.show(res.message, "error", 2000);
            // resetForm();
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    } else {
      notify.show(
        "Please select the shipping address & payment mode",
        "error",
        2000
      );
    }
  };
  countriesData = () => {
    getCountries()
      .then((response) => {
        let res = response.data;
        if (res.responseStatus == 200) {
          let country = res.responseObject;
          let countryOpt = country.map(function (values) {
            return { value: values.id, label: values.name };
          });
          this.setState({ countryOpt: countryOpt });
        }
      })
      .catch((error) => {
        console.log({ error });
      });
  };
  statesData = (countryId, status, values = null) => {
    let reqData = new FormData();
    reqData.append("countryId", countryId);
    getStatesByCountry(reqData)
      .then((response) => {
        let res = response.data;
        if (res.responseStatus == 200) {
          let state = res.responseObject;
          let stateOpts = state.map(function (values) {
            return { value: values.id, label: values.name };
          });
          this.setState({ stateOpt: stateOpts });

          if (status == true && values != null) {
            if (this.state.stateOpt && typeof values.state.id === "number") {
              values.stateId = this.getValue(
                this.state.stateOpt,
                values.state.id
              );

              // console.log({ values });
              this.setState({ addressObject: values });
              this.citiesData(values.state.id, true, values);
            }
          }
        }
      })
      .catch((error) => {
        console.log({ error });
      });
  };
  citiesData = (stateId, status, values = null) => {
    let reqData = new FormData();
    reqData.append("stateId", stateId);
    getCitiesByState(reqData)
      .then((response) => {
        let res = response.data;
        // console.log({ res });
        if (res.responseStatus == 200) {
          let city = res.responseObject;
          let cityOpts = city.map(function (values) {
            return { value: values.id, label: values.name };
          });
          this.setState({ cityOpt: cityOpts });

          if (status == true && values != null) {
            if (this.state.cityOpt && typeof values.city.id === "number") {
              values.cityId = this.getValue(this.state.cityOpt, values.city.id);

              this.setState({
                addressObject: values,
                editAddressModal: true,
              });
            }
          }
        }
      })
      .catch((error) => {
        console.log({ error });
      });
  };
  viewEditAddressModal = (status, id = null) => {
    if (status) {
      let reqData = new FormData();
      reqData.append("id", id);
      findAddress(reqData)
        .then((response) => {
          if (response.data.responseStatus == 200) {
            let values = response.data.responseObject;
            if (
              this.state.countryOpt &&
              typeof values.country.id === "number"
            ) {
              values.countryId = this.getValue(
                this.state.countryOpt,
                values.country.id
              );

              this.setState({ addressObject: values });
              this.statesData(values.country.id, true, values);
            }
          }
        })
        .catch((error) => {
          console.log({ error });
        });
    }
  };
  handleEnteredWalletAMount = (walletAmount = 0) => {
    const { userWalletBalance, netAmount, selectedPaymentMode } = this.state;
    if (parseFloat(walletAmount) > parseFloat(userWalletBalance)) {
      notify.show("Please enter valid wallet amount", "error", 2000);
      this.setState({ enteredWalletAmount: "" });
    } else {
      if (parseFloat(walletAmount) > parseFloat(netAmount)) {
        notify.show("Please enter valid wallet amount", "error", 2000);
        this.setState({ enteredWalletAmount: "" });
      } else {
        let payMode = selectedPaymentMode;
        if (parseFloat(walletAmount) === parseFloat(netAmount))
          payMode = "other";
        else payMode = "";
        this.setState({
          enteredWalletAmount: walletAmount,
          selectedPaymentMode: payMode,
        });
      }
    }
  };

  handleSelectedVoucher = (v = "", action = false) => {
    let { netAmount, selectedPaymentMode } = this.state;
    let totalMrp =
      this.props.cartReducer !== null
        ? this.props.cartReducer.grandTotalMrp
        : 0;
    let voucherAmt = 0;

    if (action === true) {
      if (v.discountType === "per") {
        voucherAmt = (parseFloat(totalMrp) / 100) * parseFloat(v.discountPer);
      } else {
        voucherAmt = parseFloat(v.discountAmt);
      }
    }
    console.log("voucherAmt ", voucherAmt);
    netAmount = totalMrp > 0 ? totalMrp - voucherAmt : 0;
    netAmount = netAmount < 0 ? 0 : netAmount;
    console.log({ netAmount });

    let payMode = selectedPaymentMode;
    if (netAmount === 0) payMode = "other";
    else payMode = "";

    this.setState({
      selectedVoucher: v,
      netAmount: netAmount,
      voucherAmount: voucherAmt,
      voucherListModal: false,
      selectedPaymentMode: payMode,
    });
  };

  render() {
    const {
      activeIndex,
      billingAddress,
      selectedBillingAddress,
      isNewAddress,
      deleteAddressModal,
      editAddressModal,
      addressObject,
      countryOpt,
      stateOpt,
      cityOpt,
      selectedPaymentMode,
      useWallet,
      userWalletBalance,
      voucherList,
      voucherListModal,
      selectedVoucher,
      enteredWalletAmount,
    } = this.state;
    return (
      <Layout {...this.props}>
        <div
          id="productlist"
          // style={{
          //   background: "linear-gradient(#dce6f2, #fff, #fff, #fff, #fff)",
          // }}
          className=""
        >
          <Notifications options={{ zIndex: 9999, top: "50px" }} />
          <div className="mycartproduct">
            <div className="cartbread">
              <Container fluid>
                <Breadcrumb className="profile-b-crumb rgst-breadcrumb mb-3 pt-0">
                  <Breadcrumb.Item
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.history.push(`/`);
                    }}
                    style={{ color: "#1776a4" }}
                  >
                    Home
                  </Breadcrumb.Item>

                  <Breadcrumb.Item active>Order Checkout</Breadcrumb.Item>
                </Breadcrumb>
              </Container>
            </div>
            <Container fluid>
              <Row>
                <Col lg="8" md="8">
                  <div className="cart-item-div">
                    <div className="pr-3">
                      <h4>Order Checkout</h4>
                      <Accordion
                        defaultActiveKey={activeIndex.toString()}
                        activeKey={activeIndex.toString()}
                        className="shipping-add"
                      >
                        <Card className="mb-3">
                          <Accordion.Toggle
                            as={Card.Header}
                            eventKey="0"
                            className="p-2 mb-0"
                            onClick={(e) => {
                              e.preventDefault();
                              this.handleIndex(0);
                            }}
                          >
                            <span className="pull-left">
                              Continue as Guest or Login
                            </span>
                            <span className="faqplus pull-right">+</span>
                          </Accordion.Toggle>
                          <Accordion.Collapse show eventKey="0" className="">
                            <Card.Body className="p-3">
                              <GuestLogin />
                              {authenticationService.currentUserValue !=
                                null && (
                                <Button
                                  className="pull-right continuebtn"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.nextStep();
                                  }}
                                >
                                  CONTINUE
                                </Button>
                              )}
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                        <Card className="mb-3">
                          <Accordion.Toggle
                            as={Card.Header}
                            eventKey="1"
                            className="p-2 mb-0"
                            onClick={(e) => {
                              e.preventDefault();
                              this.handleIndex(1);
                            }}
                          >
                            <span class="pull-left">Shipping Address</span>
                            <span class="pull-right faqplus">+</span>
                          </Accordion.Toggle>
                          <Accordion.Collapse className="" eventKey="1">
                            <Card.Body className="p-3">
                              {billingAddress.length > 0 && (
                                <a
                                  className="addnew-adres"
                                  href="javascript:void(0)"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.handleNewAddressStatus(!isNewAddress);
                                  }}
                                >
                                  {billingAddress.length > 0 &&
                                  isNewAddress == true ? (
                                    <>
                                      <i class="fa fa-close shippingcloseclass"></i>
                                    </>
                                  ) : (
                                    <>
                                      <span>+</span> Add New Address
                                    </>
                                  )}
                                </a>
                              )}
                              {(billingAddress.length == 0 ||
                                isNewAddress == true) && (
                                <AddressForm
                                  getUserAddressesFun={this.getUserAddressesFun.bind(
                                    this
                                  )}
                                  isNewAddress={isNewAddress}
                                  handleNewAddressStatus={this.handleNewAddressStatus.bind(
                                    this
                                  )}
                                />
                              )}
                              <Row>
                                {billingAddress.length > 0 &&
                                  billingAddress.map((v, i) => {
                                    return (
                                      <Col md="6">
                                        <div className="mt-2">
                                          <label className="card radiocard radiocard1   111">
                                            <input
                                              name="deliveryAddressId"
                                              className="radio"
                                              type="radio"
                                              checked={
                                                selectedBillingAddress == v.id
                                                  ? true
                                                  : false
                                              }
                                              onChange={(e) => {
                                                // setFieldValue(
                                                //   "deliveryAddressId",
                                                //   e.target.value
                                                // );
                                                this.handleSelectedBillingAddress(
                                                  v.id
                                                );
                                              }}
                                            />

                                            <span className="plan-details p-2">
                                              <span className="plan-type">
                                                <Card.Title>
                                                  <h6>
                                                    {" "}
                                                    {v.addressLine +
                                                      " " +
                                                      v.streetAddress}
                                                    &nbsp;&nbsp;
                                                    <a
                                                      href="javascript:void(0)"
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        this.viewEditAddressModal(
                                                          true,
                                                          v.id
                                                        );
                                                      }}
                                                    >
                                                      <i class="fa fa-pencil"></i>
                                                    </a>
                                                  </h6>
                                                  <div className="">
                                                    {/* <a href="javascript:void(0)">
                                                <i className="fa fa-edit"></i>
                                              </a> */}

                                                    <h5>
                                                      {v.city.name +
                                                        "-" +
                                                        v.pincode}
                                                      <a
                                                        href="javascript:void(0)"
                                                        onClick={(e) => {
                                                          e.preventDefault();
                                                          this.setState({
                                                            addressId: v.id,
                                                            deleteAddressModal: true,
                                                          });
                                                        }}
                                                        className="pull-right mr-2 pr-1"
                                                      >
                                                        <i class="fa fa-trash"></i>
                                                      </a>
                                                    </h5>
                                                  </div>
                                                </Card.Title>
                                              </span>
                                            </span>
                                          </label>
                                        </div>
                                      </Col>
                                    );
                                  })}
                              </Row>
                              <Button
                                className="continuebtn continuebtn1 pull-right"
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (
                                    authenticationService.currentUserValue !=
                                      null &&
                                    (voucherList.length > 9 ||
                                      userWalletBalance > 0)
                                  ) {
                                    this.nextStep();
                                  } else {
                                    this.handleIndex(3);
                                  }
                                }}
                              >
                                CONTINUE
                              </Button>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>

                        {authenticationService.currentUserValue != null &&
                          (voucherList.length > 9 || userWalletBalance > 0) && (
                            <Card className="mb-3">
                              <Accordion.Toggle
                                as={Card.Header}
                                eventKey="2"
                                className="p-2 mb-0"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.handleIndex(2);
                                }}
                              >
                                <span class="pull-left">Wallet & Voucher</span>
                                <span class="pull-right faqplus">+</span>
                              </Accordion.Toggle>{" "}
                              <Accordion.Collapse className="" eventKey="2">
                                <Card.Body className="p-3">
                                  <Form className="shipping-add-form">
                                    {voucherList.length > 0 && (
                                      <Row>
                                        <Col md="6">
                                          <span className="addnew-adres">
                                            Voucher List
                                          </span>
                                        </Col>
                                      </Row>
                                    )}
                                    <Row>
                                      {voucherList.length > 0 &&
                                        voucherList.map((v, i) => {
                                          return (
                                            <Col md="4">
                                              <div className="mt-2">
                                                <label
                                                  className={`${
                                                    v.voucherStatus ===
                                                    "REDEEMED"
                                                      ? "card radiocard radiocard1 voucher-redeemed-border"
                                                      : v.voucherStatus ===
                                                        "EXPIRED"
                                                      ? "card radiocard radiocard1 voucher-expired-border"
                                                      : "card radiocard radiocard1 voucher-pending-border"
                                                  }`}
                                                >
                                                  <input
                                                    name="voucherId"
                                                    className="radio"
                                                    type="checkbox"
                                                    disabled={
                                                      v.voucherStatus ===
                                                      "PENDING"
                                                        ? false
                                                        : true
                                                    }
                                                    onChange={(e) => {
                                                      if (
                                                        e.target.checked ===
                                                        true
                                                      )
                                                        this.handleSelectedVoucher(
                                                          v,
                                                          true
                                                        );
                                                      else
                                                        this.handleSelectedVoucher(
                                                          "",
                                                          false
                                                        );
                                                    }}
                                                    style={{
                                                      height: "26px",
                                                      width: "26px",
                                                      display:
                                                        v.voucherStatus ===
                                                        "PENDING"
                                                          ? ""
                                                          : "none",
                                                    }}
                                                  />

                                                  <span className="plan-details p-2">
                                                    <span className="plan-type">
                                                      <Card.Title>
                                                        <h6>
                                                          {v.voucherStatus}
                                                        </h6>
                                                        <h6>
                                                          {v.voucherStatus ===
                                                          "EXPIRED" ? (
                                                            <s>
                                                              {v.giftName}
                                                              <br />
                                                              {v.voucherCode}
                                                            </s>
                                                          ) : (
                                                            <>
                                                              {v.giftName}
                                                              <br />
                                                              {v.voucherCode}
                                                            </>
                                                          )}
                                                          &nbsp;&nbsp;
                                                        </h6>
                                                        <div className="">
                                                          <h6>
                                                            DIS.: &nbsp;
                                                            {v.discountType ===
                                                            "amt" ? (
                                                              <>
                                                                <i className="fa fa-inr"></i>
                                                                {v.discountAmt}{" "}
                                                              </>
                                                            ) : (
                                                              v.discountPer +
                                                              "%"
                                                            )}
                                                            <br />
                                                            EXP. ON:{" "}
                                                            {moment(
                                                              v.expiryDate
                                                            ).format(
                                                              "ddd D MMM YYYY HH:mm"
                                                            )}
                                                          </h6>
                                                        </div>
                                                      </Card.Title>
                                                    </span>
                                                  </span>
                                                </label>
                                              </div>
                                            </Col>
                                          );
                                        })}
                                    </Row>

                                    {userWalletBalance > 0 && (
                                      <>
                                        <Row>
                                          <Col md="6">
                                            Available Balance &nbsp;
                                            <i className="fa fa-inr"></i>{" "}
                                            {parseFloat(
                                              userWalletBalance
                                            ).toFixed(2)}
                                            /-
                                            <Form.Group
                                              style={{
                                                margin: 0,
                                                display:
                                                  useWallet == true
                                                    ? "-webkit-inline-box"
                                                    : "block",
                                              }}
                                            >
                                              <label>
                                                <input
                                                  type="checkbox"
                                                  id="useWallet"
                                                  name="useWallet"
                                                  checked={useWallet}
                                                  onClick={(e) => {
                                                    this.handleChange(
                                                      e.target.checked
                                                    );
                                                  }}
                                                  value={useWallet}
                                                />
                                                <span className="addnew-adres">
                                                  Use Wallet{" "}
                                                </span>
                                              </label>
                                              {useWallet == true && (
                                                <Form.Control
                                                  type="text"
                                                  name="walletAmount"
                                                  id="walletAmount"
                                                  placeholder="wallet amount"
                                                  onChange={(e) => {
                                                    this.handleEnteredWalletAMount(
                                                      e.target.value
                                                    );
                                                  }}
                                                  value={enteredWalletAmount}
                                                  style={{ marginLeft: "10px" }}
                                                />
                                              )}
                                            </Form.Group>
                                          </Col>
                                        </Row>
                                        {/* {useWallet == true && (
                                      <Row>
                                        <Col md="4">
                                          <Form.Group>
                                            Wallet Amount
                                            <Form.Control
                                              type="text"
                                              name="walletAmount"
                                              id="walletAmount"
                                              placeholder="wallet amount"
                                              onChange={(e) => {
                                                this.handleEnteredWalletAMount(
                                                  e.target.value
                                                );
                                              }}
                                              value={enteredWalletAmount}
                                            />
                                          </Form.Group>
                                        </Col>
                                      </Row>
                                    )} */}
                                      </>
                                    )}
                                    <Button
                                      className="continuebtn continuebtn1 pull-right"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.nextStep();
                                      }}
                                    >
                                      CONTINUE
                                    </Button>
                                  </Form>
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>
                          )}
                        <Card className="mb-3">
                          <Accordion.Toggle
                            as={Card.Header}
                            eventKey="3"
                            className="p-2 mb-0"
                            onClick={(e) => {
                              e.preventDefault();
                              this.handleIndex(3);
                            }}
                          >
                            <span class="pull-left">Payment Mode</span>
                            <span class="pull-right faqplus">+</span>
                          </Accordion.Toggle>{" "}
                          <Accordion.Collapse className="" eventKey="3">
                            <Card.Body className="p-3">
                              {/* {JSON.stringify(selectedPaymentMode)} */}
                              <Form className="shipping-add-form">
                                {["radio"].map((type) => (
                                  <div
                                    key={`inline-${type}`}
                                    className="mb-3 payment-radio"
                                  >
                                    <Row>
                                      <Col md="6">
                                        <div className="">
                                          <label className="card radiocard">
                                            <input
                                              name="paymentMode"
                                              className="radio"
                                              type="radio"
                                              disabled={
                                                selectedPaymentMode === "other"
                                                  ? true
                                                  : false
                                              }
                                              checked={
                                                selectedPaymentMode == "online"
                                                  ? true
                                                  : false
                                              }
                                              onChange={(e) => {
                                                this.handlePaymentMode(
                                                  "online"
                                                );
                                              }}
                                            />
                                            <span className="plan-details">
                                              <span className="plan-type">
                                                Online
                                              </span>
                                            </span>
                                          </label>
                                        </div>
                                      </Col>
                                      <Col md="6">
                                        <label className="card radiocard">
                                          <input
                                            name="paymentMode"
                                            className="radio"
                                            type="radio"
                                            disabled={
                                              selectedPaymentMode === "other"
                                                ? true
                                                : false
                                            }
                                            checked={
                                              selectedPaymentMode == "cod"
                                                ? true
                                                : false
                                            }
                                            onChange={(e) => {
                                              this.handlePaymentMode("cod");
                                            }}
                                          />

                                          <span className="plan-details">
                                            <span className="plan-type">
                                              COD
                                            </span>
                                          </span>
                                        </label>
                                      </Col>
                                    </Row>
                                  </div>
                                ))}
                                {selectedPaymentMode === "other" && (
                                  <label className="card radiocard">
                                    Please proceed with submit
                                  </label>
                                )}
                                <Button
                                  className="rainbow d-btn"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.handleCheckoutSubmit();
                                  }}
                                >
                                  Submit
                                </Button>
                              </Form>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>
                    </div>
                  </div>
                </Col>
                <Col lg="4" md="4">
                  {/* <CartOverview {...this.props} /> */}
                  <div className="carttab1">
                    <h3>Order Summary</h3>
                    <ul className="ordersummery1">
                      {this.props.cartReducer &&
                      this.props.cartReducer.cart != null &&
                      this.props.cartReducer.cart.length > 0
                        ? this.props.cartReducer.cart.map((v, i) => {
                            return (
                              <li>
                                <a
                                  href="#."
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.props.history.push(
                                      `/ProductDetail/${v.id}`
                                    );
                                  }}
                                >
                                  <Row>
                                    <Col lg="3" md="12" sm="12" xs="12">
                                      <div className="ordersummeryimg">
                                        <img src={v.thumbImage}></img>
                                      </div>
                                    </Col>
                                    <Col lg="6" md="12" sm="12" xs="12">
                                      <div className="orders-prdct-nm">
                                        <span>
                                          {printSimilarProductName(v.name)}
                                        </span>
                                        <p>{v.unit} Qty</p>
                                      </div>
                                    </Col>
                                    <Col lg="3" md="12" sm="12" xs="12">
                                      <div className="orders-prdct-nm">
                                        {v.discount != null && (
                                          <p
                                            style={{
                                              marginTop: "7px",
                                              lineHeight: "10px",
                                            }}
                                          >
                                            <i className="fa fa-inr"></i>
                                            <s>
                                              {parseFloat(v.totalPrice).toFixed(
                                                2
                                              )}
                                              /-
                                            </s>
                                          </p>
                                        )}
                                        <span className="order-s-price">
                                          <i className="fa fa-inr"></i>
                                          {parseFloat(v.totalMrp).toFixed(2)}
                                          /-
                                        </span>
                                      </div>

                                      {/* <div className="order-s-price">
                                        <i className="fa fa-inr"></i>
                                        {parseFloat(v.totalPrice).toFixed(2)}/-
                                      </div> */}
                                      {/* <div className="order-s-price">
                                        <i className="fa fa-inr"></i>
                                        {parseFloat(v.totalMrp).toFixed(2)}/-
                                      </div> */}
                                    </Col>
                                  </Row>
                                </a>
                              </li>
                            );
                          })
                        : ""}
                    </ul>

                    <ul className="pt-0">
                      <li>
                        <Row>
                          <Col lg="8" md="6" className="detailfont">
                            Price (
                            {this.props.cartReducer != null &&
                            this.props.cartReducer.cart != null
                              ? this.props.cartReducer.cart.length
                              : 0}{" "}
                            items)
                          </Col>
                          <Col lg="4" md="6" className="card-total detailfont">
                            <i className="fa fa-inr"></i>{" "}
                            {this.props.cartReducer &&
                            this.props.cartReducer.grandTotalPrice != false
                              ? parseFloat(
                                  this.props.cartReducer.grandTotalPrice
                                ).toFixed(2)
                              : 0.0}
                            /-
                          </Col>
                        </Row>
                      </li>
                      <li>
                        <Row>
                          <Col lg="8" md="6" className="detailfont">
                            Discount
                          </Col>
                          <Col lg="4" md="6" className="card-total detailfont">
                            <i className="fa fa-inr"></i> {/* -{" "} */}
                            {this.props.cartReducer &&
                            this.props.cartReducer.grandTotalDiscount != null
                              ? parseFloat(
                                  this.props.cartReducer.grandTotalDiscount
                                ).toFixed(2)
                              : 0.0}{" "}
                            /-
                          </Col>
                        </Row>
                      </li>
                      <li
                        className="t-order"
                        style={{
                          borderBottom: "1px dashed #ccc",
                          borderTop: "1px dashed #ccc",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <Row>
                          <Col lg="8" md="6">
                            Total Order
                          </Col>
                          <Col lg="4" md="6" className="c-total">
                            <i className="fa fa-inr"></i>{" "}
                            {this.props.cartReducer &&
                            this.props.cartReducer.grandTotalMrp != false
                              ? parseFloat(
                                  this.props.cartReducer.grandTotalMrp
                                ).toFixed(2)
                              : 0.0}
                            /-
                          </Col>
                        </Row>
                      </li>
                      {/* {authenticationService.currentUserValue != null && (
                        <>
                          {voucherList.length > 0 && (
                            <li className="coupondis">
                              <Row>
                                <Button
                                  className="rainbow c-place-orderbtn"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({ voucherListModal: true });
                                  }}
                                >
                                  View Voucher Codes
                                </Button>
                              </Row>
                            </li>
                          )}
                          {userWalletBalance > 0 && (
                            <>
                              <li>
                                <Row>
                                  <Col md="8"> Wallet Balance</Col>
                                  <Col md="4" className="c-total">
                                    <i className="fa fa-inr"></i>{" "}
                                    {parseFloat(userWalletBalance).toFixed(2)}
                                    /-
                                  </Col>
                                </Row>
                              </li>
                              <li>
                                <Row>
                                  <Col>
                                    <Form.Group>
                                      <label>
                                        <input
                                          type="checkbox"
                                          id="useWallet"
                                          name="useWallet"
                                          checked={useWallet}
                                          onClick={(e) => {
                                            this.handleChange(e.target.checked);
                                          }}
                                          value={useWallet}
                                        />
                                        Use Wallet Balance{" "}
                                      </label>
                                    </Form.Group>
                                  </Col>
                                  {useWallet == true ? (
                                    <>
                                      <Col>
                                        <Form.Group>
                                          <Form.Control
                                            type="text"
                                            name="walletAmount"
                                            id="walletAmount"
                                            placeholder="wallet amount"
                                            onChange={(e) => {
                                              this.handleEnteredWalletAMount(
                                                e.target.value
                                              );
                                              // let val = e.target.value;
                                              // if (val === "") val = 0;
                                              // this.props.applyWalletBalance({
                                              //   walletAmount: val,
                                              // });
                                            }}
                                            value={enteredWalletAmount}
                                          />
                                        </Form.Group>
                                      </Col>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </Row>
                              </li>
                            </>
                          )}
                        </>
                      )} */}

                      {selectedVoucher !== "" ? (
                        <div
                          className="pt-1"
                          style={{
                            // border: "2px solid #eee",
                            borderBottom: "none",
                            borderRadius: "5px",
                            boxShadow: " 5px #a09e9e",
                          }}
                        >
                          {/* <Row>
                            <Col md="11"></Col>
                            <Col md="1">
                              <span className="p-0" style={{ color: "red" }}>
                                <i
                                  className="fa fa-close"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.handleSelectedVoucher();
                                  }}
                                ></i>
                              </span>
                            </Col>
                          </Row> */}
                          {/* <li>
                            <Row className="mt-3">
                              <Col md="7">Selected Voucher</Col>
                              <Col md="4" className="c-total p-0">
                                {selectedVoucher.voucherCode}
                              </Col>
                            </Row>
                          </li>
                          <li
                            className="t-order mt-2"
                            style={{
                              // borderBottom: "1px dashed #ccc",
                              // borderTop: "1px dashed #ccc",
                              // marginTop: "10px",
                              // marginBottom: "10px",
                              background: "#eee",
                            }}
                          >
                            <Row>
                              <Col md="8">Voucher Type</Col>
                              <Col md="4" className="c-total">
                                {selectedVoucher.discountType.toUpperCase()}
                              </Col>
                            </Row>
                          </li> */}
                          <li
                            className="t-order mt-2"
                            style={{
                              // borderBottom: "1px dashed #ccc",
                              // borderTop: "1px dashed #ccc",
                              // marginTop: "10px",
                              // marginBottom: "10px",
                              background: "#eee",
                            }}
                          >
                            <Row>
                              <Col lg="8" md="6">
                                Voucher Amount
                              </Col>
                              <Col lg="4" md="6" className="c-total">
                                <i className="fa fa-inr"></i>{" "}
                                {this.state && this.state.voucherAmount
                                  ? parseFloat(
                                      this.state.voucherAmount
                                    ).toFixed(2)
                                  : 0}
                                /-
                              </Col>
                            </Row>
                          </li>
                        </div>
                      ) : (
                        ""
                      )}
                      <li
                        className="t-order mt-2"
                        style={{
                          // borderBottom: "1px dashed #ccc",
                          // borderTop: "1px dashed #ccc",
                          // marginTop: "10px",
                          // marginBottom: "10px",
                          background: "#eee",
                        }}
                      >
                        <Row>
                          <Col lg="8" md="6">
                            Net Amount
                          </Col>
                          <Col lg="4" md="6" className="c-total">
                            <i className="fa fa-inr"></i>{" "}
                            {this.state && this.state.netAmount
                              ? parseFloat(this.state.netAmount).toFixed(2)
                              : 0.0}
                            /-
                          </Col>
                        </Row>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>

          {/* Edit Address */}
          <Modal
            show={editAddressModal}
            onHide={() => this.setState({ editAddressModal: false })}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="forgotpwdbox"
          >
            <Modal.Header closeButton className="forgotheader pt-3 pb-0">
              <Modal.Title
                id="example-custom-modal-styling-title"
                className="forgot-pwd"
              >
                Edit Address
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="galleryumodal forgotmodal o-p-editaddress pt-0 pb-3">
              <Formik
                validateOnChange={false}
                validateOnBlur={false}
                enableReinitialize={true}
                initialValues={{
                  address_line:
                    addressObject != null ? addressObject.addressLine : "",
                  street_address:
                    addressObject != null ? addressObject.streetAddress : "",
                  cityId: addressObject != null ? addressObject.cityId : "",
                  stateId: addressObject != null ? addressObject.stateId : "",
                  countryId:
                    addressObject != null ? addressObject.countryId : "",
                  pincode: addressObject != null ? addressObject.pincode : "",
                }}
                validationSchema={Yup.object().shape({})}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  const requestData = new FormData();

                  // Billing Address
                  requestData.append("id", addressObject.id);
                  requestData.append("address_line", values.address_line);
                  requestData.append("street_address", values.street_address);
                  requestData.append("pincode", values.pincode);
                  requestData.append("countryId", values.countryId.value);
                  requestData.append("stateId", values.stateId.value);
                  requestData.append("cityId", values.cityId.value);

                  setSubmitting(true);
                  updateAddress(requestData)
                    .then((response) => {
                      setSubmitting(false);
                      let res = response.data;
                      if (res.responseStatus == 200) {
                        notify.show(res.message, "success", 2000);
                        resetForm();
                        this.setState({ editAddressModal: false }, () => {
                          this.getUserAddressesFun();
                        });
                      } else {
                        notify.show(res.message, "error", 2000);
                        // resetForm();
                      }
                    })
                    .catch((error) => {
                      console.log("error", error);
                    });
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  resetForm,
                  setFieldValue,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Form.Group>
                      <Form.Label htmlFor="address_line">
                        ADDRESS LINE
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="address_line"
                        id="address_line"
                        onChange={handleChange}
                        value={values.address_line}
                        isValid={touched.address_line && !errors.address_line}
                        isInvalid={!!errors.address_line}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.address_line}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label htmlFor="street_address">
                        STREET ADDRESS
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="street_address"
                        id="street_address"
                        onChange={handleChange}
                        value={values.street_address}
                        isValid={
                          touched.street_address && !errors.street_address
                        }
                        isInvalid={!!errors.street_address}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.street_address}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                      <Form.Label htmlFor="countryId">
                        Select Country <span className="redstar">*</span>
                      </Form.Label>
                      <Select
                        isClearable={true}
                        styles={customStyles}
                        onChange={(v) => {
                          setFieldValue("countryId", v);
                          setFieldValue("stateId", "");
                          setFieldValue("cityId", "");
                          // console.log("countryId", v);
                          if (v != null) {
                            this.statesData(v.value);
                          } else {
                            this.setState({ stateOpt: [], cityOpt: [] });
                          }
                        }}
                        className="clsselect2"
                        name="countryId"
                        options={countryOpt}
                        value={values.countryId}
                        width="200px"
                      />
                      <span className="text-danger">
                        {errors.countryId && "Country is required"}
                      </span>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label htmlFor="stateId">
                        Select State <span className="redstar">*</span>
                      </Form.Label>
                      <Select
                        isClearable={true}
                        styles={customStyles}
                        onChange={(v) => {
                          setFieldValue("stateId", v);
                          setFieldValue("cityId", "");
                          if (v != null) {
                            this.citiesData(v.value);
                          } else {
                            this.setState({ cityOpt: [] });
                          }
                        }}
                        className="clsselect2"
                        name="stateId"
                        options={stateOpt}
                        value={values.stateId}
                        width="200px"
                      />
                      <span className="text-danger">{errors.stateId}</span>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label htmlFor="cityId">
                        Select City <span className="redstar">*</span>
                      </Form.Label>
                      <Select
                        isClearable={true}
                        styles={customStyles}
                        onChange={(v) => {
                          setFieldValue("cityId", v);
                        }}
                        className="clsselect2"
                        name="cityId"
                        options={cityOpt}
                        value={values.cityId}
                        width="200px"
                      />
                      <span className="text-danger">{errors.cityId}</span>
                    </Form.Group>

                    <Form.Group>
                      <Form.Label htmlFor="pincode">PINCODE</Form.Label>
                      <Form.Control
                        type="text"
                        name="pincode"
                        id="pincode"
                        onChange={handleChange}
                        value={values.pincode}
                        isValid={touched.pincode && !errors.pincode}
                        isInvalid={!!errors.pincode}
                        maxLength={6}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.pincode}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Row>
                      <Col md={6}>
                        <Button
                          variant="primary"
                          type="submit"
                          disabled={isSubmitting}
                          className="new-u-btn rainbow rainbow-1  loginbtn"
                        >
                          Update
                        </Button>
                      </Col>
                      <Col md={6} className="mt-2">
                        <div className="pl-4 forgot">
                          <Button
                            className="btn btn-secodary"
                            variant="danger"
                            onClick={(e) => {
                              e.preventDefault();
                              resetForm();
                              this.setState({ editAddressModal: false });
                            }}
                          >
                            Cancel
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Modal.Body>
          </Modal>
          {/* Edit Address */}

          {/* dialog box start */}
          <Modal
            show={deleteAddressModal}
            onHide={() => this.setState({ deleteAddressModal: false })}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="forgotpwdbox address_box"
          >
            {" "}
            <Modal.Body className="address-modal p-0 forgotmodal ">
              <div className="addressbox">
                <i className="fa fa-trash"></i>
                <p>Are you sure, you want to delete</p>
              </div>
              <div className="address-btn">
                <Button
                  type="submit"
                  className="address-btn1"
                  variant="secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    this.deleteAddtessModalFun();
                  }}
                >
                  YES
                </Button>
                <Button
                  className="address-btn2"
                  variant="secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ deleteAddressModal: false });
                  }}
                >
                  No
                </Button>
              </div>
            </Modal.Body>
          </Modal>
          {/* dialog box end */}

          {/* Voucher List */}
          <Modal
            show={voucherListModal}
            onHide={() => this.setState({ voucherListModal: false })}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="forgotpwdbox"
          >
            <Modal.Header closeButton className="forgotheader pt-3 pb-0">
              <Modal.Title
                id="example-custom-modal-styling-title"
                className="forgot-pwd"
              >
                Voucher List
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="galleryumodal forgotmodal o-p-editaddress pt-0 pb-3">
              <div>
                <Row>
                  {voucherList.length > 0 &&
                    voucherList.map((v, i) => {
                      return (
                        <Col md="6">
                          <div className="mt-2">
                            <label className="card radiocard radiocard1   111">
                              <input
                                name="voucherId"
                                className="radio"
                                type="radio"
                                disabled={
                                  v.voucherStatus !== "PENDING" ? false : true
                                }
                                checked={selectedVoucher == v ? true : false}
                                onChange={(e) => {
                                  this.handleSelectedVoucher(v, true);
                                }}
                                style={{ height: "26px", width: "26px" }}
                              />

                              <span className="plan-details p-2">
                                <span className="plan-type">
                                  <Card.Title>
                                    <h6>
                                      {v.voucherStatus === "EXPIRED" ? (
                                        <s>
                                          {v.giftName}
                                          <br />
                                          {v.voucherCode}
                                        </s>
                                      ) : (
                                        <>
                                          {v.giftName}
                                          <br />
                                          {v.voucherCode}
                                        </>
                                      )}
                                      &nbsp;&nbsp;
                                    </h6>
                                    <div className="">
                                      <h6>
                                        DIS.: &nbsp;
                                        {v.discountType === "amt" ? (
                                          <>
                                            <i className="fa fa-inr"></i>
                                            {v.discountAmt}{" "}
                                          </>
                                        ) : (
                                          v.discountPer + "%"
                                        )}
                                        <br />
                                        EXP. ON:{" "}
                                        {moment(v.expiryDate).format(
                                          "ddd D MMM YYYY HH:mm"
                                        )}
                                      </h6>
                                    </div>
                                  </Card.Title>
                                </span>
                              </span>
                            </label>
                          </div>
                        </Col>
                      );
                    })}
                </Row>
              </div>
            </Modal.Body>
          </Modal>
          {/* Voucher List */}
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = ({ cartReducer }) => {
  return { cartReducer };
};

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      addToCart,
      removeFromCart,
      decrementProductUnit,
      clearCart,
      applyWalletBalance,
      applyVoucherCode,
    },
    dispatch
  );
};

// export default ShippingAddress;

export default connect(mapStateToProps, mapActionsToProps)(ShippingAddress);

// export default MyCart;

import axios from "axios";
import {
  brandListUrl,
  brandFindUrl,
  brandProductListUrl,
  brandProductByFilterListUrl,
  brandProductListFiltersUrl,
  productListByBrandUrl,
} from "@/services/api";

import { authLogin } from "@/_helpers/authheader";

export function getbrandList() {
  return axios({
    headers: authLogin(),
    url: brandListUrl(),
    method: "GET",
  });
}

export function brandFind(values) {
  return axios({
    url: brandFindUrl(values),
    method: "GET",
  });
}

export function brandProductList(values) {
  return axios({
    url: brandProductListUrl(values),
    method: "POST",
    data: values,
  });
}

export function brandProductByFilterList(values) {
  return axios({
    url: brandProductByFilterListUrl(values),
    method: "POST",
    data: values,
  });
}

export function brandProductListFilters(values) {
  return axios({
    headers: authLogin(),
    url: brandProductListFiltersUrl(values),
    method: "POST",
    data: values,
  });
}

export function productListByBrand(values) {
  return axios({
    headers: authLogin(),
    url: productListByBrandUrl(values),
    method: "POST",
    data: values,
  });
}

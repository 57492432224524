import React, { Component, useState } from "react";
import Zoom from "react-reveal/Zoom";
import { Modal, InputGroup } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Tab from "react-bootstrap/Tab";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import header from "./header.css";
import logogif from "@/assets/images/website_logo.png";
import User from "@/assets/images/user.jpg";
import cart from "@/assets/images/cart.png";
import kj from "@/assets/images/menu_drawer-image.png";
import { Form, FormControl, Nav, Navbar } from "react-bootstrap";
import { matchPath } from "react-router-dom";

import { connect } from "react-redux";
import {
  subgroupListByGroup,
  getHeaderGroupList,
} from "@/services/api_function";
import { authenticationService } from "@/services/api_function";
import { Formik } from "formik";
import * as Yup from "yup";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.searchForm = React.createRef();
    this.state = {
      show: false,
      animation: "zoom",
      lstGroup: [],
      lstSubGroup: [],
      activeIndex: 1,
      activeId: 1,
      searchParameterQ: "",
      searchParameterError: false,
      scrollBackground: "nav-bg",
      nav_active: "",
    };
    window.onscroll = () => {
      // console.log("onscroll");
      // console.log("Xy", { x: window.scrollX, y: window.scrollY });
      let x = window.scrollX;
      let y = window.scrollY;
      let scrollBackground;
      if (x == 0 && y == 0) {
        scrollBackground = "nav-bg";
        this.setState({ scrollBackground });
      } else {
        scrollBackground = "nav-bg newfixedmenu";
        this.setState({ scrollBackground });
      }
    };
  }

  lstGroupList = () => {
    getHeaderGroupList()
      .then((response) => {
        let res = response.data;
        if (res.responseStatus == 200) {
          let b_list = res.responseObject;
          this.setState({ lstGroup: b_list }, () => {
            this.lstSubGroupList(b_list[0]["groupId"], true);
          });
        }
      })
      .catch((error) => {
        this.setState({ lstGroup: [] });
        console.log("error", error);
      });
  };

  submitSearchData = (searchText) => {
    // console.log("submitSearchData Called");
    // console.log({ searchText });
    if (searchText == "" || searchText == undefined || searchText == null) {
      // notify.show("Please give input to search", "error", 2000);
      this.setState({
        searchParameterError: true,
      });
    } else {
      this.setState({
        searchParameterError: false,
      });
      localStorage.setItem("searchText", searchText);

      this.props.history.push(`/search/${searchText}`);
      window.location.reload();
    }
  };
  setSearchParameter = (value) => {
    this.setState({ searchParameterQ: value });
  };

  lstSubGroupList = (groupId, isFirst = false) => {
    console.log({ groupId });
    let reqData = new FormData();
    reqData.append("groupId", groupId);
    subgroupListByGroup(reqData)
      .then((response) => {
        let res = response.data;
        if (res.responseStatus == 200) {
          let b_list = res.responseObject;
          this.setState({ lstSubGroup: b_list }, () => {
            if (isFirst) {
              this.setState({ activeId: groupId });
            }
          });
        }
      })
      .catch((error) => {
        this.setState({ lstSubGroup: [] });
        console.log("error", error);
      });
  };

  componentDidMount() {
    if (this.props.location) {
      console.warn(
        "this.props.location.state <<<<<<<<<<<<< ",
        this.props.location.state
      );
      let edit_data = this.props.location.state;
      this.setSearchParameter(edit_data);
    }

    if (matchPath(window.location.pathname, { path: "/ProductList/:id" })) {
      this.setState({ nav_active: "navActive" });
    }
    this.lstGroupList();
  }
  handleOnSelect = (key) => {
    this.setState({ activeId: key });
  };

  render() {
    const pathname = window.location.pathname;
    const {
      show,
      lstGroup,
      lstSubGroup,
      activeIndex,
      activeId,
      searchParameterQ,
      searchParameterError,
      nav_active,
    } = this.state;
    return (
      <div>
        <div
          className="p-0 nav-style header-t"
          // style={{ position: "fixed", top: "0" }}
        >
          <Nav
            activeKey="/home"
            className="d-flex justify-content-end nav-style bg-dark me-auto navtop"
            // onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}
          >
            <Nav.Item>
              <Nav.Link
                href="/home"
                className={`${
                  pathname == "/TheJourney" ? "FirstNavACtive" : ""
                } text-white`}
                // href="/TheJourney"

                onClick={(e) => {
                  e.preventDefault();
                  this.props.history.push("/TheJourney");
                }}
              >
                The Journey
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                className={`${
                  pathname == "/FAQ" ? "FirstNavACtive" : ""
                } text-white me-3`}
                // href="/FAQ"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.history.push("/FAQ");
                }}
              >
                FAQ
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
        {/* <div className="header-t">
          <Navbar
            collapseOnSelect
            expand="lg"
            bg="dark"
            variant="dark"
            className="p-0 nav-style"
            
          >
            <Container fluid>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse
                id="responsive-navbar-nav"
                className="navtop justify-content-end"
                style={{ width: "100%" }}
              >
                <Nav className="me-auto">
                  <Nav.Link
                    className={`${
                      pathname == "/TheJourney" ? "FirstNavACtive" : ""
                    }`}
                    // href="/TheJourney"
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.history.push("/TheJourney");
                    }}
                  >
                    The Journey
                  </Nav.Link>

                  {/* <Nav.Link
                    className={`${
                      pathname == "/Offers" ? "FirstNavACtive" : ""
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.history.push("/Offers");
                      
                    }}
                  >
                    Offers
                  </Nav.Link> */}
        {/* <Nav.Link
                    className={`${pathname == "/FAQ" ? "FirstNavACtive" : ""}`}
                    // href="/FAQ"
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.history.push("/FAQ");
                    }}
                  >
                    FAQ
                  </Nav.Link> */}
        {/* <Nav.Link
                    href="http://insidecricket.co.in/"
                    target="_blank"
                    className="insidecricket"
                  >
                    Inside Cricket&nbsp;&nbsp;{" "}
                    <i className="fa fa-check-square-o"></i>
                  </Nav.Link>
                </Nav>
                <Nav></Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div> */}

        <div>
          <div
            className={`headertop ${this.state.scrollBackground}`}
            id="HeaderTop"
          >
            <Container fluid>
              <Row>
                <Col xs="12" lg="6" md="6">
                  <div className="d-flex">
                    <div className="logo">
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.history.push("/");
                        }}
                      >
                        <Zoom duration={1000}>
                          <img
                            className="logo-name"
                            src={logogif}
                            style={{ height: "50px" }}
                          />
                        </Zoom>
                      </a>
                    </div>
                    <div className="searchdiv">
                      <Formik
                        validateOnChange={false}
                        validateOnBlur={false}
                        ref={this.searchForm}
                        initialValues={{
                          searchParameterQ: searchParameterQ,
                        }}
                        enableReinitialize={true}
                        validationSchema={Yup.object().shape({
                          searchParameterQ: Yup.string()
                            .trim()
                            .nullable()
                            .required("input is required"),
                        })}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                          const requestData = new FormData();
                          // console.log("values", values);
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleSubmit,
                          isSubmitting,
                          resetForm,
                          setFieldValue,
                        }) => (
                          <Form inline onSubmit={handleSubmit} className="mt-2">
                            <div>
                              <FormControl
                                type="text"
                                id="searchParameterQ"
                                name="searchParameterQ"
                                placeholder="Search"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                value={values.searchParameterQ}
                                onChange={handleChange}
                                isInvalid={searchParameterError}
                              />
                            </div>

                            <Nav.Link
                              className=""
                              href="/Search"
                              onClick={(e) => {
                                e.preventDefault();
                                // this.setState({ show: true });
                                this.submitSearchData(values.searchParameterQ);
                              }}
                            >
                              <i className="fa fa-search fa-1"></i>
                            </Nav.Link>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </Col>

                <Col xs="12" lg="6" md="6">
                  <Row>
                    {/* {JSON.stringify(authenticationService.currentUserValue)} */}
                    {/* <Col md="0" lg="0" sm="0"></Col> */}
                    <Col md="9" lg="8" xs="8" sm="3">
                      {/*shop menus start*/}
                      <div class="navbar1">
                        <div class={`${nav_active} subnav`}>
                          <button class="subnavbtn">
                            Shop <i class="fa fa-caret-down"></i>
                          </button>
                          <div class="subnav-content">
                            <Row>
                              <Col md="5" lg="5" className="kedarjadhavpic">
                                <img
                                  src={kj}
                                  alt="Kedar Jadhav"
                                  className="batsmanimg"
                                ></img>
                              </Col>
                              <Col md="7" lg="7">
                                <Tab.Container
                                  id="left-tabs-example"
                                  // defaultActiveKey="first"
                                  defaultActiveKey={activeId}
                                  activeKey={activeId}
                                  onSelect={(k) => {
                                    this.handleOnSelect(k);
                                  }}
                                >
                                  <Row>
                                    <Col md={8} xs={6}>
                                      <Tab.Content>
                                        {lstGroup.length > 0 &&
                                          lstGroup.map((v, i) => {
                                            return (
                                              <Tab.Pane
                                                // eventKey="first"
                                                eventKey={v.groupId}
                                              >
                                                <div className="cricketdiv pt-3">
                                                  <h5>
                                                    <img
                                                      src={v.headerImage}
                                                      alt={v.groupName}
                                                    ></img>

                                                    {v.groupName.toUpperCase()}
                                                  </h5>
                                                  <div className="navbarscroll">
                                                    <Row>
                                                      <Col md="6">
                                                        <div className="ctgry-menu">
                                                          <ul>
                                                            {lstSubGroup.length >
                                                              0 &&
                                                              lstSubGroup.map(
                                                                (v1, i1) => {
                                                                  if (
                                                                    parseInt(
                                                                      i1
                                                                    ) %
                                                                      2 ==
                                                                    0
                                                                  ) {
                                                                    return (
                                                                      <li>
                                                                        <a
                                                                          href="#."
                                                                          onClick={(
                                                                            e
                                                                          ) => {
                                                                            e.preventDefault();
                                                                            this.props.history.push(
                                                                              `/ProductList/${v1.id}`
                                                                            );
                                                                          }}
                                                                        >
                                                                          {
                                                                            v1.subgroupName
                                                                          }
                                                                          &nbsp;
                                                                          <i className="fa fa-angle-right"></i>
                                                                        </a>
                                                                      </li>
                                                                    );
                                                                  }
                                                                }
                                                              )}
                                                          </ul>
                                                        </div>
                                                      </Col>
                                                      <Col md="6">
                                                        <div className="ctgry-menu">
                                                          <ul>
                                                            {lstSubGroup.length >
                                                              0 &&
                                                              lstSubGroup.map(
                                                                (v1, i1) => {
                                                                  if (
                                                                    parseInt(
                                                                      i1
                                                                    ) %
                                                                      2 !=
                                                                    0
                                                                  ) {
                                                                    return (
                                                                      <li>
                                                                        <a
                                                                          href="#."
                                                                          onClick={(
                                                                            e
                                                                          ) => {
                                                                            e.preventDefault();
                                                                            this.props.history.push(
                                                                              `/ProductList/${v1.id}`
                                                                            );
                                                                          }}
                                                                        >
                                                                          {
                                                                            v1.subgroupName
                                                                          }
                                                                          &nbsp;
                                                                          <i className="fa fa-angle-right"></i>
                                                                        </a>
                                                                      </li>
                                                                    );
                                                                  }
                                                                }
                                                              )}
                                                          </ul>
                                                        </div>
                                                      </Col>
                                                    </Row>
                                                  </div>
                                                </div>
                                              </Tab.Pane>
                                            );
                                          })}
                                      </Tab.Content>
                                    </Col>
                                    <Col
                                      md={4}
                                      sm={4}
                                      xs={6}
                                      className="categoryleft pr-0"
                                    >
                                      <Nav
                                        variant="pills"
                                        className="flex-column category-pills "
                                      >
                                        {lstGroup.length > 0 &&
                                          lstGroup.map((v, i) => {
                                            // console.log({ v });
                                            return (
                                              <Nav.Item
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  this.lstSubGroupList(
                                                    v.groupId
                                                  );
                                                }}
                                              >
                                                <Nav.Link eventKey={v.groupId}>
                                                  <img
                                                    src={v.headerImage}
                                                    alt={v.groupName}
                                                  ></img>{" "}
                                                  {v.groupName.toUpperCase()}
                                                </Nav.Link>
                                              </Nav.Item>
                                            );
                                          })}
                                      </Nav>
                                    </Col>
                                  </Row>
                                </Tab.Container>
                              </Col>
                            </Row>
                          </div>
                        </div>
                        <a
                          className={`${
                            pathname == "/About" ? "navActive" : ""
                          }`}
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/About`);
                          }}
                        >
                          About
                        </a>
                        <a
                          className={`${
                            pathname == "/Contact" ? "navActive" : ""
                          }`}
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/Contact`);
                          }}
                        >
                          Contact
                        </a>
                      </div>
                      {/* shop menu end */}
                    </Col>
                    <Col md="3" lg="4" xs="4" sm="6">
                      <div className="navlinkmenu d-flex">
                        {authenticationService.currentUserValue != null ? (
                          <Nav.Link
                            className=""
                            //  href="/Dashboard"
                            onClick={(e) => {
                              e.preventDefault();
                              this.props.history.push(`/Dashboard`);
                            }}
                          >
                            <img src={User} alt="My Account"></img>
                          </Nav.Link>
                        ) : (
                          <Nav.Link
                            className=""
                            // href="/Login"
                            onClick={(e) => {
                              e.preventDefault();
                              this.props.history.push(`/Login`);
                            }}
                          >
                            <img src={User} alt="My Account"></img>
                          </Nav.Link>
                        )}
                        <Nav.Link
                          className="carthover"
                          // href="/MyCart"
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/MyCart`);
                          }}
                        >
                          <div className="crtdiv badgediv">
                            <div className="crt-mycrt">
                              <h6 className="badgeclass">
                                {this.props.cartReducer != null &&
                                this.props.cartReducer.cart != null
                                  ? this.props.cartReducer.cart.length
                                  : 0}
                              </h6>
                              <img src={cart} alt="My Cart"></img>
                            </div>
                          </div>
                        </Nav.Link>
                      </div>
                    </Col>
                    {/* <Col md="1" lg="1" sm="2">
                  <Navigation />
                </Col> */}
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ cartReducer }) => {
  return { cartReducer };
};

export default connect(mapStateToProps)(Header);
// export default Header;

import React, { Component } from "react";
import { Container, Form, Row, Col, Button, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { emailRegExp, MobileRegx, pincodeReg } from "@/_helpers";
import { authenticationService } from "@/services/api_function";
import Notifications, { notify } from "react-notify-toast";

// import { getTokenFun } from "../../firebase";

export default class LoginComponent extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="existing_cust pt-0 pl-4 pr-4 ">
        <Row>
          <Col md="10">
            <div className="e-cust">
              <h2>Existing Customer</h2>
              <p>If you have an account, sign in with your username.</p>
            </div>
          </Col>
          <Col md="2">
            <div className="bguser">
              <i className="fa fa-lock"></i>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="loginform pl-3 pr-3">
            <Formik
              validateOnChange={false}
              validateOnBlur={false}
              initialValues={{
                username: "",
                password: "",
              }}
              validationSchema={Yup.object().shape({
                username: Yup.string()
                  .trim()
                  .matches(MobileRegx, "Mobile No. invalid")
                  .required("Mobile No. is required"),
                password: Yup.string().trim().required("Password is required"),
              })}
              onSubmit={(value, { setSubmitting, resetForm }) => {
                // console.log("value", value);
                setSubmitting(true);
                authenticationService
                  .login(value)
                  .then((response) => {
                    // setSubmitting(false);
                    // console.log("Login Page response", response);
                    if (response.status == "OK") {
                      localStorage.setItem(
                        "authenticationService",
                        response.token
                      );
                      notify.show("Login Done!", "success", 2000);
                      resetForm();
                      // getTokenFun();
                      setTimeout(() => {
                        this.props.history.push("/Dashboard");
                      }, 1500);
                    } else {
                      setSubmitting(false);
                      if (response.responseStatus == 404) {
                        notify.show(response.message, "error", 2000);
                      } else {
                        notify.show(
                          "Server Error! Please Check Your Connectivity",
                          "error",
                          2000
                        );
                      }
                    }
                  })
                  .catch((error) => {
                    // console.log("error", error);
                    notify.show(
                      "Authentication Failed, Try again!",
                      "error",
                      1200
                    );
                    setSubmitting(false);
                  });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                isSubmitting,
                resetForm,
              }) => (
                <Form onSubmit={handleSubmit} noValidate autoComplete="off">
                  <Form.Group>
                    <Form.Label htmlFor="username">Mobile No.</Form.Label>
                    <Form.Control
                      type="text"
                      name="username"
                      id="username"
                      placeholder="Mobile No."
                      onChange={handleChange}
                      value={values.username}
                      isValid={touched.username && !errors.username}
                      isInvalid={!!errors.username}
                      maxLength={10}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.username}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label htmlFor="password">PASSWORD</Form.Label>
                    <Form.Control
                      type="password"
                      name="password"
                      id="password"
                      placeholder="****"
                      onChange={handleChange}
                      value={values.password}
                      isValid={touched.password && !errors.password}
                      isInvalid={!!errors.password}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <div>
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={isSubmitting}
                      className="new-u-btn rainbow rainbow-1 btn"
                    >
                      {isSubmitting ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          <span className="sr-only">Loading...</span>
                        </>
                      ) : (
                        "Login"
                      )}
                    </Button>
                  </div>
                  {/* <div className="pl-4 forgot">
                    <a href="#." className="pt-5">
                      Forgot Your Password
                    </a>
                  </div> */}
                </Form>
              )}
            </Formik>
          </div>
        </Row>
      </div>
    );
  }
}

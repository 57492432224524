import React, { Component } from "react";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";

import tabletannis from "@/assets/images/tabletannis.png";
import "./batsman.css";
import {
  Form,
  FormControl,
  Button,
  Nav,
  Navbar,
  NavDropdown,
  Container,
  Tabs,
  Row,
  Col,
  Tab,
} from "react-bootstrap";
import { getGroupList } from "@/services/api_function";

class Batsman extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lstGroup: [],
    };
  }

  lstGroupList = () => {
    // console.log("grouplist");
    getGroupList()
      .then((response) => {
        let res = response.data;
        if (res.responseStatus == 200) {
          let g_list = res.responseObject;
          if (g_list.length > 0) {
            this.setState({ lstGroup: g_list });
          }
        }
      })
      .catch((error) => {
        this.setState({ lstGroup: [] });
        console.log("error", error);
      });
  };

  componentDidMount() {
    // console.log("did mount");
    this.lstGroupList();
  }
  render() {
    const { lstGroup } = this.state;

    return (
      <div className="">
        <div className="shopcategory shopcategory1 pt-5 pb-5">
          <Container fluid>
            <div className="s_category">
              <Tabs
                defaultActiveKey="Batsman"
                id="uncontrolled-tab-example"
                className="tabcontent"
              >
                <Tab eventKey="Batsman" title="Batsman">
                  <svg
                    width="1300"
                    height="600"
                    viewBox="0 0 2100 2400"
                    preserveAspectRatio="none"
                  >
                    <line
                      x1="843"
                      y1="258"
                      x2="529"
                      y2="258"
                      fill="none"
                      stroke="white"
                      stroke-width="1"
                      stroke-dasharray="10 10"
                    ></line>

                    <line
                      x1="1002"
                      y1="159"
                      x2="850"
                      y2="258"
                      fill="none"
                      stroke="white"
                      stroke-width="1"
                      stroke-dasharray="9 9"
                    ></line>
                    <line
                      x1="714"
                      y1="712"
                      x2="529"
                      y2="712"
                      fill="none"
                      stroke="white"
                      stroke-width="1"
                      stroke-dasharray="10 10"
                    ></line>
                    <line
                      x1="753"
                      y1="579"
                      x2="713"
                      y2="725"
                      fill="none"
                      stroke="white"
                      stroke-width="1"
                      stroke-dasharray="11 11"
                    ></line>
                    <line
                      x1="721"
                      y1="1120"
                      x2="529"
                      y2="1120"
                      fill="none"
                      stroke="white"
                      stroke-width="1"
                      stroke-dasharray="10 10"
                    ></line>
                    <line
                      x1="984"
                      y1="760"
                      x2="723"
                      y2="1121"
                      fill="none"
                      stroke="white"
                      stroke-width="1"
                      stroke-dasharray="10 10"
                    ></line>
                    <line
                      x1="781"
                      y1="1520"
                      x2="529"
                      y2="1520"
                      fill="none"
                      stroke="white"
                      stroke-width="1"
                      stroke-dasharray="10 10"
                    ></line>
                    <line
                      x1="840"
                      y1="2001"
                      x2="529"
                      y2="2001"
                      fill="none"
                      stroke="white"
                      stroke-width="1"
                      stroke-dasharray="10 10"
                    ></line>
                    {/* <line
                x1="766"
                y1="2175"
                x2="599"
                y2="1920"
                fill="none"
                stroke="white"
                stroke-width="1"
                stroke-dasharray="10 10"
              ></line> */}
                    <line
                      x1="1691"
                      y1="300"
                      x2="1515"
                      y2="300"
                      fill="none"
                      stroke="white"
                      stroke-width="1"
                      stroke-dasharray="10 10"
                    ></line>
                    <line
                      x1="1691"
                      y1="745"
                      x2="1499"
                      y2="745"
                      fill="none"
                      stroke="white"
                      stroke-width="1"
                      stroke-dasharray="10 10"
                    ></line>
                    <line
                      x1="1506"
                      y1="744"
                      x2="1265"
                      y2="513"
                      fill="none"
                      stroke="white"
                      stroke-width="1"
                      stroke-dasharray="10 10"
                    ></line>
                    <line
                      x1="1691"
                      y1="1197"
                      x2="1499"
                      y2="1197"
                      fill="none"
                      stroke="white"
                      stroke-width="1"
                      stroke-dasharray="10 10"
                    ></line>
                    <line
                      x1="1198"
                      y1="709"
                      x2="1499"
                      y2="1195"
                      fill="none"
                      stroke="white"
                      stroke-width="1"
                      stroke-dasharray="10 10"
                    ></line>
                    {/* Thigh pads */}
                    <line
                      x1="1691"
                      y1="1635"
                      x2="1499"
                      y2="1635"
                      fill="none"
                      stroke="white"
                      stroke-width="1"
                      stroke-dasharray="10 10"
                    ></line>
                    <line
                      x1="1198"
                      y1="1087"
                      x2="1502"
                      y2="1626"
                      fill="none"
                      stroke="white"
                      stroke-width="1"
                      stroke-dasharray="10 10"
                    ></line>
                    <line
                      x1="1691"
                      y1="2084"
                      x2="1499"
                      y2="2084"
                      fill="none"
                      stroke="white"
                      stroke-width="1"
                      stroke-dasharray="10 10"
                    ></line>
                    <line
                      x1="1306"
                      y1="1602"
                      x2="1499"
                      y2="2071"
                      fill="none"
                      stroke="white"
                      stroke-width="1"
                      stroke-dasharray="10 10"
                    ></line>
                  </svg>
                  <div class="overmap">
                    <a href="/ProductList">
                      <Fade left delay={100}>
                        <div id="cap1" className="">
                          <h6>
                            Cap / Helmet &nbsp;&nbsp;
                            <i
                              style={{ color: "#1d91f7", fontSize: "27px" }}
                              className="fa fa-angle-right"
                            ></i>
                            <i
                              style={{ fontSize: "27px", color: "#b3b1b1" }}
                              className="fa fa-angle-double-right"
                            ></i>
                          </h6>
                        </div>
                      </Fade>
                    </a>
                    <a href="/ProductList/3">
                      <Fade left delay={100}>
                        <div id="cap2" className="">
                          <h6>
                            Ball &nbsp;&nbsp;
                            <i
                              style={{ color: "#1d91f7", fontSize: "27px" }}
                              className="fa fa-angle-right"
                            ></i>
                            <i
                              style={{ fontSize: "27px", color: "#b3b1b1" }}
                              className="fa fa-angle-double-right"
                            ></i>
                          </h6>
                        </div>
                      </Fade>
                    </a>
                    <a href="/ProductList">
                      <Fade left delay={100}>
                        <div id="cap3" className="">
                          <h6>
                            Jursey &nbsp;&nbsp;
                            <i
                              style={{ color: "#1d91f7", fontSize: "27px" }}
                              className="fa fa-angle-right"
                            ></i>
                            <i
                              style={{ fontSize: "27px", color: "#b3b1b1" }}
                              className="fa fa-angle-double-right"
                            ></i>
                          </h6>
                        </div>
                      </Fade>
                    </a>
                    <a href="/ProductList">
                      <Fade left delay={100}>
                        <div id="cap4" className="">
                          <h6>
                            Stumps &nbsp;&nbsp;
                            <i
                              style={{ color: "#1d91f7", fontSize: "27px" }}
                              className="fa fa-angle-right"
                            ></i>
                            <i
                              style={{ fontSize: "27px", color: "#b3b1b1" }}
                              className="fa fa-angle-double-right"
                            ></i>
                          </h6>
                        </div>
                      </Fade>
                    </a>
                    <a href="/ProductList">
                      <Fade left delay={100}>
                        <div id="cap5" className="">
                          <h6>
                            Shoes &nbsp;&nbsp;
                            <i
                              style={{ color: "#1d91f7", fontSize: "27px" }}
                              className="fa fa-angle-right"
                            ></i>
                            <i
                              style={{ fontSize: "27px", color: "#b3b1b1" }}
                              className="fa fa-angle-double-right"
                            ></i>
                          </h6>
                        </div>
                      </Fade>
                    </a>
                    <div id="b-ball"></div>
                    <Zoom>
                      <div id="batsman"></div>
                    </Zoom>
                    <a href="/ProductList/4">
                      <Fade right delay={1000}>
                        <div id="ball1" className="">
                          <h6>
                            <i
                              style={{ fontSize: "27px", color: "#b3b1b1" }}
                              className="fa fa-angle-double-left"
                            ></i>
                            <i
                              style={{ color: "#1d91f7", fontSize: "27px" }}
                              className="fa fa-angle-left"
                            ></i>
                            &nbsp;&nbsp; Bat
                          </h6>
                        </div>
                      </Fade>
                    </a>
                    <a href="/ProductList">
                      <Fade right delay={1000}>
                        <div id="ball2" className="">
                          <h6>
                            {" "}
                            <i
                              style={{ fontSize: "27px", color: "#b3b1b1" }}
                              className="fa fa-angle-double-left"
                            ></i>
                            <i
                              style={{ color: "#1d91f7", fontSize: "27px" }}
                              className="fa fa-angle-left"
                            ></i>
                            &nbsp;&nbsp;Gloves
                          </h6>
                        </div>
                      </Fade>
                    </a>
                    <a href="/ProductList">
                      <Fade right delay={1000}>
                        <div id="ball3" className="">
                          <h6>
                            {" "}
                            <i
                              style={{ fontSize: "27px", color: "#b3b1b1" }}
                              className="fa fa-angle-double-left"
                            ></i>
                            <i
                              style={{ color: "#1d91f7", fontSize: "27px" }}
                              className="fa fa-angle-left"
                            ></i>
                            &nbsp;&nbsp;Elbow
                          </h6>
                        </div>
                      </Fade>
                    </a>
                    <a href="/ProductList">
                      <Fade right delay={1000}>
                        <div id="ball4" className="">
                          <h6>
                            {" "}
                            <i
                              style={{ fontSize: "27px", color: "#b3b1b1" }}
                              className="fa fa-angle-double-left"
                            ></i>
                            <i
                              style={{ color: "#1d91f7", fontSize: "27px" }}
                              className="fa fa-angle-left"
                            ></i>
                            &nbsp;&nbsp;Thigh Pads
                          </h6>
                        </div>
                      </Fade>
                    </a>
                    <a href="/ProductList">
                      <Fade right delay={1000}>
                        <div id="ball5" className="">
                          <h6>
                            {" "}
                            <i
                              style={{ fontSize: "27px", color: "#b3b1b1" }}
                              className="fa fa-angle-double-left"
                            ></i>
                            <i
                              style={{ color: "#1d91f7", fontSize: "27px" }}
                              className="fa fa-angle-left"
                            ></i>
                            &nbsp;&nbsp;Pads
                          </h6>
                        </div>
                      </Fade>
                    </a>
                  </div>
                </Tab>
                <Tab eventKey="Bowler" title="Bowler">
                  <svg
                    width="1300"
                    height="600"
                    viewBox="0 0 2100 2400"
                    preserveAspectRatio="none"
                  >
                    <line
                      x1="714"
                      y1="661"
                      x2="529"
                      y2="661"
                      fill="none"
                      stroke="white"
                      stroke-width="1"
                      stroke-dasharray="10 10"
                    ></line>
                    <line
                      x1="950"
                      y1="372"
                      x2="696"
                      y2="677"
                      fill="none"
                      stroke="white"
                      stroke-width="1"
                      stroke-dasharray="11 11"
                    ></line>
                    <line
                      x1="721"
                      y1="1213"
                      x2="529"
                      y2="1213"
                      fill="none"
                      stroke="white"
                      stroke-width="1"
                      stroke-dasharray="10 10"
                    ></line>
                    <line
                      x1="957"
                      y1="2337"
                      x2="723"
                      y2="1204"
                      fill="none"
                      stroke="white"
                      stroke-width="1"
                      stroke-dasharray="10 10"
                    ></line>
                    {/* /*************************** */}
                    <line
                      x1="1648"
                      y1="671"
                      x2="1509"
                      y2="674"
                      fill="none"
                      stroke="white"
                      stroke-width="1"
                      stroke-dasharray="10 10"
                    ></line>
                    <line
                      x1="1511"
                      y1="675"
                      x2="1032"
                      y2="542"
                      fill="none"
                      stroke="white"
                      stroke-width="1"
                      stroke-dasharray="10 10"
                    ></line>
                    <line
                      x1="1650"
                      y1="1248"
                      x2="1499"
                      y2="1248"
                      fill="none"
                      stroke="white"
                      stroke-width="1"
                      stroke-dasharray="10 10"
                    ></line>
                    <line
                      x1="1077"
                      y1="972"
                      x2="1488"
                      y2="1244"
                      fill="none"
                      stroke="white"
                      stroke-width="1"
                      stroke-dasharray="10 10"
                    ></line>
                  </svg>
                  <div class="overmap">
                    <a href="/productlist/3">
                      <Fade left delay={100}>
                        <div id="bowlerball" className="">
                          <h6>
                            Ball &nbsp;&nbsp;
                            <i
                              style={{ color: "#1d91f7", fontSize: "27px" }}
                              className="fa fa-angle-right"
                            ></i>
                            <i
                              style={{ fontSize: "27px", color: "#b3b1b1" }}
                              className="fa fa-angle-double-right"
                            ></i>
                          </h6>
                        </div>
                      </Fade>
                    </a>
                    <a href="/productlist">
                      <Fade left delay={100}>
                        <div id="bowlershoes" className="">
                          <h6>
                            Shoes &nbsp;&nbsp;
                            <i
                              style={{ color: "#1d91f7", fontSize: "27px" }}
                              className="fa fa-angle-right"
                            ></i>
                            <i
                              style={{ fontSize: "27px", color: "#b3b1b1" }}
                              className="fa fa-angle-double-right"
                            ></i>
                          </h6>
                        </div>
                      </Fade>
                    </a>
                    <Zoom>
                      <div id="bowler"></div>
                    </Zoom>
                    <a href="/productlist">
                      <Fade right delay={1000}>
                        <div id="jursey" className="">
                          <h6>
                            <i
                              style={{ fontSize: "27px", color: "#b3b1b1" }}
                              className="fa fa-angle-double-left"
                            ></i>
                            <i
                              style={{ color: "#1d91f7", fontSize: "27px" }}
                              className="fa fa-angle-left"
                            ></i>
                            &nbsp;&nbsp; Elbow Sleev
                          </h6>
                        </div>
                      </Fade>
                    </a>
                    <a href="/productlist">
                      <Fade right delay={1000}>
                        <div id="elbowsleev" className="">
                          <h6>
                            <i
                              style={{ fontSize: "27px", color: "#b3b1b1" }}
                              className="fa fa-angle-double-left"
                            ></i>
                            <i
                              style={{ color: "#1d91f7", fontSize: "27px" }}
                              className="fa fa-angle-left"
                            ></i>
                            &nbsp;&nbsp; Jursey
                          </h6>
                        </div>
                      </Fade>{" "}
                    </a>
                  </div>
                </Tab>
                <Tab eventKey="Wicketkeeper" title="Wicket-keeper">
                  <svg
                    width="1300"
                    height="600"
                    viewBox="0 0 2100 2400"
                    preserveAspectRatio="none"
                  >
                    <line
                      x1="714"
                      y1="661"
                      x2="529"
                      y2="661"
                      fill="none"
                      stroke="white"
                      stroke-width="1"
                      stroke-dasharray="10 10"
                    ></line>
                    <line
                      x1="1055"
                      y1="372"
                      x2="711"
                      y2="664"
                      fill="none"
                      stroke="white"
                      stroke-width="1"
                      stroke-dasharray="11 11"
                    ></line>
                    <line
                      x1="721"
                      y1="1213"
                      x2="529"
                      y2="1213"
                      fill="none"
                      stroke="white"
                      stroke-width="1"
                      stroke-dasharray="10 10"
                    ></line>
                    <line
                      x1="740"
                      y1="2060"
                      x2="723"
                      y2="1209"
                      fill="none"
                      stroke="white"
                      stroke-width="1"
                      stroke-dasharray="10 10"
                    ></line>
                    {/* /*************************** */}
                    <line
                      x1="1656"
                      y1="671"
                      x2="1499"
                      y2="671"
                      fill="none"
                      stroke="white"
                      stroke-width="1"
                      stroke-dasharray="10 10"
                    ></line>
                    <line
                      x1="1500"
                      y1="688"
                      x2="1078"
                      y2="1515"
                      fill="none"
                      stroke="white"
                      stroke-width="1"
                      stroke-dasharray="10 10"
                    ></line>
                    <line
                      x1="1679"
                      y1="1244"
                      x2="1499"
                      y2="1244"
                      fill="none"
                      stroke="white"
                      stroke-width="1"
                      stroke-dasharray="10 10"
                    ></line>
                    <line
                      x1="1257"
                      y1="1895"
                      x2="1503"
                      y2="1237"
                      fill="none"
                      stroke="white"
                      stroke-width="1"
                      stroke-dasharray="10 10"
                    ></line>
                  </svg>
                  <div class="overmap">
                    <a href="/productlist">
                      <Fade left delay={100}>
                        <div id="helmet" className="">
                          <h6>
                            Helmat / Cap &nbsp;&nbsp;
                            <i
                              style={{ color: "#1d91f7", fontSize: "27px" }}
                              className="fa fa-angle-right"
                            ></i>
                            <i
                              style={{ fontSize: "27px", color: "#b3b1b1" }}
                              className="fa fa-angle-double-right"
                            ></i>
                          </h6>
                        </div>
                      </Fade>
                    </a>
                    <a href="/productlist">
                      <Fade left delay={100}>
                        <div id="bowlershoes" className="">
                          <h6>
                            Shoes &nbsp;&nbsp;
                            <i
                              style={{ color: "#1d91f7", fontSize: "27px" }}
                              className="fa fa-angle-right"
                            ></i>
                            <i
                              style={{ fontSize: "27px", color: "#b3b1b1" }}
                              className="fa fa-angle-double-right"
                            ></i>
                          </h6>
                        </div>
                      </Fade>
                    </a>
                    <Zoom>
                      <div id="bowler2"></div>
                    </Zoom>
                    <a href="/productlist">
                      <Fade right delay={1000}>
                        <div id="gloves" className="">
                          <h6>
                            <i
                              style={{ fontSize: "27px", color: "#b3b1b1" }}
                              className="fa fa-angle-double-left"
                            ></i>
                            <i
                              style={{ color: "#1d91f7", fontSize: "27px" }}
                              className="fa fa-angle-left"
                            ></i>
                            &nbsp;&nbsp; Gloves
                          </h6>
                        </div>
                      </Fade>
                    </a>
                    <a href="/productlist">
                      <Fade right delay={1000}>
                        <div id="pads" className="">
                          <h6>
                            <i
                              style={{ fontSize: "27px", color: "#b3b1b1" }}
                              className="fa fa-angle-double-left"
                            ></i>
                            <i
                              style={{ color: "#1d91f7", fontSize: "27px" }}
                              className="fa fa-angle-left"
                            ></i>
                            &nbsp;&nbsp; Pads
                          </h6>
                        </div>
                      </Fade>{" "}
                    </a>
                  </div>
                </Tab>
              </Tabs>
            </div>
            <div className="moresport">
              <h4>More In Sports</h4>
              <Row>
                {lstGroup.length > 0 &&
                  lstGroup.map((v, i) => {
                    let gpname = v.groupName.toLowerCase();
                    if (gpname.includes("cricket") == false) {
                      return (
                        <Col xs="6" md="2">
                          <Row>
                            <a href={`/GroupList/${v.id}`}>
                              <div className="hover01 column">
                                <div className="sportnm">
                                  <figure className="sportimg">
                                    <img src={v.groupImage} />
                                  </figure>
                                  <h6>{v.groupName}</h6>
                                </div>
                              </div>
                            </a>
                          </Row>
                        </Col>
                      );
                    }
                  })}
                <Col xs="6" md="2">
                  <Row>
                    <a href="">
                      <div className="hover01 column">
                        <div className="sportnm">
                          <figure className="sportimg sportblurimg">
                            <img src={tabletannis} />
                            <p>Browse Categories</p>
                          </figure>
                        </div>
                      </div>
                    </a>
                  </Row>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
    );
  }
}
export default Batsman;

import React, { Component } from "react";
import { Layout } from "@/Pages/Layout";
import banner from "@/assets/images/banner.png";
import { Container, Breadcrumb } from "react-bootstrap";
import Fade from "react-reveal/Fade";
import "./about.css";
import AboutIntro from "@/Pages/Components/AboutComponent/AboutIntro";
import GreateReasons from "@/Pages/Components/AboutComponent/GreateReasons";
import AboutGallery from "@/Pages/Components/AboutComponent/AboutGallery";

export default class About extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Layout {...this.props}>
        <div id="About" className="pt-5" style={{overflowX:"hidden"}}>
          <div className="bannerhead">
            <Container fluid>
              <Breadcrumb className="cust-braeadcrumb pt-4">
                <Breadcrumb.Item
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.history.push(`/`);
                  }}
                >
                  Home
                </Breadcrumb.Item>

                <Breadcrumb.Item active>About Us</Breadcrumb.Item>
              </Breadcrumb>
              <h1>
                One Stop Solution
                <br />
                For all your sports needs
              </h1>
            </Container>
          </div>
          <Fade left>
            <img src={banner} className="aboutbg" alt="Banner"></img>
          </Fade>

          <AboutIntro />
          <AboutGallery />
          <GreateReasons />
        </div>
      </Layout>
    );
  }
}

import config from "config";

export function RegisterURL() {
  return `${config.apiUrl}/register-user`;
}

export function authUserURL() {
  return `${config.apiUrl}/login`;
}

export function logoutURL() {
  return `${config.apiUrl}/logout`;
}
export function authGuestUserURL() {
  return `${config.apiUrl}/registerGuestUser`;
}

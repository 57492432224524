import React, { useEffect, useState } from "react";
import { Layout } from "@/Pages/Layout";
import { Redirect } from "react-router-dom";
import { Container, Form, Row, Col, Button, Spinner } from "react-bootstrap";
import { Fade, Roll } from "react-reveal";
import "./login.css";
import Notifications, { notify } from "react-notify-toast";
import { authenticationService } from "@/services/api_function";
import LoginComponent from "./LoginComponent";

export default function LoginPage(props) {
  const [redirect, setRedirect] = useState(false);
  useEffect(() => {
    const currentUser = authenticationService.currentUserValue;
    if (currentUser) {
      setRedirect(true);
    }
  }, []);

  const renderRedirect = () => {
    if (redirect) {
      // return <Redirect to="/Dashboard" />;
      return (
        <Redirect
          to={{
            pathname: "/Dashboard",
            state: { from: props.location },
          }}
        />
      );
    }
  };
  return (
    <Layout {...props}>
      <div id="login" className="">
        {renderRedirect()}
        <Container fluid>
          <Notifications options={{ zIndex: 9999, top: "50px" }} />
          <Fade top>
            <h2>Login to access your account</h2>
          </Fade>
          <Row>
            <Col md="6">
              <Roll left>
                <LoginComponent {...props} isCheckoutLogin={false} />
              </Roll>
            </Col>
            <Col md="6">
              <Roll right>
                <div className="existing_cust new_cust pt-0 pl-4 pr-4 ">
                  <Row>
                    <Col md="10">
                      <div className="e-cust">
                        <h2>New to Ubhe Sports</h2>
                        <p>Creating an account has many benefits.</p>
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="newuser bguser">
                        <i className="fa fa-user"></i>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <div className="loginform pl-3 pr-3">
                      <Form>
                        <ul class="loginform-ul mb-0">
                          <li>
                            <i class="fa fa-check-square-o"></i> Check out
                            faster.
                          </li>
                          <li>
                            <i class="fa fa-check-square-o"></i> Save multiple
                            shipping addersses
                          </li>
                          <li>
                            <i class="fa fa-check-square-o"></i> Access your
                            address history
                          </li>
                          <li>
                            <i class="fa fa-check-square-o"></i> Track new
                            orders
                          </li>
                        </ul>
                        <div>
                          {/* <Button
                              variant="primary"
                              type="submit"
                              className="rainbow rainbow-1 mt-4 loginbtn new-u-btn"
                            >
                              Create An Account
                            </Button> */}
                          <Button
                            className="new-u-btn rainbow rainbow-1"
                            onClick={(e) => {
                              e.preventDefault();
                              // alert("create an account");
                              props.history.push("/Register");
                              // window.location.href = "/Register";
                            }}
                          >
                            Create An Account
                          </Button>
                          {/* <a
                            className="new-u-btn rainbow rainbow-1"
                            href="/Register"
                          >
                            Create An Account
                          </a> */}
                        </div>
                      </Form>
                    </div>
                  </Row>
                </div>
              </Roll>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
}

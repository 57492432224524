import {
  addDeliveryAddressUrl,
  deliveryAddressListUrl,
  getUserAddressesUrl,
  findAddressUrl,
  updateAddressUrl,
  deleteAddressUrl,
} from "@/services/api";
import axios from "axios";
import { getHeader } from "@/_helpers";

export function addDeliveryAddress(values) {
  return axios({
    url: addDeliveryAddressUrl(),
    method: "POST",
    data: values,
    headers: getHeader(),
  });
}

export function deliveryAddressList() {
  return axios({
    url: deliveryAddressListUrl(),
    method: "GET",
    headers: getHeader(),
  });
}

export function getUserAddresses() {
  return axios({
    url: getUserAddressesUrl(),
    method: "GET",
    headers: getHeader(),
  });
}

export function findAddress(values) {
  return axios({
    url: findAddressUrl(),
    method: "POST",
    headers: getHeader(),
    data: values,
  });
}

export function updateAddress(values) {
  return axios({
    url: updateAddressUrl(),
    method: "POST",
    headers: getHeader(),
    data: values,
  });
}

export function deleteAddress(values) {
  return axios({
    url: deleteAddressUrl(),
    method: "POST",
    headers: getHeader(),
    data: values,
  });
}

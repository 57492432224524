import config from "config";

export function productListByCategoryUrl() {
  return `${config.apiUrl}/product-list-category`;
}

export function productFindUrl() {
  return `${config.apiUrl}/find-product`;
}

export function productFetchUrl() {
  return `${config.apiUrl}/fetch-product`;
}

export function similarProductListByCategoryUrl() {
  return `${config.apiUrl}/similar-product-list-category`;
}

export function latestProductListUrl() {
  return `${config.apiUrl}/latest-product-list`;
}

export function getProductPriceURL() {
  return `${config.apiUrl}/getSelectedFilterPrice`;
}

export function getProductByFiltersURL() {
  return `${config.apiUrl}/product-list-category-filter`;
}

import React, { useEffect, useState } from "react";
import { Layout } from "@/Pages/Layout";
import {
  Container,
  Form,
  Row,
  Col,
  Button,
  Breadcrumb,
  FormGroup,
  Label,
} from "react-bootstrap";
import { Fade } from "react-reveal";
import "./registration.css";
import { Formik } from "formik";
import * as Yup from "yup";
import { emailRegExp, MobileRegx, pincodeReg } from "@/_helpers";
import Notifications, { notify } from "react-notify-toast";
import Select from "react-select";

import {
  authenticationService,
  getCountries,
  getStatesByCountry,
  getCitiesByState,
} from "@/services/api_function";

export default function Registrationpage(props) {
  const [countryOpt, setCountryOpt] = useState([]);
  const [stateOpt, setStateOpt] = useState([]);
  const [cityOpt, setCityOpt] = useState([]);
  useEffect(() => {
    // console.log("props history", props.history);
    // props.history.replace("/Login");
    countriesData();
  }, []);

  const countriesData = () => {
    getCountries()
      .then((response) => {
        let res = response.data;
        if (res.responseStatus == 200) {
          let country = res.responseObject;
          let countryOpt = country.map(function (values) {
            return { value: values.id, label: values.name };
          });
          setCountryOpt(countryOpt);
        }
      })
      .catch((error) => {
        console.log({ error });
      });
  };

  const statesData = (countryId) => {
    let reqData = new FormData();
    reqData.append("countryId", countryId);
    getStatesByCountry(reqData)
      .then((response) => {
        let res = response.data;
        // console.log({ res });
        if (res.responseStatus == 200) {
          let state = res.responseObject;
          let stateOpt = state.map(function (values) {
            return { value: values.id, label: values.name };
          });
          setStateOpt(stateOpt);
        }
      })
      .catch((error) => {
        console.log({ error });
      });
  };
  const customStyles = {
    control: (base) => ({
      ...base,
      height: 41,
      minHeight: 41,
      border: "1px solid #ced4da",
      padding: "0 6px",
      boxShadow: "none",
      color: "#a4a4a4",
      //lineHeight: "10",
    }),
  };
  const citiesData = (stateId) => {
    let reqData = new FormData();
    reqData.append("stateId", stateId);
    getCitiesByState(reqData)
      .then((response) => {
        let res = response.data;
        // console.log({ res });
        if (res.responseStatus == 200) {
          let city = res.responseObject;
          let cityOpt = city.map(function (values) {
            return { value: values.id, label: values.name };
          });
          setCityOpt(cityOpt);
        }
      })
      .catch((error) => {
        console.log({ error });
      });
  };

  return (
    <Layout {...props}>
      <div id="" className="registerpg">
        <div className="">
          <Container fluid>
            <Breadcrumb className="rgst-breadcrumb pt-0">
              <Breadcrumb.Item
                onClick={(e) => {
                  e.preventDefault();
                  props.history.push(`/`);
                }}
                style={{ color: "#1776a4" }}
              >
                Home
              </Breadcrumb.Item>

              <Breadcrumb.Item active>Create Account</Breadcrumb.Item>
            </Breadcrumb>
          </Container>
        </div>
        <Container fluid>
          <Notifications options={{ zIndex: 9999, top: "50px" }} />
          {/* <h2>Create New Customer Account</h2> */}
          <Row>
            <Col md="3"></Col>
            <Col md="6">
              <Fade>
                <div className="existing_cust new_cust mt-5 pt-0 pl-4 pr-4 ">
                  <Row>
                    <Col md="10">
                      <div className="e-cust">
                        <h2>Create New Customer Account</h2>
                        <p>
                          If you have an account, sign in with your email
                          address.
                        </p>
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="newuser bguser">
                        <i className="fa fa-user"></i>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <div className="registerdiv pl-3 pr-3">
                      <div className="loginhead-h4">
                        <h4>Personal Information</h4>
                        <h6></h6>
                      </div>
                      <Formik
                        validateOnChange={false}
                        validateOnBlur={false}
                        initialValues={{
                          firstName: "",
                          lastName: "",
                          email: "",
                          mobile: "",
                          password: "",
                          confirm_password: "",
                          address_line: "",
                          street_address: "",
                          cityId: "",
                          stateId: "",
                          countryId: "",
                          pincode: "",
                        }}
                        validationSchema={Yup.object().shape({
                          firstName: Yup.string()
                            .trim()
                            .required("First Name is required"),
                          lastName: Yup.string()
                            .trim()
                            .required("Last Name is required"),
                          email: Yup.string()
                            .trim()
                            .matches(emailRegExp, "email invalid")
                            .required("Email is required"),
                          mobile: Yup.string()
                            .trim()
                            .matches(MobileRegx, "Mobile invalid")
                            .required("Mobile is required"),
                          password: Yup.string()
                            .trim()
                            .required("Password is required"),
                          confirm_password: Yup.string().when("password", {
                            is: (val) => (val && val.length > 0 ? true : false),
                            then: Yup.string().oneOf(
                              [Yup.ref("password")],
                              "Both password need to be the same"
                            ),
                          }),
                          address_line: Yup.string()
                            .trim()
                            .required("Address Line is required"),
                          street_address: Yup.string()
                            .trim()
                            .required("Street Address is required"),
                          cityId: Yup.object().required("City is required"),
                          stateId: Yup.object().required("State is required"),
                          countryId: Yup.object().required(
                            "Country is required"
                          ),
                          pincode: Yup.string()
                            .trim()
                            .matches(pincodeReg, "Pincode invalid")
                            .required("Pincode is required"),
                        })}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                          const requestData = new FormData();
                          requestData.append("firstName", values.firstName);
                          requestData.append("lastName", values.lastName);
                          requestData.append("emailId", values.email);
                          requestData.append("mobileNo", values.mobile);
                          requestData.append("username", values.mobile);
                          requestData.append("password", values.password);
                          requestData.append("isAdminUser", false);

                          // Billing Address
                          requestData.append(
                            "address_line",
                            values.address_line
                          );
                          requestData.append(
                            "street_address",
                            values.street_address
                          );
                          requestData.append("pincode", values.pincode);
                          requestData.append(
                            "countryId",
                            values.countryId.value
                          );
                          requestData.append("stateId", values.stateId.value);
                          requestData.append("cityId", values.cityId.value);

                          setSubmitting(true);
                          authenticationService
                            .RegisterUser(requestData)
                            .then((response) => {
                              setSubmitting(false);
                              let res = response.data;
                              // console.log("response", res);
                              if (res.responseStatus == 200) {
                                notify.show(res.message, "success", 2000);
                                resetForm();
                                setTimeout(() => {
                                  props.history.push("/Login");
                                }, 2000);
                              } else {
                                notify.show(res.message, "error", 2000);
                                // resetForm();
                              }
                            })
                            .catch((error) => {
                              console.log("error", error);
                            });
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleSubmit,
                          isSubmitting,
                          resetForm,
                          setFieldValue,
                        }) => (
                          <Form onSubmit={handleSubmit}>
                            <Form.Group>
                              <Form.Label>FIRST NAME</Form.Label>
                              <Form.Control
                                type="text"
                                name="firstName"
                                id="firstName"
                                onChange={handleChange}
                                value={values.firstName}
                                isValid={touched.firstName && !errors.firstName}
                                isInvalid={!!errors.firstName}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.firstName}
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>LAST NAME</Form.Label>
                              <Form.Control
                                type="text"
                                name="lastName"
                                id="lastName"
                                onChange={handleChange}
                                value={values.lastName}
                                isValid={touched.lastName && !errors.lastName}
                                isInvalid={!!errors.lastName}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.lastName}
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>EMAIL ID</Form.Label>
                              <Form.Control
                                type="text"
                                name="email"
                                id="email"
                                onChange={handleChange}
                                value={values.email}
                                isValid={touched.email && !errors.email}
                                isInvalid={!!errors.email}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.email}
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>MOBILE</Form.Label>
                              <Form.Control
                                type="text"
                                name="mobile"
                                id="mobile"
                                onChange={handleChange}
                                value={values.mobile}
                                isValid={touched.mobile && !errors.mobile}
                                isInvalid={!!errors.mobile}
                                maxLength={10}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.mobile}
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>PASSWORD</Form.Label>
                              <Form.Control
                                type="password"
                                name="password"
                                id="password"
                                onChange={handleChange}
                                value={values.password}
                                isValid={touched.password && !errors.password}
                                isInvalid={!!errors.password}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.password}
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>CONFIRM PASSWORD</Form.Label>
                              <Form.Control
                                type="password"
                                name="confirm_password"
                                id="confirm_password"
                                onChange={handleChange}
                                value={values.confirm_password}
                                isValid={
                                  touched.confirm_password &&
                                  !errors.confirm_password
                                }
                                isInvalid={!!errors.confirm_password}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.confirm_password}
                              </Form.Control.Feedback>
                            </Form.Group>
                            <div className="loginhead-h4">
                              <h4>Address</h4>
                              <h6></h6>
                            </div>
                            <Form.Group>
                              <Form.Label htmlFor="address_line">
                                ADDRESS LINE
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="address_line"
                                id="address_line"
                                onChange={handleChange}
                                value={values.address_line}
                                isValid={
                                  touched.address_line && !errors.address_line
                                }
                                isInvalid={!!errors.address_line}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.address_line}
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label htmlFor="street_address">
                                STREET ADDRESS
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="street_address"
                                id="street_address"
                                onChange={handleChange}
                                value={values.street_address}
                                isValid={
                                  touched.street_address &&
                                  !errors.street_address
                                }
                                isInvalid={!!errors.street_address}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.street_address}
                              </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group>
                              <Form.Label htmlFor="countryId">
                                SELECT COUNTRY{" "}
                                <span className="redstar">*</span>
                              </Form.Label>
                              <Select
                                isClearable={true}
                                onChange={(v) => {
                                  setFieldValue("countryId", v);
                                  setFieldValue("stateId", "");
                                  setFieldValue("cityId", "");
                                  // console.log("countryId", v);
                                  if (v != null) {
                                    statesData(v.value);
                                  } else {
                                    setStateOpt([]);
                                    setCityOpt([]);
                                  }
                                }}
                                className="clsselect2"
                                name="countryId"
                                options={countryOpt}
                                value={values.countryId}
                                styles={customStyles}
                                //width="200px"
                              />
                              <span className="text-danger">
                                {errors.countryId && "Country is required"}
                              </span>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label htmlFor="stateId">
                                Select State <span className="redstar">*</span>
                              </Form.Label>
                              <Select
                                isClearable={true}
                                styles={customStyles}
                                onChange={(v) => {
                                  setFieldValue("stateId", v);
                                  setFieldValue("cityId", "");
                                  if (v != null) {
                                    citiesData(v.value);
                                  } else {
                                    setCityOpt([]);
                                  }
                                }}
                                className="clsselect2"
                                name="stateId"
                                options={stateOpt}
                                value={values.stateId}
                                width="200px"
                              />
                              <span className="text-danger">
                                {errors.stateId}
                              </span>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label htmlFor="cityId">
                                Select City <span className="redstar">*</span>
                              </Form.Label>
                              <Select
                                isClearable={true}
                                styles={customStyles}
                                onChange={(v) => {
                                  setFieldValue("cityId", v);
                                }}
                                className="clsselect2"
                                name="cityId"
                                options={cityOpt}
                                value={values.cityId}
                                width="200px"
                              />
                              <span className="text-danger">
                                {errors.cityId}
                              </span>
                            </Form.Group>

                            <Form.Group>
                              <Form.Label htmlFor="pincode">PINCODE</Form.Label>
                              <Form.Control
                                type="text"
                                name="pincode"
                                id="pincode"
                                onChange={handleChange}
                                value={values.pincode}
                                isValid={touched.pincode && !errors.pincode}
                                isInvalid={!!errors.pincode}
                                maxLength={6}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.pincode}
                              </Form.Control.Feedback>
                            </Form.Group>

                            <Row>
                              <Col md={6}>
                                <Button
                                  variant="primary"
                                  type="submit"
                                  disabled={isSubmitting}
                                  className="new-u-btn rainbow rainbow-1  loginbtn"
                                >
                                  {isSubmitting && <>Loading...</>}
                                  Create Account
                                </Button>
                              </Col>
                              <Col md={6} className="">
                                <div className="forgot">
                                  <Button
                                    className="btn btn-secodary"
                                    variant="danger"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      resetForm();
                                    }}
                                  >
                                    Reset Fields
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </Row>
                </div>
              </Fade>
            </Col>
            <Col md="3"></Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
}

import React, { Component } from "react";
import { Row, Container, Breadcrumb } from "react-bootstrap";
import emptycart from "@/assets/images/emptycart/emptycart.png";
export default class EmptyCart extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div id="productlist" className="cartproduct-list">
        <Container fluid>
          <div className="mycartproduct">
            <div className="cart-item-div" style={{ borderRight: "none" }}>
              <Breadcrumb className="profile-b-crumb rgst-breadcrumb pt-0">
                <Breadcrumb.Item
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.history.push(`/`);
                  }}
                  style={{ color: "#1776a4" }}
                >
                  Home
                </Breadcrumb.Item>

                <Breadcrumb.Item active>My Cart</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            {/* <h4>My Cart</h4> */}
            <Row>
              <div className="emptycart pt-5 mt-3 pb-5">
                <img src={emptycart} alt="Empty Cart"></img>
                <p>The cart is empty</p>
                {/* <a
                 // type="button"
                 href=""
                  class="rainbow rainbow-1 emptycartbtn btn btn-primary"
                >
                  Continue
                </a> */}
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.history.push("/");
                  }}
                  className="rainbow rainbow-1 emptycartbtn btn btn-lg btn-primary ml-3 mt-3"
                >
                  Continue Shopping
                </a>
              </div>
            </Row>
          </div>
        </Container>
      </div>
    );
  }
}

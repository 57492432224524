import React from "react";
import { Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import noproductimg from "@/assets/images/noproduct/no-product-img.png";

export default function NoProduct(props) {
  return (
    <Row>
      <div className="no-prdct-div">
        <div className="noproductimg mt-5 pt-5">
          <img src={noproductimg}></img>
          <h1 className="pl-3">Products are not available</h1>

          <a
            onClick={(e) => {
              e.preventDefault();
              props.history.push(`/`);
            }}
            className="rainbow rainbow-1 emptycartbtn btn btn-lg btn-primary ml-3 mt-3"
          >
            Continue Shopping
          </a>

          {/* <button
            type="button"
            class="rainbow rainbow-1 emptycartbtn btn btn-primary ml-3 mt-3"
            onClick={(e) => {
              e.preventDefault();
              console.log("props", props);
              // props.history.push("/");
            }}
          >
            Continue Shopping
          </button> */}
        </div>
      </div>
    </Row>
  );
}

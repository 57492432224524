import React, { Component } from "react";
import {
  Row,
  Col,
  Tabs,
  Tab,
  Button,
  Card,
  Modal,
  Form,
} from "react-bootstrap";
import Select from "react-select";

import { Formik } from "formik";
import * as Yup from "yup";
import Notifications, { notify } from "react-notify-toast";
import { applyWalletBalance } from "@/redux/cart/Action";

import { history } from "@/_helpers";
import { authenticationService, saveOrder } from "@/services/api_function";
import {
  getCountries,
  getStatesByCountry,
  getCitiesByState,
  addDeliveryAddress,
  deliveryAddressList,
  getUserWallet,
  getConversionRateListForMobile,
} from "@/services/api_function";
import { pincodeReg } from "@/_helpers";
import "./mycart.css";
import AddressForm from "./AddressForm";

class CartOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      showEditModal: false,
      deliveryAddresses: "",
      countryOpt: [],
      stateOpt: [],
      cityOpt: [],
      activeTab: 0,
      useWallet: false,
      data: "",
      convdata: "",
      cartData: "",
    };
  }

  countriesData = () => {
    getCountries()
      .then((response) => {
        let res = response.data;
        if (res.responseStatus == 200) {
          let country = res.responseObject;
          let countryOpt = country.map(function (values) {
            return { value: values.id, label: values.name };
          });
          this.setState({ countryOpt: countryOpt });
        }
      })
      .catch((error) => {
        console.log({ error });
      });
  };

  deliveryAddressData = () => {
    deliveryAddressList()
      .then((response) => {
        let res = response.data;
        if (res.responseStatus == 200) {
          this.setState({ deliveryAddresses: res.responseObject });
        }
      })
      .catch((error) => {
        console.log({ error });
      });
  };

  statesData = (countryId) => {
    let reqData = new FormData();
    reqData.append("countryId", countryId);
    getStatesByCountry(reqData)
      .then((response) => {
        let res = response.data;
        // console.log({ res });
        if (res.responseStatus == 200) {
          let state = res.responseObject;
          let stateOpt = state.map(function (values) {
            return { value: values.id, label: values.name };
          });
          this.setState({ stateOpt: stateOpt });
        }
      })
      .catch((error) => {
        console.log({ error });
      });
  };

  citiesData = (stateId) => {
    let reqData = new FormData();
    reqData.append("stateId", stateId);
    getCitiesByState(reqData)
      .then((response) => {
        let res = response.data;
        // console.log({ res });
        if (res.responseStatus == 200) {
          let city = res.responseObject;
          let cityOpt = city.map(function (values) {
            return { value: values.id, label: values.name };
          });
          this.setState({ cityOpt: cityOpt });
        }
      })
      .catch((error) => {
        console.log({ error });
      });
  };

  checkUserLogin = (key) => {
    if (authenticationService.currentUserValue != null) {
      this.setState({ activeTab: key });
    } else {
      notify.show("Please login to complete order process", "error", 2000);
      setTimeout(() => {
        this.props.history.push("/Login");
      }, 1500);
    }
  };

  componentDidMount = () => {
    // this.countriesData();
    // this.deliveryAddressData();
    // this.getUserWalletData();
    // this.getConversionRateListForMobileData();
  };
  handleChange = (status) => {
    this.setState({ useWallet: status });
  };

  getUserWalletData = () => {
    // let reqData = new FormData();
    // reqData.append("userId", authenticationService.currentUserValue.userId);
    getUserWallet()
      .then((response) => {
        let res = response.data;
        console.log("responser wallet", res);
        if (res.responseStatus == 200) {
          this.setState({
            data: res,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  getConversionRateListForMobileData = () => {
    let reqData = new FormData();
    reqData.append("userId", authenticationService.currentUserValue.userId);
    getConversionRateListForMobile(reqData)
      .then((response) => {
        let res = response.data;
        console.log("responser conversion", res);
        if (res.responseStatus == 200) {
          this.setState({
            convdata: res,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  render() {
    const { handleClose, cartData } = () => this.state;
    const cartReducer = this.props.cartReducer;
    // console.log({ cartReducer });
    const {
      show,
      showEditModal,
      countryOpt,
      stateOpt,
      cityOpt,
      deliveryAddresses,
      activeTab,
      useWallet,
      data,
      convdata,
    } = this.state;
    return (
      <div id="cartoverview">
        <Notifications options={{ zIndex: 9999, top: "50px" }} />
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{
            deliveryAddressId: "",
            paymentMode: "cod",
            amount: 0,
            points: 0,
          }}
          validationSchema={Yup.object().shape({})}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            this.setState({ cartData: values });

            setSubmitting(true);
            console.log("Sneha", { values });
            const requestData = new FormData();
            const serializedState = localStorage.getItem("state");
            let cartProducts = "";
            let grandTotalDiscount = 0;
            let grandTotalMrp = 0;
            let grandTotalPrice = 0;
            if (cartReducer != null && cartReducer.cart != null) {
              console.log("state", JSON.parse(serializedState));
              cartProducts = JSON.stringify(cartReducer.cart);
              grandTotalDiscount = cartReducer.grandTotalDiscount;
              grandTotalMrp = cartReducer.grandTotalMrp;
              grandTotalPrice = cartReducer.grandTotalPrice;
            }
            requestData.append("cartProducts", cartProducts);
            requestData.append("grandTotalDiscount", grandTotalDiscount);
            requestData.append("grandTotalMrp", grandTotalMrp);
            requestData.append("grandTotalPrice", grandTotalPrice);

            requestData.append("deliveryAddressId", values.deliveryAddressId);
            requestData.append("paymentMode", values.paymentMode);
            requestData.append("paymentStatus", "completed");
            requestData.append("orderFrom", 1);
            console.log(" values.amount", values.amount);
            requestData.append("amount", values.amount);
            requestData.append("points", values.amount / convdata.rate);

            saveOrder(requestData)
              .then((response) => {
                setSubmitting(false);
                let res = response.data;
                // console.log("response", res);
                if (res.responseStatus == 200) {
                  notify.show(res.message, "success", 2000);
                  resetForm();
                  this.props.clearCart();
                  setTimeout(() => {
                    history.push("/Dashboard");
                  }, 1500);
                } else {
                  notify.show(res.message, "error", 2000);
                  // resetForm();
                }
              })
              .catch((error) => {
                console.log("error", error);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            resetForm,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className="carttab1 mt-4">
                <h3>Price Details</h3>
                <ul>
                  <li>
                    <Row>
                      <Col md="6"  className="detailfont">
                        Price (
                        {this.props.cartReducer != null &&
                        this.props.cartReducer.cart != null
                          ? this.props.cartReducer.cart.length
                          : 0}{" "}
                        Products)
                      </Col>
                      <Col md="6" className="card-total detailfont">
                        <i className="fa fa-inr"></i>{" "}
                        {this.props.cartReducer &&
                        this.props.cartReducer.grandTotalPrice != false
                          ? parseFloat(
                              this.props.cartReducer.grandTotalPrice
                            ).toFixed(2)
                          : 0}
                        /-
                      </Col>
                    </Row>
                  </li>
                  <li>
                    <Row>
                      <Col md="6" className="detailfont">Discount</Col>
                      <Col md="6" className="card-total detailfont">
                        <i className="fa fa-inr"></i> {/* -{" "} */}
                        {this.props.cartReducer &&
                        this.props.cartReducer.grandTotalDiscount != null
                          ? parseFloat(
                              this.props.cartReducer.grandTotalDiscount
                            ).toFixed(2)
                          : 0}{" "}
                        /-
                      </Col>
                    </Row>
                  </li>

                  <li
                    className="t-order"
                    style={{
                      borderBottom: "1px dashed #ccc",
                      borderTop: "1px dashed #ccc",
                      marginTop: "10px",
                      marginBottom: "10px",
                      background: "#f9f9f9",
                    }}
                  >
                    <Row>
                      <Col md="6">Total Order</Col>
                      <Col md="6" className="c-total">
                        <i className="fa fa-inr"></i>{" "}
                        {this.props.cartReducer &&
                        this.props.cartReducer.grandTotalMrp != false
                          ? parseFloat(
                              this.props.cartReducer.grandTotalMrp
                            ).toFixed(2)
                          : 0}
                        /-
                      </Col>
                    </Row>
                  </li>
                </ul>

                {/* <Button
                  variant="primary"
                  type="submit"
                  disabled={isSubmitting}
                  className="rainbow c-place-orderbtn"
                >
                  {isSubmitting == true ? "Submitting..." : "Place Order"}
                </Button> */}
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.history.push(`/ShippingAddress`);
                  }}
                  className="rainbow c-place-orderbtn"
                >
                  Place Order
                </a>
              </div>
              <div>
                {/* <Form.Group className="createnew">
                  <Form.Label>Branch</Form.Label>
                  <Select
                    className="selectTo"
                    styles={customStyles}
                    isClearable={true}
                    isDisabled={true}
                    onChange={(v) => {
                      setFieldValue("branchId", "");

                      setFieldValue("standardId", "");
                      setFieldValue("academicYearId", "");
                      setFieldValue("divisionId", "");
                      if (v != null) {
                        setFieldValue("branchId", v);

                        this.getStandardData(v.value);
                        this.getAcademicYearData(v.value);
                      } else {
                        this.setState({
                          opStandardList: [],
                          opAcademicYearList: [],
                        });
                      }
                    }}
                    name="branchId"
                    options={opBranchList}
                    value={values.branchId}
                  />
                </Form.Group> */}
                {/* <Button
                  type="button"
                  className="cancelbtn submitbtn ms-2 "
                  variant="secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    this.transFormRef.current.resetForm();
                    this.setInitValue();
                  }}
                >
                  Reset
                  <img src={reset} className="btnico ms-1"></img>
                </Button> */}
              </div>
              {/* </Tab>
              </Tabs> */}
            </Form>
          )}
        </Formik>
        <Modal
          show={show}
          onHide={() => this.setState({ show: false })}
          dialogClassName="modal-400w"
          animation={true}
          aria-labelledby="contained-modal-title-vcenter"
          className="modal-d-address"
          centered
        >
          <Modal.Header closeButton className="p-2 d-m-heading">
            <Modal.Title id="example-custom-modal-styling-title">
              <h5>Delivery Address</h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="galleryumodal">
            <Formik
              validateOnChange={false}
              validateOnBlur={false}
              initialValues={{
                address_line: "",
                street_address: "",
                cityId: "",
                stateId: "",
                countryId: "",
                pincode: "",
              }}
              validationSchema={Yup.object().shape({
                address_line: Yup.string()
                  .trim()
                  .required("Address Line is required"),
                street_address: Yup.string()
                  .trim()
                  .required("Street Address is required"),
                cityId: Yup.object().required("City is required"),
                stateId: Yup.object().required("State is required"),
                countryId: Yup.object().required("Country is required"),
                pincode: Yup.string()
                  .trim()
                  .matches(pincodeReg, "Pincode invalid")
                  .required("Pincode is required"),
              })}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                const requestData = new FormData();
                // Delivery Address
                requestData.append("address_line", values.address_line);
                requestData.append("street_address", values.street_address);
                requestData.append("pincode", values.pincode);
                requestData.append("countryId", values.countryId.value);
                requestData.append("stateId", values.stateId.value);
                requestData.append("cityId", values.cityId.value);

                setSubmitting(true);
                addDeliveryAddress(requestData)
                  .then((response) => {
                    setSubmitting(false);
                    let res = response.data;
                    // console.log("response", res);
                    if (res.responseStatus == 200) {
                      notify.show(res.message, "success", 2000);
                      // resetForm();
                      // setTimeout(() => {
                      //   props.history.push("/Login");
                      // }, 2000);
                    } else {
                      notify.show(res.message, "error", 2000);
                      // resetForm();
                    }
                  })
                  .catch((error) => {
                    console.log("error", error);
                  });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                isSubmitting,
                resetForm,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col md="12">
                      <Form.Group controlId="ragistration.ControlTextarea1">
                        <Form.Label>ADDRESS LINE</Form.Label>
                        <Form.Control
                          as="textarea"
                          placeholder="Enter Address Line"
                          rows={2}
                          name="address_line"
                          id="address_line"
                          onChange={handleChange}
                          value={values.address_line}
                          isValid={touched.address_line && !errors.address_line}
                          isInvalid={!!errors.address_line}
                        />
                      </Form.Group>
                    </Col>
                    <Col md="12">
                      <Form.Group controlId="ragistration.ControlTextarea2">
                        <Form.Label>STREET ADDRESS</Form.Label>
                        <Form.Control
                          as="textarea"
                          placeholder="Enter Street Address"
                          rows={2}
                          name="street_address"
                          id="street_address"
                          onChange={handleChange}
                          value={values.street_address}
                          isValid={
                            touched.street_address && !errors.street_address
                          }
                          isInvalid={!!errors.street_address}
                        />
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group controlId="formcity">
                        <Form.Label>COUNTRY</Form.Label>
                        <Select
                          isClearable={true}
                          onChange={(v) => {
                            setFieldValue("countryId", v);
                            setFieldValue("stateId", "");
                            setFieldValue("cityId", "");
                            if (v != null) {
                              this.statesData(v.value);
                            } else {
                              this.setState({ stateOpt: [], cityOpt: [] });
                            }
                          }}
                          className="clsselect2"
                          name="countryId"
                          options={countryOpt}
                          value={values.countryId}
                          width="200px"
                        />
                        <span className="text-danger">
                          {errors.countryId && "Country is required"}
                        </span>
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group controlId="formstate">
                        <Form.Label>STATE</Form.Label>
                        <Select
                          isClearable={true}
                          onChange={(v) => {
                            setFieldValue("stateId", v);
                            setFieldValue("cityId", "");
                            if (v != null) {
                              this.citiesData(v.value);
                            } else {
                              this.setState({ cityOpt: [] });
                            }
                          }}
                          className="clsselect2"
                          name="stateId"
                          options={stateOpt}
                          value={values.stateId}
                          width="200px"
                        />
                        <span className="text-danger">
                          {errors.stateId && "State is required."}
                        </span>
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group controlId="formcountry">
                        <Form.Label>CITY</Form.Label>
                        <Select
                          isClearable={true}
                          onChange={(v) => {
                            setFieldValue("cityId", v);
                          }}
                          className="clsselect2"
                          name="cityId"
                          options={cityOpt}
                          value={values.cityId}
                          width="200px"
                        />
                        <span className="text-danger">
                          {errors.cityId && "City is required."}
                        </span>
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group controlId="formpincode">
                        <Form.Label>PINCODE</Form.Label>
                        <Form.Control
                          type="text"
                          name="pincode"
                          id="pincode"
                          onChange={handleChange}
                          value={values.pincode}
                          isValid={touched.pincode && !errors.pincode}
                          isInvalid={!!errors.pincode}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={isSubmitting}
                    className="new-u-btn rainbow rainbow-1  loginbtn"
                  >
                    {isSubmitting && <>Saving...</>}
                    Submit
                  </Button>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </Modal>

        {/* Delivery Address Edit Modal  */}
        <Modal
          show={showEditModal}
          onHide={() => this.setState({ showEditModal: false })}
          dialogClassName="modal-400w"
          animation={true}
          aria-labelledby="contained-modal-title-vcenter"
          className="modal-d-address"
          centered
        >
          <Modal.Header closeButton className="p-2 d-m-heading">
            <Modal.Title id="example-custom-modal-styling-title">
              <h5>Edit Delivery Address</h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="galleryumodal">
            <AddressForm />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default CartOverview;

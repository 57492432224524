import React, { Component } from "react";
import noorder from "@/assets/images/noorder/noorder.png";

export default class NoOrder extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div>
        <div className="no-prdct noorderimg mb-5 mt-5">
          <img src={noorder} alt="My Cart"></img>
          <h4 className="mt-2 mb-2">Order list empty</h4>
          <p>You have not placed any order yet.</p>
          <button
            onClick={(e) => {
              e.preventDefault();
              this.props.history.push(`/`);
            }}
            type="button"
            className="rainbow rainbow-1 mt-3 emptycartbtn btn btn-primary"
          >
            Shop Now
          </button>
        </div>
      </div>
    );
  }
}

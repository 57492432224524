import React, { useState } from "react";
import "./sidebar.css";
import { ListGroup } from "react-bootstrap";
import { Slide } from "react-reveal";
import { authenticationService } from "@/services/api_function";
import { Redirect } from "react-router-dom";

export default function Sidebar(props) {
  const [redirect, setRedirect] = useState(false);
  let pathname = window.location.pathname;

  const logoutUser = () => {
    authenticationService.logout();
    setRedirect(true);
  };
  const renderRedirect = () => {
    if (redirect) {
      return <Redirect to="/Login" />;
    }
  };
  return (
    <div id="aboutintro">
      <Slide left>
        <div className="myaccount">
          <ListGroup>
            {authenticationService.currentUserValue &&
              authenticationService.currentUserValue.isGuest != true && (
                <>
                  <ListGroup.Item
                    action
                    onClick={(e) => {
                      e.preventDefault();
                      props.history.push(`/Dashboard`);
                    }}
                    className={`${
                      pathname == "/Dashboard" || pathname == "/EditAccount"
                        ? "active"
                        : ""
                    }`}
                  >
                    <span className="pull-left">Dashboard</span>
                    <span className="pull-right">
                      <i class="fa fa-angle-right" aria-hidden="true"></i>
                    </span>
                  </ListGroup.Item>
                  <ListGroup.Item
                    action
                    onClick={(e) => {
                      e.preventDefault();
                      props.history.push(`/MyOrders`);
                    }}
                    className={`${
                      pathname == "/MyOrders" ||
                      pathname.includes("/orderDetails")
                        ? "active"
                        : ""
                    }`}
                  >
                    <span className="pull-left">My Orders</span>
                    <span className="pull-right">
                      <i class="fa fa-angle-right" aria-hidden="true"></i>
                    </span>
                  </ListGroup.Item>
                  <ListGroup.Item
                    action
                    onClick={(e) => {
                      e.preventDefault();
                      props.history.push(`/Mywishlist`);
                    }}
                    className={`${pathname == "/Mywishlist" ? "active" : ""}`}
                  >
                    <span className="pull-left">My Wishlist</span>
                    <span className="pull-right">
                      <i class="fa fa-angle-right" aria-hidden="true"></i>
                    </span>
                  </ListGroup.Item>
                  <ListGroup.Item
                    action
                    onClick={(e) => {
                      e.preventDefault();
                      props.history.push(`/Walletcart`);
                    }}
                    className={`${pathname == "/Walletcart" ? "active" : ""}`}
                  >
                    <span className="pull-left">My Wallet</span>
                    <span className="pull-right">
                      <i class="fa fa-angle-right" aria-hidden="true"></i>
                    </span>
                  </ListGroup.Item>
                </>
              )}

            <ListGroup.Item
              action
              href="javascript:void(0)"
              onClick={logoutUser}
            >
              <span className="pull-left">Logout</span>
              <span className="pull-right">
                <i class="fa fa-angle-right" aria-hidden="true"></i>
              </span>
            </ListGroup.Item>
          </ListGroup>
          {renderRedirect()}
        </div>
      </Slide>
    </div>
  );
}

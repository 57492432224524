import React, { Component } from "react";

import Fade from "react-reveal/Fade";
import "./product.css";
import { Col, Row, Button } from "react-bootstrap";
import { printProductName } from "@/_helpers";
import Notifications, { notify } from "react-notify-toast";
class ProductCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productDetails: "",
    };
  }

  componentDidMount() {
    // console.log("this.props  productDetails", this.props);
    this.setState({ productDetails: this.props.productDetails });
  }
  componentDidUpdate(prevProps) {
    // console.log("update this.props  productDetails", this.props);
    if (this.props.productDetails !== prevProps.productDetails) {
      this.setState({ productDetails: this.props.productDetails });
    }
  }
  addToCartFN = ({
    id,
    name,
    price,
    mrp,
    discountType,
    discount,
    unit,
    brandName,
    firstImage,
    discountInPercentage,
    discountInAmount,
    filters,
  }) => {
    this.props.addToCart({
      id,
      name,
      price,
      mrp,
      discountType,
      discount,
      unit,
      brandName,
      firstImage,
      discountInPercentage,
      discountInAmount,
      filters,
    });

    notify.show("Product added to cart", "success", 2000);
  };
  render() {
    // console.log("cart from store ", this.props.cartReducer);
    // const { cart } = this.props;
    const { productDetails } = this.state;
    return (
      <Col md="6" lg="3" sm="4">
        <Notifications options={{ zIndex: 9999, top: "50px" }} />

        <div className="productitem grid1 post">
          <figure class="effect-kira1">
            <a
              onClick={(e) => {
                e.preventDefault();
                this.props.history.push(
                  `/ProductDetail/${productDetails.productId}`
                );
              }}
            >
              {productDetails.discountType != "none" ? (
                <div className="disoffer">
                  {productDetails.discountLabel} Offer
                </div>
              ) : (
                <div className="disoffer" style={{ background: "transparent" }}>
                  &nbsp;
                </div>
              )}
              <Fade duration={2000}>
                <img
                  src={productDetails.firstImage}
                  alt={
                    productDetails &&
                    printProductName(productDetails.productName)
                  }
                ></img>
              </Fade>
            </a>
            <div className="productitem-info">
              <h5>
                {productDetails && printProductName(productDetails.productName)}
              </h5>
              <h6>
                <i className="fa fa-inr"></i>{" "}
                {productDetails ? parseFloat(productDetails.mrp).toFixed(2) : 0}
                /-&nbsp;&nbsp;
                {productDetails.discountType != "none" && (
                  <s>
                    <i className="fa fa-inr"></i>{" "}
                    {productDetails
                      ? parseFloat(productDetails.basePrice).toFixed(2)
                      : 0}
                    /-
                  </s>
                )}
              </h6>
              {/* <Button
            // onClick={() => {
            //   this.addToCartFN({
            //     id: productDetails.productId,
            //     name: productDetails.productName,
            //     price: productDetails.basePrice,
            //     mrp: productDetails.mrp,
            //     discountType: productDetails.discountType,
            //     discount: productDetails.discount,
            //     unit: 1,
            //     brandName: productDetails.brandName,
            //     firstImage: productDetails.firstImage,
            //     discountInPercentage: productDetails.discountInPercentage,
            //     discountInAmount: productDetails.discountInAmount,
            //     filters: productDetails.filterPriceDTOList[0],
            //   });
            // }}
            >
              View Details
            </Button> */}
              <a
                onClick={(e) => {
                  e.preventDefault();
                  this.props.history.push(
                    `/ProductDetail/${productDetails.productId}`
                  );
                }}
              >
                View Details
              </a>
            </div>
          </figure>
        </div>
      </Col>
    );
  }
}

export { ProductCard };

import {
  saveOrderUrl,
  fetchUserOrdersUrl,
  fetchUserOrderProductsUrl,
  saveOrderRepairFromWebsiteUrl,
} from "@/services/api";
import axios from "axios";
import { getHeader } from "@/_helpers";

export function saveOrder(values) {
  return axios({
    url: saveOrderUrl(),
    method: "POST",
    data: values,
    headers: getHeader(),
  });
}

export function fetchUserOrders() {
  return axios({
    url: fetchUserOrdersUrl(),
    method: "GET",
    headers: getHeader(),
  });
}

export function fetchUserOrderProducts(values) {
  return axios({
    url: fetchUserOrderProductsUrl(),
    method: "POST",
    data: values,
    headers: getHeader(),
  });
}

export function saveOrderRepairFromWebsite(values) {
  return axios({
    url: saveOrderRepairFromWebsiteUrl(),
    method: "POST",
    data: values,
    headers: getHeader(),
  });
}

import axios from "axios";
import {
  listOfLocationsForSelectUrl,
  getContactSupportDataUrl,
} from "@/services/api";
import { authHeader } from "@/_helpers";

export function listOfLocationsForSelect() {
  return axios({
    url: listOfLocationsForSelectUrl(),
    method: "GET",
  });
}

export function getContactSupportData() {
  return axios({
    headers: authHeader(),
    url: getContactSupportDataUrl(),
    method: "GET",
  });
}

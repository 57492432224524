import React, { Component } from "react";
import Zoom from "react-reveal/Zoom";
import Item from "./item";
import brandcarousel from "./brandcarousel.css";
import Nav from "react-bootstrap/Nav";
import Carousel from "react-elastic-carousel";
import { history } from "@/_helpers";
import { getbrandList } from "@/services/api_function";
import { Link } from "react-router-dom";
class BrandCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lstBrand: [],
    };
  }

  lstBrandList = () => {
    // console.log("brandlist");
    getbrandList()
      .then((response) => {
        let res = response.data;
        if (res.responseStatus == 200) {
          let b_list = res.responseObject;
          if (b_list.length > 0) {
            this.setState({ lstBrand: b_list });
          }
        }
      })
      .catch((error) => {
        this.setState({ lstBrand: [] });
        console.log("error", error);
      });
  };

  componentDidMount() {
    // console.log("did mount");
    this.lstBrandList();
  }

  render() {
    const breakPoints = [
      { width: 1, itemsToShow: 1 },
      { width: 550, itemsToShow: 4 },
      { width: 768, itemsToShow: 3 },
      { width: 1200, itemsToShow: 6 },
      { width: 1306, itemsToShow: 4 },
      { width: 1366, itemsToShow: 6 },
    ];
    const { lstBrand } = this.state;
    return (
      <div className="branddiv">
        <Carousel breakPoints={breakPoints}>
          {lstBrand.length > 0 &&
            lstBrand.map((v, i) => {
              return (
                <Item>
                  <Zoom>
                    <a
                      className="brand-cursor"
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/BrandList/` + v.id);
                      }}
                    >
                      <img src={v.brandImage} alt={v.brandName}></img>
                    </a>
                  </Zoom>
                </Item>
              );
            })}
        </Carousel>
      </div>
    );
  }
}
export default BrandCarousel;

import React from "react";
import Fade from "react-reveal/Fade";
import { Container, Row, Col } from "react-bootstrap";
import InstagramEmbed from "react-instagram-embed";

import smile from "@/assets/images/smile.png";
import star from "@/assets/images/star.png";
import pic1 from "@/assets/images/pic1.png";
import instapic2 from "@/assets/images/instapic2.png";
import instapic3 from "@/assets/images/instapic3.png";
import instapic4 from "@/assets/images/instapic4.png";
import instapic5 from "@/assets/images/instapic5.png";
import "./viewinstagram.css";
class ViewInstagram extends React.Component {
  render() {
    return (
      <div className="viewinstabg pt-5">
        <Container fluid>
          <div className="p-5 instagram">
            <Row>
              <Col lg="8" md="12">
                <Row>
                  <Col md="4">
                    <InstagramEmbed
                      // url="https://www.instagram.com/p/B4FQ-CyFsav/"
                      // clientAccessToken="667b7882978c2eeb09fc791a83451ec2"
                      maxWidth={320}
                      hideCaption={false}
                      containerTagName="div"
                      protocol=""
                      injectScript
                      onLoading={() => {}}
                      onSuccess={() => {}}
                      onAfterRender={() => {}}
                      onFailure={() => {}}
                    />

                    <Row>
                      <div className="hover01 column">
                        <div>
                          <figure>
                            <img src={pic1} alt="Instagram" />
                          </figure>
                        </div>
                      </div>
                    </Row>
                  </Col>
                  <Col md="4">
                    <Row>
                      <div className="hover01 column">
                        <div>
                          <figure>
                            <img src={instapic2} alt="Instagram" />
                          </figure>
                        </div>
                      </div>
                    </Row>
                  </Col>
                  <Col md="4">
                    <Row>
                      <div className="hover01 column">
                        <div>
                          <figure>
                            <img
                              src={instapic3}
                              alt="Instagram"
                              alt="Instagram"
                            />
                          </figure>
                        </div>
                      </div>
                    </Row>
                  </Col>
                  <Col md="4">
                    <Row>
                      <div className="hover01 column">
                        <div>
                          <figure>
                            <img src={instapic4} alt="Instagram" />
                          </figure>
                        </div>
                      </div>
                    </Row>
                  </Col>
                  <Col md="4">
                    <Row>
                      <div className="hover01 column">
                        <div>
                          <figure>
                            <img src={instapic5} alt="Instagram" />
                          </figure>
                        </div>
                      </div>
                    </Row>
                  </Col>
                  <Col md="4">
                    <Row>
                      <div className="hover01 column">
                        <div>
                          <figure>
                            <img src={instapic2} alt="Instagram" />
                          </figure>
                        </div>
                      </div>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col lg="4" md="12">
                <div className="viewinsta pt-5">
                  <img src={smile} alt="Smile"></img>
                  <h4 className="pt-3">
                    Delivered Happiness :) <br />
                    to our customers
                  </h4>
                  <img src={star} alt="Smile" resizeMode="repeat"></img>&nbsp;
                  <img src={star} alt="Smile" resizeMode="repeat"></img>&nbsp;
                  <img src={star} alt="Smile" resizeMode="repeat"></img>&nbsp;
                  <img src={star} alt="Smile" resizeMode="repeat"></img>&nbsp;
                  <img src={star} alt="Smile" resizeMode="repeat"></img>
                  <p className="pt-3 pb-3">
                    We have served thousands of customers
                    <br /> with a delightful experience
                  </p>
                  <a className="rainbow rainbow-1" href="">
                    View In Instagram
                  </a>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    );
  }
}
export default ViewInstagram;

import React, { Component } from "react";
import WishList from "@/Pages/Components/WishlistComponent/WishList";
import Notifications, { notify } from "react-notify-toast";
// import { Layout } from "@/Pages/Layout";
import { SidebarLayout } from "@/Pages/Layout";
class MyWishList extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <SidebarLayout
        breadcrum_parent_name_url="/Dashboard"
        breadcrum_parent_name="My Account"
        breadcrum_child_name="My Wishlist"
        {...this.props}
      >
        <Notifications options={{ zIndex: 9999, top: "50px" }} />

        <WishList {...this.props} />
      </SidebarLayout>
    );
  }
}

export default MyWishList;

import React, { Component } from "react";
import Carousel from "react-spring-3d-carousel";
import { v4 as uuidv4 } from "uuid";
import { config } from "react-spring";
import { bindActionCreators } from "redux";
import { addToCart } from "@/redux/cart/Action";
import { connect } from "react-redux";
import { latestProductList } from "@/services/api_function";
import { printProductName } from "@/_helpers";
import Notifications, { notify } from "react-notify-toast";

import Fade from "react-reveal/Fade";
import "./latestarrival.css";
class LatestArrivals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      goToSlide: 0,
      offsetRadius: 2,
      Navigation: true,
      config: config.gentle,
      lstLatestProducts: "",
      slides: "",
    };
  }

  addToCartFN = ({
    id,
    name,
    price,
    mrp,
    discountType,
    discount,
    unit,
    brandName,
    firstImage,
    discountInPercentage,
    discountInAmount,
    filters,
  }) => {
    this.props.addToCart({
      id,
      name,
      price,
      mrp,
      discountType,
      discount,
      unit,
      brandName,
      firstImage,
      discountInPercentage,
      discountInAmount,
      filters,
    });

    notify.show("Product added to cart", "success", 2000);
  };
  getLatestProductList = () => {
    // console.log("props", this.props);
    latestProductList()
      .then((response) => {
        let res = response.data;
        if (res.responseStatus == 200) {
          // console.log("res.responseObject ", res.responseObject);
          this.setState({
            lstLatestProducts: res.responseObject,
          });
          const { goToSlide } = this.state;

          let imageResult = res.responseObject.map((product, index) => {
            return {
              key: uuidv4(),
              content: (
                <div className="">
                  <div className="arrivalimg">
                    <img src={product.firstImage} alt={product.firstImage} />
                  </div>
                  <div className="productnm">
                    <h5>{printProductName(product.productName)} </h5>
                    <p>
                      <i className="fa fa-inr"></i>
                      {product ? parseFloat(product.mrp).toFixed(2) : 0}
                      /-{" "}
                      {product.discountType != "none" && (
                        <s>
                          {product
                            ? parseFloat(product.basePrice).toFixed(2)
                            : 0}
                          /-
                        </s>
                      )}
                    </p>

                    <a
                      // href={`/productDetail/${product.productId}`}
                      onClick={(e) => {
                        e.preventDefault();
                        console.warn("clicked");
                        this.props.history.push(
                          `/productDetail/${product.productId}`
                        );
                      }}
                      // onClick={(e) => {
                      //   e.preventDefault();
                      //   // console.log("props", this.props);
                      //   this.addToCartFN({
                      //     id: product.productId,
                      //     name: product.productName,
                      //     price: product.basePrice,
                      //     mrp: product.mrp,
                      //     discountType: product.discountType,
                      //     discount: product.discount,
                      //     unit: 1,
                      //     brandName: product.brandName,
                      //     firstImage: product.firstImage,
                      //     discountInPercentage: product.discountInPercentage,
                      //     discountInAmount: product.discountInAmount,
                      //     filters: product.filterPriceDTOList[0],
                      //   });
                      // }}
                    >
                      View Details
                    </a>
                  </div>
                </div>
              ),
            };
          });
          imageResult = imageResult.map((slide, index) => {
            return {
              ...slide,
              onClick: () => this.setState({ goToSlide: index }),
            };
          });

          this.setState({ slides: imageResult });
        }
      })
      .catch((error) => {
        this.setState({ lstLatestProducts: "" });
        console.log("error", error);
      });
  };

  componentDidMount() {
    this.getLatestProductList();
  }

  componentWillReceiveProps(prevProps) {
    this.getLatestProductList();
  }

  onChangeInput = (e) => {
    this.setState({
      [e.target.name]: parseInt(e.target.value, 10) || 0,
    });
  };

  render() {
    const { slides, goToSlide, offsetRadius, config } = this.state;
    console.log({ goToSlide });
    return (
      <div className="latest">
        <Notifications options={{ zIndex: 9999, top: "50px" }} />
        <div className="arrival pt-5 pb-4">
          <Fade top>
            <h1>LATEST ARRIVALS</h1>
          </Fade>
        </div>
        <div
          style={{ width: "80%", height: "500px", margin: "0 auto" }}
          className="latestgallery"
        >
          <Carousel
            slides={slides}
            goToSlide={goToSlide}
            offsetRadius={offsetRadius}
            showNavigation={true}
            animationConfig={config}
          />
          {/* <div
            style={{
              margin: "0 auto",
              marginTop: "2rem",
              width: "50%",
              display: "flex",
              justifyContent: "space-around",
            }}
          ></div> */}
        </div>
      </div>
    );
  }
}

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      addToCart,
    },
    dispatch
  );
};

export default connect(null, mapActionsToProps)(LatestArrivals);

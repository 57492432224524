import React, { Component } from "react";
import Slide from "react-reveal/Slide";
import { SidebarLayout } from "@/Pages/Layout";
import { Col, Row } from "react-bootstrap";
import Notifications, { notify } from "react-notify-toast";
import moment from "moment";
import "./myorder.css";
import NoOrder from "./NoOrder";
import { fetchUserOrderProducts } from "@/services/api_function";
import { bindActionCreators } from "redux";
import { addToCart } from "@/redux/cart/Action";
import { connect } from "react-redux";

class MyOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderId: "",
      orderProductList: [],
    };
  }

  fetchUserOrderProductsFun = () => {
    let values = new FormData();
    values.append("orderId", this.state.orderId);
    fetchUserOrderProducts(values)
      .then((response) => {
        // console.log({ response });
        let res = response.data;
        if (res.responseStatus == 200) {
          this.setState({ orderProductList: res.responseObject });
        } else {
          notify.show(res.message, "error", 2000);
        }
      })
      .catch((error) => {
        console.log({ error });
      });
  };
  componentDidMount() {
    // console.log("this.props.match.params.id ", this.props.match.params.id);
    this.setState({ orderId: this.props.match.params.id }, () => {
      this.fetchUserOrderProductsFun();
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.setState({ orderId: this.props.match.params.id }, () => {
        this.fetchUserOrderProductsFun();
      });
    }
  }

  render() {
    const { orderProductList } = this.state;
    return (
      <SidebarLayout
        breadcrum_parent_name_url="/MyOrders"
        breadcrum_parent_name="My Orders"
        breadcrum_child_name="Order Details"
        {...this.props}
      >
        <Notifications options={{ zIndex: 9999, top: "50px" }} />
        <Slide right>
          <div className="myorder">
            {orderProductList.length > 0 ? (
              orderProductList.map((product) => {
                let filterList =
                  product.filters != null ? JSON.parse(product.filters) : "";
                return (
                  <div className="o-list">
                    <Row>
                      <Col lg="2" md="3" className="o-list-img">
                        <img src={product.productImage} alt="Cap"></img>
                      </Col>
                      <Col lg="7" md="9">
                        <div className="order-det">
                          <h6>Order Id: {product.orderNo}</h6>
                          <h4>{product.productName}</h4>

                          {filterList &&
                            filterList != "" &&
                            filterList.map((vi, ii) => {
                              return (
                                <p style={{ paddingTop: "0", margin: 0 }}>
                                  {vi.filterName} :{" "}
                                  <span>{vi.subfilterName}</span>
                                </p>
                              );
                            })}

                          <p style={{ paddingTop: "0", margin: 0 }}>
                            {moment(product.orderDate).format(
                              "ddd D MMM YYYY HH:MM A"
                            )}{" "}
                          </p>
                          {/* <p>5th Jan 2021, 12:13 pm </p> */}
                        </div>
                      </Col>
                      <Col lg="3" md="12">
                        <div className="o-delivery">
                          <p>{product.orderStatus}</p>
                          <h5>
                            Total : <i className="fa fa-inr"> </i>{" "}
                            {parseFloat(product.totalMrp).toFixed(2)}/-{" "}
                            {/* <i className="fa fa-angle-right angleright"></i> */}
                          </h5>
                          {/* <a
                            // href={"/productDetail/" + product.productId}
                            onClick={() => {
                              console.log({ product });
                              this.props.addToCart({
                                id: product.productId,
                                name: product.productName,
                                price: product.price,
                                mrp: product.mrp,
                                discountType: product.discountType,
                                discount: product.discount,
                                unit: 1,
                                brandName: product.brandName,
                                firstImage: product.productImage,
                                filters:
                                  product.filters != null
                                    ? JSON.parse(product.filters)
                                    : "",
                              });
                            }}
                          >
                            Buy Again
                          </a> */}
                        </div>
                      </Col>
                    </Row>
                  </div>
                );
              })
            ) : (
              <NoOrder {...this.props} />
            )}
          </div>
        </Slide>
      </SidebarLayout>
    );
  }
}

const mapStateToProps = ({ cartReducer }) => {
  return { cartReducer };
};

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      addToCart,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapActionsToProps)(MyOrders);
// export default MyOrders;

import React from "react";
import Loader from "react-loader-spinner";

export default function CustomLoader() {
  return (
    <div>
      <center>
        <div className="loadersvg">
          <Loader
            className=""
            type="Oval"
            color="#ccc"
            height={70}
            width={70}
            timeout={100000}
          />
        </div>
      </center>
    </div>
  );
}

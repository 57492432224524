import React, { Component } from "react";
import { Row, Col, Button } from "react-bootstrap";
import "./mycart.css";

class MyCartPg extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div id="aboutintro">
        <div className="cartlist">
          <h4>My Cart</h4>

          <div className="cartitem">
            <div className="crt_btn">
              <p className="pull-left">
                {this.props.cartReducer != null &&
                this.props.cartReducer.cart != null
                  ? this.props.cartReducer.cart.length
                  : 0}{" "}
                Items Added
              </p>
              <Button
                className="pull-right"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.clearCart();
                }}
              >
                <i className="fa fa-trash"></i> Clear All
              </Button>
            </div>
            <div className="mycartscrollbar">
              {this.props.cartReducer &&
              this.props.cartReducer.cart != null &&
              this.props.cartReducer.cart.length > 0
                ? this.props.cartReducer.cart.map((v, i) => {
                    return (
                      <div className="c-box-intem">
                        <Row>
                          <Col xs="5" md="2">
                            <a>
                              <div className="cartimg">
                                <img
                                  src={v.firstImage}
                                  alt="productImage"
                                ></img>
                              </div>
                            </a>
                          </Col>
                          <Col xs="7" md="5">
                            <div class="cartinfo">
                              <h3>{v.name}</h3>
                              <p>
                                Brand: <span>{v.brandName}</span>
                              </p>
                              <div>
                                {v.filters &&
                                  v.filters.filterDTOList.map((vi, ii) => {
                                    return (
                                      <p>
                                        {vi.filterName} :{" "}
                                        <span>{vi.subfilterName}</span>
                                      </p>
                                    );
                                  })}
                              </div>
                            </div>
                          </Col>
                          <Col xs="12" md="5">
                            <div class="cartitemrate">
                              <a
                                href="javascript:void(0)"
                                onClick={(e) => {
                                  e.preventDefault();
                                  console.log("v", v);
                                  this.props.removeFromCart({
                                    id: v.id,
                                    filters: v.filters,
                                  });
                                }}
                              >
                                <i className="fa fa-trash"></i>
                              </a>
                              <div className="cart-prdt-qty">
                                <div className="cartqty">
                                  <div className="def-number-input number-input">
                                    <button
                                      disabled={v.unit <= 1 ? true : false}
                                      className="minus"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.props.decrementProductUnit({
                                          id: v.id,
                                          mrp: v.mrp,
                                          price: v.price,
                                          discount: v.discount,
                                          currentUnit: v.unit,
                                          unit: -1,
                                          filters: v.filters,
                                        });
                                      }}
                                    ></button>
                                    <input
                                      readOnly={true}
                                      className="quantity"
                                      name="quantity"
                                      value={v.unit}
                                      onChange={() => console.log("change")}
                                      type="number"
                                    />
                                    <button
                                      className="plus"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.props.addToCart({
                                          id: v.id,
                                          name: v.name,
                                          price: v.price,
                                          mrp: v.mrp,
                                          discountType: v.discountType,
                                          discount: v.discount,
                                          unit: 1,
                                          brandName: v.brandName,
                                          firstImage: v.firstImage,
                                          thumbImage: v.thumbImage,
                                          filters: v.filters,
                                        });
                                      }}
                                    ></button>
                                  </div>
                                </div>
                                <div className="prdt-price">
                                  {v.discountType != "none" && (
                                    <s>
                                      <i className="fa fa-inr"></i>{" "}
                                      {parseFloat(v.totalPrice).toFixed(2)}
                                      /-
                                    </s>
                                  )}
                                  <br />
                                  <b>
                                    <i className="fa fa-inr"></i>{" "}
                                    {parseFloat(v.totalMrp).toFixed(2)}
                                    /-
                                  </b>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    );
                  })
                : ""}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MyCartPg;

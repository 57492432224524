import React, { Component } from "react";
import { SidebarLayout } from "@/Pages/Layout";
import { Slide } from "react-reveal";
import { Form, Row, Col, Button } from "react-bootstrap";
import "@/Pages/Dashboard/dashboard.css";

import { history } from "@/_helpers";
import Notifications, { notify } from "react-notify-toast";
import { emailRegExp } from "@/_helpers";
import { Formik } from "formik";
import * as Yup from "yup";

import {
  getAccountDetails,
  updateAccountDetails,
} from "@/services/api_function";

class EditAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountDetails: "",
    };
  }

  getAccountDetailsFun = () => {
    getAccountDetails()
      .then((response) => {
        let res = response.data;
        console.log("res  ", res);
        if (res.responseStatus == 200) {
          console.log("res.responseObject ", res.responseObject);
          this.setState({
            accountDetails: res.responseObject,
          });
        }
      })
      .catch((error) => {
        this.setState({ accountDetails: "" });
        console.log("error", error);
      });
  };

  componentDidMount() {
    this.getAccountDetailsFun();
  }
  render() {
    const { accountDetails } = this.state;
    return (
      <SidebarLayout
        breadcrum_parent_name_url="/MyAccount"
        breadcrum_parent_name="My Account"
        breadcrum_child_name="Dashboard"
        {...this.props}
      >
        <Notifications options={{ zIndex: 9999, top: "50px" }} />
        <Slide right>
          <div className="accountinfo">
            <Col md="6">
              <h4>Account Information</h4>
              <Row>
                <div className="accountdetail pr-4">
                  <Formik
                    validateOnChange={false}
                    validateOnBlur={false}
                    enableReinitialize={true}
                    initialValues={{
                      firstName:
                        accountDetails && accountDetails.firstName != null
                          ? accountDetails.firstName
                          : "",
                      lastName:
                        accountDetails && accountDetails.lastName != null
                          ? accountDetails.lastName
                          : "",
                      email:
                        accountDetails && accountDetails.emailId != null
                          ? accountDetails.emailId
                          : "",
                    }}
                    validationSchema={Yup.object().shape({
                      firstName: Yup.string()
                        .trim()
                        .required("First Name is required"),
                      lastName: Yup.string()
                        .trim()
                        .required("Last Name is required"),
                      email: Yup.string()
                        .trim()
                        .matches(emailRegExp, "email invalid"),
                    })}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      let requestData = new FormData();
                      requestData.append(
                        "id",
                        accountDetails && accountDetails.id != null
                          ? accountDetails.id
                          : ""
                      );
                      requestData.append("firstName", values.firstName);
                      requestData.append("lastName", values.lastName);
                      requestData.append("emailId", values.email);

                      setSubmitting(true);
                      updateAccountDetails(requestData)
                        .then((response) => {
                          setSubmitting(false);
                          let res = response.data;
                          // console.log("response", res);
                          if (res.responseStatus == 200) {
                            notify.show(res.message, "success", 2000);
                            resetForm();
                            setTimeout(() => {
                              this.props.history.push("/Dashboard");
                            }, 2000);
                          } else {
                            notify.show(res.message, "error", 2000);
                            // resetForm();
                          }
                        })
                        .catch((error) => {
                          console.log("error", error);
                        });
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      resetForm,
                      setFieldValue,
                    }) => (
                      <Form className="account-info" onSubmit={handleSubmit}>
                        <Row>
                          <Col md="12">
                            <Form.Group>
                              <Form.Label>FIRST NAME</Form.Label>
                              <Form.Control
                                type="text"
                                name="firstName"
                                id="firstName"
                                onChange={handleChange}
                                value={values.firstName}
                                isValid={touched.firstName && !errors.firstName}
                                isInvalid={!!errors.firstName}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.firstName}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <Form.Group>
                              <Form.Label>LAST NAME</Form.Label>
                              <Form.Control
                                type="text"
                                name="lastName"
                                id="lastName"
                                onChange={handleChange}
                                value={values.lastName}
                                isValid={touched.lastName && !errors.lastName}
                                isInvalid={!!errors.lastName}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.lastName}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <Form.Group>
                              <Form.Label>EMAIL ID</Form.Label>
                              <Form.Control
                                type="text"
                                name="email"
                                id="email"
                                onChange={handleChange}
                                value={values.email}
                                isValid={touched.email && !errors.email}
                                isInvalid={!!errors.email}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.email}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="4">
                            <Button
                              variant="primary"
                              type="submit"
                              className="forgotbtn rainbow rainbow-1 mt-0 mb-2"
                            >
                              Submit
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Row>
            </Col>
          </div>
        </Slide>
      </SidebarLayout>
    );
  }
}
export default EditAccount;

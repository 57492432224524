import React, { Component } from "react";
import "./wishlist.css";
import { Col, Row, Form, Button, Dropdown, Item } from "react-bootstrap";
import { ProductCard } from "@/Pages/Components/ProductListComponent/ProductCard";
import { fetchWishlistProducts } from "@/services/api_function";

import { bindActionCreators } from "redux";
import { addToCart } from "@/redux/cart/Action";
import { connect } from "react-redux";
import NoWishlist from "./NoWishlist";

class WishList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wishlistProducts: [],
    };
  }

  fetchWishlistProductsFun = () => {
    fetchWishlistProducts()
      .then((response) => {
        console.log({ response });
        let res = response.data;
        if (res.responseStatus == 200) {
          this.setState({ wishlistProducts: res.responseObject });
        }
      })
      .catch((error) => {
        console.log({ error });
      });
  };

  componentDidMount() {
    this.fetchWishlistProductsFun();
  }

  render() {
    const { wishlistProducts } = this.state;
    return wishlistProducts.length > 0 ? (
      <div className="pro-item">
        <Row>
          <Row className="pro-filter col">
            <Col md="9" className="filteritems">
              <p>{wishlistProducts.length} Item(s)</p>
            </Col>
          </Row>
        </Row>
        <Row>
          {wishlistProducts.length > 0 &&
            wishlistProducts.map((product, i) => {
              if (product.productId !== null) {
                return (
                  <ProductCard
                    key={product.productId}
                    productDetails={product}
                    {...this.props}
                  />
                );
              }
            })}
        </Row>
      </div>
    ) : (
      <div>
        <NoWishlist {...this.props} />
      </div>
    );
  }
}
const mapStateToProps = ({ cartReducer }) => {
  return { cartReducer };
};

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      addToCart,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapActionsToProps)(WishList);

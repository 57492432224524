import React, { useEffect } from "react";
import Header from "@/Pages/Components/header/Header";
import Footer from "@/Pages/Components/footer/Footer";

function Layout(props) {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="App">
      <Header {...props} />
      <div>{props.children}</div>
      <Footer {...props} />
    </div>
  );
}

export { Layout };

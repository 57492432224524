import React, { Component } from "react";

import Slide from "react-reveal/Slide";

import { Container, Row, Col, Card } from "react-bootstrap";

import "./map.css";

class Mapmarker extends Component {
  render() {
    return (
      <div id="mapmarker" className="pb-5">
        <Row className="justify-content-center">
          <Col md="10">
          <div className="contactdiv mb-4 mt-4">
            <h1>Connect with one of our outlets</h1>
          </div>
          <div className="mapborder">
            <Slide top>
              <Row className="mb-2">
                <Col lg="8" md="6" className="">
                  <iframe
                    // src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d3783.2351960186525!2d73.83491571436872!3d18.518270774078903!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x3bc2bf41feac6a93%3A0x940f5b065e4f4d46!2sM3%20SPORTS%2C%20872%2C%20Bhandarkar%20Rd%2C%20Deccan%20Gymkhana%2C%20Pune%2C%20Maharashtra%20411004!3m2!1d18.5182657!2d73.8371044!5e0!3m2!1sen!2sin!4v1609923624024!5m2!1sen!2sin"
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7566.7182341986445!2d73.84573048650742!3d18.51266690490745!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c07691f6df49%3A0xeea9958622364851!2sUbhe%20Sports!5e0!3m2!1sen!2sin!4v1681477179269!5m2!1sen!2sin"
                    width="100%"
                    height="300"
                    className=""
                    frameborder="0"
                  ></iframe>
                </Col>
                <Col lg="4" md="6">
                  <div className="contactinfo cntbg2">
                    <h4>Bhandarkar Road, Deccan</h4>
                    <h6>CONTACT INFROMATION</h6>
                    <address>
                      872, Bhandarkar Rd, Deccan Gymkhana, Pune, Maharashtra
                      411004
                    </address>

                    <h6>PHONE</h6>
                    <address>+91 9834810274</address>

                    <h6>OPEN HOURSE :</h6>
                    <address>Mon to Sun (10:00 am - 8:30 pm)</address>
                  </div>
                </Col>
              </Row>
            </Slide>
          </div>
          <div className="mapborder">
            <Slide bottom>
              <Row className="mb-2">
                <Col lg="8" md="6" className="">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7566.7182341986445!2d73.84573048650742!3d18.51266690490745!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c07691f6df49%3A0xeea9958622364851!2sUbhe%20Sports!5e0!3m2!1sen!2sin!4v1681477179269!5m2!1sen!2sin"
                    // src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d60531.84436912716!2d73.8500239404112!3d18.51804121547526!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x3bc2c10733c6ffc9%3A0x60b82b7c20ad5fd0!2sM3%20sports%2C%20Amanora%20Park%20Town%2C%20Hadapsar%2C%20Pune%2C%20Maharashtra%20411028!3m2!1d18.5176604!2d73.9329829!5e0!3m2!1sen!2sin!4v1609923485082!5m2!1sen!2sin"
                    width="100%"
                    height="300"
                    className="mapclass mt-4"
                    frameborder="0"
                  ></iframe>
                </Col>
                <Col lg="4" md="6" >
                  <div className="contactinfo cntbg2">
                    <h4>Magarpatta City, Hadapsar</h4>
                    <h6>CONTACT INFROMATION</h6>
                    <address>
                      Magarpatta Road, Magarpatta City- Hadapsar, Near Seasons
                      Mall, Pune-411028
                    </address>

                    <h6>PHONE</h6>
                    <address>+91 9373029167</address>

                    <h6>OPEN HOURSE :</h6>
                    <address>Mon to Sun (9:00 am - 10:30 pm)</address>
                  </div>
                </Col>
              </Row>
            </Slide>
          </div>
          </Col>
        </Row>
        
        
      </div>
    );
  }
}
export default Mapmarker;

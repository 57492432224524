import React, { useEffect } from "react";
import { authenticationService } from "@/services/api_function";
import Header from "@/Pages/Components/header/Header";
import Footer from "@/Pages/Components/footer/Footer";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";

import Sidebar from "./Sidebar";

function SidebarLayout(props) {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div>
      <Header {...props} />
      <div id="myprofile" className="pt-5">
        <div className="myaccountbg contact-braeadcrumb cust-braeadcrumb pt-3 mb-4">
          <Container fluid>
            <Breadcrumb className="policybreadcrumb">
              <Breadcrumb.Item
                onClick={(e) => {
                  e.preventDefault();
                  props.history.push(`${props.breadcrum_parent_name_url}`);
                }}
                // href={props.breadcrum_parent_name_url}
              >
                {/* {props.breadcrum_parent_name_url} */}
                {props.breadcrum_parent_name}
              </Breadcrumb.Item>

              <Breadcrumb.Item active>
                {" "}
                {props.breadcrum_child_name}
              </Breadcrumb.Item>
            </Breadcrumb>

            <h1>{props.breadcrum_child_name} </h1>
          </Container>
        </div>
        <Container fluid>
          <Row>
            <Col md="3">
              <Sidebar {...props} />
            </Col>
            <Col md="9">{props.children}</Col>
          </Row>
        </Container>
      </div>
      <Footer {...props} />
    </div>
  );
}

export { SidebarLayout };

import React, { Component } from "react";

import Fade from "react-reveal/Fade";
import "./product.css";
import { Col, Row, Button } from "react-bootstrap";
import { printProductName } from "@/_helpers";
import Notifications, { notify } from "react-notify-toast";

import parse from "html-react-parser";

class ProductCardListView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productDetails: "",
    };
  }

  componentDidMount() {
    this.setState({ productDetails: this.props.productDetails });
  }
  componentDidUpdate(prevProps) {
    if (this.props.productDetails !== prevProps.productDetails) {
      this.setState({ productDetails: this.props.productDetails });
    }
  }
  addToCartFN = ({
    id,
    name,
    price,
    mrp,
    discountType,
    discount,
    unit,
    brandName,
    firstImage,
    thumbImage,
    discountInPercentage,
    discountInAmount,
    filters,
  }) => {
    this.props.addToCart({
      id,
      name,
      price,
      mrp,
      discountType,
      discount,
      unit,
      brandName,
      firstImage,
      thumbImage,
      discountInPercentage,
      discountInAmount,
      filters,
    });

    notify.show("Product added to cart", "success", 2000);
  };
  render() {
    const { productDetails } = this.state;
    return (
      <div className="col-md-12">
        <Notifications options={{ zIndex: 9999, top: "50px" }} />
        <div className="gridprdctimg">
          <Row className="mx-0">
            <Col md="4" style={{ textAlign: "center", margin: "auto" }}>
              <a
                onClick={(e) => {
                  e.preventDefault();
                  this.props.history.push(
                    `/ProductDetail/${productDetails.productId}`
                  );
                }}
              >
                <Fade duration={2000}>
                  <img
                    alt={
                      productDetails &&
                      printProductName(productDetails.productName)
                    }
                    className=""
                    src={productDetails.firstImage}
                  />
                </Fade>
              </a>
            </Col>
            <Col md="5">
              <div className="productitem-info1 prdctlistview">
                <h5>{productDetails && productDetails.productName}</h5>
                <>
                  {productDetails &&
                    parse(
                      productDetails.description
                        ? productDetails.description.toString()
                        : ""
                    )}
                </>

                {/* <ul>
                  <li>Lorem Ipsum is simply dummy text of the printing </li>
                  <li>
                    {" "}
                    A galley of type and scrambled it to make a type specimen
                    book
                  </li>
                  <li>Containing Lorem Ipsum passages</li>
                  <li>
                    It was popularised in the 1960s with the release of Letraset
                  </li>
                  <li>
                    Desktop publishing software like Aldus PageMaker including
                    versions
                  </li>
                  <li>Printing and typesetting industry</li>
                  <li>
                    As opposed to using 'Content here, content here', making
                  </li>
                  <li>Various versions have evolved over the years</li>
                </ul> */}
              </div>
            </Col>
            <Col md="3">
              <div className="productitem-info prdctlistview">
                <h3>
                  <i className="fa fa-inr"></i>{" "}
                  {productDetails
                    ? parseFloat(productDetails.mrp).toFixed(2)
                    : 0}
                  /-&nbsp;&nbsp;{" "}
                  {productDetails.discountType != "none" && (
                    <s style={{ color: "#a52d4d" }}>
                      <i className="fa fa-inr"></i>{" "}
                      {productDetails
                        ? parseFloat(productDetails.basePrice).toFixed(2)
                        : 0}
                      /-
                    </s>
                  )}
                </h3>
                {productDetails.discountType != "none" ? (
                  <div className="disoffer1">
                    {productDetails.discountLabel} Offer
                  </div>
                ) : (
                  <div
                    className="disoffer1"
                    style={{ background: "transparent" }}
                  >
                    &nbsp;
                  </div>
                )}

                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    this.addToCartFN({
                      id: productDetails.productId,
                      name: productDetails.productName,
                      price: productDetails.basePrice,
                      mrp: productDetails.mrp,
                      discountType: productDetails.discountType,
                      discount: productDetails.discount,
                      unit: 1,
                      brandName: productDetails.brandName,
                      firstImage: productDetails.firstImage,
                      thumbImage: productDetails.thumbImage,
                      discountInPercentage: productDetails.discountInPercentage,
                      discountInAmount: productDetails.discountInAmount,
                      filters: productDetails.filterPriceDTOList[0],
                    });
                  }}
                >
                  Add To Cart
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export { ProductCardListView };

import React, { Component } from "react";
import { Container, Breadcrumb } from "react-bootstrap";
import { Layout } from "@/Pages/Layout";
class ReturnRefunds extends Component {
  render() {
    return (
      <Layout {...this.props}>
        <div id="privacy" className="pt-4 mt-3">
          <div className="privacy">
            <Container fluid>
              <div>
                <Breadcrumb className="policybreadcrumb">
                  <Breadcrumb.Item
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.history.push(`/`);
                    }}
                  >
                    Home
                  </Breadcrumb.Item>

                  <Breadcrumb.Item active>Terms & Conditions</Breadcrumb.Item>
                </Breadcrumb>

                <div className="privacytitle">
                  <h1>Returns & Refunds</h1>
                  <p>Last updated 15 January 2021</p>
                </div>
              </div>
            </Container>
          </div>
          <div id="privacyinfo">
            <Container fluid>
              <div className="privacyinfo pt-4">
                <h2>Returns & Refunds</h2>
                <p>Thank you for shopping at Ubhe Sports.</p>
                <p>
                  If, for any reason, You are not completely satisfied with a
                  purchase We invite You to review our policy on refunds and
                  returns. This Return and Refund Policy has been created with
                  the help of the{" "}
                  <a
                    target="_blank"
                    href="https://www.termsfeed.com/return-refund-policy-generator/"
                  >
                    Return and Refund Policy Generator.
                  </a>
                </p>
                <p>
                  The following terms are applicable for any products that You
                  purchased with Us.
                </p>
                <h2>Interpretation and Definitions</h2>
                <h4>Interpretation </h4>

                <p>
                  <br />
                  The words of which the initial letter is capitalized have
                  meanings defined under the following conditions. The following
                  definitions shall have the same meaning regardless of whether
                  they appear in singular or in plural.
                </p>
                <h4>Definitions</h4>

                <p>
                  {" "}
                  <br />
                  For the purposes of this Return and Refund Policy:
                </p>
                <ul>
                  <li>
                    Company (referred to as either "the Company", "We", "Us" or
                    "Our" in this Agreement) refers to Ubhe Sports.
                  </li>

                  <li>
                    Goods refer to the items offered for sale on the Service.
                  </li>

                  <li>
                    Orders mean a request by You to purchase Goods from Us.
                  </li>

                  <li>Service refers to the Website.</li>

                  <li>
                    Website refers to Ubhe Sports, accessible from
                    www.m3sports.com
                  </li>

                  <li>
                    You means the individual accessing or using the Service, or
                    the company, or other legal entity on behalf of which such
                    individual is accessing or using the Service, as applicable.
                  </li>
                </ul>
                <h2>Your Order Cancellation Rights</h2>
                <p>
                  You are entitled to cancel Your Order within 14 days without
                  giving any reason for doing so.
                </p>
                <p>
                  The deadline for cancelling an Order is 14 days from the date
                  on which You received the Goods or on which a third party you
                  have appointed, who is not the carrier, takes possession of
                  the product delivered.{" "}
                </p>
                <p>
                  In order to exercise Your right of cancellation, You must
                  inform Us of your decision by means of a clear statement. You
                  can inform us of your decision by:
                </p>
                <ul>
                  <li>
                    By email:{" "}
                    <a href="http://cdn-cgi/l/email-protection" target="_blank">
                      [email protected],
                    </a>
                  </li>

                  <li>
                    By visiting this page on our website:{" "}
                    <a
                      href="https://www.hugedomains.com/domain_profile.cfm?d=m3sports&e=com"
                      target="_blank"
                    >
                      www.m3sports.com/returns-and-refunds
                    </a>
                  </li>
                </ul>
                <p>
                  We will reimburse You no later than 14 days from the day on
                  which We receive the returned Goods. We will use the same
                  means of payment as You used for the Order, and You will not
                  incur any fees for such reimbursement.
                </p>
                <h2>Conditions for Returns</h2>
                <p>
                  In order for the Goods to be eligible for a return, please
                  make sure that:
                </p>
                <ul>
                  <li>The Goods were purchased in the last 14 days</li>
                  <li>The Goods are in the original packaging</li>
                </ul>
                <p>The following Goods cannot be returned:</p>
                <ul>
                  <li>
                    The supply of Goods made to Your specifications or clearly
                    personalized.
                  </li>
                  <li>
                    The supply of Goods which according to their nature are not
                    suitable to be returned, deteriorate rapidly or where the
                    date of expiry is over.
                  </li>
                  <li>
                    The supply of Goods which are not suitable for return due to
                    health protection or hygiene reasons and were unsealed after
                    delivery.
                  </li>
                  <li>
                    The supply of Goods which are, after delivery, according to
                    their nature, inseparably mixed with other items.
                  </li>
                  <li>
                    The supply of Goods which are, after delivery, according to
                    their nature, inseparably mixed with other items.
                  </li>
                </ul>
                <p>
                  We reserve the right to refuse returns of any merchandise that
                  does not meet the above return conditions in our sole
                  discretion.
                </p>
                <p>
                  Only regular priced Goods may be refunded. Unfortunately,
                  Goods on sale cannot be refunded. This exclusion may not apply
                  to You if it is not permitted by applicable law.
                </p>
                <h2>Returning Goods</h2>
                <p>
                  You are responsible for the cost and risk of returning the
                  Goods to Us. You should send the Goods at the following
                  address:
                </p>
                <p>Ubhe Sports, Bhandarkar Road, Deccan, Pune 411004, India</p>
                <p>
                  We cannot be held responsible for Goods damaged or lost in
                  return shipment. Therefore, We recommend an insured and
                  trackable mail service. We are unable to issue a refund
                  without actual receipt of the Goods or proof of received
                  return delivery.
                </p>
                <h2>Gifts</h2>
                <p>
                  If the Goods were marked as a gift when purchased and then
                  shipped directly to you, You'll receive a gift credit for the
                  value of your return. Once the returned product is received, a
                  gift certificate will be mailed to You.
                </p>
                <p>
                  If the Goods weren't marked as a gift when purchased, or the
                  gift giver had the Order shipped to themselves to give it to
                  You later, We will send the refund to the gift giver.
                </p>
                <h2>Contact Us</h2>
                <p>
                  If you have any questions about our Returns and Refunds
                  Policy, please contact us:
                </p>
                <ul>
                  <li>
                    By email:{" "}
                    <a href="http://cdn-cgi/l/email-protection" target="_blank">
                      [email protected],
                    </a>
                  </li>

                  <li>
                    By visiting this page on our website:{" "}
                    <a
                      href="https://www.hugedomains.com/domain_profile.cfm?d=m3sports&e=com"
                      target="_blank"
                    >
                      www.m3sports.com/returns-and-refunds
                    </a>
                  </li>
                </ul>
              </div>
            </Container>
          </div>
        </div>
      </Layout>
    );
  }
}

export default ReturnRefunds;

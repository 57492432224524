import config from "config";

export function getAccountDetailsUrl() {
  return `${config.apiUrl}/getAccountDetails`;
}

export function updateAccountDetailsUrl() {
  return `${config.apiUrl}/updateAccountDetails`;
}

export function checkPasswordUrl() {
  return `${config.apiUrl}/checkPassword`;
}

export function changePasswordUrl() {
  return `${config.apiUrl}/changePassword`;
}

import React, { Component } from "react";
import parse from "html-react-parser";

export default class ProductDesc extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { productDetails } = this.props;
    return (
      <div>
        <div className="prdct-description">
          <h6>Product Description</h6>
          <>
            {parse(
              productDetails.productDescription
                ? productDetails.productDescription.toString()
                : ""
            )}
          </>
          {/* <div className="sharebtn">
            <button>
              <i className="fa fa-share-alt"></i>&nbsp;&nbsp; Share This Item
            </button>
            <button>
              <i className="fa fa-gift"></i>&nbsp;&nbsp; Gift This Product
            </button>
          </div> */}
        </div>
      </div>
    );
  }
}

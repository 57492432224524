import config from "config";

export function addToWishlistUrl() {
  return `${config.apiUrl}/addToWishlist`;
}

export function findProductInWishlistUrl() {
  return `${config.apiUrl}/findProductInWishlist`;
}

export function fetchWishlistProductsUrl() {
  return `${config.apiUrl}/fetchWishlistProducts`;
}

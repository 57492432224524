import React from "react";
import { Layout } from "@/Pages/Layout";
import SliderCarousel from "@/Pages/Components/slidercarousel/SliderCarousel";
import BrandCarousel from "@/Pages/Components/brandcarousel/BrandCarousel";
import ViewInstagram from "@/Pages/Components/viewinstagram/ViewInstagram";
import Batsman from "@/Pages/Components/Batsman/Batsman";
import ShopCategory from "@/Pages/Components/shopcategory/ShopCategory";
import OnlineShop from "@/Pages/Components/shopcategory/OnlineShop";
import Map from "@/Pages/Components/shopcategory/Map";

import LatestArrivals from "@/Pages/Components/latestarrivals/LatestArrivals";
// import ScrollToTop from "@/Pages/Components/ScrollToTop/ScrollToTop";
import Offers from "@/Pages/Components/offers/Offer";

function Home(props) {
  return (
    <Layout {...props}>
      {/* <SliderCarousel /> */}
      {/* <ShopCategory /> */}
      {/* <Batsman /> */}
      <OnlineShop {...props} />
      {/* <Map {...props} /> */}
      <BrandCarousel {...props} />

      <LatestArrivals {...props} />
      <Offers {...props} />
      {/* <ViewInstagram {...props} /> */}
      {/* <ScrollToTop {...props} /> */}
    </Layout>
  );
}

export { Home };

import {
  getAccountDetailsUrl,
  updateAccountDetailsUrl,
  checkPasswordUrl,
  changePasswordUrl,
} from "@/services/api";
import axios from "axios";
import { getHeader } from "@/_helpers";

export function getAccountDetails() {
  return axios({
    url: getAccountDetailsUrl(),
    method: "GET",
    headers: getHeader(),
  });
}

export function updateAccountDetails(values) {
  return axios({
    url: updateAccountDetailsUrl(),
    method: "POST",
    headers: getHeader(),
    data: values,
  });
}

export function checkPassword(values) {
  return axios({
    url: checkPasswordUrl(),
    method: "POST",
    headers: getHeader(),
    data: values,
  });
}

export function changePassword(values) {
  return axios({
    url: changePasswordUrl(),
    method: "POST",
    headers: getHeader(),
    data: values,
  });
}

import axios from "axios";
import {
  groupListUrl,
  getHeaderGroupListUrl,
  groupProductLitFiltersUrl,
  productListByGroupUrl,
} from "@/services/api";
import { getHeader, authLogin } from "@/_helpers";

export function getGroupList() {
  return axios({
    headers: authLogin(),
    url: groupListUrl(),
    method: "GET",
  });
}
export function getHeaderGroupList() {
  return axios({
    headers: authLogin(),
    url: getHeaderGroupListUrl(),
    method: "GET",
  });
}

export function productListByGroup(values) {
  return axios({
    headers: authLogin(),
    url: productListByGroupUrl(),
    method: "POST",
    data: values,
  });
}

export function groupProductLitFilters(values) {
  return axios({
    headers: authLogin(),
    url: groupProductLitFiltersUrl(),
    method: "POST",
    data: values,
  });
}

import config from "config";

export function addDeliveryAddressUrl() {
  return `${config.apiUrl}/add-delivery-address`;
}

export function deliveryAddressListUrl() {
  return `${config.apiUrl}/delivery-address-list`;
}

export function getUserAddressesUrl() {
  return `${config.apiUrl}/getUserAddresses`;
}

export function findAddressUrl() {
  return `${config.apiUrl}/findAddress`;
}

export function updateAddressUrl() {
  return `${config.apiUrl}/updateAddress`;
}

export function deleteAddressUrl() {
  return `${config.apiUrl}/deleteAddress`;
}

import React, { Component } from "react";
import { Container, Form, Row, Col, Button, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import {
  getCountries,
  getStatesByCountry,
  getCitiesByState,
  addDeliveryAddress,
  deliveryAddressList,
} from "@/services/api_function";
import { history, pincodeReg } from "@/_helpers";
import Notifications, { notify } from "react-notify-toast";

export default class AddressForm extends Component {
  constructor(props) {
    super(props);
    this.state = { countryOpt: [], stateOpt: [], cityOpt: [] };
  }
  statesData = (countryId) => {
    let reqData = new FormData();
    reqData.append("countryId", countryId);
    getStatesByCountry(reqData)
      .then((response) => {
        let res = response.data;
        // console.log({ res });
        if (res.responseStatus == 200) {
          let state = res.responseObject;
          let stateOpt = state.map(function (values) {
            return { value: values.id, label: values.name };
          });
          this.setState({ stateOpt: stateOpt });
        }
      })
      .catch((error) => {
        console.log({ error });
      });
  };

  citiesData = (stateId) => {
    let reqData = new FormData();
    reqData.append("stateId", stateId);
    getCitiesByState(reqData)
      .then((response) => {
        let res = response.data;
        // console.log({ res });
        if (res.responseStatus == 200) {
          let city = res.responseObject;
          let cityOpt = city.map(function (values) {
            return { value: values.id, label: values.name };
          });
          this.setState({ cityOpt: cityOpt });
        }
      })
      .catch((error) => {
        console.log({ error });
      });
  };

  countriesData = () => {
    getCountries()
      .then((response) => {
        let res = response.data;
        if (res.responseStatus == 200) {
          let country = res.responseObject;
          let countryOpt = country.map(function (values) {
            return { value: values.id, label: values.name };
          });
          this.setState({ countryOpt: countryOpt });
        }
      })
      .catch((error) => {
        console.log({ error });
      });
  };
  componentDidMount() {
    this.countriesData();
  }
  render() {
    const { activeIndex, countryOpt, stateOpt, cityOpt } = this.state;
    return (
      <div>
        <Notifications options={{ zIndex: 9999, top: "50px" }} />

        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          enableReinitialize={true}
          initialValues={{
            address_line: "",
            street_address: "",
            cityId: "",
            stateId: "",
            countryId: "",
            pincode: "",
          }}
          validationSchema={Yup.object().shape({
            address_line: Yup.string()
              .trim()
              .required("Address Line is required"),
            street_address: Yup.string()
              .trim()
              .required("Street Address is required"),
            cityId: Yup.object().required("City is required"),
            stateId: Yup.object().required("State is required"),
            countryId: Yup.object().required("Country is required"),
            pincode: Yup.string()
              .trim()
              .matches(pincodeReg, "Pincode invalid")
              .required("Pincode is required"),
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            const requestData = new FormData();
            // Delivery Address
            requestData.append("address_line", values.address_line);
            requestData.append("street_address", values.street_address);
            requestData.append("pincode", values.pincode);
            requestData.append("countryId", values.countryId.value);
            requestData.append("stateId", values.stateId.value);
            requestData.append("cityId", values.cityId.value);

            setSubmitting(true);
            addDeliveryAddress(requestData)
              .then((response) => {
                setSubmitting(false);
                let res = response.data;
                // console.log("response", res);
                if (res.responseStatus == 200) {
                  notify.show(res.message, "success", 2000);
                  this.props.getUserAddressesFun();
                  this.props.handleNewAddressStatus(false);
                  // resetForm();
                  // setTimeout(() => {
                  //   props.history.push("/Login");
                  // }, 2000);
                } else {
                  notify.show(res.message, "error", 2000);
                  // resetForm();
                }
              })
              .catch((error) => {
                console.log("error", error);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            resetForm,
            setFieldValue,
          }) => (
            <Form
              onSubmit={handleSubmit}
              className="shipping-add-form shippingbg mb-3"
            >
              <Row>
                <Col md="6">
                  <Form.Group controlId="ragistration.ControlTextarea1">
                    <Form.Label>ADDRESS LINE</Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder="Enter Address Line"
                      rows={3}
                      name="address_line"
                      id="address_line"
                      onChange={handleChange}
                      value={values.address_line}
                      isValid={touched.address_line && !errors.address_line}
                      isInvalid={!!errors.address_line}
                    />
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group controlId="ragistration.ControlTextarea2">
                    <Form.Label>STREET ADDRESS</Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder="Enter Street Address"
                      rows={3}
                      name="street_address"
                      id="street_address"
                      onChange={handleChange}
                      value={values.street_address}
                      isValid={touched.street_address && !errors.street_address}
                      isInvalid={!!errors.street_address}
                    />
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group controlId="formcity">
                    <Form.Label>COUNTRY</Form.Label>
                    <Select
                      isClearable={true}
                      onChange={(v) => {
                        setFieldValue("countryId", v);
                        setFieldValue("stateId", "");
                        setFieldValue("cityId", "");
                        if (v != null) {
                          this.statesData(v.value);
                        } else {
                          this.setState({ stateOpt: [], cityOpt: [] });
                        }
                      }}
                      className="clsselect2"
                      name="countryId"
                      options={countryOpt}
                      value={values.countryId}
                      width="200px"
                    />
                    <span className="text-danger">
                      {errors.countryId && "Country is required"}
                    </span>
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group controlId="formstate">
                    <Form.Label>STATE</Form.Label>
                    <Select
                      isClearable={true}
                      onChange={(v) => {
                        setFieldValue("stateId", v);
                        setFieldValue("cityId", "");
                        if (v != null) {
                          this.citiesData(v.value);
                        } else {
                          this.setState({ cityOpt: [] });
                        }
                      }}
                      className="clsselect2"
                      name="stateId"
                      options={stateOpt}
                      value={values.stateId}
                      width="200px"
                    />
                    <span className="text-danger">
                      {errors.stateId && "State is required."}
                    </span>
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group controlId="formcountry">
                    <Form.Label>CITY</Form.Label>
                    <Select
                      isClearable={true}
                      onChange={(v) => {
                        setFieldValue("cityId", v);
                      }}
                      className="clsselect2"
                      name="cityId"
                      options={cityOpt}
                      value={values.cityId}
                      width="200px"
                    />
                    <span className="text-danger">
                      {errors.cityId && "City is required."}
                    </span>
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group controlId="formpincode">
                    <Form.Label>PINCODE</Form.Label>
                    <Form.Control
                      type="text"
                      name="pincode"
                      id="pincode"
                      onChange={handleChange}
                      value={values.pincode}
                      isValid={touched.pincode && !errors.pincode}
                      isInvalid={!!errors.pincode}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Button
                variant="primary"
                type="submit"
                disabled={isSubmitting}
                className="new-u-btn rainbow rainbow-1  loginbtn"
              >
                {isSubmitting && <>Saving...</>}
                Submit
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

import React from "react";

import { Carousel } from "react-bootstrap";
// import slider from "@/assets/images/slider1.jpg";
// import slider4 from "@/assets/images/slider4.png";
import "./slidercarousel.css";
class SliderCarousel extends React.Component {
  render() {
    return (
      <div className="sliderdiv pt-5">
        {/* <Carousel>
          <Carousel.Item interval={1000}>
            <img className="d-block w-100" src={slider} alt="First slide" />
            <Carousel.Caption>
              <a className="rainbow rainbow-1" href="">
                Browse All
              </a>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={500}>
        <img className="d-block w-100" src={slider4} alt="Third slide" />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
        </Carousel> */}
      </div>
    );
  }
}
export default SliderCarousel;

import {
  getCountriesURL,
  getStatesByCountryURL,
  getCitiesByStateURL,
} from "@/services/api";

import { authLogin } from "@/_helpers";
import axios from "axios";

export function getCountries() {
  return axios({
    url: getCountriesURL(),
    method: "GET",
  });
}

export function getStatesByCountry(reqData) {
  return axios({
    url: getStatesByCountryURL(),
    method: "POST",
    data: reqData,
  });
}

export function getCitiesByState(reqData) {
  return axios({
    url: getCitiesByStateURL(),
    method: "POST",
    data: reqData,
  });
}

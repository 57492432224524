import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  DECREMENT_PRODUCT_UNIT,
  CLEAR_CART,
  APPLY_WALLET_BALANCE,
  APPLY_VOUCHER_CODE,
} from "@/redux/constants";
import { arrSort } from "@/_helpers";
const initialState = {
  cart: [],
  grandTotalPrice: 0,
  grandTotalMrp: 0,
  grandTotalDiscount: 0,
  walletAmount: 0,
  netAmount: 0,
  points: 0,
  voucherCode: "",
  voucherType: "",
  voucherAmount: 0,
};

// const initialState = [];

function cartReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_TO_CART:
      console.log("initialState >>>>>>> ", initialState);
      console.log("state >>>>>>> ", state);
      let product = action.payload;
      let cartS = state.cart;
      let is_new = true;
      let filterIds = product.filters.filterDTOList.map((v) => v.subfilterId);
      filterIds = arrSort(filterIds);
      product["filterIds"] = filterIds;
      product["filters"] = product.filters;
      product["totalPrice"] =
        parseInt(product.unit) * parseFloat(product.filters.price);
      product["totalMrp"] =
        parseInt(product.unit) * parseFloat(product.filters.mrp);
      product["totalDiscount"] = product.totalPrice - product.totalMrp;

      let final_cart_details = cartS.filter((e) => {
        let EfilterIds = arrSort(e.filterIds);
        let PfilterIds = arrSort(product.filterIds);
        if (
          parseInt(e.id) == parseInt(product.id) &&
          EfilterIds.join(",") == PfilterIds.join(",")
        ) {
          // console.log("exist");
          is_new = false;
          if (e.unit <= 0) {
            e.unit = parseInt(1);
          } else {
            e.unit = parseInt(e.unit) + 1;
            e["totalPrice"] =
              parseInt(e.unit) * parseFloat(product.filters.price);
            e["totalMrp"] = parseInt(e.unit) * parseFloat(product.filters.mrp);
            e["totalDiscount"] = e.totalPrice - e.totalMrp;
          }
        }
        return e;
      });
      let final_cart = [];
      if (is_new == true) {
        final_cart = [...final_cart_details, product];
      } else {
        final_cart = [...final_cart_details];
      }

      let totalPrice = getTotalPrice(final_cart);
      let totalMrp = getTotalMrp(final_cart);
      let totalDiscount = totalPrice - totalMrp;
      let walletAmount = state.walletAmount;
      let netAmount = totalMrp - walletAmount;
      return {
        ...state,
        cart: final_cart,
        grandTotalPrice: totalPrice,
        grandTotalMrp: totalMrp,
        grandTotalDiscount: totalDiscount,
        walletAmount: walletAmount,
        netAmount: netAmount,
      };
      break;

    case REMOVE_FROM_CART:
      let r_product = action.payload;
      let r_filterIds = r_product.filters.filterDTOList.map(
        (v) => v.subfilterId
      );
      r_filterIds = arrSort(r_filterIds);
      let cart = state.cart.filter((item) => {
        let itemFilterIds = arrSort(item.filterIds);
        if (
          item.id == action.payload.id &&
          itemFilterIds.join(",") == r_filterIds.join(",")
        ) {
          // console.log("found", item);
        } else {
          return item;
        }
      });

      let r_totalPrice = getTotalPrice(cart);
      let r_totalMrp = getTotalMrp(cart);
      let r_totalDiscount = r_totalPrice - r_totalMrp;
      let r_walletAmount = state.walletAmount;
      let r_netAmount = r_totalMrp - r_walletAmount;
      return {
        ...state,
        cart: cart,
        grandTotalPrice: r_totalPrice,
        grandTotalMrp: r_totalMrp,
        grandTotalDiscount: r_totalDiscount,
        walletAmount: r_walletAmount,
        netAmount: r_netAmount,
      };
      break;

    case DECREMENT_PRODUCT_UNIT:
      let d_product = action.payload;
      let d_filterIds = d_product.filters.filterDTOList.map(
        (v) => v.subfilterId
      );
      d_product["filterIds"] = d_filterIds;
      let d_cartS = state.cart;
      let d_is_new = true;
      let d_final_cart_details = d_cartS.filter((e) => {
        if (
          parseInt(e.id) == parseInt(d_product.id) &&
          e.filterIds.join(",") == d_product.filterIds.join(",")
        ) {
          d_is_new = false;
          if (e.unit == 0) {
            e.unit = parseInt(1);
          } else {
            e.unit = parseInt(e.unit) + d_product.unit;
            e["totalPrice"] =
              parseInt(e.unit) * parseFloat(d_product.filters.price);
            e["totalMrp"] =
              parseInt(e.unit) * parseFloat(d_product.filters.mrp);
            e["totalDiscount"] = e.totalPrice - e.totalMrp;
          }
        }
        return e;
      });

      let d_final_cart = [];
      if (d_is_new == true) {
        d_final_cart = [...d_final_cart_details, d_product];
      } else {
        d_final_cart = [...d_final_cart_details];
      }
      // console.log("final_cart", final_cart);
      let d_totalPrice = getTotalPrice(d_final_cart);
      // console.log({ totalPrice });
      let d_totalMrp = getTotalMrp(d_final_cart);
      // console.log({ totalMrp });
      let d_totalDiscount = d_totalPrice - d_totalMrp;

      let d_walletAmount = state.walletAmount;
      let d_netAmount = d_totalMrp - d_walletAmount;
      return {
        ...state,
        cart: d_final_cart,
        grandTotalPrice: d_totalPrice,
        grandTotalMrp: d_totalMrp,
        grandTotalDiscount: d_totalDiscount,
        walletAmount: d_walletAmount,
        netAmount: d_netAmount,
      };
      break;

    case CLEAR_CART:
      // let cc_walletAmount = state.walletAmount;
      // let cc_netAmount =
      //   state.totalMrp > 0 ? state.totalMrp - cc_walletAmount : state.totalMrp;
      return {
        ...state,
        cart: [],
        grandTotalPrice: 0,
        grandTotalMrp: 0,
        grandTotalDiscount: 0,
        walletAmount: 0,
        netAmount: 0,
      };
      break;

    case APPLY_WALLET_BALANCE:
      let data = action.payload;
      console.log("data", data);
      cartS = state.cart;
      console.log("carts", cartS);
      totalPrice = getTotalPrice(cartS);
      console.log({ totalPrice });
      totalMrp = getTotalMrp(cartS);
      console.log({ totalMrp });
      totalDiscount = totalPrice - totalMrp;
      console.log({ totalDiscount });
      walletAmount = data.walletAmount;
      console.log({ walletAmount });
      netAmount = totalMrp - walletAmount;
      console.log({ netAmount });
      return {
        ...state,
        cart: cartS,
        grandTotalPrice: totalPrice,
        grandTotalMrp: totalMrp,
        grandTotalDiscount: totalDiscount,
        walletAmount: walletAmount,
        netAmount: netAmount,
      };
      break;

    case APPLY_VOUCHER_CODE:
      let voucherData = action.payload;
      console.log("voucherData", voucherData);
      cartS = state.cart;
      console.log("carts", cartS);
      totalPrice = getTotalPrice(cartS);
      console.log({ totalPrice });
      totalMrp = getTotalMrp(cartS);
      console.log({ totalMrp });
      totalDiscount = totalPrice - totalMrp;
      console.log({ totalDiscount });

      let voucherAmt = 0;
      if (voucherData.discountType === "per") {
        voucherAmt =
          (parseFloat(totalMrp) / 100) * parseFloat(voucherData.discountPer);
      } else {
        voucherAmt = parseFloat(voucherData.discountAmt);
      }

      console.log("voucherAmt ", voucherAmt);
      netAmount = totalMrp - voucherAmt;
      console.log({ netAmount });
      return {
        ...state,
        cart: cartS,
        grandTotalPrice: totalPrice,
        grandTotalMrp: totalMrp,
        grandTotalDiscount: totalDiscount,
        walletAmount: 0,
        voucherAmount: voucherAmt,
        netAmount: netAmount,
      };
      break;

    default:
      return state;
  }
}
export default cartReducer;

const findProductIndex = (cart, productId, filterIds = []) => {
  // console.log("cart", { cart });
  let res = -1;
  res = cart.find((p, i) => {
    // console.log("p id", p.id);
    // console.log("productId", productId);
    console.log("p filter Ids ", JSON.stringify(p.filtersIds));
    console.log("filterIds", JSON.stringify(filterIds));

    if (
      parseInt(p.id) == parseInt(productId) &&
      p.filterIds.join(",") == filterIds.join(",")
    ) {
      return p;
    }
  });
  console.log("res", res);
  return res;
};

const getTotalPrice = (cart) => {
  return (
    cart.length > 0 &&
    cart.reduce((prev, next) => prev + parseFloat(next.totalPrice), 0)
    // cart.reduce((prev, next) => prev + parseFloat(next.totalPrice), 0).toFixed()
  );
};

const getTotalMrp = (cart) => {
  return (
    cart.length > 0 &&
    cart.reduce((prev, next) => prev + parseFloat(next.totalMrp), 0)
    // cart.reduce((prev, next) => prev + parseFloat(next.totalMrp), 0).toFixed()
  );
};

const updateProductUnit = (cart, product) => {
  const productIndex = findProductIndex(cart, product.id);
  const updatedProducts = [...cart];
  const existingProduct = updatedProducts[productIndex];

  // console.log({ product });
  const updatedUnitProduct = {
    ...existingProduct,
    unit: existingProduct.unit + product.unit,
    totalMrp:
      parseInt(existingProduct.unit + product.unit) * parseFloat(product.mrp),
    totalPrice:
      parseInt(existingProduct.unit + product.unit) * parseFloat(product.price),
    totalDiscount:
      parseInt(existingProduct.unit + product.unit) *
        parseFloat(product.price) -
      parseInt(existingProduct.unit + product.unit) * parseFloat(product.mrp),
  };

  updatedProducts[productIndex] = updatedUnitProduct;
  return updatedProducts;
};

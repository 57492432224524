import config from "config";

export function saveOrderUrl() {
  return `${config.apiUrl}/save-order`;
}

export function fetchUserOrdersUrl() {
  return `${config.apiUrl}/fetchUserOrders`;
}

export function fetchUserOrderProductsUrl() {
  return `${config.apiUrl}/fetchUserOrderProducts`;
}

export function saveOrderRepairFromWebsiteUrl() {
  return `${config.apiUrl}/saveOrderRepairFromWebsite`;
}

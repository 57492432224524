import React, { Component } from "react";
import tabletannis from "@/assets/images/tabletannis.png";
import tbg from "@/assets/images/tbg.png";
import bg22 from "@/assets/images/bg22.png";
import bg33 from "@/assets/images/bg33.png";
import banner1 from "@/assets/images/banner1.png";
import banner2 from "@/assets/images/banner2.png";
import banner3 from "@/assets/images/banner3.png";
import helmet from "@/assets/images/helmet.svg";
import ball from "@/assets/images/ball.svg";
import elbowgaurd from "@/assets/images/elbowgaurd.svg";
import elbowsleeves from "@/assets/images/elbowsleeves.svg";
import stump from "@/assets/images/stump.svg";
import shoes from "@/assets/images/shoes.svg";
import bat from "@/assets/images/bat.svg";
import gloves from "@/assets/images/gloves.svg";
import jursey from "@/assets/images/jursey.svg";
import thighpad from "@/assets/images/thighpad.svg";
import pads from "@/assets/images/pads.svg";
import { getGroupList } from "@/services/api_function";
import {
  Col,
  Row,
  Form,
  Button,
  Dropdown,
  Item,
  Container,
  Tabs,
  Tab,
} from "react-bootstrap";

class OnlineShop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lstGroup: [],
      scrollBackground: "nav-bg",
    };
    this.handleScroll = this.handleScroll.bind(this);
  }
  handleScroll() {
    console.log(" this.state.scrollBackground ", this.state.scrollBackground);
    this.setState({
      scrollBackground: !this.state.scrollBackground,
    });
  }
  lstGroupList = () => {
    console.log("grouplist");
    getGroupList()
      .then((response) => {
        let res = response.data;
        if (res.responseStatus == 200) {
          let g_list = res.responseObject;
          if (g_list.length > 0) {
            this.setState({ lstGroup: g_list });
          }
        }
      })
      .catch((error) => {
        this.setState({ lstGroup: [] });
        console.log("error", error);
      });
  };

  componentDidMount() {
    console.log("did mount");
    this.lstGroupList();
  }
  render() {
    const { lstGroup } = this.state;
    const scrollBg = this.scrollBackground ? "nav-bg scrolling" : "nav-bg";
    return (
      <div className="pro-item">
        {/* <div className=" tab-title"></div> */}
        <div
          className="multilinksimg multiimgetab"
          // onScroll={() => {
          //   console.log("scrolling");
          //   // this.handleScroll;
          // }}
        >
          <Container fluid>
            <div className="shopby-h1">
              <h1 className="">SHOP BY CATEGORY</h1>
            </div>
            <Tabs
              defaultActiveKey="home"
              id="uncontrolled-tab-example"
              className="mt-5 mb-3 pt-5 tabcontent"
            >
              <Tab
                eventKey="home"
                title="Batsman"
                style={{ paddingBottom: "60px" }}
              >
                <div className="mobileview">
                  <Row>
                    <Col md="2" sm="0" xs="0"></Col>
                    <Col md="1" sm="1" xs="1" className="p-0 my-auto">
                      {/* <div style={{ display: "block" }}> */}
                      <div
                        className="bannerimagestyle"
                        style={{ float: "right" }}
                      >
                        <img
                          src={helmet}
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/ProductList/11`);
                          }}
                        />
                      </div>
                      <div
                        className="bannerimagestyle"
                        style={{ float: "right" }}
                      >
                        <img
                          src={ball}
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/ProductList/3`);
                          }}
                        />
                      </div>
                      <div
                        className="bannerimagestyle"
                        style={{ float: "right" }}
                      >
                        <img
                          src={elbowgaurd}
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/ProductList/9`);
                          }}
                        />
                      </div>
                      <div
                        className="bannerimagestyle"
                        style={{ float: "right" }}
                      >
                        <img
                          src={stump}
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/ProductList/5`);
                          }}
                        />
                      </div>
                      <div
                        className="bannerimagestyle"
                        style={{ float: "right" }}
                      >
                        <img
                          src={shoes}
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/ProductList/6`);
                          }}
                        />
                      </div>
                      {/* </div> */}
                    </Col>
                    <Col md="6" sm="10" xs="10">
                      <img src={banner1} />
                    </Col>
                    <Col md="1" sm="1" xs="1" className="p-0 my-auto">
                      <div
                        className="bannerimagestyle"
                        style={{ float: "left" }}
                      >
                        <img
                          src={bat}
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/ProductList/4`);
                          }}
                        />
                      </div>
                      <div
                        className="bannerimagestyle"
                        style={{ float: "left" }}
                      >
                        <img
                          src={gloves}
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/ProductList/8`);
                          }}
                        />
                      </div>
                      <div
                        className="bannerimagestyle"
                        style={{ float: "left" }}
                      >
                        <img
                          src={jursey}
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/ProductList/12`);
                          }}
                        />
                      </div>
                      <div
                        className="bannerimagestyle"
                        style={{ float: "left" }}
                      >
                        <img
                          src={thighpad}
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/ProductList/14`);
                          }}
                        />
                      </div>
                      <div
                        className="bannerimagestyle"
                        style={{ float: "left" }}
                      >
                        <img
                          src={pads}
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/ProductList/13`);
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="2" sm="0" xs="0"></Col>
                  </Row>
                  {/* <img src="tbg.png" usemap="#image-map" />

                  <map name="image-map">
                    <area
                      // target=""
                      alt="Cap / Helmets"
                      title="Cap / Helmets"
                      href="#."
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/ProductList/11`);
                      }}
                      coords="0,4,102,37"
                      shape="rect"
                      className="caphoverimg"
                    />
                    <area
                      // target=""
                      alt="Ball"
                      title="Ball"
                      href="#."
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/ProductList/3`);
                      }}
                      coords="2,42,99,71"
                      shape="rect"
                    />
                    <area
                      // target=""
                      alt="Elbow Guard"
                      title="Elbow Guard"
                      href="#."
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/ProductList/9`);
                      }}
                      coords="1,74,95,107"
                      shape="rect"
                    />
                    <area
                      // target=""
                      alt="Stumps"
                      title="Stumps"
                      href="#."
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/ProductList/5`);
                      }}
                      coords="0,111,92,143"
                      shape="rect"
                    />
                    <area
                      // target=""
                      alt="Shoes"
                      title="Shoes"
                      href="#."
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/ProductList/6`);
                      }}
                      coords="2,146,89,177"
                      shape="rect"
                    />
                    <area
                      // target=""
                      alt="Bats"
                      title="Bats"
                      href="#."
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/ProductList/4`);
                      }}
                      coords="284,3,374,38"
                      shape="rect"
                    />
                    <area
                      // target=""
                      alt="Gloves"
                      title="Gloves"
                      href="#."
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/ProductList/8`);
                      }}
                      coords="283,41,373,70"
                      shape="rect"
                    />
                    <area
                      // target=""
                      alt="Jursey"
                      title="Jursey"
                      href="#."
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/ProductList/12`);
                      }}
                      coords="283,74,373,107"
                      shape="rect"
                    />
                    <area
                      // target=""
                      alt="Thigh Pad"
                      title="Thigh Pad"
                      href="#."
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/ProductList/14`);
                      }}
                      coords="284,111,374,141"
                      shape="rect"
                    />
                    <area
                      // target=""
                      alt="Pads"
                      title="Pads"
                      href="#."
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/ProductList/13`);
                      }}
                      coords="285,144,373,176"
                      shape="0"
                    />
                  </map> */}
                </div>
                <div className="tabletview">
                  <Row>
                    <Col md="2" sm="0" xs="0"></Col>
                    <Col md="1" sm="1" xs="1" className="p-0 my-auto">
                      {/* <div style={{ display: "block" }}> */}
                      <div
                        className="bannerimagestyle"
                        style={{ float: "right" }}
                      >
                        <img
                          src={helmet}
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/ProductList/11`);
                          }}
                        />
                      </div>
                      <div
                        className="bannerimagestyle"
                        style={{ float: "right" }}
                      >
                        <img
                          src={ball}
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/ProductList/3`);
                          }}
                        />
                      </div>
                      <div
                        className="bannerimagestyle"
                        style={{ float: "right" }}
                      >
                        <img
                          src={elbowgaurd}
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/ProductList/9`);
                          }}
                        />
                      </div>
                      <div
                        className="bannerimagestyle"
                        style={{ float: "right" }}
                      >
                        <img
                          src={stump}
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/ProductList/5`);
                          }}
                        />
                      </div>
                      <div
                        className="bannerimagestyle"
                        style={{ float: "right" }}
                      >
                        <img
                          src={shoes}
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/ProductList/6`);
                          }}
                        />
                      </div>
                      {/* </div> */}
                    </Col>
                    <Col md="6" sm="10" xs="10" className="p-0">
                      <img src={banner1} />
                    </Col>
                    <Col md="1" sm="1" xs="1" className="p-0 my-auto">
                      <div
                        className="bannerimagestyle"
                        style={{ float: "left" }}
                      >
                        <img
                          src={bat}
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/ProductList/4`);
                          }}
                        />
                      </div>
                      <div
                        className="bannerimagestyle"
                        style={{ float: "left" }}
                      >
                        <img
                          src={gloves}
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/ProductList/8`);
                          }}
                        />
                      </div>
                      <div
                        className="bannerimagestyle"
                        style={{ float: "left" }}
                      >
                        <img
                          src={jursey}
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/ProductList/12`);
                          }}
                        />
                      </div>
                      <div
                        className="bannerimagestyle"
                        style={{ float: "left" }}
                      >
                        <img
                          src={thighpad}
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/ProductList/14`);
                          }}
                        />
                      </div>
                      <div
                        className="bannerimagestyle"
                        style={{ float: "left" }}
                      >
                        <img
                          src={pads}
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/ProductList/13`);
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="2" sm="0" xs="0"></Col>
                  </Row>
                  {/* <img src="tbg.png" usemap="#image-map7" />

                  <map name="image-map7">
                    <area
                      alt="Helmet"
                      title="Helmet"
                      href="#."
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/ProductList/11`);
                      }}
                      coords="7,11,178,69"
                      shape="rect"
                    />
                    <area
                      alt="Ball"
                      title="Ball"
                      href="#."
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/ProductList/3`);
                      }}
                      coords="7,74,179,132"
                      shape="rect"
                    />
                    <area
                      alt="Elbow Guard"
                      title="Elbow Guard"
                      href="#."
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/ProductList/9`);
                      }}
                      coords="7,137,179,194"
                      shape="rect"
                    />
                    <area
                      alt="Stumps"
                      title="Stumps"
                      href="#."
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/ProductList/5`);
                      }}
                      coords="8,201,177,259"
                      shape="rect"
                    />
                    <area
                      alt="Shoes"
                      title="Shoes"
                      href="#."
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/ProductList/6`);
                      }}
                      coords="8,264,175,320"
                      shape="rect"
                    />
                    <area
                      alt="Bats"
                      title="Bats"
                      // href="/ProductList/4"
                      href="#."
                      onClick={(e) => {
                        e.preventDefault();
                        console.log("clicked");
                        this.props.history.push(`/ProductList/4`);
                      }}
                      // coords="535,9,675,70"
                      coords="250,11,400,25"
                      shape="rect"
                    />
                    <area
                      alt="Gloves"
                      title="Gloves"
                      href="#."
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/ProductList/8`);
                      }}
                      // coords="537,74,677,132"
                      coords="200,30,600,60"
                      shape="rect"
                    />
                    <area
                      alt="Jursey"
                      title="Jursey"
                      href="#."
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/ProductList/12`);
                      }}
                      // coords="538,138,677,194"
                      coords="100,40,800,100"
                      shape="rect"
                    />
                    <area
                      alt="Thigh Pad"
                      title="Thigh Pad"
                      href="#."
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/ProductList/14`);
                      }}
                      // coords="509,201,678,256"
                      coords="260,100,800,130"
                      shape="rect"
                    />
                    <area
                      alt="Pads"
                      title="Pads"
                      href="#."
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/ProductList/13`);
                      }}
                      // coords="532,263,677,321"
                      coords="280,140,800,170"
                      shape="rect"
                    />
                  </map> */}
                </div>
                <div className="desktopview">
                  <Row>
                    <Col md="2" sm="0" xs="0"></Col>
                    <Col md="1" sm="1" xs="1" className="p-0 my-auto">
                      {/* <div style={{ display: "block" }}> */}
                      <div
                        className="bannerimagestyle"
                        style={{ float: "right" }}
                      >
                        <img
                          src={helmet}
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/ProductList/11`);
                          }}
                        />
                      </div>
                      <div
                        className="bannerimagestyle"
                        style={{ float: "right" }}
                      >
                        <img
                          src={ball}
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/ProductList/3`);
                          }}
                        />
                      </div>
                      <div
                        className="bannerimagestyle"
                        style={{ float: "right" }}
                      >
                        <img
                          src={elbowgaurd}
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/ProductList/9`);
                          }}
                        />
                      </div>
                      <div
                        className="bannerimagestyle"
                        style={{ float: "right" }}
                      >
                        <img
                          src={stump}
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/ProductList/5`);
                          }}
                        />
                      </div>
                      <div
                        className="bannerimagestyle"
                        style={{ float: "right" }}
                      >
                        <img
                          src={shoes}
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/ProductList/6`);
                          }}
                        />
                      </div>
                      {/* </div> */}
                    </Col>
                    <Col md="6" sm="10" xs="10">
                      <img src={banner1} />
                    </Col>
                    <Col md="1" sm="1" xs="1" className="p-0 my-auto">
                      <div
                        className="bannerimagestyle"
                        style={{ float: "left" }}
                      >
                        <img
                          src={bat}
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/ProductList/4`);
                          }}
                        />
                      </div>
                      <div
                        className="bannerimagestyle"
                        style={{ float: "left" }}
                      >
                        <img
                          src={gloves}
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/ProductList/8`);
                          }}
                        />
                      </div>
                      <div
                        className="bannerimagestyle"
                        style={{ float: "left" }}
                      >
                        <img
                          src={jursey}
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/ProductList/12`);
                          }}
                        />
                      </div>
                      <div
                        className="bannerimagestyle"
                        style={{ float: "left" }}
                      >
                        <img
                          src={thighpad}
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/ProductList/14`);
                          }}
                        />
                      </div>
                      <div
                        className="bannerimagestyle"
                        style={{ float: "left" }}
                      >
                        <img
                          src={pads}
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/ProductList/13`);
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="2" sm="0" xs="0"></Col>
                  </Row>

                  {/* usemap="#image-map1" */}
                  {/* <map name="image-map1">
                    <area
                      alt="Helmet"
                      title="Helmet"
                      href="#."
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/ProductList/11`);
                      }}
                      coords="8,12,277,108"
                      shape="rect"
                      className="caphoverimg"
                    />
                    <area
                      alt="Ball"
                      title="Ball"
                      href="#."
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/ProductList/3`);
                      }}
                      coords="8,122,281,217"
                      shape="rect"
                      className="ballhoverimg"
                    />
                    <area
                      alt="Elbow Guard"
                      title="Elbow Guard"
                      href="#."
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/ProductList/9`);
                      }}
                      coords="8,231,278,326"
                      shape="rect"
                    />
                    <area
                      alt="Stumps"
                      title="Stumps"
                      href="#."
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/ProductList/5`);
                      }}
                      coords="7,340,280,436"
                      shape="rect"
                    />
                    <area
                      alt="Shoes"
                      title="Shoes"
                      href="#."
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/ProductList/6`);
                      }}
                      coords="8,449,280,545"
                      shape="rect"
                    />
                    <area
                      alt="Bats"
                      title="Bats"
                      href="#."
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/ProductList/4`);
                      }}
                      coords="919,14,1152,109"
                      shape="rect"
                    />
                    <area
                      alt="Gloves"
                      title="Gloves"
                      href="#."
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/ProductList/8`);
                      }}
                      coords="919,121,1145,215"
                      shape="rect"
                    />
                    <area
                      alt="Jursey"
                      title="Jursey"
                      href="#."
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/ProductList/12`);
                      }}
                      coords="915,232,1143,327"
                      shape="rect"
                    />
                    <area
                      alt="Thigh Pad"
                      title="Thigh Pad"
                      href="#."
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/ProductList/14`);
                      }}
                      coords="870,340,1143,436"
                      shape="rect"
                    />
                    <area
                      alt="Pads"
                      title="Pads"
                      href="#."
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/ProductList/13`);
                      }}
                      coords="870,450,1143,544"
                      shape="rect"
                    />
                  </map>  */}
                </div>
              </Tab>
              <Tab
                eventKey="profile"
                title="Bowler"
                style={{ paddingBottom: "60px" }}
              >
                <div className="mobileview">
                  {/* <img src="bg22.png" usemap="#image-map22" />
                  <map name="image-map22">
                    <area
                      alt="Ball"
                      title="Ball"
                      href="#."
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/ProductList/3`);
                      }}
                      coords="0,46,87,81"
                      shape="rect"
                    />
                    <area
                      alt="Shoes"
                      title="Shoes"
                      href="#."
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/ProductList/6`);
                      }}
                      coords="0,93,87,126"
                      shape="rect"
                    />
                    <area
                      alt="Elbow Sleeve"
                      title="Elbow Sleeve"
                      href="#."
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/ProductList/10`);
                      }}
                      coords="278,46,373,82"
                      shape="rect"
                    />
                    <area
                      alt="Jursey"
                      title="Jursey"
                      href="#."
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/ProductList/12`);
                      }}
                      coords="279,94,373,127"
                      shape="rect"
                    />
                  </map> */}
                  <Row>
                    <Col lg="2" md="2" sm="0" xs="0"></Col>
                    <Col lg="1" md="1" sm="1" xs="1" className="p-0 my-auto">
                      <div
                        className="bannerimagestyletwotab"
                        style={{ float: "right" }}
                      >
                        <img
                          src={ball}
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/ProductList/3`);
                          }}
                        />
                      </div>
                      <div
                        className="bannerimagestyletwotab"
                        style={{ float: "right" }}
                      >
                        <img
                          src={shoes}
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/ProductList/6`);
                          }}
                        />
                      </div>
                    </Col>
                    <Col lg="6" md="6" sm="10" xs="10" className="p-0">
                      <img src={banner2}></img>
                    </Col>
                    <Col lg="1" md="1" sm="1" xs="1" className="p-0 my-auto">
                      <div
                        className="bannerimagestyletwotab"
                        style={{ float: "left" }}
                      >
                        <img
                          src={elbowsleeves}
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/ProductList/10`);
                          }}
                        />
                      </div>
                      <div
                        className="bannerimagestyletwotab"
                        style={{ float: "left" }}
                      >
                        <img
                          src={jursey}
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/ProductList/12`);
                          }}
                        />
                      </div>
                    </Col>
                    <Col lg="2" md="2" sm="0" xs="0"></Col>
                  </Row>
                </div>
                <div className="tabletview">
                  {/* <img src="bg22.png" usemap="#image-map8" />

                  <map name="image-map8">
                    <area
                      alt="Ball"
                      title="Ball"
                      href="#."
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/ProductList/3`);
                      }}
                      coords="15,87,159,145"
                      shape="rect"
                    />
                    <area
                      alt="Shoes"
                      title="Shoes"
                      href="#."
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/ProductList/6`);
                      }}
                      coords="17,170,158,226"
                      shape="rect"
                    />
                    <area
                      alt="Elbow Sleeve"
                      title="Elbow Sleeve"
                      href="#."
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/ProductList/10`);
                      }}
                      coords="499,86,665,147"
                      shape="rect"
                    />
                    <area
                      alt="Jursey"
                      title="Jursey"
                      href="#."
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/ProductList/12`);
                      }}
                      coords="499,170,665,226"
                      shape="rect"
                    />
                  </map> */}
                  <Row>
                    <Col lg="2" md="2" sm="0" xs="0"></Col>
                    <Col lg="1" md="1" sm="1" xs="1" className="p-0 my-auto">
                      <div
                        className="bannerimagestyletwotab"
                        style={{ float: "right" }}
                      >
                        <img
                          src={ball}
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/ProductList/3`);
                          }}
                        />
                      </div>
                      <div
                        className="bannerimagestyletwotab"
                        style={{ float: "right" }}
                      >
                        <img
                          src={shoes}
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/ProductList/6`);
                          }}
                        />
                      </div>
                    </Col>
                    <Col lg="6" md="6" sm="10" xs="10" className="p-0">
                      <img src={banner2}></img>
                    </Col>
                    <Col lg="1" md="1" sm="1" xs="1" className="p-0 my-auto">
                      <div
                        className="bannerimagestyletwotab"
                        style={{ float: "left" }}
                      >
                        <img
                          src={elbowsleeves}
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/ProductList/10`);
                          }}
                        />
                      </div>
                      <div
                        className="bannerimagestyletwotab"
                        style={{ float: "left" }}
                      >
                        <img
                          src={jursey}
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/ProductList/12`);
                          }}
                        />
                      </div>
                    </Col>
                    <Col lg="2" md="2" sm="0" xs="0"></Col>
                  </Row>
                </div>
                <div className="desktopview">
                  {/* <img src="bg22.png" usemap="#image-map2" />

                  <map name="image-map2">
                    <area
                      alt="Ball"
                      title="Ball"
                      href="#."
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/ProductList/3`);
                      }}
                      coords="252,239,6,143"
                      shape="rect"
                    />
                    <area
                      alt="Shoes"
                      title="Shoes"
                      href="#."
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/ProductList/6`);
                      }}
                      coords="6,287,247,382"
                      shape="0"
                    />
                    <area
                      alt="Elbow Sleeve"
                      title="Elbow Sleeve"
                      href="#."
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/ProductList/10`);
                      }}
                      coords="1141,238,853,143"
                      shape="0"
                    />
                    <area
                      alt="Jursey"
                      title="Jursey"
                      href="#."
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/ProductList/12`);
                      }}
                      coords="858,288,1143,382"
                      shape="0"
                    />
                  </map> */}

                  <Row>
                    <Col lg="2" md="2" sm="0" xs="0"></Col>
                    <Col lg="1" md="1" sm="1" xs="1" className="p-0 my-auto">
                      <div
                        className="bannerimagestyletwotab"
                        style={{ float: "right" }}
                      >
                        <img
                          src={ball}
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/ProductList/3`);
                          }}
                        />
                      </div>
                      <div
                        className="bannerimagestyletwotab"
                        style={{ float: "right" }}
                      >
                        <img
                          src={shoes}
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/ProductList/6`);
                          }}
                        />
                      </div>
                    </Col>
                    <Col lg="6" md="6" sm="10" xs="10" className="p-0">
                      <img src={banner2}></img>
                    </Col>
                    <Col lg="1" md="1" sm="1" xs="1" className="p-0 my-auto">
                      <div
                        className="bannerimagestyletwotab"
                        style={{ float: "left" }}
                      >
                        <img
                          src={elbowsleeves}
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/ProductList/10`);
                          }}
                        />
                      </div>
                      <div
                        className="bannerimagestyletwotab"
                        style={{ float: "left" }}
                      >
                        <img
                          src={jursey}
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/ProductList/12`);
                          }}
                        />
                      </div>
                    </Col>
                    <Col lg="2" md="2" sm="0" xs="0"></Col>
                  </Row>
                </div>
              </Tab>
              <Tab
                eventKey="contact"
                title="Wicket Keeper"
                style={{ paddingBottom: "60px" }}
              >
                <div className="mobileview">
                  {/* <img src="bg33.png" usemap="#image-map33" />
                  <map name="image-map33">
                    <area
                      alt="Helmets"
                      title="Helmets"
                      href="#."
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/ProductList/11`);
                      }}
                      coords="5,43,84,74"
                      shape="rect"
                    />
                    <area
                      alt="Shoes"
                      title="Shoes"
                      href="#."
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/ProductList/6`);
                      }}
                      coords="6,89,80,121"
                      shape="rect"
                    />
                    <area
                      alt="Gloves"
                      title="Gloves"
                      href="#."
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/ProductList/7`);
                      }}
                      coords="285,42,372,75"
                      shape="rect"
                    />
                    <area
                      alt="Pads"
                      title="Pads"
                      href="#."
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/ProductList/13`);
                      }}
                      coords="285,89,371,120"
                      shape="rect"
                    />
                  </map> */}
                  <Row>
                    <Col lg="2" md="2" sm="0" xs="0"></Col>
                    <Col lg="1" md="1" sm="1" xs="1" className="p-0 my-auto">
                      <div
                        className="bannerimagestyletwotab"
                        style={{ float: "right" }}
                      >
                        <img
                          src={helmet}
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/ProductList/11`);
                          }}
                        />
                      </div>
                      <div
                        className="bannerimagestyletwotab"
                        style={{ float: "right" }}
                      >
                        <img
                          src={shoes}
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/ProductList/6`);
                          }}
                        />
                      </div>
                    </Col>
                    <Col lg="6" md="6" sm="10" xs="10" className="p-0">
                      <img src={banner3}></img>
                    </Col>
                    <Col lg="1" md="1" sm="1" xs="1" className="p-0 my-auto">
                      <div
                        className="bannerimagestyletwotab"
                        style={{ float: "left" }}
                      >
                        <img
                          src={gloves}
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/ProductList/7`);
                          }}
                        />
                      </div>
                      <div
                        className="bannerimagestyletwotab"
                        style={{ float: "left" }}
                      >
                        <img
                          src={pads}
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/ProductList/13`);
                          }}
                        />
                      </div>
                    </Col>
                    <Col lg="2" md="2" sm="0" xs="0"></Col>
                  </Row>
                </div>
                <div className="tabletview">
                  {/* <img src="bg33.png" usemap="#image-map9" />

                  <map name="image-map9">
                    <area
                      alt="Helmets"
                      title="Helmets"
                      href="#."
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/ProductList/11`);
                      }}
                      coords="13,66,169,124"
                      shape="rect"
                    />
                    <area
                      alt="Shoes"
                      title="Shoes"
                      href="#."
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/ProductList/6`);
                      }}
                      coords="13,149,147,207"
                      shape="rect"
                    />
                    <area
                      alt="Gloves"
                      title="Gloves"
                      href="#."
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/ProductList/7`);
                      }}
                      coords="520,68,668,125"
                      shape="rect"
                    />
                    <area
                      alt="Pads"
                      title="Pads"
                      href="#."
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/ProductList/13`);
                      }}
                      coords="521,150,666,207"
                      shape="rect"
                    />
                  </map> */}
                  <Row>
                    <Col lg="2" md="2" sm="0" xs="0"></Col>
                    <Col lg="1" md="1" sm="1" xs="1" className="p-0 my-auto">
                      <div
                        className="bannerimagestyletwotab"
                        style={{ float: "right" }}
                      >
                        <img
                          src={helmet}
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/ProductList/11`);
                          }}
                        />
                      </div>
                      <div
                        className="bannerimagestyletwotab"
                        style={{ float: "right" }}
                      >
                        <img
                          src={shoes}
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/ProductList/6`);
                          }}
                        />
                      </div>
                    </Col>
                    <Col lg="6" md="6" sm="10" xs="10" className="p-0">
                      <img src={banner3}></img>
                    </Col>
                    <Col lg="1" md="1" sm="1" xs="1" className="p-0 my-auto">
                      <div
                        className="bannerimagestyletwotab"
                        style={{ float: "left" }}
                      >
                        <img
                          src={gloves}
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/ProductList/7`);
                          }}
                        />
                      </div>
                      <div
                        className="bannerimagestyletwotab"
                        style={{ float: "left" }}
                      >
                        <img
                          src={pads}
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/ProductList/13`);
                          }}
                        />
                      </div>
                    </Col>
                    <Col lg="2" md="2" sm="0" xs="0"></Col>
                  </Row>
                </div>
                <div className="desktopview">
                  {/* <img src="bg33.png" usemap="#image-map3" />

                  <map name="image-map3">
                    <area
                      alt="Helmets"
                      title="Helmets"
                      href="#."
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/ProductList/11`);
                      }}
                      coords="26,112,272,207"
                      shape="rect"
                    />
                    <area
                      alt="Shoes"
                      title="Shoes"
                      href="#."
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/ProductList/6`);
                      }}
                      coords="27,254,270,349"
                      shape="0"
                    />
                    <area
                      alt="Gloves"
                      title="Gloves"
                      href="#."
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/ProductList/7`);
                      }}
                      coords="898,109,1161,208"
                      shape="0"
                    />
                    <area
                      alt="Pads"
                      title="Pads"
                      href="#."
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/ProductList/13`);
                      }}
                      coords="899,255,1160,350"
                      shape="0"
                    />
                  </map> */}

                  <Row>
                    <Col lg="2" md="2" sm="0" xs="0"></Col>
                    <Col lg="1" md="1" sm="1" xs="1" className="p-0 my-auto">
                      <div
                        className="bannerimagestyletwotab"
                        style={{ float: "right" }}
                      >
                        <img
                          src={helmet}
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/ProductList/11`);
                          }}
                        />
                      </div>
                      <div
                        className="bannerimagestyletwotab"
                        style={{ float: "right" }}
                      >
                        <img
                          src={shoes}
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/ProductList/6`);
                          }}
                        />
                      </div>
                    </Col>
                    <Col lg="6" md="6" sm="10" xs="10" className="p-0">
                      <img src={banner3}></img>
                    </Col>
                    <Col lg="1" md="1" sm="1" xs="1" className="p-0 my-auto">
                      <div
                        className="bannerimagestyletwotab"
                        style={{ float: "left" }}
                      >
                        <img
                          src={gloves}
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/ProductList/7`);
                          }}
                        />
                      </div>
                      <div
                        className="bannerimagestyletwotab"
                        style={{ float: "left" }}
                      >
                        <img
                          src={pads}
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/ProductList/13`);
                          }}
                        />
                      </div>
                    </Col>
                    <Col lg="2" md="2" sm="0" xs="0"></Col>
                  </Row>
                </div>
              </Tab>
            </Tabs>
            {/* <div className="moresport">
              <h4>More In Sports</h4>
              <Row>
                {lstGroup.length > 0 &&
                  lstGroup.map((v, i) => {
                    let gpname = v.groupName.toLowerCase();
                    if (gpname.includes("cricket") == false) {
                      return (
                        <Col xs="6" md="2">
                          <Row>
                            <a href={`/GroupList/${v.id}`}>
                              <div className="hover01 column">
                                <div className="sportnm">
                                  <figure className="sportimg">
                                    <img src={v.groupImage} />
                                  </figure>
                                  <h6>{v.groupName}</h6>
                                </div>
                              </div>
                            </a>
                          </Row>
                        </Col>
                      );
                    }
                  })}
                <Col xs="6" md="2">
                  <Row>
                    <a href="">
                      <div className="hover01 column">
                        <div className="sportnm">
                          <figure className="sportimg sportblurimg">
                            <img src={tabletannis} />
                            <p>Browse Categories</p>
                          </figure>
                        </div>
                      </div>
                    </a>
                  </Row>
                </Col>
              </Row>
            </div> */}
          </Container>
        </div>
      </div>
    );
  }
}

export default OnlineShop;

import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  DECREMENT_PRODUCT_UNIT,
  CLEAR_CART,
  APPLY_WALLET_BALANCE,
  APPLY_VOUCHER_CODE,
} from "@/redux/constants";

export function addToCart({
  id,
  name,
  price,
  mrp,
  discountType,
  discount,
  unit,
  brandName,
  firstImage,
  thumbImage,
  discountInPercentage,
  discountInAmount,
  filters,
}) {
  return {
    type: ADD_TO_CART,
    payload: {
      id,
      name,
      price,
      mrp,
      discountType,
      discount,
      unit,
      brandName,
      firstImage,
      thumbImage,
      discountInPercentage,
      discountInAmount,
      filters,
    },
  };
}

export function removeFromCart({ id, filters }) {
  return {
    type: REMOVE_FROM_CART,
    payload: { id, filters },
  };
}

export function applyWalletBalance({ walletAmount }) {
  return {
    type: APPLY_WALLET_BALANCE,
    payload: { walletAmount },
  };
}

export function applyVoucherCode(voucherData) {
  return {
    type: APPLY_VOUCHER_CODE,
    payload: voucherData,
  };
}

export function decrementProductUnit({
  id,
  price,
  mrp,
  discount,
  currentUnit,
  unit,
  filters,
}) {
  return {
    type: DECREMENT_PRODUCT_UNIT,
    payload: {
      id,
      price,
      mrp,
      discount,
      currentUnit,
      unit,
      filters,
    },
  };
}

export function clearCart() {
  return {
    type: CLEAR_CART,
    payload: {},
  };
}

import React, { Component } from "react";
import Slide from "react-reveal/Slide";
import { SidebarLayout } from "@/Pages/Layout";
import { Col, Row, Table } from "react-bootstrap";
import Notifications, { notify } from "react-notify-toast";
import moment from "moment";
import reward from "@/assets/images/reward.png";
//import "./myorder.css";
import "./dashboard.css";
import {
  getWalletHistoryForWeb,
  getUserWallet,
  authenticationService,
} from "@/services/api_function";

class WalletCart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userWalletBalance: 0,
      walletList: [],
    };
  }

  getUserWalletData = () => {
    let reqData = new FormData();
    reqData.append("userId", authenticationService.currentUserValue.userId);
    getUserWallet(reqData)
      .then((response) => {
        let res = response.data;
        console.log("responser wallet", res);
        if (res.responseStatus == 200) {
          this.setState({
            userWalletBalance: res.response,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  getWalletHistoryForWebFun = () => {
    getWalletHistoryForWeb()
      .then((response) => {
        let res = response.data;
        if (res.responseStatus == 200) {
          this.setState({ walletList: res.response });
        } else {
          notify.show(res.message, "error", 2000);
        }
      })
      .catch((error) => {
        console.log({ error });
      });
  };
  componentDidMount() {
    this.getUserWalletData();
    this.getWalletHistoryForWebFun();
  }

  render() {
    const { userWalletBalance, walletList } = this.state;
    return (
      <SidebarLayout
        breadcrum_parent_name_url="/Dashboard"
        breadcrum_parent_name="My Account"
        breadcrum_child_name="Wallet Rewards"
        {...this.props}
      >
        <Notifications options={{ zIndex: 9999, top: "50px" }} />
        <Slide right>
          <div className="accountinfo">
            <div className="walletcard mb-3">
              <Row>
                <Col lg="10">
                  <p>My Wallet Reward Amount</p>
                  <h2>{userWalletBalance}</h2>
                </Col>
                <Col lg="2">
                  <img src={reward} alt="Logo" className="img-style"></img>
                </Col>
              </Row>
            </div>
            {walletList.length > 0 && (
              <>
                <h4>Reward History</h4>
                <div>
                  <Table className="table_style">
                    <thead>
                      <tr>
                        <th>DATE</th>
                        <th>Order No</th>
                        <th>DETAILS</th>
                        <th>POINTS</th>
                      </tr>
                    </thead>

                    <tbody>
                      {walletList.length > 0 &&
                        walletList.map((v, i) => {
                          let color = "#65cb76";
                          if (v.flag === "O") color = "red";
                          return (
                            <tr>
                              <td>
                                {moment(v.tranxDate).format("DD MMM YYYY")}
                              </td>
                              <td>{v.orderNo}</td>
                              <td>{v.walletName}</td>

                              <td style={{ color: color }}>
                                {v.flag === "I" ? "+" : "-"} {v.pointsInAmount}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </div>
              </>
            )}
          </div>
        </Slide>
      </SidebarLayout>
    );
  }
}
export default WalletCart;

import React, { Component } from "react";
import wishlist from "@/assets/images/nowishlist/wishlist.png";

export default class NoWishlist extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="no-prdct mb-5">
        <img src={wishlist} alt="My Cart" className="img-no-wishlist"></img>
        {/* <i className="fa fa-heart-o"></i> */}
        <h5 className="mt-2 mb-2">Your wishlist is empty</h5>
        <p>
          Seems like you don't have wishes here.
          <br /> Make Wish
        </p>
        <button
          onClick={(e) => {
            e.preventDefault();
            this.props.history.push(`/`);
          }}
          type="button"
          className="rainbow rainbow-1 mt-3 emptycartbtn btn btn-primary"
        >
          Start Shopping
        </button>
      </div>
    );
  }
}

import React, { Component } from "react";
import { Container, Button, Row, Col, Modal } from "react-bootstrap";

import Notifications, { notify } from "react-notify-toast";
import sizechart from "@/assets/images/sizechart.jpg";
import Select from "react-select";
import {
  authenticationService,
  addToWishlist,
  findProductInWishlist,
} from "@/services/api_function";

const customStyles = {
  control: (base) => ({
    ...base,
    height: 37,
    minHeight: 37,
    border: "1px solid #ced4da",
    padding: "0 3px",
    boxShadow: "1px 1px 3px #ccc",
    color: "#333",
    fontFamily: "Nunito Sans, sans-serif",
    //lineHeight: "33",
  }),
};

export default class ProductDetailInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isProductInWishlist: false,
      isSizeChart: false,
      sizeChartImage: null,
    };
  }
  addToWishlistFun = (productId, filters, status) => {
    // console.log({ productId, filters, status });
    let filterIds = filters.filterDTOList.map((v) => v.subfilterId);

    let currentUser = authenticationService.currentUserValue;
    // console.log({ currentUser });

    if (currentUser != null) {
      // let reqData = {
      //   productId: productId,
      //   filters: filters,
      //   filterIds: filterIds.join(","),
      //   status: status,
      // };
      let reqData = new FormData();
      reqData.append("productId", productId);
      reqData.append("filters", JSON.stringify(filters));
      reqData.append("filterIds", filterIds.join(","));
      reqData.append("status", status);

      addToWishlist(reqData)
        .then((response) => {
          let res = response.data;
          if (res.responseStatus == 200) {
            notify.show(res.message, "success", 2000);
          } else {
            notify.show(res.message, "error", 2000);
            // resetForm();
          }
        })
        .catch((error) => {
          console.log({ error });
        });
    } else {
      notify.show("Please login first", "error", 2000);
      this.setState({
        isProductInWishlist: false,
      });
    }
  };

  handleWishlist = (status) => {
    const { productDetails, addCartFilter } = this.props;
    this.setState(
      {
        isProductInWishlist: status,
      },
      () => {
        this.addToWishlistFun(productDetails.productId, addCartFilter, status);
      }
    );
  };

  handleSizeChartImage = (status = false, image = "") => {
    if (status) {
      this.setState({
        isSizeChart: status,
        sizeChartImage: image,
      });
    } else {
      this.setState({
        isSizeChart: status,
      });
    }
  };

  getSubFiltersName = (filterId) => {
    const { filters } = this.props;
    let obj = filters.find((v) => v.filterId == filterId);
    let SubfilterName = "";
    if (obj != undefined) {
      SubfilterName = obj.subFilterName;
    }
    return SubfilterName;
  };
  getFilterClasses = (filterId, subfilterId) => {
    const { filters } = this.props;
    let classes = "";
    if (filters != undefined) {
      filters.map((vi) => {
        if (
          parseInt(vi.filterId) === parseInt(filterId) &&
          parseInt(vi.subfilterId) === parseInt(subfilterId)
        ) {
          classes += " sizecoloractive";
        }
      });
    }
    return classes;
  };
  getFilterValues = (filterId) => {
    const { filters } = this.props;
    let classes = "";
    if (filters != undefined) {
      filters.map((vi) => {
        if (parseInt(vi.filterId) === parseInt(filterId)) {
          classes = vi;
        }
      });
    }
    return classes;
  };
  componentWillReceiveProps(props) {
    if (props.productDetails) {
      this.setState({ isProductInWishlist: props.productDetails.isInWishlist });
    }
  }
  render() {
    const { productDetails, cartObject, addCartFilter } = this.props;
    const { isProductInWishlist, isSizeChart, sizeChartImage } = this.state;
    return (
      <div>
        <div className="product-img-zoom">
          {/* <Container fluid> */}
          <Notifications options={{ zIndex: 9999, top: "50px" }} />
          <div className="product-info">
            <h1>
              {productDetails.productName ? productDetails.productName : "NA"}
            </h1>
            <h6>
              Brand :{" "}
              <span>
                {productDetails.brandName ? productDetails.brandName : "NA"}
              </span>
            </h6>
            {addCartFilter && (
              <h2 className="mb-3">
                <i className="fa fa-inr"></i>{" "}
                {addCartFilter && parseFloat(addCartFilter.mrp).toFixed(2)}
                &nbsp;&nbsp;
                {addCartFilter && addCartFilter.discount && (
                  <s>
                    <i className="fa fa-inr"></i>{" "}
                    {addCartFilter &&
                      parseFloat(addCartFilter.price).toFixed(2)}
                    /-
                  </s>
                )}
              </h2>
            )}

            {productDetails.discountLabel != null && addCartFilter && (
              <h5 className="">
                {" "}
                <i className="fa fa-tags"></i> {addCartFilter.discountLabel}
              </h5>
            )}

            <div className="prdct-color mt-3 prdct-size">
              <Row>
                {productDetails
                  ? productDetails.productFilterList
                    ? productDetails["productFilterList"].map((v, i) => {
                        return (
                          <>
                            {v.isColor == true ? (
                              <Col md={12} className="">
                                <p>
                                  {v.filterName
                                    ? v.filterName.toUpperCase()
                                    : ""}
                                  :{" "}
                                  <span>
                                    {this.getSubFiltersName(v.filterId)}
                                  </span>
                                </p>

                                <div className="sizediv">
                                  <ul>
                                    {v.subFilterList.map((vi, ii) => {
                                      return vi.hexCode != "" ? (
                                        <li
                                          className="filledcolor"
                                          className={this.getFilterClasses(
                                            v.filterId,
                                            vi.productSubFilterId
                                          )}
                                          onClick={(e) => {
                                            e.preventDefault();

                                            this.props.handleFilterSelection(
                                              v.filterId,
                                              vi.productSubFilterId
                                            );
                                          }}
                                        >
                                          <a
                                            style={{
                                              color:
                                                vi.hexCode != ""
                                                  ? vi.hexCode
                                                  : "",
                                            }}
                                            href=""
                                          >
                                            <span
                                              style={{
                                                border: "2px solid",
                                                padding: "1px 3px 2px 4px",
                                                borderRadius: "4px",
                                                background:
                                                  vi.hexCode != ""
                                                    ? vi.hexCode
                                                    : "",
                                              }}
                                            >
                                              co
                                            </span>
                                          </a>
                                        </li>
                                      ) : (
                                        <li
                                          className={this.getFilterClasses(
                                            v.filterId,
                                            vi.productSubFilterId
                                          )}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.props.handleFilterSelection(
                                              v.filterId,
                                              vi.productSubFilterId
                                            );
                                          }}
                                        >
                                          <a>{vi.shortCode}</a>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </div>
                              </Col>
                            ) : (
                              <Col md="5" xs="6">
                                <div className="mt-2 prdct-material">
                                  <p>
                                    {" "}
                                    {v.filterName
                                      ? v.filterName.toUpperCase()
                                      : ""}
                                    :{" "}
                                    <span>
                                      {this.getSubFiltersName(v.filterId)}
                                    </span>
                                    {v.filterName.toUpperCase() === "SIZE" ? (
                                      productDetails.sizeChartImage != null ? (
                                        <a
                                          href="javascript:void(0)"
                                          onClick={() => {
                                            this.handleSizeChartImage(
                                              true,
                                              productDetails.sizeChartImage
                                            );
                                          }}
                                          className="sizechart"
                                        >
                                          Size Chart{" "}
                                          <i className="fa fa-angle-right"></i>
                                        </a>
                                      ) : (
                                        ""
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </p>{" "}
                                  <Select
                                    // isClearable={true}
                                    className="clsselect2"
                                    options={v.subopt}
                                    onChange={(value, isClearable) => {
                                      this.props.handleFilterSelection(
                                        v.filterId,
                                        value,
                                        true,
                                        isClearable
                                      );
                                    }}
                                    styles={customStyles}
                                    value={this.getFilterValues(v.filterId)}
                                  />
                                </div>
                              </Col>
                            )}
                          </>
                        );
                      })
                    : ""
                  : ""}
              </Row>

              <Modal
                show={isSizeChart}
                size="md"
                onHide={() => this.handleSizeChartImage(false)}
                //  dialogClassName="modal-400w"
                // aria-labelledby="example-custom-modal-styling-title"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header closeButton className="p-1 size-title">
                  <Modal.Title id="example-custom-modal-styling-title">
                    Size Chart
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="sizechartimg p-0">
                  <img src={sizeChartImage} alt="Size Chart"></img>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

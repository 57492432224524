import axios from "axios";
import {
  subgroupListUrl,
  subgroupListByGroupUrl,
  subgroupFindUrl,
  subGroupsListForRepairsUrl,
} from "@/services/api";
import { getHeader, authLogin } from "@/_helpers";

export function getSubGroupList() {
  return axios({
    url: subgroupListUrl(),
    method: "GET",
  });
}

export function subgroupListByGroup(values) {
  return axios({
    headers: authLogin(),
    url: subgroupListByGroupUrl(),
    method: "POST",
    data: values,
  });
}

export function subgroupFind(id) {
  return axios({
    url: subgroupFindUrl(id),
    method: "GET",
    headers: getHeader(),
  });
}

export function subGroupsListForRepairs() {
  return axios({
    url: subGroupsListForRepairsUrl(),
    method: "GET",
  });
}

import React, { Component } from "react";

import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";
import Rotate from "react-reveal/Rotate";
import Container from "react-bootstrap/Container";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// import batsman from "@/assets/images/batsman.png";
// import ball from "@/assets/images/ball.png";
// import ball1 from "@/assets/images/ball1.png";
// import elbow from "@/assets/images/elbow.png";
// import stapms from "@/assets/images/stamps.png";
// import shoes from "@/assets/images/shoes.png";
// import bat1 from "@/assets/images/bat1.png";
// import gloves from "@/assets/images/gloves.png";
// import jursey from "@/assets/images/jursey.png";
// import thighpad from "@/assets/images/thighpad.png";
// import pads from "@/assets/images/pads.png";
// import ElbowSleeve from "@/assets/images/ElbowSleeve.png";
// import cap from "@/assets/images/cap.png";
// import football from "@/assets/images/football.png";
// import tabletannis from "@/assets/images/tabletannis.png";
import "./shopcategory.css";
import { getGroupList } from "@/services/api_function";
class ShopCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lstGroup: [],
    };
  }

  lstGroupList = () => {
    console.log("grouplist");
    getGroupList()
      .then((response) => {
        let res = response.data;
        if (res.responseStatus == 200) {
          let g_list = res.responseObject;
          if (g_list.length > 0) {
            this.setState({ lstGroup: g_list });
          }
        }
      })
      .catch((error) => {
        this.setState({ lstGroup: [] });
        console.log("error", error);
      });
  };

  componentDidMount() {
    console.log("did mount");
    this.lstGroupList();
  }

  render() {
    const { lstGroup } = this.state;
    return (
      <div className="shopcategory shopcategory2 pt-4 pb-5">
        <Container fluid>
          <div className="s_category">
            <h1 className="pull-left">SHOP BY CATEGORY</h1>
            {/* <Tabs
              defaultActiveKey="home"
              id="uncontrolled-tab-example"
              className="tabcontent pt-5"
            >
              <Tab eventKey="home" title="Batsman" className="mt-3">
                <Row>
                  <Col xs="6" md="4">
                    <div className="elements mt-3">
                      <a href="/ProductList">
                        <Fade left delay={100}>
                          <div>
                            <img src={cap} alt="Cap/Helmet"></img>
                          </div>
                          <div>
                            <h4 className="ml-3">Cap/ Helmets</h4>
                          </div>
                        </Fade>
                      </a>
                    </div>
                    <div className="elements pt-3">
                      <a href="#.">
                        <Fade left delay={100}>
                          <div>
                            <img src={ball1} alt="Ball"></img>
                          </div>
                          <div>
                            <h4 className="ml-3">Ball</h4>
                          </div>
                        </Fade>
                      </a>
                    </div>
                    <div className="elements pt-3">
                      <a href="#.">
                        <Fade left delay={100}>
                          <div>
                            <img src={jursey} alt="Jursey"></img>
                          </div>
                          <div>
                            <h4 className="ml-3">Jursey</h4>
                          </div>
                        </Fade>
                      </a>
                    </div>
                    <div className="elements pt-3">
                      <a href="#.">
                        <Fade left delay={100}>
                          <div>
                            <img src={stapms} alt="Stamp"></img>
                          </div>
                          <div>
                            <h4 className="ml-3">Stupms</h4>
                          </div>
                        </Fade>
                      </a>
                    </div>
                    <div className="elements pt-3">
                      <a href="#.">
                        <Fade left delay={100}>
                          <div>
                            <img src={shoes} alt="Cap/Helmet"></img>
                          </div>
                          <div>
                            <h4 className="ml-3">Shoes</h4>
                          </div>
                        </Fade>
                      </a>
                    </div>
                  </Col>
                  <Col className="shop-cat" md="4">
                    <div className="batsman  mt-3">
                      <Rotate top right className="pt-5">
                        <img className="" src={ball} alt="Ball"></img>
                      </Rotate>
                      <Zoom>
                        <img src={batsman} alt="Batsman"></img>
                      </Zoom>
                    </div>
                  </Col>
                  <Col xs="6" md="4">
                    <div className="elements  mt-3">
                      <a href="#.">
                        <Fade right delay={800}>
                          <div>
                            <img src={bat1} alt="Bat"></img>
                          </div>
                          <div>
                            <h4 className="mr-3">Bats</h4>
                          </div>
                        </Fade>
                      </a>
                    </div>
                    <div className="elements  pt-3">
                      <a href="#.">
                        <Fade right delay={800}>
                          <div>
                            <img src={gloves} alt="Cap/Helmet"></img>
                          </div>
                          <div>
                            <h4 className="mr-3">Gloves</h4>
                          </div>
                        </Fade>
                      </a>
                    </div>
                    <div className="elements  pt-3">
                      <a href="#.">
                        <Fade right delay={800}>
                          <div>
                            <img src={elbow} alt="Elbow"></img>
                          </div>
                          <div>
                            <h4 className="mr-3">Elbow Guard</h4>
                          </div>
                        </Fade>
                      </a>
                    </div>
                    <div className="elements  pt-3">
                      <a href="#.">
                        <Fade right delay={800}>
                          <div>
                            <img src={thighpad} alt="Cap/Helmet"></img>
                          </div>
                          <div>
                            <h4 className="mr-3">Thigh Pads</h4>
                          </div>
                        </Fade>
                      </a>
                    </div>
                    <div className="elements  pt-3">
                      <a href="#.">
                        <Fade right delay={800}>
                          <div>
                            <img src={pads} alt="Cap/Helmet"></img>
                          </div>
                          <div>
                            <h4 className="mr-3">Pads</h4>
                          </div>
                        </Fade>
                      </a>
                    </div>
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey="profile" title="Bowler" className="mt-3">
                <Row>
                  <Col xs="6" md="4">
                    <div className="elements mt-3">
                      <a href="/ProductList">
                        <Fade left delay={100}>
                          <div>
                            <img src={ball1} alt="Cap/Helmet"></img>
                          </div>
                          <div>
                            <h4 className="ml-3">Ball</h4>
                          </div>
                          <div></div>
                        </Fade>
                      </a>
                    </div>
                    <div className="elements mt-3">
                      <a href="/ProductList">
                        <Fade left delay={100}>
                          <div>
                            <img src={shoes} alt="Shoes"></img>
                          </div>
                          <div>
                            <h4 className="ml-3">Shoes</h4>
                          </div>
                          <div></div>
                        </Fade>
                      </a>
                    </div>
                  </Col>

                  <Col xs="6" md="4">
                    <div className="elements  pt-3">
                      <a href="#.">
                        <Fade right delay={800}>
                          <div>
                            <img src={ElbowSleeve} alt="Elbow Sleeve"></img>
                          </div>
                          <div>
                            <h4 className="mr-3">Elbow Sleeve</h4>
                          </div>
                        </Fade>
                      </a>
                    </div>
                    <div className="elements  pt-2">
                      <a href="#.">
                        <Fade right delay={800}>
                          <div>
                            <img src={jursey} alt="Jursey"></img>
                          </div>
                          <div>
                            <h4 className="mr-3">Jursey</h4>
                          </div>
                        </Fade>
                      </a>
                    </div>
                  </Col>
                </Row>
              </Tab>
              <Tab
                eventKey="contact"
                title="Wicketk
              eeper"
                className="mt-3"
              >
                <Row>
                  <Col xs="6" md="4">
                    <div className="elements pt-4">
                      <a href="#.">
                        <Fade left delay={100}>
                          <div>
                            <img src={cap} alt="Helmet/Cap"></img>
                          </div>
                          <div>
                            <h4 className="ml-3">Helmet/Cap</h4>
                          </div>
                        </Fade>
                      </a>
                    </div>

                    <div className="elements pt-3">
                      <a href="#.">
                        <Fade left delay={100}>
                          <div>
                            <img src={shoes} alt="Cap/Helmet"></img>
                          </div>
                          <div>
                            <h4 className="ml-3">Shoes</h4>
                          </div>
                        </Fade>
                      </a>
                    </div>
                  </Col>

                  <Col xs="6" md="4">
                    <div className="elements mt-4">
                      <a href="#.">
                        <Fade right delay={500}>
                          <div>
                            <img src={gloves} alt="Gloves"></img>
                          </div>
                          <div>
                            <h4 className="mr-3">Gloves</h4>
                          </div>
                        </Fade>
                      </a>
                    </div>
                    <div className="elements mt-4">
                      <a href="#.">
                        <Fade right delay={500}>
                          <div>
                            <img src={pads} alt="Pads"></img>
                          </div>
                          <div>
                            <h4 className="mr-3">Pads</h4>
                          </div>
                        </Fade>
                      </a>
                    </div>
                  </Col>
                </Row>
              </Tab>
            </Tabs> */}
          </div>
          <div className="moresport">
            <h4>More In Sports</h4>
            <Row>
              {lstGroup.length > 0 &&
                lstGroup.map((v, i) => {
                  return (
                    <Col xs="6" md="2">
                      <Row>
                        <a href="">
                          <div className="hover01 column">
                            <div className="sportnm">
                              <figure className="sportimg">
                                <img src={v.groupImage} />
                              </figure>
                              <h6>{v.groupName}</h6>
                            </div>
                          </div>
                        </a>
                      </Row>
                    </Col>
                  );
                })}
            </Row>
          </div>
        </Container>
      </div>
    );
  }
}
export default ShopCategory;

import React, { Component } from "react";
import { Row, Col, Container, Breadcrumb } from "react-bootstrap";
import { Layout } from "@/Pages/Layout";
class NewsDetail extends Component {
  render() {
    return (
      <Layout {...this.props}>
        <div id="Newsdetail" className="pt-5">
          <Row>
            <Col md="6" className="newsleftbg">
              <Container fluid>
                <div className="pt-3">
                  <Breadcrumb className="newsdetailbreadcrumb">
                    <Breadcrumb.Item
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/`);
                      }}
                    >
                      Home
                    </Breadcrumb.Item>

                    <Breadcrumb.Item active>News</Breadcrumb.Item>
                  </Breadcrumb>
                </div>
                <div className="newstitle">
                  <Col>
                    <p>
                      21 January 2021 &nbsp;|&nbsp;{" "}
                      <i className="fa fa-newspaper-o"></i> News
                    </p>
                    <h3>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Integer posuere erat a ante.
                    </h3>
                    <ul className="share-m">
                      <li>Share :&nbsp;&nbsp;</li>
                      <li>
                        <a className="menu__link" href="#0">
                          <i class="fa fa-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a className="menu__link" href="#0">
                          <i class="fa fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a className="menu__link" href="#0">
                          <i class="fa fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a className="menu__link" href="#0">
                          <i class="fa fa-youtube"></i>
                        </a>
                      </li>
                    </ul>
                  </Col>
                </div>
              </Container>
            </Col>
            <Col md="6" className="newsrightbg"></Col>
          </Row>
        </div>
        <div className="newsdetail-info pt-5">
          <Container fluid>
            <h3>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
              posuere erat a ante.
            </h3>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </p>
            <p>
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. The
              point of using Lorem Ipsum is that it has a more-or-less normal
              distribution of letters, as opposed to using 'Content here,
              content here', making it look like readable English. Many desktop
              publishing packages and web page editors now use Lorem Ipsum as
              their default model text, and a search for 'lorem ipsum' will
              uncover many web sites still in their infancy. Various versions
              have evolved over the years, sometimes by accident, sometimes on
              purpose (injected humour and the like).
            </p>
            <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h3>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </p>
            <p>
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. The
              point of using Lorem Ipsum is that it has a more-or-less normal
              distribution of letters, as opposed to using 'Content here,
              content here', making it look like readable English. Many desktop
              publishing packages and web page editors now use Lorem Ipsum as
              their default model text, and a search for 'lorem ipsum' will
              uncover many web sites still in their infancy. Various versions
              have evolved over the years, sometimes by accident, sometimes on
              purpose (injected humour and the like).
            </p>
            <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h3>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </p>
            <p>
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. The
              point of using Lorem Ipsum is that it has a more-or-less normal
              distribution of letters, as opposed to using 'Content here,
              content here', making it look like readable English. Many desktop
              publishing packages and web page editors now use Lorem Ipsum as
              their default model text, and a search for 'lorem ipsum' will
              uncover many web sites still in their infancy. Various versions
              have evolved over the years, sometimes by accident, sometimes on
              purpose (injected humour and the like).
            </p>
          </Container>
        </div>
      </Layout>
    );
  }
}

export default NewsDetail;

import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "@/Pages/Components/header/Header";
import ProductDetailInfo from "@/Pages/Components/ProductDetailComponent/ProductDetailInfo";
import ProductCart from "@/Pages/Components/ProductDetailComponent/ProductCart";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Footer from "@/Pages/Components/footer/Footer";
import SimilarProduct from "@/Pages/Components/ProductDetailComponent/SimilarProducts";
import ProductZoom from "@/Pages/Components/ProductDetailComponent/ProductZoom/ProductZoom";
import { productFetch } from "@/services/api_function";
import { bindActionCreators } from "redux";
import { addToCart, decrementProductUnit } from "@/redux/cart/Action";
import { connect } from "react-redux";
import {
  findArrayElementByProductId,
  arrSort,
  printProductNameInDetails,
} from "@/_helpers";
import Notifications, { notify } from "react-notify-toast";
import CustomLoader from "@/Pages/Components/CustomLoader";
import NoProductDetailAvailable from "./NoProductDetailAvailable";
import ProductDesc from "./ProductDesc";
class ProductDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productId: "",
      categoryId: "",
      productDetails: "",
      filters: [],
      addCartFilter: "",
      filterPrice: 0,
      cartObject: "",
      isLoading: false,
    };
  }

  findCartObject = (carts, productData, filters) => {
    let cartObj;
    cartObj = findArrayElementByProductId(carts, productData, filters);
    this.setState({ cartObject: cartObj });
  };

  getProductDetails = () => {
    let reqData = new FormData();
    reqData.append("id", this.state.productId);
    this.setState({ isLoading: true });
    productFetch(reqData)
      .then((response) => {
        let res = response.data;
        if (res.responseStatus == 200) {
          let filterLst = [];

          res.responseObject.productFilterList.map((v) => {
            let objFilter = {
              filterId: v.filterId,
              filterName: v.filterName,
              subfilterId: v.subFilterList[0].productSubFilterId,
              subFilterName: v.subFilterList[0].subFilterName,
              hexCode: v.subFilterList[0].hexCode,
              shortCode: v.subFilterList[0].shortCode,
              label: v.subFilterList[0].subFilterName,
              value: v.subFilterList[0].productSubFilterId,
            };
            filterLst.push(objFilter);
          });

          res.responseObject.productFilterList =
            res.responseObject.productFilterList.map((v) => {
              let subopt = v.subFilterList.map((vi) => {
                return {
                  label: vi.subFilterName,
                  value: vi.productSubFilterId,
                  productSubFilterId: vi.productSubFilterId,
                  subfilterId: vi.productSubFilterId,
                  filterId: vi.filterId,
                  subFilterName: vi.subFilterName,
                  hexCode: vi.hexCode,
                  shortCode: vi.shortCode,
                  status: vi.status,
                };
              });
              v["subopt"] = subopt;
              return v;
            });
          this.setState(
            {
              productDetails: res.responseObject,
              categoryId: res.responseObject.categoryId,
              filters: filterLst,
              addCartFilter: res.responseObject.filterPriceDTOList[0],
              isLoading: false,
            },
            () => {
              this.findCartObject(
                this.props.cartReducer.cart,
                this.state.productDetails,
                this.state.filters
              );
            }
          );
        } else {
          this.setState({ productDetails: "", isLoading: false });
        }
      })
      .catch((error) => {
        this.setState({ productDetails: "", isLoading: false });
        console.log("error", error);
      });
  };

  handleFilterSelection = (
    filterId,
    subfilterId,
    isSelect = false,
    isClear = false
  ) => {
    let { filters, productDetails } = this.state;
    let subfilterObj = "";
    let FinalFilters = [];
    let addCartFilter;
    if (isSelect == false && isClear == false) {
      productDetails.productFilterList.filter((v) => {
        v.subFilterList.filter((vi) => {
          if (vi.filterId == filterId && vi.productSubFilterId == subfilterId) {
            subfilterObj = vi;
          }
        });
      });

      FinalFilters = filters.map((v) => {
        if (v.filterId == filterId) {
          v.subfilterId = subfilterId;
          v.hexCode = subfilterObj ? subfilterObj.hexCode : "";
          v.shortCode = subfilterObj ? subfilterObj.shortCode : "";
          v.subFilterName = subfilterObj ? subfilterObj.subFilterName : "";
          v.value = subfilterObj ? subfilterObj.value : "";
          v.label = subfilterObj ? subfilterObj.label : "";
        }
        return v;
      });

      let filterIds = FinalFilters.map((v) => v.subfilterId);
      filterIds = arrSort(filterIds);

      productDetails.filterPriceDTOList.map((v) => {
        let subfilterIds = v.filterDTOList.map((v) => v.subfilterId);
        subfilterIds = arrSort(subfilterIds);
        if (filterIds.join(",") == subfilterIds.join(",")) {
          addCartFilter = v;
        }
      });

      // console.log("addCartFilter", addCartFilter);
    } else {
      productDetails.productFilterList.filter((v) => {
        v.subFilterList.filter((vi) => {
          if (
            vi.filterId == filterId &&
            vi.productSubFilterId == subfilterId.value
          ) {
            subfilterObj = vi;
          }
        });
      });

      FinalFilters = filters.map((v) => {
        if (v.filterId == filterId) {
          v = subfilterId;
        }
        return v;
      });
      let filterIds = FinalFilters.map((v) => v.subfilterId);
      filterIds = arrSort(filterIds);

      productDetails.filterPriceDTOList.map((v) => {
        let subfilterIds = v.filterDTOList.map((v) => v.subfilterId);
        subfilterIds = arrSort(subfilterIds);
        console.log("filterIds.join(", ")", filterIds.join(","));
        console.log("subfilterIds.join(", ")", subfilterIds.join(","));
        if (filterIds.join(",") == subfilterIds.join(",")) {
          addCartFilter = v;
        }
      });
    }
    console.log({ addCartFilter });
    this.setState(
      { filters: FinalFilters, addCartFilter: addCartFilter },
      () => {
        this.findCartObject(
          this.props.cartReducer.cart,
          this.state.productDetails,
          this.state.filters
        );
      }
    );
  };

  componentDidMount() {
    // console.log(" this.props.match.params.id  ", this.props.match.params.id);
    this.setState({ productId: this.props.match.params.id }, () => {
      this.getProductDetails();
    });
  }

  componentDidUpdate(prevProps) {
    // console.log("prevProps", prevProps);
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.setState({ productId: this.props.match.params.id }, () => {
        this.getProductDetails();
      });
    }
    if (this.props.cartReducer.cart !== prevProps.cartReducer.cart) {
      this.findCartObject(
        this.props.cartReducer.cart,
        this.state.productDetails,
        this.state.filters
      );
    }
  }

  addToCartFN = ({
    id,
    name,
    price,
    mrp,
    discountType,
    discount,
    unit,
    brandName,
    firstImage,
    thumbImage,
    discountInPercentage,
    discountInAmount,
    filters,
  }) => {
    let filtersArr = filters.filterDTOList;

    this.props.addToCart({
      id,
      name,
      price,
      mrp,
      discountType,
      discount,
      unit,
      brandName,
      firstImage,
      thumbImage,
      discountInPercentage,
      discountInAmount,
      filters,
    });
    this.findCartObject(
      this.props.cartReducer.cart,
      this.state.productDetails,
      filtersArr
    );
    notify.show("Product added to cart", "success", 2000);
  };

  addToCartSimilarFN = ({
    id,
    name,
    price,
    mrp,
    discountType,
    discount,
    unit,
    brandName,
    firstImage,
    thumbImage,
    discountInPercentage,
    discountInAmount,
    filters,
  }) => {
    this.props.addToCart({
      id,
      name,
      price,
      mrp,
      discountType,
      discount,
      unit,
      brandName,
      firstImage,
      thumbImage,
      discountInPercentage,
      discountInAmount,
      filters,
    });

    notify.show("Product added to cart", "success", 2000);
  };
  removeFromCartFN = ({
    id,
    price,
    mrp,
    discount,
    currentUnit,
    unit,
    filters,
  }) => {
    let filtersArr = filters.filterDTOList;
    this.props.decrementProductUnit({
      id,
      price,
      mrp,
      discount,
      currentUnit,
      unit,
      filters,
    });
    this.findCartObject(
      this.props.cartReducer.cart,
      this.state.productDetails,
      filtersArr
    );
    notify.show("Product removed from cart", "success", 2000);
  };
  render() {
    const {
      productDetails,
      productId,
      categoryId,
      filters,
      cartObject,
      addCartFilter,
      isLoading,
    } = this.state;
    // console.log("filters", filters);

    //  const { cartReducer } = this.props
    //  if (cartReducer) this.setState(prevState => ({ language: prevState.language = language }))
    return (
      <div>
        <Header {...this.props} />
        <div id="product-det" className="pt-5">
          <Container fluid>
            <Notifications options={{ zIndex: 9999, top: "50px" }} />
            <Row>
              <Col md="6">
                <Breadcrumb className="profile-b-crumb rgst-breadcrumb pt-0">
                  <Breadcrumb.Item
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.history.push(`/`);
                    }}
                    style={{ color: "#1776a4" }}
                  >
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.history.push(
                        `/GroupList/${productDetails.groupId}`
                      );
                    }}
                  >
                    {productDetails.groupName ? productDetails.groupName : ""}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.history.push(
                        `/ProductList/${productDetails.subGroupId}`
                      );
                    }}
                    style={{ color: "#1776a4" }}
                  >
                    {productDetails.subGroupName
                      ? productDetails.subGroupName
                      : ""}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>
                    {productDetails.productName
                      ? printProductNameInDetails(productDetails.productName)
                      : ""}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
            {isLoading ? (
              <CustomLoader />
            ) : productDetails != "" ? (
              <div className="productzoom">
                <Row>
                  <Col md="4">
                    <ProductZoom productDetails={productDetails} />
                  </Col>
                  <Col md="8">
                    <Row>
                      <Col md="8" className="prdct-dt-padding">
                        <ProductDetailInfo
                          productDetails={productDetails}
                          handleFilterSelection={this.handleFilterSelection.bind(
                            this
                          )}
                          cartObject={cartObject}
                          filters={filters}
                          addCartFilter={addCartFilter}
                          addToCartFN={this.addToCartFN.bind(this)}
                          removeFromCartFN={this.removeFromCartFN.bind(this)}
                          {...this.props}
                        />
                      </Col>
                      <Col md="4">
                        <Row>
                          <ProductCart
                            productDetails={productDetails}
                            handleFilterSelection={this.handleFilterSelection.bind(
                              this
                            )}
                            cartObject={cartObject}
                            filters={filters}
                            addCartFilter={addCartFilter}
                            addToCartFN={this.addToCartFN.bind(this)}
                            removeFromCartFN={this.removeFromCartFN.bind(this)}
                            {...this.props}
                          />
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12" className="p-0">
                        <ProductDesc productDetails={productDetails} />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            ) : (
              <NoProductDetailAvailable />
            )}
          </Container>
        </div>
        <div className="fluid__instructions similar-prdct">
          {categoryId > 0 && (
            <SimilarProduct
              productId={productId}
              categoryId={categoryId}
              addToCartSimilarFN={this.addToCartSimilarFN.bind(this)}
              {...this.props}
            />
          )}
        </div>
        <Footer {...this.props} />
      </div>
    );
  }
}

const mapStateToProps = ({ cartReducer }) => {
  return { cartReducer };
};

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      addToCart,
      decrementProductUnit,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapActionsToProps)(ProductDetail);
// export default ProductDetail;

import React, { Component, useState } from "react";
import { PrivateRoute } from "@/routes/PrivateRoute";
import { Provider } from "react-redux";

import "@/assets/css/style.css";
import "@/assets/css/responsive.css";
//import Header from "../src/components/header/Headertop";
import "bootstrap/dist/css/bootstrap.min.css";
import { Router, Route, Link, Switch, Redirect } from "react-router-dom";
import { history } from "@/_helpers";
import Register from "@/Pages/Registration/Registrationpage";
import LoginPage from "@/Pages/Login/LoginPage";
import { Home } from "@/Pages/Home";
import Dashboard from "@/Pages/Dashboard/Dashboard";
import MyOrders from "@/Pages/MyOrders/MyOrders";
import OrderSummary from "@/Pages/MyOrders/OrderSummary";
import About from "@/Pages/About/About";
import Contact from "@/Pages/Contact/Contact";
import Search from "@/Pages/Search/Search";
import ProductList from "@/Pages/ProductList/ProductList";
import ProductDetail from "@/Pages/ProductDetail/ProductDetail";
import TheJourney from "@/Pages/TheJourney/TheJourney";
import Offers from "@/Pages/Offers/Offers";
import WalletCart from "@/Pages/Offers/WalletCart";

import News from "@/Pages/News/News";
import NewsDetail from "@/Pages/News/NewsDetail";
import FAQ from "@/Pages/FAQ/FAQ";
import PrivacyPolicies from "@/Pages/PrivacyPolicies/PrivacyPolicies";
import ShippingAddress from "@/Pages/ShippingAddress/ShippingAddress";
import TermsConditions from "@/Pages/TermsConditions/TermsConditions";
import ReturnRefunds from "@/Pages/ReturnsRefunds/ReturnRefunds";
import MyCart from "@/Pages/MyCart/MyCart";
import MyWishlist from "@/Pages/MyWishlist/MyWishList";
import RepaireSportsKit from "@/Pages/RepaireSportsKit/RepaireSportsKit";
import EditAccount from "@/Pages/EditAccount/EditAccount";
import BrandList from "@/Pages/BrandProducts/BrandList";
import GroupProductList from "@/Pages/GroupProducts/GroupProductList";
import store from "@/redux/store";

// import logo from "./logo.svg";
// import "./App.css";

function App() {
  return (
    <Provider store={store}>
      {/* <OrderNotification /> */}
      <Router history={history}>
        <Switch>
          {/* Common Route Start */}
          <Route exact path="/" component={Home} />
          <Route exact path="/Register" component={Register} />
          <Route exact path="/Login" component={LoginPage} />
          <Route exact path="/About" component={About} />
          <Route exact path="/Contact" component={Contact} />
          <Route exact path="/Search/:query" component={Search} />
          <Route exact path="/Search" component={Search} />
          <Route
            exact
            path="/ProductList/:id"
            key="default"
            component={ProductList}
          />
          <Route exact path="/BrandList/:id" component={BrandList} />
          <Route exact path="/GroupList/:id" component={GroupProductList} />

          <Route exact path="/ProductDetail/:id" component={ProductDetail} />

          <Route exact path="/TheJourney" component={TheJourney} />
          <Route exact path="/Offers" component={Offers} />

          <Route exact path="/News" component={News} />
          <Route exact path="/NewsDetail" component={NewsDetail} />
          <Route exact path="/FAQ" component={FAQ} />

          <Route exact path="/PrivacyPolicies" component={PrivacyPolicies} />
          <Route exact path="/ShippingAddress" component={ShippingAddress} />
          <Route exact path="/TermsConditions" component={TermsConditions} />
          <Route exact path="/RepaireSportsKit" component={RepaireSportsKit} />
          <Route exact path="/ReturnRefunds" component={ReturnRefunds} />

          <PrivateRoute exact path="/Dashboard" component={Dashboard} />
          <PrivateRoute exact path="/EditAccount" component={EditAccount} />
          <Route exact path="/MyCart" component={MyCart} />
          <PrivateRoute exact path="/MyWishlist" component={MyWishlist} />
          <PrivateRoute exact path="/orderDetails/:id" component={MyOrders} />
          <PrivateRoute exact path="/MyOrders" component={OrderSummary} />
          <PrivateRoute exact path="/Walletcart" component={WalletCart} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;

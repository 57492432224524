import React, { Component } from "react";
import { Layout } from "@/Pages/Layout";
import {
  Container,
  Row,
  Col,
  Breadcrumb,
  Button,
  Tabs,
  Tab,
} from "react-bootstrap";
import {
  searchProductByName,
  getSearchProductFilters,
} from "@/services/api_function";

import "@/Pages/ProductList/productlist.css";

import ProductSidebar from "@/Pages/Components/ProductListComponent/ProductSidebar";
import { ProductCard } from "@/Pages/Components/ProductListComponent/ProductCard";
import { ProductCardListView } from "@/Pages/Components/ProductListComponent/ProductCardListView";
import NoProduct from "@/Pages/ProductList/NoProduct";
import CustomLoader from "@/Pages/Components/CustomLoader";

export default class Search extends Component {
  constructor(props) {
    super(props);
    this.divproductlist = React.createRef();
    this.state = {
      searchQuery: "",
      lstproductList: [],
      selectedFilters: [],
      filterList: [],

      totalProducts: 0,
      pages: 0,
      currentPage: 1,
      pageLimit: 8,
      isLoading: false,
      sidebarLoading: false,
    };
  }

  goToNextPage() {
    // not yet implemented
    let page = parseInt(this.state.currentPage);
    this.setState({ currentPage: page + 1 }, () => {
      this.searchProductByNameFun();
    });
  }

  goToPreviousPage() {
    // not yet implemented
    let page = parseInt(this.state.currentPage);
    this.setState({ currentPage: page - 1 }, () => {
      this.searchProductByNameFun();
    });
  }

  changePage = (event) => {
    // not yet implemented
    const pageNumber = Number(event.target.textContent);
    this.setState({ currentPage: pageNumber }, () => {
      this.searchProductByNameFun();
    });
  };

  searchProductByNameFun = () => {
    const { currentPage, pageLimit, selectedFilters, searchQuery } = this.state;

    let reqselectedFilters = selectedFilters.map((v) =>
      v.selectedSubfilters.length > 0 ? v.selectedSubfilters : ""
    );
    reqselectedFilters = reqselectedFilters.filter((v) => v != "");
    console.log(
      "reqselectedFilters.join ",
      reqselectedFilters.length > 0 ? reqselectedFilters.join(",") : ""
    );

    let reqData = {
      selectedFilters:
        reqselectedFilters.length > 0 ? reqselectedFilters.join(",") : "",
      searchParameter: searchQuery,
      pageNo: currentPage,
      pageSize: pageLimit,
    };

    this.setState({ isLoading: true });
    searchProductByName(reqData)
      .then((response) => {
        // console.log("response", response);
        let res = response.data;
        if (res.responseStatus == 200) {
          console.log("res.responseObject ", res.responseObject);

          this.setState({
            lstproductList:
              res.responseObject != null ? res.responseObject.data : [],
            totalProducts:
              res.responseObject != null ? res.responseObject.total : 0,
            pages:
              res.responseObject != null ? res.responseObject.total_pages : 0,

            isLoading: false,
          });
        } else if (res.responseStatus == 404) {
          this.setState({
            totalProducts: 0,
            lstproductList: [],
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log("error", error);
      });
  };

  getSearchProductFiltersFun = () => {
    const { searchQuery, selectedFilters } = this.state;
    let reqData = { searchParameter: searchQuery };
    getSearchProductFilters(reqData)
      .then((response) => {
        // console.log("response", response);
        let res = response.data;
        if (res.responseStatus == 200) {
          console.log("res.responseObject ", res.responseObject);
          this.setState({
            filterList: res.responseObject,
          });
        }
      })
      .catch((error) => {
        this.setState({ productFilters: [] });
        console.log("error", error);
      });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.match) {
      window.addEventListener("scroll", this.handleScroll);
      this.setState({ searchQuery: this.props.match.params.query }, () => {
        this.searchProductByNameFun();
        this.getSearchProductFiltersFun();
      });
    } else if (this.props.location) {
      this.setState({ searchQuery: this.props.location.state }, () => {
        this.searchProductByNameFun();
        this.getSearchProductFiltersFun();
      });
    }
  }

  handleSelectedFilters = (filterId, subFilterId, status) => {
    // console.log({ filterId, subFilterId, status });
    const { selectedFilters } = this.state;
    let upselectedFilters;
    if (status) {
      let isNew = true;
      upselectedFilters = selectedFilters.map((v) => {
        if (parseInt(v.filterId) == parseInt(filterId)) {
          isNew = false;
          let innerarr = v.selectedSubfilters;
          if (!innerarr.includes(subFilterId)) {
            innerarr.push(subFilterId);
          }
          v.selectedSubfilters = innerarr;
        }
        return v;
      });
      if (isNew == true) {
        let newFilter = {
          filterId: filterId,
          selectedSubfilters: [parseInt(subFilterId)],
        };
        upselectedFilters = [...selectedFilters, newFilter];
      }
    } else {
      // let isNew = true;
      upselectedFilters = selectedFilters.map((v) => {
        if (v.filterId == filterId) {
          // isNew = false;
          let innerarr = v.selectedSubfilters;
          var index = innerarr.indexOf(subFilterId);
          if (index !== -1) {
            innerarr.splice(index, 1);
          }
          v.selectedSubfilters = innerarr;
        }
        return v;
      });
    }
    this.setState(
      { selectedFilters: upselectedFilters, currentPage: 1 },
      () => {
        console.log(this.state.selectedFilters);
        this.searchProductByNameFun();
        this.getSearchProductFiltersFun();
      }
    );
  };

  render() {
    const {
      selectedFilters,
      searchQuery,

      lstproductList,
      filterList,

      totalProducts,
      currentPage,
      pages,
      isLoading,
      sidebarLoading,
    } = this.state;
    return (
      <Layout {...this.props}>
        <div id="productlist1" className="prdct-list-bc">
          <div className="productlistbreadcrumb searchbreadbrumb">
            <Container fluid>
              <Row>
                <Col md="3">
                  <Breadcrumb className="rgst-breadcrumb pt-0">
                    <Breadcrumb.Item
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/`);
                      }}
                      style={{ color: "#1776a4" }}
                    >
                      Home
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Search Result</Breadcrumb.Item>
                  </Breadcrumb>
                </Col>
              </Row>
              <div className="searchresult">
                <div className="seacrhresulttitle">
                  <h4>Showing Search Result For : {searchQuery}</h4>
                </div>
              </div>
            </Container>
          </div>

          <div className="filter-prdct">
            <Container
              fluid
              ref={this.divproductlist}
              className="containerfluid1"
            >
              {isLoading == false ||
              lstproductList.length > 0 ||
              selectedFilters.length > 0 ? (
                <Row>
                  <Col md="2">
                    <ProductSidebar
                      lstproductList={lstproductList}
                      sidebarLoading={sidebarLoading}
                      productFilters={filterList}
                      selectedFilters={selectedFilters}
                      handleSelectedFilters={this.handleSelectedFilters.bind(
                        this
                      )}
                      {...this.props}
                    />
                  </Col>

                  <Col md="10" style={{ paddingLeft: "1px" }}>
                    <div className="tabgrid">
                      <Tabs
                        defaultActiveKey="prdctgrid"
                        transition={false}
                        id="noanim-tab-example"
                        className="mb-3 pagenxt"
                      >
                        <Tab
                          eventKey="prdctgrid"
                          title={
                            <span>
                              <i className="fa fa-th-large" />{" "}
                            </span>
                          }
                        >
                          {isLoading ? (
                            <CustomLoader />
                          ) : lstproductList && lstproductList.length == 0 ? (
                            <NoProduct {...this.props} />
                          ) : (
                            <Row>
                              {lstproductList.map((data, idx) => (
                                <ProductCard
                                  {...this.props}
                                  key={idx}
                                  productDetails={data}
                                />
                              ))}
                            </Row>
                          )}
                        </Tab>
                        <Tab
                          eventKey="prdctlist"
                          title={
                            <span>
                              <i className="fa fa-th-list" />{" "}
                            </span>
                          }
                        >
                          {isLoading ? (
                            <CustomLoader />
                          ) : lstproductList && lstproductList.length == 0 ? (
                            <NoProduct {...this.props} />
                          ) : (
                            <Row>
                              {lstproductList.map((data, idx) => (
                                <ProductCardListView
                                  {...this.props}
                                  key={idx}
                                  productDetails={data}
                                />
                              ))}
                            </Row>
                          )}
                        </Tab>
                        <Tab
                          // eventKey={"prdcttotal"}
                          tabClassName="prdcttot"
                          disabled
                          title={
                            <span className={"headerClass"}>
                              <strong className="totprdct">
                                {totalProducts}
                              </strong>{" "}
                              Products
                            </span>
                          }
                        ></Tab>
                        <Tab
                          tabClassName="prdctprev"
                          // eventKey={"prdctprev"}
                          title={
                            currentPage == 1 ? (
                              <Button type="button" disabled>
                                Prev <span> &nbsp;&nbsp;| </span>
                              </Button>
                            ) : (
                              <Button
                                type="button"
                                onClick={this.goToPreviousPage.bind(this)}
                              >
                                Prev <span> &nbsp;&nbsp;| </span>
                              </Button>
                            )
                          }
                        ></Tab>
                        <Tab
                          //eventKey={"prdctnext"}
                          tabClassName="prdctnxt"
                          title={
                            currentPage === pages ? (
                              <Button type="button" disabled>
                                Next &nbsp;
                              </Button>
                            ) : (
                              <Button
                                type="button"
                                onClick={this.goToNextPage.bind(this)}
                              >
                                Next &nbsp;
                              </Button>
                            )
                          }
                        ></Tab>
                      </Tabs>
                    </div>
                  </Col>
                </Row>
              ) : (
                <CustomLoader />
              )}
            </Container>
          </div>
        </div>
      </Layout>
    );
  }
}

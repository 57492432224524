import { BehaviorSubject } from "rxjs";
import {
  authHeader,
  authLogin,
  getHeader,
  handleLoginResponse,
  history,
} from "@/_helpers";
import jwt_decode from "jwt-decode";
import axios from "axios";

import { RegisterURL, authUserURL, authGuestUserURL } from "../api";

const currentUserSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem("loginUser"))
);
const currentUserToken = new BehaviorSubject(
  JSON.parse(localStorage.getItem("refreshToken"))
);
export const authenticationService = {
  RegisterUser,
  login,
  logout,
  loginGuest,
  currentUser: currentUserSubject.asObservable(),
  currentUserToken: currentUserToken.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  },
  get currentUserToken() {
    // console.log("currentUserToken",currentUserToken.value)
    return currentUserToken.value;
  },
};

function loginGuest(userDa) {
  console.log("userDa wx", userDa);
  const userData = {
    lastName: userDa["lastName"],
    firstName: userDa["firstName"],
    mobileNo: userDa["mobileNo"],
  };
  const requestOption = {
    method: "POST",
    headers: authLogin(),
    body: JSON.stringify(userData),
  };
  const URL = authGuestUserURL();
  return fetch(URL, requestOption)
    .then(handleLoginResponse)
    .then((response) => {
      // console.log("handle response", response);
      if (response.responseStatus == 200) {
        let decoded = jwt_decode(response.responseObject);
        decoded["token"] = response.responseObject;
        console.log("decoded==== >", JSON.stringify(decoded));
        localStorage.setItem("loginUser", JSON.stringify(decoded));
        localStorage.setItem("refreshToken", JSON.stringify(response));
        currentUserSubject.next(decoded);
        currentUserToken.next(response.responseObject);
        return decoded;
      } else {
        return response;
      }
    });
}

function RegisterUser(register_details) {
  // const requestOption = {
  //   method: "POST",
  //   headers: authLogin(),
  //   body: register_details,
  // };
  // const URL = RegisterURL();
  // return fetch(URL, requestOption).then((response) => {
  //   return response.json();
  // });

  return axios({
    url: RegisterURL(),
    method: "POST",
    headers: authLogin(),
    data: register_details,
  });
}

function login(userDa) {
  const userData = {
    username: userDa["username"],
    password: userDa["password"],
  };
  const requestOption = {
    method: "POST",
    headers: authLogin(),
    body: JSON.stringify(userData),
  };
  const URL = authUserURL();
  return fetch(URL, requestOption)
    .then(handleLoginResponse)
    .then((response) => {
      // console.log("handle response", response);
      if (response.responseStatus == 200) {
        let decoded = jwt_decode(response.responseObject);
        decoded["token"] = response.responseObject;
        console.log("decoded==== >", JSON.stringify(decoded));
        localStorage.setItem("loginUser", JSON.stringify(decoded));
        localStorage.setItem("refreshToken", JSON.stringify(response));
        currentUserSubject.next(decoded);
        currentUserToken.next(response.responseObject);
        return decoded;
      } else {
        return response;
      }
    });
}

function logout() {
  // localStorage.clear();
  localStorage.removeItem("loginUser");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("authenticationService");
  currentUserSubject.next(null);
  history.push("/Login");
  // !This is problem when we are logout
  // const URL = logoutURL();
  // return fetch(URL, {
  //   method: "POST",
  //   headers: authHeader()
  // }).then(
  //   (response) => {
  //     return response;
  //   }
  // );
}

import axios from "axios";
import { authLogin } from "@/_helpers/authheader";
import { listOfFAQsUrl } from "@/services/api";

export function listOfFAQs() {
  return axios({
    headers: authLogin(),
    url: listOfFAQsUrl(),
    method: "GET",
  });
}

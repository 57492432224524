import React, { Component } from "react";
import Fade from "react-reveal/Fade";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import stamp from "@/assets/images/stamp.png";
import "./aboutstyle.css";
class AboutIntro extends Component {
  render() {
    return (
      <div id="aboutintro" style={{overflowX:"hidden"}}>
        <Container>
          <Row>
            <Col md="6">
              <Fade bottom>
                <Row>
                  <Col>
                  <div className="about-head">
                  <h1>
                    INDIAN'S LEADING
                    <br />
                    <b>
                      SPORTS
                      <br />
                      E-COMMERCE
                    </b>
                  </h1>
                </div></Col>
                <Col lg="8" md="12" className="brand">
                <h1 >
                    100% ORIGINAL <br />
                    DIRECT FROM <br />
                    THE BRAND
                  </h1>
                </Col>
                <Col lg="4" md="12">
                <img src={stamp} alt="Stamp" className="w-auto"></img>
                </Col>
                </Row>
               
               
              </Fade>
            </Col>
            <Col md="6">
              <Fade bottom>
                <div className="aboutinfo">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.
                  </p>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.
                  </p>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.
                  </p>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.
                  </p>
                </div>
              </Fade>
            </Col>
          </Row>
        </Container>
        <div className="galleryimg"></div>
      </div>
    );
  }
}

export default AboutIntro;

import React, { Component } from "react";
import { Container, Button, Row, Col } from "react-bootstrap";
import parse from "html-react-parser";
import Notifications, { notify } from "react-notify-toast";
import {
  authenticationService,
  addToWishlist,
  findProductInWishlist,
} from "@/services/api_function";
export default class ProductCart extends Component {
  constructor(props) {
    super(props);
    this.state = { isProductInWishlist: false };
  }
  addToWishlistFun = (productId, filters, status) => {
    // console.log({ productId, filters, status });
    let filterIds = filters.filterDTOList.map((v) => v.subfilterId);

    let currentUser = authenticationService.currentUserValue;
    // console.log({ currentUser });

    if (currentUser != null) {
      // let reqData = {
      //   productId: productId,
      //   filters: filters,
      //   filterIds: filterIds.join(","),
      //   status: status,
      // };
      let reqData = new FormData();
      reqData.append("productId", productId);
      reqData.append("filters", JSON.stringify(filters));
      reqData.append("filterIds", filterIds.join(","));
      reqData.append("status", status);

      addToWishlist(reqData)
        .then((response) => {
          let res = response.data;
          if (res.responseStatus == 200) {
            notify.show(res.message, "success", 2000);
          } else {
            notify.show(res.message, "error", 2000);
            // resetForm();
          }
        })
        .catch((error) => {
          console.log({ error });
        });
    } else {
      notify.show("Please login first", "error", 2000);
      this.setState({
        isProductInWishlist: false,
      });
    }
  };

  handleWishlist = (status) => {
    const { productDetails, addCartFilter } = this.props;
    this.setState(
      {
        isProductInWishlist: status,
      },
      () => {
        this.addToWishlistFun(productDetails.productId, addCartFilter, status);
      }
    );
  };

  getSubFiltersName = (filterId) => {
    const { filters } = this.props;
    let obj = filters.find((v) => v.filterId == filterId);
    let SubfilterName = "";
    if (obj != undefined) {
      SubfilterName = obj.subFilterName;
    }
    return SubfilterName;
  };
  getFilterClasses = (filterId, subfilterId) => {
    const { filters } = this.props;
    let classes = "";
    if (filters != undefined) {
      filters.map((vi) => {
        if (
          parseInt(vi.filterId) === parseInt(filterId) &&
          parseInt(vi.subfilterId) === parseInt(subfilterId)
        ) {
          classes += " sizecoloractive";
        }
      });
    }
    return classes;
  };
  componentWillReceiveProps(props) {
    if (props.productDetails) {
      this.setState({ isProductInWishlist: props.productDetails.isInWishlist });
    }
  }
  render() {
    const { productDetails, cartObject, addCartFilter } = this.props;
    const { isProductInWishlist } = this.state;
    return (
      <div>
        <div className="product-img-zoom prdct-stock">
          {/* <Container fluid> */}
          <Notifications options={{ zIndex: 9999, top: "50px" }} />
          <div className="pt-3">
            {/* <pre>{JSON.stringify(productDetails.firstImage, undefined, 2)}</pre> */}
            <div className="prdct-color prdct-size">
              <p className="">
                AVAILABILITY:{" "}
                {addCartFilter ? (
                  <span style={{ color: "#4dda4d" }}>
                    In Stock <i className="fa fa-check-circle"></i>
                  </span>
                ) : (
                  <span>Out Of Stock</span>
                )}
              </p>
            </div>
          </div>
          {addCartFilter && (
            <div className="prdct-qty">
              <h5>QUANTITY</h5>
              <div className="cart-prdt-qty prdct-det-qty">
                <div className="cartqty">
                  <div className="def-number-input number-input num-input-qty">
                    <button
                      disabled={
                        (cartObject != null && cartObject.unit <= 1) ||
                        addCartFilter == undefined
                          ? true
                          : false
                      }
                      className="minus"
                      onClick={() => {
                        if (cartObject) {
                          this.props.removeFromCartFN({
                            id: cartObject.id,
                            price: cartObject.price,
                            mrp: cartObject.mrp,
                            discount: cartObject.discount,
                            currentUnit: cartObject.unit,
                            unit: -1,
                            filters: addCartFilter,
                          });
                        }
                      }}
                    ></button>
                    <input
                      readOnly={true}
                      className="quantity"
                      name="quantity"
                      value={cartObject ? cartObject.unit : 1}
                      onChange={() => console.log("change")}
                      type="number"
                    />
                    <button
                      className="plus"
                      disabled={addCartFilter == undefined ? true : false}
                      onClick={() => {
                        if (cartObject) {
                          this.props.addToCartFN({
                            id: cartObject.id,
                            name: cartObject.name,
                            price: cartObject.price,
                            mrp: cartObject.mrp,
                            discountType: cartObject.discountType,
                            discount: cartObject.discount,
                            unit: 1,
                            brandName: cartObject.brandName,
                            firstImage: cartObject.firstImage,
                            thumbImage: cartObject.thumbImage,
                            filters: addCartFilter,
                          });
                        } else {
                          this.props.addToCartFN({
                            id: productDetails.productId,
                            name: productDetails.productName,
                            price: productDetails.defaultPrice,
                            mrp: productDetails.mrp,
                            discountType: productDetails.discountType,
                            discount: productDetails.discount,
                            unit: 1,
                            brandName: productDetails.brandName,
                            firstImage: productDetails.firstImage,
                            thumbImage: productDetails.thumbImage,
                            discountInPercentage:
                              productDetails.discountInPercentage,
                            discountInAmount: productDetails.discountInAmount,
                            filters: addCartFilter,
                          });
                        }
                      }}
                    ></button>
                  </div>
                </div>
                <div className="prdt-price prdt-qty-price">
                  <b>
                    <i className="fa fa-inr"></i>{" "}
                    {cartObject
                      ? parseFloat(cartObject.totalMrp).toFixed(2)
                      : addCartFilter
                      ? addCartFilter.discount != null
                        ? addCartFilter.filterPrice
                          ? addCartFilter.filterPrice
                          : parseFloat(addCartFilter.mrp).toFixed(2)
                        : parseFloat(addCartFilter.mrp).toFixed(2)
                      : "Not Available"}
                    {/* <pre>{JSON.stringify(addCartFilter, undefined, 2)}</pre> */}
                    /-
                  </b>
                </div>
                <Button
                  className="rainbow rainbow-1 mt-3 prdct-qty-icon"
                  disabled={addCartFilter == undefined ? true : false}
                  onClick={() => {
                    if (cartObject) {
                      this.props.history.push("/MyCart");
                    } else {
                      this.props.addToCartFN({
                        id: productDetails.productId,
                        name: productDetails.productName,
                        price: productDetails.defaultPrice,
                        mrp: productDetails.mrp,
                        discountType: productDetails.discountType,
                        discount: productDetails.discount,
                        unit: 1,
                        brandName: productDetails.brandName,
                        firstImage: productDetails.firstImage,
                        thumbImage: productDetails.thumbImage,
                        discountInPercentage:
                          productDetails.discountInPercentage,
                        discountInAmount: productDetails.discountInAmount,
                        filters: addCartFilter,
                      });
                    }
                  }}
                  style={{ marginLeft: "10px" }}
                >
                  <i className="fa fa-shopping-cart"></i>{" "}
                  {/* {JSON.stringify(cartObject)} */}
                  {cartObject ? "Go To Cart" : "Add To Cart"}
                  {/* Add to Cart */}
                </Button>

                <div
                  className="checkboxes-container"
                  style={{ marginLeft: "10px" }}
                >
                  <div className="control-group d-flex">
                    <input
                      className="red-heart-checkbox"
                      id="red-check2"
                      type="checkbox"
                      checked={isProductInWishlist}
                      onChange={(e) => {
                        this.handleWishlist(e.target.checked);
                      }}
                    />
                    <label
                      style={{ color: "#000" }}
                      for="red-check2"
                      title="Add to WishList"
                    >
                      Add to Favourites
                    </label>
                  </div>
                </div>
                {/* <div>
                  <h2 style={{ color: "#fff" }}>Go To Cart</h2>
                </div> */}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

import React, { Component } from "react";
import Fade from "react-reveal/Fade";
import { Container, Row, Col } from "react-bootstrap";
class AboutIntro extends Component {
  render() {
    return (
      <div id="greate-reason" className="pb-5">
        <Container>
          <Row>
            <Col md="5"></Col>
            <Col md="7">
              <div className="reasonclass pt-3 pb-5">
                <Fade top>
                  <h1>Greate Reason</h1>
                  <h4>To Shop With Ubhe Sports</h4>
                </Fade>
              </div>
              <Row>
                <Col lg="6" md="12">
                  <div className="reasonlist mt-2">
                    <Fade bottom>
                      <ul>
                        <li>
                          <div className="ballimg">1</div>
                          <p className="pl-2">
                            &nbsp;Professional cricketers shop with us
                          </p>
                        </li>
                        <li>
                          <div className="ballimg">2</div>
                          <p className="pl-2">
                            &nbsp;Professional cricketers shop with us
                          </p>
                        </li>
                        <li>
                          <div className="ballimg">3</div>
                          <p className="pl-2">
                            &nbsp;Professional cricketers shop with us
                          </p>
                        </li>
                        <li>
                          <div className="ballimg">4</div>
                          <p className="pl-2">
                            &nbsp;Professional cricketers shop with us
                          </p>
                        </li>
                      </ul>
                    </Fade>
                  </div>
                </Col>
                <Col lg="6"  md="12">
                  <div className="reasonlist mt-2">
                    <Fade bottom>
                      <ul>
                        <li>
                          <div className="ballimg">5</div>
                          <p className="pl-2">
                            &nbsp;Professional cricketers shop with us
                          </p>
                        </li>
                        <li>
                          <div className="ballimg">6</div>
                          <p className="pl-2">
                            &nbsp;Professional cricketers shop with us
                          </p>
                        </li>
                        <li>
                          <div className="ballimg">7</div>
                          <p className="pl-2">
                            &nbsp;Professional cricketers shop with us
                          </p>
                        </li>
                        <li>
                          <div className="ballimg">8</div>
                          <p className="pl-2">
                            &nbsp;Professional cricketers shop with us
                          </p>
                        </li>
                      </ul>
                    </Fade>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default AboutIntro;

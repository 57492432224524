import React, { Component } from "react";
import Fade from "react-reveal/Fade";

import "./contact.css";
import banner from "@/assets/images/contactbanner.jpg";

import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import { Layout } from "@/Pages/Layout";
import Map from "@/Pages/Components/ContactComponent/Map";
import { getContactSupportData } from "@/services/api_function";
class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contactData: "",
    };
  }

  getContactSupportDataFun = () => {
    getContactSupportData()
      .then((response) => {
        console.log({ response });
        let res = response.data;
        if (res.responseStatus == 200) {
          this.setState({ contactData: res.response });
        }
      })
      .catch((error) => {
        console.log({ error });
      });
  };

  componentDidMount() {
    this.getContactSupportDataFun();
  }

  render() {
    let { contactData } = this.state;
    return (
      <Layout {...this.props}>
        <div id="About" className="pt-5">
          <div className="contactbanner bannerhead pt-4">
            <Container fluid>
              <Breadcrumb className="contact-braeadcrumb cust-braeadcrumb breadcrumbstyle">
                <Breadcrumb.Item
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.history.push(`/`);
                  }}
                >
                  Home
                </Breadcrumb.Item>

                <Breadcrumb.Item active>Contact Us</Breadcrumb.Item>
              </Breadcrumb>
            </Container>
            <h1>Get In Touch</h1>
            <p>
              Want to get in touch? We'd love to here from you. Here's how you
              can reach us...
            </p>
          </div>
          <div className="cntbannerbg">
            <Fade top>
              <img src={banner} alt="Banner"></img>
            </Fade>
          </div>
        </div>
        <div className="talkwithbox">
          <Container fluid>
            <Row>
              <Col md="1"></Col>
              <Col md="5" className="mb-2">
                <div className="talkwith">
                  <h4>Talk With Us</h4>
                  <p>Get in touch with customer support for any assistance</p>
                  <a
                    className="new-call-btn rainbow rainbow-1"
                    href={`tel:${
                      contactData != "" ? contactData.contactNo : "9091777711"
                    }`}
                    target="_blank"
                  >
                    Call Us
                  </a>
                </div>
              </Col>
              <Col md="5" className="mb-2">
                <div className="talkwith">
                  <h4>Help & Support</h4>
                  <p>Our support team will give you answer fast</p>
                  <a
                    className="new-call-btn rainbow rainbow-1"
                    href={`mailto:${
                      contactData != ""
                        ? contactData.emailId
                        : "rahulp@opethic.com"
                    }`}
                  >
                    Contact Support
                  </a>
                </div>
              </Col>

              <Col md="1"></Col>
            </Row>
          </Container>
        </div>
        <Map />
      </Layout>
    );
  }
}

export default Contact;

import {
  createWalletUrl,
  getAllWalletUrl,
  fetchWalletUrl,
  updateWalletUrl,
  deleteWalletUrl,
  getWalletUrl,
  getUserWalletUrl,
  getConversionRateListForMobileUrl,
  listOfGiftVoucherForWebUrl,
  getWalletHistoryForWebUrl,
} from "@/services/api";
import { getHeader } from "@/_helpers";

import axios from "axios";

export function createWallet(request) {
  return axios({
    url: createWalletUrl(),
    method: "POST",
    headers: getHeader(),
    data: request,
  });
}
export function getAllWallet() {
  return axios({
    url: getAllWalletUrl(),
    method: "GET",
    headers: getHeader(),
  });
}
export function fetchWallet(id) {
  return axios({
    url: fetchWalletUrl(id),
    method: "GET",
    headers: getHeader(),
  });
}

export function updateWallet(values) {
  return axios({
    url: updateWalletUrl(),
    method: "POST",
    headers: getHeader(),
    data: values,
  });
}
export function getWallet(values) {
  return axios({
    url: getWalletUrl(),
    method: "POST",
    headers: getHeader(),
    data: values,
  });
}
export function getUserWallet(values) {
  return axios({
    url: getUserWalletUrl(),
    method: "GET",
    headers: getHeader(),
    data: values,
  });
}
export function getConversionRateListForMobile(values) {
  return axios({
    url: getConversionRateListForMobileUrl(),
    method: "POST",
    headers: getHeader(),
    data: values,
  });
}
export function deleteWallet(id) {
  return axios({
    url: deleteWalletUrl(id),
    method: "GET",
    headers: getHeader(),
  });
}

export function listOfGiftVoucherForWeb() {
  return axios({
    url: listOfGiftVoucherForWebUrl(),
    method: "GET",
    headers: getHeader(),
  });
}

export function getWalletHistoryForWeb() {
  return axios({
    url: getWalletHistoryForWebUrl(),
    method: "GET",
    headers: getHeader(),
  });
}

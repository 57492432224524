import React, { Component } from "react";
import { SidebarLayout } from "@/Pages/Layout";
import "./dashboard.css";
import { Slide } from "react-reveal";
import { Row, Col, Form, Button, Modal } from "react-bootstrap";
import Notifications, { notify } from "react-notify-toast";
import { emailRegExp } from "@/_helpers";
import { Formik } from "formik";
import * as Yup from "yup";
import Select from "react-select";

import {
  getAccountDetails,
  updateAccountDetails,
  checkPassword,
  changePassword,
  getUserAddresses,
  getCountries,
  getStatesByCountry,
  getCitiesByState,
  findAddress,
  updateAddress,
  deleteAddress,
} from "@/services/api_function";

const customStyles = {
  control: (base) => ({
    ...base,
    height: 34,
    minHeight: 34,
    border: "1px solid #ced4da",
    padding: "0 6px",
    boxShadow: "none",
    color: "#a4a4a4",
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "13px",
    //lineHeight: "10",
  }),
};
class Dashboard extends Component {
  getValue = (opts, val) => {
    return opts.find((o) => o.value === val);
  };

  constructor(props) {
    super(props);
    this.state = {
      countryOpt: [],
      stateOpt: [],
      cityOpt: [],
      accountDetails: "",
      billingAddress: "",
      editAccountDetailFlag: false,
      editChangePassword: false,
      editAddressModal: false,
      deleteAddressModal: false,
      addressObject: "",
      addressId: "",
    };
  }

  getAccountDetailsFun = () => {
    getAccountDetails()
      .then((response) => {
        let res = response.data;
        if (res.responseStatus == 200) {
          this.setState({
            accountDetails: res.responseObject.userObject,
          });
        }
      })
      .catch((error) => {
        this.setState({ accountDetails: "" });
        console.log("error", error);
      });
  };

  checkPasswordFun = (values, id, setFieldValue) => {
    let reqData = new FormData();
    reqData.append("currentPassword", values);
    reqData.append("id", id);
    checkPassword(reqData)
      .then((response) => {
        let res = response.data;
        if (res.responseStatus == 200) {
        } else {
          setFieldValue("currentPassword", "");
          notify.show(res.message, "error", 2000);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  getUserAddressesFun = () => {
    getUserAddresses()
      .then((response) => {
        let res = response.data;
        if (res.responseStatus == 200) {
          this.setState({ billingAddress: res.responseObject });
        }
      })
      .catch((error) => {
        this.setState({ billingAddress: "" });
        console.log("error", error);
      });
    // console.log("fun called");
  };

  countriesData = () => {
    getCountries()
      .then((response) => {
        let res = response.data;
        if (res.responseStatus == 200) {
          let country = res.responseObject;
          let countryOpt = country.map(function (values) {
            return { value: values.id, label: values.name };
          });
          this.setState({ countryOpt: countryOpt });
        }
      })
      .catch((error) => {
        console.log({ error });
      });
  };

  statesData = (countryId, status, values = null) => {
    let reqData = new FormData();
    reqData.append("countryId", countryId);
    getStatesByCountry(reqData)
      .then((response) => {
        let res = response.data;
        if (res.responseStatus == 200) {
          let state = res.responseObject;
          let stateOpts = state.map(function (values) {
            return { value: values.id, label: values.name };
          });
          this.setState({ stateOpt: stateOpts });

          if (status == true && values != null) {
            if (this.state.stateOpt && typeof values.state.id === "number") {
              values.stateId = this.getValue(
                this.state.stateOpt,
                values.state.id
              );

              // console.log({ values });
              this.setState({ addressObject: values });
              this.citiesData(values.state.id, true, values);
            }
          }
        }
      })
      .catch((error) => {
        console.log({ error });
      });
  };

  citiesData = (stateId, status, values = null) => {
    let reqData = new FormData();
    reqData.append("stateId", stateId);
    getCitiesByState(reqData)
      .then((response) => {
        let res = response.data;
        if (res.responseStatus == 200) {
          let city = res.responseObject;
          let cityOpts = city.map(function (values) {
            return { value: values.id, label: values.name };
          });
          this.setState({ cityOpt: cityOpts });

          if (status == true && values != null) {
            if (this.state.cityOpt && typeof values.city.id === "number") {
              values.cityId = this.getValue(this.state.cityOpt, values.city.id);

              this.setState({
                addressObject: values,
                editAddressModal: true,
              });
            }
          }
        }
      })
      .catch((error) => {
        console.log({ error });
      });
  };

  viewEditAddressModal = (status, id = null) => {
    if (status) {
      let reqData = new FormData();
      reqData.append("id", id);
      findAddress(reqData)
        .then((response) => {
          if (response.data.responseStatus == 200) {
            let values = response.data.responseObject;
            if (
              this.state.countryOpt &&
              typeof values.country.id === "number"
            ) {
              values.countryId = this.getValue(
                this.state.countryOpt,
                values.country.id
              );

              this.setState({ addressObject: values });
              this.statesData(values.country.id, true, values);
            }
          }
        })
        .catch((error) => {
          console.log({ error });
        });
    }
  };

  deleteAddtessModalFun = () => {
    let requestData = new FormData();
    requestData.append("id", this.state.addressId);
    deleteAddress(requestData)
      .then((response) => {
        let res = response.data;
        if (res.responseStatus == 200) {
          notify.show(res.message, "success", 2000);
          this.setState({ deleteAddressModal: false }, () => {
            this.getUserAddressesFun();
          });
        } else {
          notify.show(res.message, "error", 2000);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  componentDidMount() {
    this.getAccountDetailsFun();
    this.getUserAddressesFun();
    this.countriesData();
  }

  render() {
    const {
      accountDetails,
      editAccountDetailFlag,
      editChangePassword,
      billingAddress,
      billingString,
      editAddressModal,
      countryOpt,
      stateOpt,
      cityOpt,
      addressObject,
      deleteAddressModal,
      addressId,
    } = this.state;
    return (
      <SidebarLayout
        breadcrum_parent_name_url="/Dashboard"
        breadcrum_parent_name="My Account"
        breadcrum_child_name="Dashboard"
        {...this.props}
      >
        <Notifications options={{ zIndex: 9999, top: "50px" }} />

        <Slide right>
          <div className="accountinfo">
            <h4>Account Information</h4>
            {editAccountDetailFlag == false && editChangePassword == false && (
              <div className="accountdetail">
                <p>Contact Information :</p>
                <ul>
                  <li>
                    <span className="info-span">Name :</span>
                    <span className="span-name">
                      {" "}
                      {accountDetails != null
                        ? accountDetails.firstName +
                          " " +
                          accountDetails.lastName
                        : "NA"}
                    </span>
                  </li>
                  <li>
                    <span className="info-span">Email :</span>
                    <span className="span-name">
                      {" "}
                      {accountDetails && accountDetails.emailId != null
                        ? accountDetails.emailId
                        : "-"}
                    </span>
                  </li>
                  <li className="editpwd">
                    <a
                      href="javascript:void(0)"
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ editAccountDetailFlag: true });
                      }}
                    >
                      Edit |
                    </a>
                    <a
                      href="javascript:void(0)"
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ editChangePassword: true });
                      }}
                    >
                      {" "}
                      Change Password
                    </a>
                  </li>
                </ul>
              </div>
            )}

            {/* Account Details Form start */}
            {editAccountDetailFlag == true && (
              <div className="accountdetail pr-4">
                <Formik
                  validateOnChange={false}
                  validateOnBlur={false}
                  enableReinitialize={true}
                  initialValues={{
                    firstName:
                      accountDetails && accountDetails.firstName != null
                        ? accountDetails.firstName
                        : "",
                    lastName:
                      accountDetails && accountDetails.lastName != null
                        ? accountDetails.lastName
                        : "",
                    email:
                      accountDetails && accountDetails.emailId != null
                        ? accountDetails.emailId
                        : "",
                  }}
                  validationSchema={Yup.object().shape({
                    firstName: Yup.string()
                      .trim()
                      .required("First Name is required"),
                    lastName: Yup.string()
                      .trim()
                      .required("Last Name is required"),
                    emailId: Yup.lazy((v) => {
                      if (v != undefined) {
                        return Yup.string()
                          .trim()
                          .matches(emailRegExp, "Enter valid email id")
                          .required("Email Id is required");
                      }
                      return Yup.string().notRequired();
                    }),
                  })}
                  onSubmit={(values, { setSubmitting, resetForm }) => {
                    let requestData = new FormData();
                    requestData.append(
                      "id",
                      accountDetails && accountDetails.id != null
                        ? accountDetails.id
                        : ""
                    );
                    requestData.append("firstName", values.firstName);
                    requestData.append("lastName", values.lastName);
                    requestData.append("emailId", values.emailId);

                    setSubmitting(true);
                    updateAccountDetails(requestData)
                      .then((response) => {
                        setSubmitting(false);
                        let res = response.data;
                        // console.log("response", res);
                        if (res.responseStatus == 200) {
                          notify.show(res.message, "success", 2000);
                          resetForm();
                          this.setState({
                            editAccountDetailFlag: false,
                            accountDetails: res.responseObject,
                          });
                        } else {
                          notify.show(res.message, "error", 2000);
                          // resetForm();
                        }
                      })
                      .catch((error) => {
                        console.log("error", error);
                      });
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    resetForm,
                    setFieldValue,
                  }) => (
                    <Form className="account-info" onSubmit={handleSubmit}>
                      <Row>
                        <Col md="12">
                          <Form.Group>
                            <Form.Label>FIRST NAME</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="first name"
                              name="firstName"
                              id="firstName"
                              onChange={handleChange}
                              value={values.firstName}
                              isValid={touched.firstName && !errors.firstName}
                              isInvalid={!!errors.firstName}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.firstName}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <Form.Group>
                            <Form.Label>LAST NAME</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="last name"
                              name="lastName"
                              id="lastName"
                              onChange={handleChange}
                              value={values.lastName}
                              isValid={touched.lastName && !errors.lastName}
                              isInvalid={!!errors.lastName}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.lastName}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <Form.Group>
                            <Form.Label>EMAIL ID</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="email id"
                              name="emailId"
                              id="emailId"
                              onChange={handleChange}
                              value={values.emailId}
                              isValid={touched.emailId && !errors.emailId}
                              isInvalid={!!errors.emailId}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.emailId}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4">
                          <Button
                            variant="primary"
                            type="submit"
                            className="forgotbtn rainbow rainbow-1 mt-0 mb-2"
                          >
                            Submit
                          </Button>
                        </Col>
                        <Col md="4">
                          <Button
                            type="button"
                            className="btn btn-secodary"
                            variant="danger"
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({ editAccountDetailFlag: false });
                            }}
                          >
                            Cancel
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </div>
            )}
            {/* Account Details FORm ENd */}

            {/* Change password Form start */}
            {editChangePassword == true && (
              <div className="accountdetail pr-4">
                <Formik
                  validateOnChange={false}
                  validateOnBlur={false}
                  enableReinitialize={true}
                  initialValues={{
                    currentPassword: "",
                    newPassword: "",
                    confirmPassword: "",
                  }}
                  validationSchema={Yup.object().shape({
                    currentPassword: Yup.string()
                      .trim()
                      .required("Current password is required"),
                    newPassword: Yup.string()
                      .trim()
                      .required("New password is required"),
                    confirmPassword: Yup.string().when("newPassword", {
                      is: (val) => (val && val.length > 0 ? true : false),
                      then: Yup.string().oneOf(
                        [Yup.ref("newPassword")],
                        "Both password need to be the same"
                      ),
                    }),
                  })}
                  onSubmit={(values, { setSubmitting, resetForm }) => {
                    let requestData = new FormData();
                    requestData.append(
                      "id",
                      accountDetails && accountDetails.id != null
                        ? accountDetails.id
                        : ""
                    );
                    requestData.append(
                      "currentPassword",
                      values.currentPassword
                    );
                    requestData.append("newPassword", values.newPassword);

                    setSubmitting(true);
                    changePassword(requestData)
                      .then((response) => {
                        setSubmitting(false);
                        let res = response.data;
                        // console.log("response", res);
                        if (res.responseStatus == 200) {
                          notify.show(res.message, "success", 2000);
                          resetForm();
                          this.setState({ editChangePassword: false });
                        } else {
                          notify.show(res.message, "error", 2000);
                          // resetForm();
                        }
                      })
                      .catch((error) => {
                        console.log("error", error);
                      });
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    resetForm,
                    setFieldValue,
                  }) => (
                    <Form className="account-info" onSubmit={handleSubmit}>
                      <Row>
                        <Col md="12">
                          <Form.Group controlId="ragistration.ControlTextarea2">
                            <Form.Label>CURRENT PASSWORD</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="current password"
                              name="currentPassword"
                              id="currentPassword"
                              onChange={handleChange}
                              onBlur={(e) => {
                                e.preventDefault();
                                this.checkPasswordFun(
                                  e.target.value,
                                  accountDetails.id,
                                  setFieldValue
                                );
                              }}
                              value={values.currentPassword}
                              isValid={
                                touched.currentPassword &&
                                !errors.currentPassword
                              }
                              isInvalid={!!errors.currentPassword}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.currentPassword}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col md="12">
                          <Form.Group controlId="ragistration.ControlTextarea2">
                            <Form.Label>NEW PASSWORD</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="new password"
                              name="newPassword"
                              id="newPassword"
                              onChange={handleChange}
                              value={values.newPassword}
                              isValid={
                                touched.newPassword && !errors.newPassword
                              }
                              isInvalid={!!errors.newPassword}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.newPassword}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col md="12">
                          <Form.Group controlId="ragistration.ControlTextarea2">
                            <Form.Label>NEW CONFIRM PASSWORD</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="confirm password"
                              name="confirmPassword"
                              id="confirmPassword"
                              onChange={handleChange}
                              value={values.confirmPassword}
                              isValid={
                                touched.confirmPassword &&
                                !errors.confirmPassword
                              }
                              isInvalid={!!errors.confirmPassword}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.confirmPassword}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4">
                          <Button
                            variant="primary"
                            type="submit"
                            className="forgotbtn rainbow rainbow-1 mt-0 mb-2"
                          >
                            Submit
                          </Button>
                        </Col>
                        <Col md="4">
                          <Button
                            type="button"
                            className="btn btn-secodary"
                            variant="danger"
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({ editChangePassword: false });
                            }}
                          >
                            Cancel
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </div>
            )}
            {/* Change password FORm ENd */}
          </div>
          <div className="accountinfo pt-3 mb-3">
            <div className="accountinfo">
              <h4>Address Book</h4>
              <div className="accountdetail manage-add">
                {billingAddress.length > 0 && (
                  <Row>
                    {billingAddress.map((v, i) => {
                      return (
                        <Col md="6">
                          <ul>
                            <Row>
                              <Col md="10">
                                <li>
                                  <span className="span-name">
                                    {v.addressLine +
                                      ", " +
                                      v.streetAddress +
                                      ","}{" "}
                                    <br />
                                    {v.city.name + " - " + v.pincode}
                                  </span>
                                </li>
                              </Col>
                              <Col md="2">
                                <li className="editpwd editadd">
                                  <a
                                    href="javascript:void(0)"
                                    onClick={() => {
                                      this.viewEditAddressModal(true, v.id);
                                    }}
                                  >
                                    <i class="fa fa-pencil"></i>
                                  </a>
                                  <a
                                    href="javascript:void(0)"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.setState({
                                        addressId: v.id,
                                        deleteAddressModal: true,
                                      });
                                    }}
                                  >
                                    <i class="fa fa-trash"></i>
                                  </a>
                                </li>
                              </Col>
                            </Row>
                          </ul>
                        </Col>
                      );
                    })}
                  </Row>
                )}
              </div>
            </div>
          </div>

          <Modal
            show={editAddressModal}
            onHide={() => this.setState({ editAddressModal: false })}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="forgotpwdbox"
          >
            <Modal.Header closeButton className="forgotheader pt-3 pb-0">
              <Modal.Title
                id="example-custom-modal-styling-title"
                className="forgot-pwd"
              >
                Edit Address
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="galleryumodal forgotmodal pt-0 pb-3">
              <Formik
                validateOnChange={false}
                validateOnBlur={false}
                enableReinitialize={true}
                initialValues={{
                  address_line:
                    addressObject != null ? addressObject.addressLine : "",
                  street_address:
                    addressObject != null ? addressObject.streetAddress : "",
                  cityId: addressObject != null ? addressObject.cityId : "",
                  stateId: addressObject != null ? addressObject.stateId : "",
                  countryId:
                    addressObject != null ? addressObject.countryId : "",
                  pincode: addressObject != null ? addressObject.pincode : "",
                }}
                validationSchema={Yup.object().shape({})}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  const requestData = new FormData();

                  // Billing Address
                  requestData.append("id", addressObject.id);
                  requestData.append("address_line", values.address_line);
                  requestData.append("street_address", values.street_address);
                  requestData.append("pincode", values.pincode);
                  requestData.append("countryId", values.countryId.value);
                  requestData.append("stateId", values.stateId.value);
                  requestData.append("cityId", values.cityId.value);

                  setSubmitting(true);
                  updateAddress(requestData)
                    .then((response) => {
                      setSubmitting(false);
                      let res = response.data;
                      // console.log("response", res);
                      if (res.responseStatus == 200) {
                        notify.show(res.message, "success", 2000);
                        resetForm();
                        this.setState({ editAddressModal: false }, () => {
                          this.getUserAddressesFun();
                        });
                      } else {
                        notify.show(res.message, "error", 2000);
                        // resetForm();
                      }
                    })
                    .catch((error) => {
                      console.log("error", error);
                    });
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  resetForm,
                  setFieldValue,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Form.Group>
                      <Form.Label htmlFor="address_line">
                        ADDRESS LINE
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="address_line"
                        id="address_line"
                        onChange={handleChange}
                        value={values.address_line}
                        isValid={touched.address_line && !errors.address_line}
                        isInvalid={!!errors.address_line}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.address_line}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label htmlFor="street_address">
                        STREET ADDRESS
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="street_address"
                        id="street_address"
                        onChange={handleChange}
                        value={values.street_address}
                        isValid={
                          touched.street_address && !errors.street_address
                        }
                        isInvalid={!!errors.street_address}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.street_address}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                      <Form.Label htmlFor="countryId">
                        Select Country <span className="redstar">*</span>
                      </Form.Label>
                      <Select
                        isClearable={true}
                        styles={customStyles}
                        onChange={(v) => {
                          setFieldValue("countryId", v);
                          setFieldValue("stateId", "");
                          setFieldValue("cityId", "");
                          // console.log("countryId", v);
                          if (v != null) {
                            this.statesData(v.value);
                          } else {
                            this.setState({ stateOpt: [], cityOpt: [] });
                          }
                        }}
                        className="clsselect2"
                        name="countryId"
                        options={countryOpt}
                        value={values.countryId}
                        width="200px"
                      />
                      <span className="text-danger">
                        {errors.countryId && "Country is required"}
                      </span>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label htmlFor="stateId">
                        Select State <span className="redstar">*</span>
                      </Form.Label>
                      <Select
                        isClearable={true}
                        // styles={customStyles}
                        onChange={(v) => {
                          setFieldValue("stateId", v);
                          setFieldValue("cityId", "");
                          if (v != null) {
                            this.citiesData(v.value);
                          } else {
                            this.setState({ cityOpt: [] });
                          }
                        }}
                        className="clsselect2"
                        name="stateId"
                        options={stateOpt}
                        value={values.stateId}
                        width="200px"
                      />
                      <span className="text-danger">{errors.stateId}</span>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label htmlFor="cityId">
                        Select City <span className="redstar">*</span>
                      </Form.Label>
                      <Select
                        isClearable={true}
                        // styles={customStyles}
                        onChange={(v) => {
                          setFieldValue("cityId", v);
                        }}
                        className="clsselect2"
                        name="cityId"
                        options={cityOpt}
                        value={values.cityId}
                        width="200px"
                      />
                      <span className="text-danger">{errors.cityId}</span>
                    </Form.Group>

                    <Form.Group>
                      <Form.Label htmlFor="pincode">PINCODE</Form.Label>
                      <Form.Control
                        type="text"
                        name="pincode"
                        id="pincode"
                        onChange={handleChange}
                        value={values.pincode}
                        isValid={touched.pincode && !errors.pincode}
                        isInvalid={!!errors.pincode}
                        maxLength={6}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.pincode}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Row>
                      <Col md={6}>
                        <Button
                          variant="primary"
                          type="submit"
                          disabled={isSubmitting}
                          className="new-u-btn rainbow rainbow-1  loginbtn"
                        >
                          Update
                        </Button>
                      </Col>
                      <Col md={6} className="mt-2">
                        <div className="pl-4 forgot">
                          <Button
                            className="btn btn-secodary"
                            variant="danger"
                            onClick={(e) => {
                              e.preventDefault();
                              resetForm();
                              this.setState({ editAddressModal: false });
                            }}
                          >
                            Cancel
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Modal.Body>
          </Modal>

          {/* dialog box start */}
          <Modal
            show={deleteAddressModal}
            onHide={() => this.setState({ deleteAddressModal: false })}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="forgotpwdbox address_box"
          >
            {" "}
            <Modal.Body className="address-modal p-0 forgotmodal ">
              <div className="addressbox">
                <i className="fa fa-trash"></i>
                <p>Are you sure, you want to delete</p>
              </div>
              <div className="address-btn">
                <Button
                  type="submit"
                  className="address-btn1"
                  variant="secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    this.deleteAddtessModalFun();
                  }}
                >
                  YES
                </Button>
                <Button
                  className="address-btn2"
                  variant="secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ deleteAddressModal: false });
                  }}
                >
                  No
                </Button>
              </div>
            </Modal.Body>
          </Modal>
          {/* dialog box end */}
        </Slide>
      </SidebarLayout>
    );
  }
}
export default Dashboard;

import axios from "axios";
import {
  searchProductURL,
  searchProductFiltersURL,
  getSearchProductFiltersUrl,
  searchProductByNameUrl,
} from "@/services/api";

import { authLogin } from "@/_helpers/authheader";

export function searchProduct(values) {
  return axios({
    url: searchProductURL(values),
    method: "POST",
    data: values,
  });
}

export function searchProductFilters(values) {
  return axios({
    url: searchProductFiltersURL(values),
    method: "POST",
    data: values,
  });
}

export function getSearchProductFilters(values) {
  return axios({
    headers: authLogin(),
    url: getSearchProductFiltersUrl(values),
    method: "POST",
    data: values,
  });
}

export function searchProductByName(values) {
  return axios({
    headers: authLogin(),
    url: searchProductByNameUrl(values),
    method: "POST",
    data: values,
  });
}

import React, { PropTypes, Component } from "react";
import "./product.css";

import { Form, Button, Nav, Accordion, Card, Row, Col } from "react-bootstrap";
import CustomLoader from "@/Pages/Components/CustomLoader";
class ProductSidebar extends Component {
  constructor(props) {
    super(props);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.state = {
      menuActive: true,
    };
  }

  toggleMenu() {
    let menuState = !this.state.menuActive;
    this.setState({
      menuActive: menuState,
    });
  }
  handleFilterSelectedCheckBox = (filterId, subFilterId) => {
    // console.log("filters", { filterId, subFilterId });
    const { selectedFilters } = this.props;
    let res = false;
    let obj = selectedFilters.find((v) => v.filterId == filterId);
    // console.log("obj", obj);
    if (obj != undefined) {
      if (obj.selectedSubfilters.includes(subFilterId)) {
        res = true;
      }
    }
    // console.log({ res });
    return res;
  };

  getFilterClasses = (filterId, subfilterId) => {
    const { filters } = this.props;
    let classes = "";
    if (filters != undefined) {
      filters.map((vi) => {
        if (
          parseInt(vi.filterId) === parseInt(filterId) &&
          parseInt(vi.subfilterId) === parseInt(subfilterId)
        ) {
          classes += " sizecoloractive";
        }
      });
    }
    return classes;
  };
  render() {
    const { menuActive } = this.state;
    const {
      sidebarLoading,
      selectedBrand,
      selectedGroup,
      selectedSubGroup,
      selectedCategory,
      selectedSubCategory,
      selectedFilters,
      lstproductList,
    } = this.props;
    return (
      <>
        {sidebarLoading ? (
          <CustomLoader />
        ) : (
          lstproductList.length > 0 && (
            <div id="menu">
              <div id="pro-sidebar">
                <h4>Filter By </h4>
              </div>
              {/* BRAND LIST */}
              {this.props.brandList && this.props.brandList.length > 0 && (
                <Accordion key={"brandlst"} defaultActiveKey={"brandlst"}>
                  <Card className="prdct-side-bar">
                    <Accordion.Toggle
                      as={Button}
                      variant="link"
                      className="p-2"
                      eventKey={"brandlst"}
                    >
                      <div className="pro-brand">
                        <span className="pull-left">BRANDS</span>
                        <span className="pull-right">
                          <i
                            className="fa fa-chevron-down"
                            onClick={this.toggleMenu}
                          ></i>
                        </span>
                      </div>
                    </Accordion.Toggle>

                    <Accordion.Collapse eventKey={"brandlst"}>
                      <Card.Body className="pr-2 pl-2 pt-0 pb-2">
                        {this.props.brandList.length > 0 && (
                          <ul className="brandlist">
                            {this.props.brandList.map((v) => {
                              return (
                                <li>
                                  <Form>
                                    <Nav.Link href="#." className="p-0">
                                      <Form.Group>
                                        <label className="formcheck">
                                          <Form.Check
                                            className="pr-2"
                                            value={v.brandId}
                                            onClick={(e) => {
                                              let checked = e.target.checked;
                                              this.props.updateSelectedBrands(
                                                parseInt(e.target.value),
                                                checked
                                              );
                                            }}
                                            checked={selectedBrand.includes(
                                              v.brandId
                                            )}
                                          />
                                          <span>{v.brandName}</span>
                                        </label>
                                      </Form.Group>
                                    </Nav.Link>
                                  </Form>
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              )}
              {/* COLOR FILTER LIST  */}
              {this.props.productFilters &&
                this.props.productFilters.length > 0 &&
                this.props.productFilters.map((v, i) => {
                  return (
                    v.isColor == true && (
                      <Accordion
                        key={`filter-${v.filterId}`}
                        defaultActiveKey={`filter-${v.filterId}`}
                      >
                        <Card className="prdct-side-bar">
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            className="p-2"
                            eventKey={`filter-${v.filterId}`}
                          >
                            {v.isColor == true && (
                              <div className="pro-brand">
                                <span className="pull-left">
                                  {v.filterName.toUpperCase()}
                                </span>
                                <span className="pull-right">
                                  <i
                                    className="fa fa-chevron-down"
                                    onClick={this.toggleMenu}
                                  ></i>
                                </span>
                              </div>
                            )}
                          </Accordion.Toggle>

                          <Accordion.Collapse eventKey={`filter-${v.filterId}`}>
                            <Card.Body className="pr-2 pl-2 pt-0 pb-2">
                              {v.isColor == true && (
                                <>
                                  <div className="price">
                                    <div className="pricerangelabel colorlabel">
                                      <ul>
                                        {v.filterDTOList.map((vi, ind) => {
                                          return (
                                            <li>
                                              <label>
                                                <input
                                                  type="checkbox"
                                                  name="product"
                                                  class="card-input-element"
                                                  checked={this.handleFilterSelectedCheckBox(
                                                    vi.filterId,
                                                    vi.subfilterId
                                                  )}
                                                  onClick={(e) => {
                                                    let checked =
                                                      e.target.checked;
                                                    this.props.handleSelectedFilters(
                                                      parseInt(vi.filterId),
                                                      parseInt(vi.subfilterId),
                                                      checked
                                                    );
                                                  }}
                                                />
                                                <div
                                                  className="panel panel-default card-input "
                                                  style={{
                                                    backgroundColor: vi.hexCode,
                                                    color: vi.hexCode,
                                                  }}
                                                >
                                                  <div className="panel-heading">
                                                    <span>&nbsp;&nbsp;</span>
                                                  </div>
                                                </div>
                                              </label>
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    </div>
                                  </div>
                                </>
                              )}
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>
                    )
                  );
                })}
              {/* GROUP LIST */}
              {this.props.groupList && this.props.groupList.length > 0 && (
                <Accordion key={"grouplst"} defaultActiveKey={"grouplst"}>
                  <Card className="prdct-side-bar">
                    <Accordion.Toggle
                      as={Button}
                      variant="link"
                      className="p-2"
                      eventKey={"grouplst"}
                    >
                      <div className="pro-brand">
                        <span className="pull-left">SPORTS</span>
                        <span className="pull-right">
                          <i
                            className="fa fa-chevron-down"
                            onClick={this.toggleMenu}
                          ></i>
                        </span>
                      </div>
                    </Accordion.Toggle>

                    <Accordion.Collapse eventKey={"grouplst"}>
                      <Card.Body className="pr-2 pl-2 pt-0 pb-2">
                        {this.props.groupList.length > 0 && (
                          <ul className="brandlist">
                            {this.props.groupList.map((v) => {
                              return (
                                <li>
                                  <Form>
                                    <Nav.Link href="#." className="p-0">
                                      <Form.Group>
                                        <label className="formcheck">
                                          <Form.Check
                                            className="pr-2"
                                            value={v.groupId}
                                            onClick={(e) => {
                                              let checked = e.target.checked;
                                              this.props.handleSelectedGroup(
                                                parseInt(e.target.value),
                                                checked
                                              );
                                            }}
                                            checked={selectedGroup.includes(
                                              v.groupId
                                            )}
                                          />
                                          <span>{v.groupName}</span>
                                        </label>
                                      </Form.Group>
                                    </Nav.Link>
                                  </Form>
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              )}
              {/* SUBGROUP LIST */}
              {this.props.subGroupList &&
                this.props.subGroupList.length > 0 && (
                  <Accordion
                    key={"subGrouplst"}
                    defaultActiveKey={"subGrouplst"}
                  >
                    <Card className="prdct-side-bar">
                      <Accordion.Toggle
                        as={Button}
                        variant="link"
                        className="p-2"
                        eventKey={"subGrouplst"}
                      >
                        <div className="pro-brand">
                          <span className="pull-left">SUB-SPORTS</span>
                          <span className="pull-right">
                            <i
                              className="fa fa-chevron-down"
                              onClick={this.toggleMenu}
                            ></i>
                          </span>
                        </div>
                      </Accordion.Toggle>

                      <Accordion.Collapse eventKey={"subGrouplst"}>
                        <Card.Body className="pr-2 pl-2 pt-0 pb-2">
                          {this.props.subGroupList.length > 0 && (
                            <ul className="brandlist prdctfilterscroll">
                              {this.props.subGroupList.map((v) => {
                                return (
                                  <li>
                                    <Form>
                                      <Nav.Link href="#." className="p-0">
                                        <Form.Group>
                                          <label className="formcheck">
                                            <Form.Check
                                              className="pr-2"
                                              value={v.subGroupId}
                                              onClick={(e) => {
                                                let checked = e.target.checked;
                                                this.props.handleSelectedSubGroup(
                                                  parseInt(e.target.value),
                                                  checked
                                                );
                                              }}
                                              checked={selectedSubGroup.includes(
                                                v.subGroupId
                                              )}
                                            />
                                            <span>{v.subGroupName}</span>
                                          </label>
                                        </Form.Group>
                                      </Nav.Link>
                                    </Form>
                                  </li>
                                );
                              })}
                            </ul>
                          )}
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                )}
              {/* CATEGORY LIST */}
              {this.props.categoryList &&
                this.props.categoryList.length > 0 && (
                  <Accordion
                    key={"categorylst"}
                    defaultActiveKey={"categorylst"}
                  >
                    <Card className="prdct-side-bar">
                      <Accordion.Toggle
                        onClick={(e) => {
                          console.log("e", e);
                        }}
                        as={Button}
                        variant="link"
                        className="p-2"
                        eventKey={"categorylst"}
                      >
                        <div className="pro-brand">
                          <span className="pull-left">CATEGORY</span>
                          {menuActive}
                          <span className="pull-right">
                            <i
                              className="fa fa-chevron-down"
                              onClick={this.toggleMenu}
                            />
                          </span>
                        </div>
                      </Accordion.Toggle>

                      <Accordion.Collapse eventKey={"categorylst"}>
                        <Card.Body className="pr-2 pl-2 pt-0 pb-2">
                          {this.props.categoryList.length > 0 && (
                            <ul className="brandlist productcategorylist">
                              {this.props.categoryList.map((v) => {
                                return (
                                  <li>
                                    <Nav.Link href="#." className="p-0">
                                      <Form.Group>
                                        <label className="formcheck">
                                          <Form.Check
                                            className="pr-2"
                                            value={v.categoryId}
                                            onClick={(e) => {
                                              let checked = e.target.checked;
                                              this.props.handleSelectedCategory(
                                                parseInt(e.target.value),
                                                checked
                                              );
                                            }}
                                            checked={selectedCategory.includes(
                                              v.categoryId
                                            )}
                                          />
                                          <span>{v.categoryName}</span>
                                        </label>
                                      </Form.Group>
                                    </Nav.Link>
                                  </li>
                                );
                              })}
                            </ul>
                          )}
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                )}
              {/* SUB_CATEGORY LIST */}
              {this.props.subCategoryList &&
                this.props.subCategoryList.length > 0 && (
                  <Accordion
                    key={"subcategorylst"}
                    defaultActiveKey={"subcategorylst"}
                  >
                    <Card className="prdct-side-bar">
                      <Accordion.Toggle
                        onClick={(e) => {
                          console.log("e", e);
                        }}
                        as={Button}
                        variant="link"
                        className="p-2"
                        eventKey={"subcategorylst"}
                      >
                        <div className="pro-brand">
                          <span className="pull-left">MODEL</span>
                          {menuActive}
                          <span className="pull-right">
                            <i
                              className="fa fa-chevron-down"
                              onClick={this.toggleMenu}
                            />
                          </span>
                        </div>
                      </Accordion.Toggle>

                      <Accordion.Collapse eventKey={"subcategorylst"}>
                        <Card.Body className="pr-2 pl-2 pt-0 pb-2">
                          {this.props.subCategoryList.length > 0 && (
                            <ul className="brandlist productCategoryList">
                              {this.props.subCategoryList.map((v) => {
                                return (
                                  <li>
                                    <Nav.Link href="#." className="p-0">
                                      <Form.Group>
                                        <label className="formcheck">
                                          <Form.Check
                                            className="pr-2"
                                            value={v.subCategoryId}
                                            onClick={(e) => {
                                              let checked = e.target.checked;
                                              this.props.handleSelectedSubCategory(
                                                parseInt(e.target.value),
                                                checked
                                              );
                                            }}
                                            checked={selectedSubCategory.includes(
                                              v.subCategoryId
                                            )}
                                          />
                                          <span>{v.subCategoryName}</span>
                                        </label>
                                      </Form.Group>
                                    </Nav.Link>
                                  </li>
                                );
                              })}
                            </ul>
                          )}
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                )}
              {/* <pre>{JSON.stringify(this.props.selectedFilters, undefined, 2)}</pre> */}
              {/* FILTERS EXCEPT COLOR */}
              {this.props.productFilters &&
                this.props.productFilters.length > 0 &&
                this.props.productFilters.map((v, i) => {
                  return (
                    v.isColor == false && (
                      <Accordion
                        key={`filter-${v.filterId}`}
                        defaultActiveKey={`filter-${v.filterId}`}
                      >
                        <Card className="prdct-side-bar">
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            className="p-2"
                            eventKey={`filter-${v.filterId}`}
                          >
                            {v.isColor == false && (
                              <div className="pro-brand">
                                <span className="pull-left">
                                  {v.filterName.toUpperCase()}
                                </span>
                                <span className="pull-right">
                                  <i
                                    className="fa fa-chevron-down"
                                    onClick={this.toggleMenu}
                                  ></i>
                                </span>
                              </div>
                            )}
                          </Accordion.Toggle>

                          <Accordion.Collapse eventKey={`filter-${v.filterId}`}>
                            <Card.Body className="pr-2 pl-2 pt-0 pb-2">
                              {v.isColor == false && (
                                <div className="price">
                                  <div className="pricerangelabel">
                                    <ul className="brandlist prdctfilterscroll">
                                      {v.filterDTOList.map((vi) => {
                                        return (
                                          <li>
                                            <label>
                                              <input
                                                type="checkbox"
                                                name="product"
                                                class="card-input-element"
                                                checked={this.handleFilterSelectedCheckBox(
                                                  vi.filterId,
                                                  vi.subfilterId
                                                )}
                                                onClick={(e) => {
                                                  let checked =
                                                    e.target.checked;
                                                  this.props.handleSelectedFilters(
                                                    parseInt(vi.filterId),
                                                    parseInt(vi.subfilterId),
                                                    checked
                                                  );
                                                }}
                                              />

                                              <div class="panel panel-default card-input">
                                                <div class="panel-heading">
                                                  <span>
                                                    {vi.subfilterName}
                                                  </span>
                                                </div>
                                              </div>
                                            </label>
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  </div>
                                </div>
                              )}
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>
                    )
                  );
                })}
            </div>
          )
        )}
      </>
    );
  }
}

export default ProductSidebar;

import config from "config";

export function searchProductURL() {
  return `${config.apiUrl}/searchProducts`;
}

export function searchProductFiltersURL() {
  return `${config.apiUrl}/searchProductsfilters`;
}

export function searchProductByNameUrl() {
  return `${config.apiUrl}/searchProductByName`;
}

export function getSearchProductFiltersUrl() {
  return `${config.apiUrl}/getSearchProductFilters`;
}

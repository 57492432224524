import axios from "axios";
import {
  productListBySubgroupIdUrl,
  productListFiltersUrl,
} from "@/services/api";
import { authHeader } from "@/_helpers";

export function productListBySubgroupId(values) {
  return axios({
    headers: authHeader(),
    url: productListBySubgroupIdUrl(),
    method: "POST",
    data: values,
  });
}

export function productListFilters(values) {
  return axios({
    headers: authHeader(),
    url: productListFiltersUrl(),
    method: "POST",
    data: values,
  });
}

import config from "config";

export function createWalletUrl() {
  return `${config.apiUrl}/createWallet`;
}
export function getAllWalletUrl() {
  return `${config.apiUrl}/getAllWallet`;
}

export function fetchWalletUrl(id) {
  return `${config.apiUrl}/fetchWallet/${id}`;
}
export function updateWalletUrl() {
  return `${config.apiUrl}/updateWallet`;
}
export function deleteWalletUrl(id) {
  return `${config.apiUrl}/deleteWallet/${id}`;
}
export function getWalletUrl() {
  return `${config.apiUrl}/getUserWalletForMobile`;
}
export function getUserWalletUrl() {
  return `${config.apiUrl}/getUserWallet`;
}
export function getConversionRateListForMobileUrl() {
  return `${config.apiUrl}/getConversionRateListForMobile`;
}

export function listOfGiftVoucherForWebUrl() {
  return `${config.apiUrl}/listOfGiftVoucherForWeb`;
}

export function getWalletHistoryForWebUrl() {
  return `${config.apiUrl}/getWalletHistoryForWeb`;
}

import config from "config";

export function getCountriesURL() {
  return `${config.apiUrl}/getCountries`;
}

export function getStatesByCountryURL() {
  return `${config.apiUrl}/getStatesByCountry`;
}

export function getCitiesByStateURL() {
  return `${config.apiUrl}/getCitiesByState`;
}

import React, { Component } from "react";
import Fade from "react-reveal/Fade";
import cricket from "@/assets/images/news/cricket.png";
import ground from "@/assets/images/news/ground.png";
import stadium1 from "@/assets/images/news/stadium1.png";
import man from "@/assets/images/news/man.png";
import ground1 from "@/assets/images/news/ground1.png";
import man1 from "@/assets/images/news/man1.png";
import stadium from "@/assets/images/news/stadium.png";
import batman from "@/assets/images/news/batman.png";
import { Layout } from "@/Pages/Layout";
import { Row, Card, Col, Container, Breadcrumb } from "react-bootstrap";
class News extends Component {
  render() {
    return (
      <Layout {...this.props}>
        <div id="privacy" className="pt-5">
          <Fade top>
            <div className="pt-3 mt-1 newsbg">
              <Container fluid>
                <div>
                  <Breadcrumb className="newsbreadcrumb">
                    <Breadcrumb.Item
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/`);
                      }}
                    >
                      Home
                    </Breadcrumb.Item>

                    <Breadcrumb.Item active>News</Breadcrumb.Item>
                  </Breadcrumb>
                </div>

                <h1>News</h1>
              </Container>
            </div>
          </Fade>
          <div id="newsinfo" className="pt-5">
            <Container fluid>
              <div className="newsbox">
                <Row>
                  <Col md="4">
                    <Card className="news-card">
                      <Card.Body className="p-0">
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/NewsDetail`);
                          }}
                        >
                          <blockquote className="blockquote mb-0 newsimg">
                            <Fade top duration={1000}>
                              <img src={cricket} alt="Cricket"></img>
                            </Fade>
                            <div className="newsinfo">
                              <h6>
                                20 January 2021 |{" "}
                                <i className="fa fa-newspaper-o"></i> &nbsp;News
                              </h6>
                              <p>
                                {" "}
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Integer posuere erat a ante.{" "}
                              </p>
                            </div>
                            {/* <footer className="blockquote-footer">
                           
                          </footer> */}
                          </blockquote>
                        </a>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md="4">
                    <Card className="news-card">
                      <Card.Body className="p-0">
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/NewsDetail`);
                          }}
                        >
                          <blockquote className="blockquote mb-0 newsimg">
                            <Fade top duration={1000}>
                              <img src={ground} alt="ground"></img>
                            </Fade>
                            <div className="newsinfo">
                              <h6>
                                20 January 2021 |{" "}
                                <i className="fa fa-newspaper-o"></i> &nbsp;News
                              </h6>
                              <p>
                                {" "}
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Integer posuere erat a ante.{" "}
                              </p>
                            </div>
                            {/* <footer className="blockquote-footer">
                           
                          </footer> */}
                          </blockquote>
                        </a>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md="4">
                    <Card className="news-card">
                      <Card.Body className="p-0">
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/NewsDetail`);
                          }}
                        >
                          <blockquote className="blockquote mb-0 newsimg">
                            <Fade top duration={1000}>
                              <img src={stadium} alt="stadium"></img>
                            </Fade>
                            <div className="newsinfo">
                              <h6>
                                20 January 2021 |{" "}
                                <i className="fa fa-newspaper-o"></i> &nbsp;News
                              </h6>
                              <p>
                                {" "}
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Integer posuere erat a ante.{" "}
                              </p>
                            </div>
                            {/* <footer className="blockquote-footer">
                           
                          </footer> */}
                          </blockquote>
                        </a>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col md="4">
                    <Card className="news-card">
                      <Card.Body className="p-0">
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/NewsDetail`);
                          }}
                        >
                          <blockquote className="blockquote mb-0 newsimg">
                            <Fade top duration={1000}>
                              <img src={stadium1} alt="stadium1"></img>
                            </Fade>
                            <div className="newsinfo">
                              <h6>
                                20 January 2021 |{" "}
                                <i className="fa fa-newspaper-o"></i> &nbsp;News
                              </h6>
                              <p>
                                {" "}
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Integer posuere erat a ante.{" "}
                              </p>
                            </div>
                            {/* <footer className="blockquote-footer">
                           
                          </footer> */}
                          </blockquote>
                        </a>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md="4">
                    <Card className="news-card">
                      <Card.Body className="p-0">
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/NewsDetail`);
                          }}
                        >
                          <blockquote className="blockquote mb-0 newsimg">
                            <Fade top duration={1000}>
                              <img src={man} alt="man"></img>
                            </Fade>
                            <div className="newsinfo">
                              <h6>
                                20 January 2021 |{" "}
                                <i className="fa fa-newspaper-o"></i> &nbsp;News
                              </h6>
                              <p>
                                {" "}
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Integer posuere erat a ante.{" "}
                              </p>
                            </div>
                            {/* <footer className="blockquote-footer">
                           
                          </footer> */}
                          </blockquote>
                        </a>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md="4">
                    <Card className="news-card">
                      <Card.Body className="p-0">
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/NewsDetail`);
                          }}
                        >
                          <blockquote className="blockquote mb-0 newsimg">
                            <Fade top duration={1000}>
                              <img src={ground1} alt="ground1"></img>
                            </Fade>
                            <div className="newsinfo">
                              <h6>
                                20 January 2021 |{" "}
                                <i className="fa fa-newspaper-o"></i> &nbsp;News
                              </h6>
                              <p>
                                {" "}
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Integer posuere erat a ante.{" "}
                              </p>
                            </div>
                            {/* <footer className="blockquote-footer">
                           
                          </footer> */}
                          </blockquote>
                        </a>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col md="4">
                    <Card className="news-card">
                      <Card.Body className="p-0">
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/NewsDetail`);
                          }}
                        >
                          <blockquote className="blockquote mb-0 newsimg">
                            <Fade top duration={1000}>
                              <img src={batman} alt="stadium"></img>
                            </Fade>
                            <div className="newsinfo">
                              <h6>
                                20 January 2021 |{" "}
                                <i className="fa fa-newspaper-o"></i> &nbsp;News
                              </h6>
                              <p>
                                {" "}
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Integer posuere erat a ante.{" "}
                              </p>
                            </div>
                            {/* <footer className="blockquote-footer">
                           
                          </footer> */}
                          </blockquote>
                        </a>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md="4">
                    <Card className="news-card">
                      <Card.Body className="p-0">
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/NewsDetail`);
                          }}
                        >
                          <blockquote className="blockquote mb-0 newsimg">
                            <Fade top duration={1000}>
                              <img src={man1} alt="News 1"></img>
                            </Fade>
                            <div className="newsinfo">
                              <h6>
                                20 January 2021 |{" "}
                                <i className="fa fa-newspaper-o"></i> &nbsp;News
                              </h6>
                              <p>
                                {" "}
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Integer posuere erat a ante.{" "}
                              </p>
                            </div>
                            {/* <footer className="blockquote-footer">
                           
                          </footer> */}
                          </blockquote>
                        </a>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
        </div>
      </Layout>
    );
  }
}

export default News;

import React, { Component } from "react";
import { Container, Form, Row, Col, Button, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { emailRegExp, MobileRegx, pincodeReg } from "@/_helpers";
import { authenticationService } from "@/services/api_function";
import Notifications, { notify } from "react-notify-toast";

export default class LoginOnShipping extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="">
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{
            username: "",
            password: "",
          }}
          validationSchema={Yup.object().shape({
            username: Yup.string()
              .trim()
              .matches(MobileRegx, "Mobile No. invalid")
              .required("Mobile No. is required"),
            password: Yup.string().trim().required("Password is required"),
          })}
          onSubmit={(value, { setSubmitting, resetForm }) => {
            // console.log("value", value);
            setSubmitting(true);
            authenticationService
              .login(value)
              .then((response) => {
                setSubmitting(false);

                if (response.status == "OK") {
                  localStorage.setItem("authenticationService", response.token);
                  notify.show("Login Done!", "success", 2000);
                  resetForm();
                  window.location.reload();
                } else {
                  setSubmitting(false);
                  if (response.responseStatus == 404) {
                    notify.show(response.message, "error", 2000);
                  } else {
                    notify.show(
                      "Server Error! Please Check Your Connectivity",
                      "error",
                      2000
                    );
                  }
                }
              })
              .catch((error) => {
                console.log("error", error);
                notify.show("Authentication Failed, Try again!", "error", 1200);
                setSubmitting(false);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            resetForm,
          }) => (
            <Form
              onSubmit={handleSubmit}
              noValidate
              autoComplete="off"
              className="shipping-add-form"
            >
              {/* <div className="guestlogin">
                <h6>Continue as Guest or Login</h6>
              </div> */}
              <Row>
                <Col md="6">
                  <Form.Group>
                    <Form.Label htmlFor="username">Mobile No..</Form.Label>
                    <Form.Control
                      type="text"
                      name="username"
                      id="username"
                      onChange={handleChange}
                      value={values.username}
                      isValid={touched.username && !errors.username}
                      isInvalid={!!errors.username}
                      maxLength={10}
                      placeholder="Mobile No."
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.username}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group>
                    <Form.Label htmlFor="password">Password</Form.Label>
                    <Form.Control
                      type="password"
                      name="password"
                      id="password"
                      onChange={handleChange}
                      value={values.password}
                      isValid={touched.password && !errors.password}
                      isInvalid={!!errors.password}
                      placeholder="****"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col>
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={isSubmitting}
                    className="kit-sub-btn rainbow rainbow-1 btn"
                  >
                    {isSubmitting ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        <span className="sr-only">Loading...</span>
                      </>
                    ) : (
                      "Login"
                    )}
                  </Button>
                  <Button
                    variant="primary"
                    disabled={isSubmitting}
                    className="kit-sub-btn loginback ml-2"
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.handleLoginShow(false);
                    }}
                  >
                    {isSubmitting ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        <span className="sr-only">Loading...</span>
                      </>
                    ) : (
                      "Back"
                    )}
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

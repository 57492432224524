import config from "config";

export function groupListUrl() {
  return `${config.apiUrl}/group-list`;
}

export function getHeaderGroupListUrl() {
  return `${config.apiUrl}/list-groups-subgroup`;
}

export function productListByGroupUrl() {
  return `${config.apiUrl}/productListByGroup`;
}

export function groupProductLitFiltersUrl() {
  return `${config.apiUrl}/groupProductLitFilters`;
}

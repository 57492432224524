import React, { Component } from "react";
import { Layout } from "@/Pages/Layout";
import Zoom from "react-reveal/Zoom";
import {
  Accordion,
  Container,
  Card,
  Row,
  Col,
  Breadcrumb,
} from "react-bootstrap";

import { listOfFAQs } from "@/services/api_function";

import "./faq.css";
import mailquery from "@/assets/images/mail.png";

export default class FAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lstOfFaqs: [],
      activeId: 0,
    };
  }

  listOfFAQsFun = () => {
    listOfFAQs()
      .then((response) => {
        let res = response.data;
        if (res.responseStatus == 200) {
          let faq_list = res.responseObject;
          this.setState({
            lstOfFaqs: faq_list,
            activeId: faq_list[0]["faqId"],
          });
        }
      })
      .catch((error) => {
        this.setState({ lstOfFaqs: [] });
        console.log({ error });
      });
  };

  componentDidMount() {
    this.listOfFAQsFun();
  }
  handleOnSelect = (key) => {
    this.setState({ activeId: key });
  };

  render() {
    const { lstOfFaqs, activeId } = this.state;
    return (
      <Layout {...this.props}>
        <div id="faq" className="pt-4 mt-3">
          <div className="f-question">
            <Container fluid>
              <Breadcrumb className="rgst-breadcrumb pt-3">
                <Breadcrumb.Item
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.history.push(`/`);
                  }}
                >
                  Home
                </Breadcrumb.Item>

                <Breadcrumb.Item active>FAQ</Breadcrumb.Item>
              </Breadcrumb>

              <h1>Freqently Asked Question</h1>
              <p>Browse through the most frequently asked question</p>
            </Container>
          </div>
          <div className="faq-info">
            <Container fluid>
              {lstOfFaqs.length > 0 &&
                lstOfFaqs.map((v, i) => {
                  return (
                    <Accordion
                      defaultActiveKey={activeId}
                      activeKey={activeId}
                      onSelect={(k) => {
                        this.handleOnSelect(k);
                      }}
                      className="accordioncard"
                    >
                      <Card>
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey={v.faqId}
                          className="accordionfaq"
                        >
                          <span className="pull-left">1. {v.question}?</span>
                          <span className="faqplus pull-right">+</span>
                        </Accordion.Toggle>
                        <Accordion.Collapse
                          show
                          eventKey={v.faqId}
                          className="accordionfaq"
                        >
                          <Card.Body>{v.answer}</Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  );
                })}
            </Container>
          </div>
          <div className="query pt-4">
            <Container fluid>
              <Row>
                <Col md="3"></Col>
                <Col md="2">
                  <div>
                    <Zoom>
                      <img src={mailquery} alt="Mail Query"></img>
                    </Zoom>
                  </div>
                </Col>
                <Col md="4">
                  <div className="querymail">
                    <h3>Can't find what you're looking for</h3>
                    <p>
                      Email our query to{" "}
                      <a href="mailto:helpdesk@m3sports.com">
                        helpdesk@m3sports.com
                      </a>
                    </p>
                  </div>
                </Col>
                <Col md="3"></Col>
              </Row>
            </Container>
          </div>
        </div>
      </Layout>
    );
  }
}

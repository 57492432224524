import React from "react";
// import Icon from "@material-ui/core/Icon";
// import { NavLink, Link } from "react-router-dom";
//import { Route, Switch } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import Fade from "react-reveal/Fade";
// import Zoom from "react-reveal/Zoom";
import header from "./footer.css";
import support from "@/assets/images/support.png";
import safe from "@/assets/images/safe.png";
import ScrollToTop from "@/Pages/Components/ScrollToTop/ScrollToTop";
//import styled from "styled-components";
//import FontAwesome from "react-fontawesome";
//import rocket from "react-fontawesome";

const socialMediaLinks = [
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/ashutosh-hathidara-88710b138/",
    FontAwesome: "coffee", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
];

class Footer extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="footerbg">
        <div className="securepolicy">
          <Container fluid>
            <Row>
              <Col xs="12" md="4">
                <div className="box1 pt-3 pb-3">
                  <div className="boxleft">
                    <img src={support} alt="Shop With Ccnfidence"></img>
                  </div>
                  <div className="boxright pt-2 pl-2">
                    <h5>Shop with confidence</h5>
                    <p>Genuine products gaurantee</p>
                  </div>
                </div>
              </Col>
              <Col xs="12" md="4">
                <div className="box1 pt-3 pb-3">
                  <div className="boxleft">
                    <img src={support} alt="Shop With Ccnfidence"></img>
                  </div>
                  <div className="boxright pt-2 pl-2">
                    <h5>Online Support</h5>
                    <p>We support online 24*7 hours</p>
                  </div>
                </div>
              </Col>
              <Col xs="12" md="4">
                <div className="box1 pt-3 pb-3">
                  <div className="boxleft">
                    <img src={safe} alt="Shop With Ccnfidence"></img>
                  </div>
                  <div className="boxright pt-2 pl-2">
                    <h5>Safe Payments</h5>
                    <p>With 100% secure payments</p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Container fluid>
          <Row>
            <Col md="3">
              <div className="div1">
                <h5>CONTACT INFORMATION</h5>
                <address>
                  872, Bhandarkar Rd, Deccan Gymkhana, Pune, Maharashtra 411004
                  <br />
                  <div className="pt-2">
                    <a href="tel:919834810274" target="_blank">
                      {" "}
                      +91 98348 10274
                    </a>
                  </div>
                </address>
              </div>
            </Col>
            <Col md="3">
              <div className="div1">
                <h5>INFORMATION</h5>
                <Nav className="flex-column footerlink">
                  <Nav.Link
                    // href="/About"
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.history.push(`/About`);
                    }}
                  >
                    About Us
                  </Nav.Link>
                  <Nav.Link
                    // href="/MyCart"
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.history.push(`/MyCart`);
                    }}
                  >
                    Delivery
                  </Nav.Link>
                  <Nav.Link
                    // href="/faq"
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.history.push(`/faq`);
                    }}
                    eventKey="link-2"
                  >
                    FAQs
                  </Nav.Link>
                  <Nav.Link
                    // href="/privacypolicies"
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.history.push(`/privacypolicies`);
                    }}
                  >
                    Privacy Policies
                  </Nav.Link>
                  <Nav.Link
                    // href="/termsconditions"
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.history.push(`/termsconditions`);
                    }}
                  >
                    Terms and Conditions
                  </Nav.Link>
                </Nav>
              </div>
            </Col>
            <Col md="3">
              <div className="div1">
                <h5>CUSTOMER SERVICES</h5>
                <Nav className="flex-column footerlink">
                  <Nav.Link
                    // href="/Contact"
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.history.push(`/Contact`);
                    }}
                  >
                    Contact Us
                  </Nav.Link>
                  <Nav.Link
                    // href="/ReturnRefunds"
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.history.push(`/ReturnRefunds`);
                    }}
                  >
                    Return & Refunds
                  </Nav.Link>
                  {/* <Nav.Link eventKey="link-2">Search Terms</Nav.Link>
                    <Nav.Link>Payment Option</Nav.Link> */}
                </Nav>
              </div>
            </Col>
            <Col md="3">
              <div className="div1">
                <h5>My Account</h5>
                <Nav className="flex-column footerlink">
                  <Nav.Link
                    // href="/MyProfile"
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.history.push(`/Dashboard`);
                    }}
                  >
                    My Account
                  </Nav.Link>
                  <Nav.Link
                    // href="/MyOrder"
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.history.push(`/MyOrders`);
                    }}
                  >
                    Order History
                  </Nav.Link>
                  <Nav.Link
                    // href="/Mywishlist"
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.history.push(`/Mywishlist`);
                    }}
                  >
                    My Wishlist
                  </Nav.Link>
                </Nav>
              </div>
            </Col>
          </Row>
          <hr />

          <Row>
            <Col md="6">
              <div className="footerleft">
                <p>Copyright &copy; 2021 Ubhe Sports. All Rights Reserved</p>
              </div>
            </Col>
            <Col md="6">
              <div>
                <ScrollToTop />
                <ul className="s-media socialmedia">
                  <li>
                    <a className="menu__link" href="#0">
                      <i class="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a className="menu__link" href="#0">
                      <i class="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a className="menu__link" href="#0">
                      <i class="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a className="menu__link" href="#0">
                      <i class="fa fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
export default Footer;

import React, { Component } from "react";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";
import offer from "@/assets/images/offer.png";
import offer1 from "@/assets/images/offer1.png";
import offer11 from "@/assets/images/offer11.jpg";
import offer2 from "@/assets/images/offer2.png";
import offer3 from "@/assets/images/offer3.png";
import { Layout } from "@/Pages/Layout";

import {
  Row,
  Col,
  Button,
  Modal,
  Container,
  Breadcrumb,
} from "react-bootstrap";
class Offers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }
  render() {
    const { show } = this.state;
    return (
      <Layout {...this.props}>
        <div id="offers" className="pt-5">
          <Fade top>
            <div className="pt-3 mt-1 offerbg">
              <Container fluid>
                <div>
                  <Breadcrumb className="newsbreadcrumb">
                    <Breadcrumb.Item
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/`);
                      }}
                    >
                      Home
                    </Breadcrumb.Item>

                    <Breadcrumb.Item active>Offers</Breadcrumb.Item>
                  </Breadcrumb>
                </div>

                <h1>Offers</h1>
              </Container>
            </div>
          </Fade>

          <div id="newsinfo" className="pt-5">
            <Container fluid>
              <div className="newsbox">
                <h5>Latest offers from Ubhe Sports</h5>
                <Row>
                  <Col md="6">
                    <a
                      href="#."
                      onClick={(e) => {
                        this.setState({ show: true });
                      }}
                    >
                      <div className="offerdiv">
                        <Row>
                          <Col md="6">
                            <Zoom duration={2000}>
                              <img src={offer} alt="Offer"></img>
                            </Zoom>
                          </Col>
                          <Col md="6">
                            <div className="offerinfo">
                              <div className="pull-left">
                                <i className="fa fa-tags"></i> Offer
                              </div>
                              <div className="pull-right offer-expiry">
                                Expiring 15 March 2021
                              </div>
                              <p>
                                Amazing chashback on shopping online. Upto 50%
                                off.
                              </p>
                              {/* <Button className=" btnoffer">
                                CODE : GTY50PUNE
                              </Button> */}
                              <Button className="border1">
                                CODE : GTY50PUNE
                              </Button>

                              <Button className="getofferbtn">
                                Get This Offer
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </a>
                  </Col>
                  <Col md="6">
                    <div className="offerdiv">
                      <Row>
                        <Col md="6">
                          <Zoom duration={2000} delay="500">
                            <img src={offer1} alt="Offer"></img>
                          </Zoom>
                        </Col>
                        <Col md="6">
                          <div className="offerinfo">
                            <div className="pull-left">
                              <i className="fa fa-tags"></i> Offer
                            </div>
                            <div className="pull-right offer-expiry">
                              Expiring 15 March 2021
                            </div>
                            <p>
                              Amazing chashback on shopping online. Upto 50%
                              off.
                            </p>

                            <Button className="border1">
                              CODE : GTY50PUNE
                            </Button>
                            <Button className="getofferbtn">
                              Get This Offer
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="offerdiv">
                      <Row>
                        <Col md="6">
                          <Zoom duration={2000} delay="1000">
                            <img src={offer2} alt="Offer"></img>
                          </Zoom>
                        </Col>
                        <Col md="6">
                          <div className="offerinfo">
                            <div className="pull-left">
                              <i className="fa fa-tags"></i> Offer
                            </div>
                            <div className="pull-right offer-expiry">
                              Expiring 15 March 2021
                            </div>
                            <p>
                              Amazing chashback on shopping online. Upto 50%
                              off.
                            </p>
                            <Button className="border1">
                              CODE : GTY50PUNE
                            </Button>
                            <Button className="getofferbtn">
                              Get This Offer
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="offerdiv">
                      <Row>
                        <Col md="6">
                          <Zoom duration={2000} delay="1500">
                            <img src={offer3} alt="Offer"></img>
                          </Zoom>
                        </Col>
                        <Col md="6">
                          <div className="offerinfo">
                            <div className="pull-left">
                              <i className="fa fa-tags"></i> Offer
                            </div>
                            <div className="pull-right offer-expiry">
                              Expiring 15 March 2021
                            </div>
                            <p>
                              Amazing chashback on shopping online. Upto 50%
                              off.
                            </p>
                            <Button className="border1">
                              CODE : GTY50PUNE
                            </Button>
                            <Button className="getofferbtn">
                              Get This Offer
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
            <Modal
              show={show}
              size="xl"
              onHide={() => this.setState({ show: false })}
              //dialogClassName="modal-400w"
              dialogClassName="modal-90w"
              // aria-labelledby="example-modal-sizes-title-lg"
              aria-labelledby="example-custom-modal-styling-title"
              className="offermodal"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                  {/* {" "}
                  <div className="pull-right">colse</div> */}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="galleryumodal p-0">
                <div className="offerdiv offerpopup">
                  <Row>
                    <Col md="6">
                      <img src={offer11} alt="Offer"></img>
                    </Col>
                    <Col md="6">
                      <div className="offerinfo">
                        <div className="offerinfoleft">
                          <div className="pull-left">
                            <i className="fa fa-tags"></i> Offer
                          </div>
                          <div className="pull-right offer-expiry">
                            Expiring 15 March 2021
                          </div>
                          <p>
                            Amazing chashback on shopping online. Upto 50% off.
                          </p>
                          <Button className="border1">CODE : GTY50PUNE</Button>
                        </div>
                        <div className="offerbottom">
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's
                          </p>

                          <h6>How to Reedom this offer</h6>
                          <p>
                            Contrary to popular belief, Lorem Ipsum is not
                            simply random text.
                          </p>
                          <ul>
                            <li>
                              Neque porro quisquam est qui dolorem ipsum quia{" "}
                            </li>
                            <li>Lorem Ipsum has been the industry's </li>
                            <li>Neque porro quisquam </li>
                            <li>
                              Neque porro quisquam est qui dolorem ipsum quia{" "}
                            </li>
                            <li>
                              Neque porro quisquam est qui dolorem ipsum quia{" "}
                            </li>
                          </ul>
                        </div>
                        {/* <Button className="border1">
                              CODE : GTY50PUNE
                            </Button> */}
                        <Button className="rainbow rainbow-1">
                          Get This Offer
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Offers;

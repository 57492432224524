import config from "config";

export function subgroupListUrl() {
  return `${config.apiUrl}/subgroup-list`;
}

export function subgroupListByGroupUrl() {
  return `${config.apiUrl}/subgroup-list-group`;
}

export function subgroupFindUrl(id) {
  return `${config.apiUrl}/subgroup-details/${id}`;
}

export function subGroupProductListBySubgroupUrl(id) {
  return `${config.apiUrl}/subGroupProductListBySubgroup`;
}

export function subGroupsListForRepairsUrl() {
  return `${config.apiUrl}/subGroupsListForRepairs`;
}

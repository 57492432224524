import React, { Component } from "react";
import { Layout } from "@/Pages/Layout";
import {
  Col,
  Row,
  Breadcrumb,
  Container,
  Button,
  Tabs,
  Tab,
} from "react-bootstrap";
import {
  productListBySubgroupId,
  productListFilters,
} from "@/services/api_function";
import { matchPath } from "react-router-dom";

import { bindActionCreators } from "redux";
import { addToCart } from "@/redux/cart/Action";
import { connect } from "react-redux";
import ProductSidebar from "@/Pages/Components/ProductListComponent/ProductSidebar";
import { ProductCard } from "@/Pages/Components/ProductListComponent/ProductCard";
import { ProductCardListView } from "@/Pages/Components/ProductListComponent/ProductCardListView";
import NoProduct from "@/Pages/ProductList/NoProduct";
import CustomLoader from "@/Pages/Components/CustomLoader";

import "./productlist.css";

class ProductList extends Component {
  constructor(props) {
    super(props);
    this.divproductlist = React.createRef();
    this.state = {
      subGroupId: 0,

      lstproductList: [],
      subGroupDetails: "",
      groupDetails: "",

      subGroupData: "",
      selectedSubCategory: [],
      selectedCategory: [],
      selectedBrand: [],
      selectedFilters: [],

      brandList: [],
      categoryList: [],
      subCategoryList: [],
      filterList: [],

      isLoading: false,
      sidebarLoading: false,

      totalProducts: 0,
      pages: 0,
      currentPage: 1,
      pageLimit: 8,
    };
  }

  goToNextPage() {
    // not yet implemented
    let page = parseInt(this.state.currentPage);
    this.setState({ currentPage: page + 1 }, () => {
      this.getProductList();
    });
  }

  goToPreviousPage() {
    // not yet implemented
    let page = parseInt(this.state.currentPage);
    this.setState({ currentPage: page - 1 }, () => {
      this.getProductList();
    });
  }

  changePage = (event) => {
    // not yet implemented
    const pageNumber = Number(event.target.textContent);
    this.setState({ currentPage: pageNumber }, () => {
      this.getProductList();
    });
  };

  getProductList = () => {
    const {
      subGroupId,
      selectedCategory,
      selectedBrand,
      selectedFilters,
      selectedSubCategory,

      currentPage,
      pageLimit,
    } = this.state;

    let reqData = new FormData();
    reqData.append("pageNo", currentPage);
    reqData.append("pageSize", pageLimit);
    reqData.append("subGroupId", subGroupId);
    reqData.append(
      "selectedBrand",
      selectedBrand.length > 0 ? selectedBrand.join(",") : ""
    );

    reqData.append(
      "selectedCategory",
      selectedCategory.length > 0 ? selectedCategory.join(",") : ""
    );

    reqData.append(
      "selectedSubCategory",
      selectedSubCategory.length > 0 ? selectedSubCategory.join(",") : ""
    );

    let reqselectedFilters = selectedFilters.map((v) =>
      v.selectedSubfilters.length > 0 ? v.selectedSubfilters : ""
    );
    reqselectedFilters = reqselectedFilters.filter((v) => v != "");
    console.log(
      "reqselectedFilters.join ",
      reqselectedFilters.length > 0 ? reqselectedFilters.join(",") : ""
    );
    reqData.append("selectedFilters", reqselectedFilters.join(","));

    for (var pair of reqData.entries()) {
      console.log(pair[0] + "=> " + pair[1]);
    }

    this.setState({ isLoading: true });
    productListBySubgroupId(reqData)
      .then((response) => {
        let res = response.data;
        if (res.responseStatus == 200) {
          this.setState({
            subGroupDetails: res.responseObject.subGroupData,
            groupDetails: res.responseObject.groupData,
            lstproductList:
              res.responseObject.productList != null
                ? res.responseObject.productList.data
                : [],
            totalProducts:
              res.responseObject.productList != null
                ? res.responseObject.productList.total
                : 0,
            pages:
              res.responseObject.productList != null
                ? res.responseObject.productList.total_pages
                : 0,
            isLoading: false,
          });
        } else if (res.responseStatus == 404) {
          this.setState({
            subGroupDetails: res.responseObject.subGroupData
              ? res.responseObject.subGroupData
              : "",
            groupDetails: res.responseObject.groupData
              ? res.responseObject.groupData
              : "",
            totalProducts: 0,
            lstproductList: [],
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log("error", error);
      });
  };

  getProductFilterList = () => {
    const {
      subGroupId,
      selectedCategory,
      selectedBrand,
      selectedFilters,
      selectedSubCategory,
    } = this.state;
    let reqData = new FormData();
    reqData.append("subGroupId", subGroupId);
    reqData.append(
      "selectedBrand",
      selectedBrand.length > 0 ? selectedBrand.join(",") : ""
    );
    reqData.append(
      "selectedCategory",
      selectedCategory.length > 0 ? selectedCategory.join(",") : ""
    );
    reqData.append(
      "selectedSubCategory",
      selectedSubCategory.length > 0 ? selectedSubCategory.join(",") : ""
    );

    let reqselectedFilters = selectedFilters.map((v) =>
      v.selectedSubfilters.length > 0 ? v.selectedSubfilters : ""
    );
    reqselectedFilters = reqselectedFilters.filter((v) => v != "");
    console.log(
      "reqselectedFilters.join ",
      reqselectedFilters.length > 0 ? reqselectedFilters.join(",") : ""
    );
    reqData.append("selectedFilters", reqselectedFilters.join(","));

    this.setState({ sidebarLoading: true });
    productListFilters(reqData)
      .then((response) => {
        let res = response.data;
        if (res.responseStatus == 200) {
          let { brandList, categoryList, productFilters, subCategoryList } =
            res.responseObject;

          this.setState({
            brandList: brandList,
            categoryList: categoryList,
            subCategoryList: subCategoryList,
            filterList: productFilters,
            sidebarLoading: false,
          });
        } else {
          this.setState({ sidebarLoading: false });
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  componentDidMount() {
    this.setState({ subGroupId: this.props.match.params.id }, () => {
      this.getProductList();
      this.getProductFilterList();
    });
  }
  componentWillReceiveProps(next, prev) {
    if (matchPath(next.location.pathname, { path: "/ProductList/:id" })) {
      if (next.match.params.id !== this.props.match.params.id) {
        this.reloadProductList(next.match.params.id);
      }
    }
  }
  reloadProductList = (subCategoryId) => {
    this.setState({ subGroupId: subCategoryId }, () => {
      this.getProductList();
      this.getProductFilterList();
    });
  };
  updateSelectedBrands = (brandId, status) => {
    // console.log({ brandId });
    let arr = this.state.selectedBrand;
    if (status) {
      if (!arr.includes(brandId)) {
        arr.push(brandId);
      }
    } else {
      var index = arr.indexOf(brandId);
      if (index !== -1) {
        arr.splice(index, 1);
      }
    }
    this.setState({ selectedBrand: arr, currentPage: 1 }, () => {
      this.getProductList();
      this.getProductFilterList();
    });
  };

  handleSelectedCategory = (categoryId, status) => {
    // console.log({ categoryId });
    let arr = this.state.selectedCategory;
    if (status) {
      if (!arr.includes(categoryId)) {
        // console.log({ arr });
        arr.push(categoryId);
      }
    } else {
      var index = arr.indexOf(categoryId);
      if (index !== -1) {
        arr.splice(index, 1);
      }
    }
    this.setState({ selectedCategory: arr, currentPage: 1 }, () => {
      this.getProductList();
      this.getProductFilterList();
    });
  };

  handleSelectedSubCategory = (subCategoryId, status) => {
    // console.log({ subCategoryId });
    let arr = this.state.selectedSubCategory;
    if (status) {
      if (!arr.includes(subCategoryId)) {
        arr.push(subCategoryId);
      }
    } else {
      var index = arr.indexOf(subCategoryId);
      if (index !== -1) {
        arr.splice(index, 1);
      }
    }
    this.setState({ selectedSubCategory: arr, currentPage: 1 }, () => {
      this.getProductList();
      this.getProductFilterList();
    });
  };

  handleSelectedFilters = (filterId, subFilterId, status) => {
    // console.log({ filterId, subFilterId, status });
    const { selectedFilters } = this.state;
    let upselectedFilters;
    if (status) {
      let isNew = true;
      upselectedFilters = selectedFilters.map((v) => {
        if (parseInt(v.filterId) == parseInt(filterId)) {
          isNew = false;
          let innerarr = v.selectedSubfilters;
          if (!innerarr.includes(subFilterId)) {
            innerarr.push(subFilterId);
          }
          v.selectedSubfilters = innerarr;
        }
        return v;
      });
      if (isNew == true) {
        let newFilter = {
          filterId: filterId,
          selectedSubfilters: [parseInt(subFilterId)],
        };
        upselectedFilters = [...selectedFilters, newFilter];
      }
    } else {
      // let isNew = true;
      upselectedFilters = selectedFilters.map((v) => {
        if (v.filterId == filterId) {
          // isNew = false;
          let innerarr = v.selectedSubfilters;
          var index = innerarr.indexOf(subFilterId);
          if (index !== -1) {
            innerarr.splice(index, 1);
          }
          v.selectedSubfilters = innerarr;
        }
        return v;
      });
    }
    this.setState(
      { selectedFilters: upselectedFilters, currentPage: 1 },
      () => {
        console.log(this.state.selectedFilters);
        this.getProductList();
        this.getProductFilterList();
      }
    );
  };

  render() {
    const {
      brandList,
      categoryList,
      subCategoryList,
      filterList,

      selectedBrand,
      selectedCategory,
      selectedSubCategory,
      lstproductList,
      isLoading,
      sidebarLoading,
      subGroupDetails,
      groupDetails,
      selectedFilters,

      totalProducts,
      currentPage,
      pages,
    } = this.state;

    return (
      <Layout {...this.props}>
        <div id="productlist1" className="prdct-list-bc">
          <div className="productlistbreadcrumb">
            <Container fluid>
              <Row>
                <Col md="3">
                  <Breadcrumb className="prdctlist profile-b-crumb shopbreadcrumb pt-0 pb-2">
                    <Breadcrumb.Item
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/`);
                      }}
                      style={{ color: "#1776a4" }}
                    >
                      Home
                    </Breadcrumb.Item>
                    <Breadcrumb.Item
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(
                          `/groupList/${groupDetails.groupId}`
                        );
                      }}
                      style={{ color: "#1776a4" }}
                    >
                      {groupDetails && groupDetails.groupName}
                    </Breadcrumb.Item>

                    <Breadcrumb.Item active>
                      {subGroupDetails && subGroupDetails.subGroupName}
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="catogrytitle">
            <Container fluid>
              <div className="headeingtab">
                <h1>
                  {groupDetails && groupDetails.groupName}{" "}
                  {subGroupDetails && subGroupDetails.subGroupName}
                </h1>
              </div>
            </Container>
          </div>

          <div className="filter-prdct">
            <Container
              fluid
              ref={this.divproductlist}
              className="containerfluid1"
            >
              {isLoading == false ||
              lstproductList.length > 0 ||
              selectedBrand.length > 0 ||
              selectedCategory.length > 0 ||
              selectedSubCategory.length > 0 ||
              selectedFilters.length > 0 ? (
                <Row>
                  <Col lg="2" md="4">
                    <ProductSidebar
                      lstproductList={lstproductList}
                      sidebarLoading={sidebarLoading}
                      brandList={brandList}
                      categoryList={categoryList}
                      subCategoryList={subCategoryList}
                      productFilters={filterList}
                      selectedBrand={selectedBrand}
                      selectedCategory={selectedCategory}
                      selectedSubCategory={selectedSubCategory}
                      selectedFilters={selectedFilters}
                      updateSelectedBrands={this.updateSelectedBrands.bind(
                        this
                      )}
                      handleSelectedCategory={this.handleSelectedCategory.bind(
                        this
                      )}
                      handleSelectedSubCategory={this.handleSelectedSubCategory.bind(
                        this
                      )}
                      handleSelectedFilters={this.handleSelectedFilters.bind(
                        this
                      )}
                      {...this.props}
                    />
                  </Col>

                  <Col lg="10" md="8" style={{ paddingLeft: "1px" }}>
                    <div className="tabgrid">
                      <Tabs
                        defaultActiveKey="prdctgrid"
                        transition={false}
                        id="noanim-tab-example"
                        className="mb-3 pagenxt"
                      >
                        <Tab
                          eventKey="prdctgrid"
                          title={
                            <span>
                              <i className="fa fa-th-large" />{" "}
                            </span>
                          }
                        >
                          {isLoading ? (
                            <CustomLoader />
                          ) : lstproductList && lstproductList.length == 0 ? (
                            <NoProduct />
                          ) : (
                            <Row>
                              {lstproductList.map((data, idx) => (
                                <ProductCard
                                  {...this.props}
                                  key={idx}
                                  productDetails={data}
                                />
                              ))}
                            </Row>
                          )}
                        </Tab>
                        <Tab
                          eventKey="prdctlist"
                          title={
                            <span>
                              <i className="fa fa-th-list" />{" "}
                            </span>
                          }
                        >
                          {isLoading ? (
                            <CustomLoader />
                          ) : lstproductList && lstproductList.length == 0 ? (
                            <NoProduct />
                          ) : (
                            <Row>
                              {lstproductList.map((data, idx) => (
                                <ProductCardListView
                                  {...this.props}
                                  key={idx}
                                  productDetails={data}
                                />
                              ))}
                            </Row>
                          )}
                        </Tab>
                        <Tab
                          // eventKey={"prdcttotal"}
                          tabClassName="prdcttot"
                          disabled
                          title={
                            <span className={"headerClass"}>
                              <strong className="totprdct">
                                {totalProducts}
                              </strong>{" "}
                              Products
                            </span>
                          }
                        ></Tab>
                        <Tab
                          tabClassName="prdctprev"
                          // eventKey={"prdctprev"}
                          title={
                            currentPage == 1 ? (
                              <Button type="button" disabled>
                                Prev <span> &nbsp;&nbsp;| </span>
                              </Button>
                            ) : (
                              <Button
                                type="button"
                                onClick={this.goToPreviousPage.bind(this)}
                              >
                                Prev <span> &nbsp;&nbsp;| </span>
                              </Button>
                            )
                          }
                        ></Tab>
                        <Tab
                          //eventKey={"prdctnext"}
                          tabClassName="prdctnxt"
                          title={
                            currentPage === pages ? (
                              <Button type="button" disabled>
                                Next &nbsp;
                              </Button>
                            ) : (
                              <Button
                                type="button"
                                onClick={this.goToNextPage.bind(this)}
                              >
                                Next &nbsp;
                              </Button>
                            )
                          }
                        ></Tab>
                      </Tabs>
                    </div>
                  </Col>
                </Row>
              ) : (
                <CustomLoader />
              )}
            </Container>
          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = ({ cartReducer }) => {
  return { cartReducer };
};

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      addToCart,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapActionsToProps)(ProductList);

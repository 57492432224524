import {
  addToWishlistUrl,
  findProductInWishlistUrl,
  fetchWishlistProductsUrl,
} from "@/services/api";
import axios from "axios";
import { getHeader } from "@/_helpers";

export function addToWishlist(values) {
  return axios({
    url: addToWishlistUrl(),
    method: "POST",
    data: values,
    headers: getHeader(),
  });
}

export function findProductInWishlist(values) {
  return axios({
    url: findProductInWishlistUrl(),
    method: "POST",
    data: values,
    headers: getHeader(),
  });
}

export function fetchWishlistProducts() {
  return axios({
    url: fetchWishlistProductsUrl(),
    method: "GET",
    headers: getHeader(),
  });
}

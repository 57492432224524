import React, { Component } from "react";
import { Row, Nav, Col } from "react-bootstrap";
import ReactImageMagnify from "react-image-magnify";
import Carousel from "react-elastic-carousel";

import "./app1.css";
import Item from "./item";

const breakPoints = [
  { width: 1, itemsToShow: 4 },
  { width: 550, itemsToShow: 2, itemsToScroll: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 1200, itemsToShow: 4 },
];
export default class ProductZoom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productDetails: "",
      imageList: "",
      zoomImage: "",
      activeImage: 0,
    };
  }

  componentDidMount() {
    // console.log("props", this.props);
    this.setState({
      // productDetails: this.props,
      imageList: this.props.productDetails.productImageList,
      zoomImage: this.props.productDetails.firstImage,
    });
  }
  componentWillReceiveProps(prevProps) {
    // console.log("props", prevProps);
    this.setState({
      // productDetails: prevProps.productDetails,
      imageList: prevProps.productDetails.productImageList,
      zoomImage: prevProps.productDetails.firstImage,
    });
  }
  render() {
    let { imageList, zoomImage, activeImage } = this.state;
    return (
      <div id="productzoom">
        {/* <Row>
          <Col md={3}>
            
          </Col>
          <Col md={9}> */}
        <div className="fluid">
          <div className="fluid__image-container">
            <ReactImageMagnify
              {...{
                smallImage: {
                  alt: "Wristwatch by Ted Baker London",
                  isFluidWidth: true,
                  src: zoomImage ? zoomImage : "",
                  width: 2000,
                  srcSet: this.srcSet,
                  sizes:
                    "(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px",
                },
                largeImage: {
                  src: zoomImage ? zoomImage : "",
                  //width: 1500,
                  width: 1063,
                  //height: 1800,
                  height: 1278,
                },
                enlargedImageContainerDimensions: {
                  width: "250%",
                  height: "121%",
                },
              }}
            />
          </div>

          <div style={{ height: "330px" }} className="productzoomheight" />
        </div>
        {imageList && (
          <Carousel
            // verticalMode
            itemsToShow={4}
            breakPoints={breakPoints}
            className="rectcarousel-arrow thumbcarousel"
          >
            {imageList &&
              imageList.map((image, i) => (
                <Item
                  onClick={(e) => {
                    e.preventDefault();
                    // console.log("image clicked");
                    this.setState({
                      zoomImage: image.imagePath,
                      activeImage: i,
                    });
                  }}
                >
                  <Nav.Link
                    className={`capzoom 
                      ${activeImage == i ? "activethumb" : "disblethumb"}
                    `}
                  >
                    <img src={image.thumbImagePath} disable></img>
                  </Nav.Link>
                </Item>
              ))}
          </Carousel>
        )}
        {/* <div className="">
          <ul>
            <li>
              <a href="">
                <img src={image.imagePath} alt="mrf"></img>
              </a>
            </li>
          </ul>

        </div> */}
        {/* </Col>
        </Row> */}
      </div>
    );
  }
}

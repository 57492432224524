import React, { Component, useState } from "react";
import { Container, Button, Nav } from "react-bootstrap";
import Item from "./item";
import Carousel from "react-elastic-carousel";

import { bindActionCreators } from "redux";
import { addToCart } from "@/redux/cart/Action";
import { connect } from "react-redux";
import { similarProductListByCategory } from "@/services/api_function";
import { printSimilarProductName } from "@/_helpers";

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2, itemsToScroll: 2 },
  { width: 768, itemsToShow: 4 },
  { width: 1200, itemsToShow: 4 },
];

// const { index, direction } = this.state;
class SimilarProduct extends Component {
  constructor(props) {
    super(props);
    this.state = { productId: "", categoryId: "", lstSimilarProducts: "" };
  }

  getSimilarProductList = () => {
    let reqData = new FormData();
    reqData.append("categoryId", this.state.categoryId);
    reqData.append("productId", this.state.productId);
    similarProductListByCategory(reqData)
      .then((response) => {
        let res = response.data;
        if (res.responseStatus == 200) {
          // console.log("res.responseObject ", res.responseObject);
          this.setState({
            lstSimilarProducts: res.responseObject,
          });
        }
      })
      .catch((error) => {
        this.setState({ lstSimilarProducts: "" });
        console.log("error", error);
      });
  };

  componentDidMount() {
    // console.log(" this.props  ", this.props);
    this.setState(
      {
        productId: this.props.productId,
        categoryId: this.props.categoryId,
      },
      () => {
        this.getSimilarProductList();
      }
    );
  }

  componentWillReceiveProps(prevProps) {
    // console.log("props", prevProps);
    this.setState(
      {
        productId: prevProps.productId,
        categoryId: prevProps.categoryId,
      },
      () => {
        this.getSimilarProductList();
      }
    );
  }

  render() {
    const { lstSimilarProducts } = this.state;
    return (
      <div id="similarproduct">
        {lstSimilarProducts.length > 0 && (
          <Container fluid>
            <h3>SIMILAR PRODUCTS</h3>
            <Carousel itemsToShow={4} breakPoints={breakPoints}>
              {lstSimilarProducts &&
                lstSimilarProducts.map((product) => (
                  <Item>
                    <Nav.Link
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(
                          `/ProductDetail/${product.productId}`
                        );
                      }}
                    >
                      <div className="productitem similar-prdct-img">
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(
                              `/ProductDetail/${product.productId}`
                            );
                          }}
                        >
                          <img
                            src={product.firstImage}
                            alt={product.productName}
                          ></img>
                        </a>
                        <div className="productitem-info similar-prdct-cart">
                          <h5>
                            {printSimilarProductName(product.productName)}
                          </h5>
                          <h6>
                            <i className="fa fa-inr"></i>{" "}
                            {parseFloat(product.mrp).toFixed(2)} /-&nbsp;&nbsp;
                            {product.discountType != "none" && (
                              <s>
                                <i className="fa fa-inr"></i>{" "}
                                {parseFloat(product.basePrice).toFixed(2)} /-
                              </s>
                            )}
                            <br />
                            <a
                              href="javascript:void(0)"
                              onClick={() => {
                                this.props.addToCartSimilarFN({
                                  id: product.productId,
                                  name: product.productName,
                                  price: product.basePrice,
                                  mrp: product.mrp,
                                  discountType: product.discountType,
                                  discount: product.discount,
                                  unit: 1,
                                  brandName: product.brandName,
                                  firstImage: product.firstImage,
                                  thumbImage: product.thumbImage,
                                  discountInPercentage:
                                    product.discountInPercentage,
                                  discountInAmount: product.discountInAmount,
                                  filters: product.filterPriceDTOList[0],
                                });
                              }}
                            >
                              Add To Cart
                            </a>
                          </h6>
                        </div>
                      </div>
                    </Nav.Link>
                  </Item>
                ))}
            </Carousel>
          </Container>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ cartReducer }) => {
  return { cartReducer };
};

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      addToCart,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapActionsToProps)(SimilarProduct);
// export default SimilarProduct;

import React, { Component, useState } from "react";
import { Fade, Slide } from "react-reveal";
import { Container, Modal, Button, Carousel, Row, Col } from "react-bootstrap";

import pic2 from "@/assets/images/pic2.png";
import pic3 from "@/assets/images/pic3.png";
import pic4 from "@/assets/images/pic4.png";
import pic5 from "@/assets/images/pic5.png";
import "./aboutstyle.css";
class AboutGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  render() {
    const { show } = this.state;
    return (
      <div id="aboutgallery">
        <Row>
          <Col md="3">
            <Fade top>
              <div className="pic1">
                <a
                  //   href=""
                  onClick={() => {
                    this.setState({ show: true });
                  }}
                >
                  <img src={pic2} alt="Pic1"></img>
                </a>
              </div>
            </Fade>
          </Col>
          <Col md="3">
            <Fade bottom>
              <div className="pic2">
                <a
                  //   href=""
                  onClick={() => {
                    this.setState({ show: true });
                  }}
                >
                  <img src={pic3} alt="Pic1"></img>
                </a>
              </div>
            </Fade>
          </Col>
          <Col md="3">
            <Fade top>
              <div className="pic3">
                <a
                  //   href=""
                  onClick={() => {
                    this.setState({ show: true });
                  }}
                >
                  <img src={pic3} alt="Pic1"></img>
                </a>
              </div>
            </Fade>
          </Col>
          <Col md="3">
            <Fade bottom>
              <div className="pic4">
                <a
                  //   href=""
                  onClick={() => {
                    this.setState({ show: true });
                  }}
                >
                  <img src={pic5} alt="Pic1"></img>
                </a>
              </div>
            </Fade>
          </Col>

          <Modal
            show={show}
            onHide={() => this.setState({ show: false })}
            dialogClassName="modal-400w"
            // aria-labelledby="example-custom-modal-styling-title"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            {/* <Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title">
                Custom Modal Styling
              </Modal.Title>
            </Modal.Header> */}
            <Modal.Body className="galleryumodal p-0">
              <Carousel
                // activeIndex={index}
                // direction={direction}
                onSelect={this.handleSelect}
              >
                <Carousel.Item>
                  <img className="d-block w-100" src={pic2} alt="First slide" />
                  <Carousel.Caption className="gallerycaption">
                    <Slide bottom>
                      <h3>First slide label</h3>
                    </Slide>

                    {/* <p>
                      Nulla vitae elit libero, a pharetra augue mollis interdum.
                    </p> */}
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" src={pic3} alt="Third slide" />

                  <Carousel.Caption className="gallerycaption">
                    <Slide bottom>
                      <h3>Second slide label</h3>
                    </Slide>
                    {/* <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </p> */}
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" src={pic4} alt="Third slide" />

                  <Carousel.Caption className="gallerycaption">
                    <Slide bottom>
                      <h3>Third slide label</h3>
                    </Slide>
                    {/* <p>
                      Praesent commodo cursus magna, vel scelerisque nisl
                      consectetur.
                    </p> */}
                  </Carousel.Caption>
                </Carousel.Item>
              </Carousel>
            </Modal.Body>
          </Modal>
        </Row>

        <div className="fact">
          <Container fluid>
            <h4>INTERESTING FACTS</h4>

            <div className="factdiv">
              <h4>3000+</h4>
              <p>Happy Customers</p>
              <div class="factdivborder"></div>
            </div>
            <div className="factdiv">
              <h4>50+</h4>
              <p>Product Variations</p>
              <div class="factdivborder"></div>
            </div>
            <div className="factdiv">
              <h4>100%</h4>
              <p>Genuene Product</p>
            </div>
          </Container>
        </div>
      </div>
    );
  }
}

export default AboutGallery;

import React, { Component } from "react";
import { SidebarLayout } from "@/Pages/Layout";
import Slide from "react-reveal/Slide";
import moment from "moment";
import Notifications, { notify } from "react-notify-toast";
import { Col, Row } from "react-bootstrap";
import { fetchUserOrders } from "@/services/api_function";
import NoOrder from "./NoOrder";
class OrderSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ordersList: [],
    };
  }

  fetchUserOrdersFun = () => {
    fetchUserOrders()
      .then((response) => {
        // console.log({ response });
        let res = response.data;
        if (res.responseStatus == 200) {
          this.setState({ ordersList: res.responseObject });
        } else {
          notify.show(res.message, "error", 2000);
        }
      })
      .catch((error) => {
        console.log({ error });
      });
  };

  componentDidMount() {
    this.fetchUserOrdersFun();
  }

  render() {
    const { ordersList } = this.state;
    return (
      <SidebarLayout
        breadcrum_parent_name_url="/Dashboard"
        breadcrum_parent_name="My Account"
        breadcrum_child_name="My Orders"
        {...this.props}
      >
        <Notifications options={{ zIndex: 9999, top: "50px" }} />
        <Slide right>
          <div>
            {/* {JSON.stringify(ordersList)} */}
            <div className="ordsummery-top">
              {ordersList.length > 0 ? (
                ordersList.map((order) => {
                  return (
                    <div className="accountinfo">
                      <div className="ordersummery">
                        <Row>
                          <Col md="5">
                            <a
                              onClick={(e) => {
                                e.preventDefault();
                                this.props.history.push(
                                  `/orderDetails/${order.id}`
                                );
                              }}
                            >
                              <div className="order-s">
                                <h6>
                                  {" "}
                                  <i className="fa fa-gift"></i> ORDERID
                                  {order.orderNo}
                                  <span>
                                    {" "}
                                    &nbsp;&nbsp;| Order On{" "}
                                    {moment(order.orderDate).format(
                                      "ddd D MMM YYYY"
                                    )}
                                  </span>
                                </h6>
                                <p>
                                  Check Order Details &nbsp;
                                  <i className="fa fa-angle-double-right"></i>
                                </p>
                              </div>
                            </a>
                          </Col>
                          <Col md="4">
                            <div className="order-s order-d">
                              <h6>
                                Delivered on:{" "}
                                {order.deliveryDate != ""
                                  ? moment(order.deliveryDate).format(
                                      "ddd D MMM YYYY HH:mm"
                                    )
                                  : "-"}
                              </h6>
                              <p>
                                {"Your order has been " +
                                  " " +
                                  order.orderStatus}
                              </p>
                            </div>
                          </Col>
                          <Col md="3">
                            <div className="order-track">
                              <h5>
                                Payment Mode: {order.paymentMode.toUpperCase()}
                              </h5>
                              <p>
                                <i className="fa fa-inr"></i>{" "}
                                {parseFloat(order.netPrice).toFixed(2)}
                                /-{" "}
                                {order.netPrice > 0 &&
                                order.grandTotalDiscount != 0 ? (
                                  <span>
                                    &nbsp;
                                    <i className="fa fa-inr"></i>
                                    {order.grandTotalDiscount.toFixed(2)}
                                    &nbsp;Off
                                  </span>
                                ) : (
                                  ""
                                )}
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  );
                })
              ) : (
                <NoOrder {...this.props} />
              )}
            </div>
          </div>
        </Slide>
      </SidebarLayout>
    );
  }
}

export default OrderSummary;

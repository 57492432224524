import React, { Component } from "react";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import { Timeline, TimelineEvent } from "react-event-timeline";
import pic4 from "@/assets/images/pic4.png";
import { Layout } from "@/Pages/Layout";
class Journey extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Layout {...this.props}>
        <div id="productlist" className="cartproduct-list">
          <div className="mycartproduct myjourneybread">
            <Container fluid>
              <Row>
                <Col md="3">
                  <Breadcrumb className="profile-b-crumb rgst-breadcrumb pt-0">
                    <Breadcrumb.Item
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/`);
                      }}
                      style={{ color: "#1776a4" }}
                    >
                      Home
                    </Breadcrumb.Item>

                    <Breadcrumb.Item active>The Journey</Breadcrumb.Item>
                  </Breadcrumb>
                </Col>
              </Row>
            </Container>
          </div>
          <Container fluid>
            {/* <h2>Create New Customer Account</h2> */}

            <div className="px-auto">
              <div className="journey-h">
                <Fade top>
                  <h1>THE JOURNEY OF</h1>
                </Fade>
                <h1 className="pb-5">
                  <b>
                    <Fade bottom>UBHE SPORTS</Fade>
                  </b>
                </h1>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop
                </p>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop
                </p>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                </p>
              </div>
              <div className="timelinediv">
                <Timeline
                  class="time"
                  // /lineColor={{ borderColor: "#ff0000" }}"
                  lineStyle={{ background: "#1776a4", width: "2px" }}
                >
                  <TimelineEvent
                    className="timelinedate"
                    iconColor="#fff"
                    // lineColor="#ff0000"
                    bubbleStyle={{
                      backgroundColor: "#1776a4",
                      padding: "30px",
                      textAlign: "center",
                      marginLeft: "-18px",
                      boxShadow: "5px 2px 25px #ccc",
                      border: "5px solid #fff",
                    }}
                    icon={
                      <i
                        className="fa fa-flag fa-2x"
                        style={{ margin: "0x auto" }}
                      ></i>
                    }
                    style={{
                      paddingLeft: "50px",
                      boxShadow: "unset",
                      margin: "0x auto",
                    }}
                    iconStyle={{ marginLeft: "-10px" }}
                  >
                    <Row>
                      <Col md="7">
                        <div className="t-year">
                          <h4>2015</h4>
                          <h3>IT ALL STARTED</h3>
                          <p>
                            Formed In 2015, Ubhe sports helps to connect indian
                            cricket fans with the best possible cricket
                            equipment, accessories and attire.
                          </p>
                        </div>
                      </Col>
                      <Col md="5" className="imagetime">
                        <Zoom>
                          <img src={pic4} alt="" className="picture" />
                        </Zoom>
                      </Col>
                    </Row>
                  </TimelineEvent>

                  <TimelineEvent
                    className="timelinedate"
                    iconColor="#fff"
                    // lineColor="#ff0000"
                    bubbleStyle={{
                      backgroundColor: "#1776a4",
                      padding: "30px",
                      textAlign: "center",
                      marginLeft: "-18px",
                      boxShadow: "5px 2px 25px #ccc",
                      border: "5px solid #fff",
                    }}
                    icon={
                      <i
                        className="fa fa-flag fa-2x"
                        style={{ margin: "0x auto" }}
                      ></i>
                    }
                    style={{
                      paddingLeft: "50px",
                      boxShadow: "unset",
                      margin: "0x auto",
                    }}
                    iconStyle={{ marginLeft: "-10px" }}
                  >
                    <Row>
                      <Col md="7">
                        <div className="t-year">
                          <h4>2016</h4>
                          <h3>OPENED SECOND OUTLET</h3>
                          <p>
                            Formed In 2015, Ubhe sports helps to connect indian
                            cricket fans with the best possible cricket
                            equipment, accessories and attire.
                          </p>
                        </div>
                      </Col>
                      <Col md="5" className="imagetime">
                        <Zoom>
                          <img src={pic4} alt="" className="picture" />
                        </Zoom>
                      </Col>
                    </Row>
                  </TimelineEvent>
                  <TimelineEvent
                    className="timelinedate"
                    iconColor="#fff"
                    lineStyle={{
                      background: "#ff0000",
                      borderColor: "#ff0000",
                      color: "#ff0000",
                    }}
                    // lineColor="#ff0000"
                    bubbleStyle={{
                      backgroundColor: "#1776a4",
                      padding: "30px",
                      textAlign: "center",
                      marginLeft: "-18px",
                      boxShadow: "5px 2px 25px #ccc",
                      border: "5px solid #fff",
                    }}
                    icon={
                      <i
                        className="fa fa-flag fa-2x"
                        style={{ margin: "0x auto" }}
                      ></i>
                    }
                    style={{
                      paddingLeft: "50px",
                      boxShadow: "unset",
                      margin: "0x auto",
                    }}
                    iconStyle={{ marginLeft: "-10px" }}
                  >
                    <Row>
                      <Col md="7">
                        <div className="t-year">
                          <h4>2019</h4>
                          <h3>MOST POPLOUR IN SPORTS GEAR</h3>
                          <p>
                            Formed In 2015, Ubhe sports helps to connect indian
                            cricket fans with the best possible cricket
                            equipment, accessories and attire.
                          </p>
                        </div>
                      </Col>
                      <Col md="5" className="imagetime">
                        <Zoom>
                          <img src={pic4} alt="" className="picture" />
                        </Zoom>
                      </Col>
                    </Row>
                  </TimelineEvent>
                  <TimelineEvent
                    className="timelinedate"
                    iconColor="#fff"
                    bubbleStyle={{
                      backgroundColor: "#1776a4",
                      padding: "30px",
                      textAlign: "center",
                      marginLeft: "-18px",
                      boxShadow: "5px 2px 25px #ccc",
                      border: "5px solid #fff",
                    }}
                    icon={
                      <i
                        className="fa fa-flag fa-2x"
                        style={{ margin: "0x auto" }}
                      ></i>
                    }
                    style={{
                      paddingLeft: "50px",
                      boxShadow: "unset",
                      margin: "0x auto",
                    }}
                    iconStyle={{ marginLeft: "-10px" }}
                  >
                    <Row>
                      <Col md="7">
                        <div className="t-year">
                          <h4>2020</h4>
                          <h3>THE LEGACY CONTINUES</h3>
                          <p>
                            Formed In 2015, Ubhe sports helps to connect indian
                            cricket fans with the best possible cricket
                            equipment, accessories and attire.
                          </p>
                        </div>
                      </Col>
                      <Col md="5" className="imagetime">
                        <Zoom>
                          <img src={pic4} alt="" className="picture" />
                        </Zoom>
                      </Col>
                    </Row>
                  </TimelineEvent>
                </Timeline>
              </div>
            </div>
          </Container>
        </div>
      </Layout>
    );
  }
}

export default Journey;

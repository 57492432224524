import React, { Component } from "react";
import { Form, Row, Col, Button, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { MobileRegx } from "@/_helpers";
import { authenticationService } from "@/services/api_function";
import Notifications, { notify } from "react-notify-toast";

export default class ContinueAsGuest extends Component {
  render() {
    return (
      <div>
        <Notifications options={{ zIndex: 9999, top: "50px" }} />
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{
            mobileNo: "",
            firstName: "",
            lastName: "",
          }}
          validationSchema={Yup.object().shape({
            mobileNo: Yup.string()
              .trim()
              .matches(MobileRegx, "Mobile No. invalid")
              .required("Mobile No. is required"),
            firstName: Yup.string().trim().required("First Name is required"),
            lastName: Yup.string().trim().required("Last Name is required"),
          })}
          onSubmit={(value, { setSubmitting, resetForm }) => {
            console.log("value", value);
            setSubmitting(false);
            setSubmitting(true);
            authenticationService
              .loginGuest(value)
              .then((response) => {
                console.log("response", response);
                setSubmitting(false);

                if (response.status == "OK") {
                  localStorage.setItem("authenticationService", response.token);
                  notify.show("Login Done!", "success", 2000);
                  resetForm();
                  window.location.reload();
                } else {
                  setSubmitting(false);
                  if (response.responseStatus == 404) {
                    notify.show(response.message, "error", 2000);
                  } else {
                    notify.show(
                      "Server Error! Please Check Your Connectivity",
                      "error",
                      2000
                    );
                  }
                }
              })
              .catch((error) => {
                console.log("error", error);
                notify.show("Authentication Failed, Try again!", "error", 1200);
                setSubmitting(false);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            resetForm,
          }) => (
            <Form
              onSubmit={handleSubmit}
              noValidate
              autoComplete="off"
              className="shipping-add-form"
            >
              {/* <div className="guestlogin">
                <h6>Continue as Guest or Login</h6>
              </div> */}
              <Row>
                <Col md="4">
                  <Form.Group>
                    <Form.Label>Mobile No.</Form.Label>
                    <Form.Control
                      type="text"
                      name="mobileNo"
                      id="mobileNo"
                      onChange={handleChange}
                      value={values.mobileNo}
                      isValid={touched.mobileNo && !errors.mobileNo}
                      isInvalid={!!errors.mobileNo}
                      maxLength={10}
                      placeholder="Mobile No."
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.mobileNo}
                    </Form.Control.Feedback>{" "}
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group>
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="firstName"
                      id="firstName"
                      onChange={handleChange}
                      value={values.firstName}
                      isValid={touched.firstName && !errors.firstName}
                      isInvalid={!!errors.firstName}
                      placeholder="First Name"
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.firstName}
                    </Form.Control.Feedback>{" "}
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group>
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      placeholder="Last Name"
                      type="text"
                      name="lastName"
                      id="lastName"
                      onChange={handleChange}
                      value={values.lastName}
                      isValid={touched.lastName && !errors.lastName}
                      isInvalid={!!errors.lastName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.lastName}
                    </Form.Control.Feedback>{" "}
                  </Form.Group>
                </Col>

                <Col md="6">
                  <Form.Group></Form.Group>
                </Col>
              </Row>

              <div>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={isSubmitting}
                  className="kit-sub-btn rainbow rainbow-1 btn"
                >
                  {isSubmitting ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <span className="sr-only">Loading...</span>
                    </>
                  ) : (
                    "Submit"
                  )}
                </Button>
                <p className="alreadyacnt">
                  Already have an account ?{" "}
                  <a
                    href="#."
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.handleLoginShow(true);
                    }}
                  >
                    Login
                  </a>
                </p>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

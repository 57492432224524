import React, { Component } from "react";

import Fade from "react-reveal/Fade";
import { Container, Row, Col } from "react-bootstrap";

import newsaleimg from "@/assets/images/newsale.png";
import img1 from "@/assets/images/img1.png";
import insidecricket from "@/assets/images/insidecricket.png";
import "./offer.css";
import { authenticationService } from "@/services/api_function";
import { history } from "@/_helpers";
import Notifications, { notify } from "react-notify-toast";
import logogif from "@/assets/images/website_logo.png";
class Offers extends React.Component {
  constructor(props) {
    super(props);
  }

  // checkUserLoginFun = () => {
  //   let currentUser = authenticationService.currentUserValue;
  //   // console.log({ currentUser });
  //   if (currentUser != null) {
  //     // /RepaireSportsKit
  //     history.push(`/RepaireSportsKit`);
  //   } else {
  //     notify.show("Please login first", "error", 2000);
  //   }
  // };
  render() {
    return (
      <div className="offer mt-5">
        <Container fluid>
          <Row>
            <Col md="8">
              <Fade left>
                <div className="offergrid">
                  <figure className="effect-sadie mt-3">
                    <div className="">
                      <img src={newsaleimg} alt="New Year" />
                      <figcaption>
                        {/* <h2>
                          Thoughtful <span>Goliath</span>
                        </h2> */}
                        <p>New Offer Sale </p>
                        <a href="#"></a>
                      </figcaption>
                    </div>
                  </figure>
                </div>
              </Fade>
            </Col>
            <Col md="4">
              <Fade right>
                <div className="grid">
                  <figure className="effect-goliath">
                    <div className="">
                      <img src={img1} alt="Repair Sports Kit" />
                      <figcaption>
                        <h2>
                          Repair <span>Sports Kit</span>
                        </h2>
                        <p>Repair your existing sports Kits</p>
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push(`/RepaireSportsKit`);
                          }}
                        ></a>
                      </figcaption>
                    </div>
                  </figure>
                </div>
                <div className="grid">
                  <figure className="effect-goliath mt-2">
                    <div className="">
                      <img src={insidecricket} alt="Inside Cricket" />
                      <figcaption>
                        <h2>
                          Inside <span>Cricket</span>
                        </h2>
                        <p>Maharashtra's 1st bilingual sports weekly</p>
                        <a
                          href="http://insidecricket.co.in/#/home"
                          target="_blank"
                        ></a>
                      </figcaption>
                    </div>
                  </figure>
                </div>
              </Fade>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="intro pt-4">
                <Fade top>
                  <h4>About Ubhe Sports</h4>
                </Fade>
                <Fade bottom>
                  <p>
                    One of the top retailers of sporting goods in the vicinity,
                    Ubhe SPORTS situated in Deccan Gymkhana brings to you sports
                    accessories and other equipment from the most trusted names
                    of the industry. They provide a full-line product range in
                    traditional sporting goods store format. Their product mix
                    includes different apparel and accessories, a broad
                    selection of outdoor and athletic equipment for team sports
                    etc. They staff at the store is very courteous and makes
                    sure you purchase exactly what you are looking for.
                  </p>
                  <a href="/About">Know More</a>
                </Fade>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <Fade left>
                <iframe
                 src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7566.7182341986445!2d73.84573048650742!3d18.51266690490745!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c07691f6df49%3A0xeea9958622364851!2sUbhe%20Sports!5e0!3m2!1sen!2sin!4v1681477179269!5m2!1sen!2sin"
                 // src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15138.89527708052!2d73.8577586!3d18.4508447!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xea048d25f2da48f!2sKimaya%20Fintech%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1608879055190!5m2!1sen!2sin"
                  // src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d3783.2351960186525!2d73.83491571436872!3d18.518270774078903!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x3bc2bf41feac6a93%3A0x940f5b065e4f4d46!2sM3%20SPORTS%2C%20872%2C%20Bhandarkar%20Rd%2C%20Deccan%20Gymkhana%2C%20Pune%2C%20Maharashtra%20411004!3m2!1d18.5182657!2d73.8371044!5e0!3m2!1sen!2sin!4v1609923624024!5m2!1sen!2sin"
                  width="100%"
                  height="300"
                  className="mapclass mt-4"
                  frameborder="0"
                  // style="border:0;"
                  // allowfullscreen=""
                  // aria-hidden="false"
                  // tabindex="0"
                ></iframe>
              </Fade>
              <div className="gmap">
                  <Row className="justify-content-center">
                    <Col md="12">
                      <Row className="justify-content-center p-3">
                       <Col md="9" sm="9" xs="9" className="p-0">
                       <div className="gmap-left">
                        <h5>
                          <span className="style1">Ubhe Sports : </span> Bhandarkar Road, Deccan
                        </h5>
                      </div>
                      </Col> 
                       <Col md="3" sm="3" xs="3" className="p-0">
                       <div className="gmap-right">
                        <img src={logogif} alt="Logo" className="img-style"></img>
                      </div>
                       </Col>
                      </Row>
                     
                    
                    </Col>
                    <Col md="3" className=""></Col>
                  </Row>
                </div>
              
            </Col>
            <Col md="6">
              <Fade right>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7566.7182341986445!2d73.84573048650742!3d18.51266690490745!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c07691f6df49%3A0xeea9958622364851!2sUbhe%20Sports!5e0!3m2!1sen!2sin!4v1681477179269!5m2!1sen!2sin"
                  // src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d60531.84436912716!2d73.8500239404112!3d18.51804121547526!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x3bc2c10733c6ffc9%3A0x60b82b7c20ad5fd0!2sM3%20sports%2C%20Amanora%20Park%20Town%2C%20Hadapsar%2C%20Pune%2C%20Maharashtra%20411028!3m2!1d18.5176604!2d73.9329829!5e0!3m2!1sen!2sin!4v1609923485082!5m2!1sen!2sin"
                  width="100%"
                  height="300"
                  className="mapclass mt-4"
                  frameborder="0"
                  // style="border:0;"
                  // allowfullscreen=""
                  // aria-hidden="false"
                  // tabindex="0"
                ></iframe>
                 <div className="gmap">
                  <Row className="justify-content-center">
                    <Col md="12" sm="12" xs="12">
                      <Row className="justify-content-center p-3">
                       <Col md="9" sm="9" xs="9" className="p-0">
                       <div className="gmap-left">
                        <h5>
                          <span  className="style1">Ubhe Sports : </span> Bhandarkar Road, Deccan
                        </h5>
                      </div>
                      </Col> 
                       <Col md="3" sm="3" xs="3" className="p-0">
                       <div className="gmap-right">
                        <img src={logogif} alt="Logo" className="img-style"></img>
                      </div>
                       </Col>
                      </Row>
                     
                    
                    </Col>
                    <Col md="3" className=""></Col>
                  </Row>
                </div>
              </Fade>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
export default Offers;

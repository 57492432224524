import React from "react";
import { Route, Redirect } from "react-router-dom";
import { authenticationService } from "@/services/api_function";
// import { Role } from "@/_helpers";

export const PrivateRoute = ({ component: Component, roles, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const currentUser = authenticationService.currentUserValue;
      console.log("currentUser in PrivateRoute", currentUser);
      if (!currentUser) {
        // not logged in so redirect to login page with the return url
        return (
          <Redirect
            to={{ pathname: "/Login", state: "" }}
            // to={{ pathname: "/Login", state: { from: props.location } }}
          />
        );
      } else {
        return <Component {...props} />;
      }
    }}
  />
);

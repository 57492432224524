import React, { Component } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import { Layout } from "@/Pages/Layout";
import MyCartPg from "@/Pages/Components/MyCartComponent1/MyCartPg";
import CartOverview from "@/Pages/Components/MyCartComponent1/CartOverview";
import EmptyCart from "./EmptyCart";

import { bindActionCreators } from "redux";
import {
  addToCart,
  removeFromCart,
  decrementProductUnit,
  clearCart,
  applyWalletBalance,
} from "@/redux/cart/Action";
import { connect } from "react-redux";
class MyCart extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Layout {...this.props}>
        {this.props.cartReducer &&
        this.props.cartReducer.cart != null &&
        this.props.cartReducer.cart.length > 0 ? (
          <div id="productlist" className="cartproduct-list">
            <div className="cartbread">
              <Container fluid>
                <Breadcrumb className="profile-b-crumb rgst-breadcrumb mb-3 pt-0">
                  <Breadcrumb.Item
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.history.push(`/`);
                    }}
                    style={{ color: "#1776a4" }}
                  >
                    Home
                  </Breadcrumb.Item>

                  <Breadcrumb.Item active>My Cart</Breadcrumb.Item>
                </Breadcrumb>
              </Container>
            </div>
            <Container fluid>
              <div className="mycartproduct">
                <Row>
                  <Col lg="8" md="8">
                    <div className="cart-item-div">
                      <MyCartPg {...this.props} />
                    </div>
                  </Col>
                  <Col lg="4" md="4">
                    <CartOverview {...this.props} />
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
        ) : (
          <EmptyCart {...this.props} />
        )}
      </Layout>
    );
  }
}

const mapStateToProps = ({ cartReducer }) => {
  return { cartReducer };
};

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      addToCart,
      removeFromCart,
      decrementProductUnit,
      clearCart,
      applyWalletBalance,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapActionsToProps)(MyCart);

// export default MyCart;

import React, { Component } from "react";
import LoginOnShippingOrderRepair from "./LoginOnShippingOrderRepair";
import { authenticationService } from "@/services/api_function";
import ContinueAsGuestOrderRepair from "./ContinueAsGuestOrderRepair";
export default class GuestLoginOrder extends Component {
  constructor(props) {
    super(props);
    this.state = { isLogin: false };
  }
  handleLoginShow = (status) => {
    this.setState({ isLogin: status });
  };
  render() {
    const { isLogin } = this.state;
    return (
      <div>
        {authenticationService.currentUserValue == null ? (
          isLogin ? (
            <LoginOnShippingOrderRepair
              {...this.props}
              isCheckoutLogin={true}
              handleLoginShow={this.handleLoginShow.bind(this)}
            />
          ) : (
            <ContinueAsGuestOrderRepair
              {...this.props}
              isCheckoutLogin={true}
              handleLoginShow={this.handleLoginShow.bind(this)}
            />
          )
        ) : (
          <div className="wel-msg">
            <h1>
              Welcome {authenticationService.currentUserValue.firstName}...
              <i class="fa fa-smile-o" aria-hidden="true"></i>{" "}
            </h1>
          </div>
        )}
      </div>
    );
  }
}
